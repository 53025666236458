import { Role } from '../../ts/enums/Role';
import { UserPostModel } from '../../ts/interfaces/models/post/UserPostModel';
import { ModalNewUserType } from './../../ts/interfaces/forms/ModalNewUserType';
import { UserModel } from './../../ts/interfaces/models/UserModel';
import { User } from './../../ts/types/user';

export const parseUsersModelArrayToUserArray = (users: UserModel[]): User[] => {
  const createDate = (newDate: Date) => {
    const date = new Date(newDate);
    const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    return month <= 9 ? `${day}/0${month}/${year}` : `${day}/${month}/${year}`;
  };

  const parsedUsers = users.map(
    ({ id, name, first_name, last_name, email, location_id, department_id, role, createdAt }) => {
      const Role = role as Role;
      return {
        id: id?.toString(),
        createdAt: createdAt ? createDate(createdAt) : createDate(new Date()),
        firstName: first_name,
        lastName: last_name,
        locationId: location_id,
        role: Role,
        departmentId: department_id,
        email,
        name,
      };
    }
  );
  return parsedUsers;
};

export const parseUserModelToUser = (user: UserModel): User => {
  const createDate = (newDate: Date) => {
    const date = new Date(newDate);
    const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    return month <= 9 ? `${day}/0${month}/${year}` : `${day}/${month}/${year}`;
  };

  const { id, name, first_name, last_name, email, location_id, department_id, role, createdAt } = user;
  const Role = role as Role;

  return {
    id: id?.toString(),
    createdAt: createdAt ? createDate(createdAt) : createDate(new Date()),
    firstName: first_name,
    lastName: last_name,
    locationId: location_id,
    role: Role,
    departmentId: department_id,
    email,
    name,
  };
};

export const parseModalNewUserTypeToUserPostModel = ({
  department,
  email,
  firstName,
  lastName,
  location,
  role,
}: ModalNewUserType): UserPostModel => {
  return {
    name: `${firstName} ${lastName}`,
    first_name: firstName,
    last_name: lastName,
    email: email,
    role: role,
    location_id: location,
    department_id: department,
  };
};
