export const columns = ['Candidate', 'Job Position', 'Recruiter', 'Email', 'Status', 'Stage'];
export const rows = [
  {
    candidate: 'John Doe',
    jobPosition: 'Software Engineer',
    recruiter: 'Jane',
    email: 'john.doe@example.com',
    status: 'Pending',
    stage: 'Phone Interview',
  },
  {
    candidate: 'Alice Smith',
    jobPosition: 'Frontend Developer',
    recruiter: 'Bob',
    email: 'alice.smith@example.com',
    status: 'In Progress',
    stage: 'Technical Interview',
  },
  {
    candidate: 'Mike Johnson',
    jobPosition: 'Data Analyst',
    recruiter: 'Emily',
    email: 'mike.johnson@example.com',
    status: 'Accepted',
    stage: 'Offer Negotiation',
  },
  {
    candidate: 'Sarah Williams',
    jobPosition: 'Product Manager',
    recruiter: 'Alex',
    email: 'sarah.williams@example.com',
    status: 'Rejected',
    stage: 'Final Interview',
  },
  {
    candidate: 'Jessica Brown',
    jobPosition: 'UI/UX Designer',
    recruiter: 'Chris',
    email: 'jessica.brown@example.com',
    status: 'In Progress',
    stage: 'Portfolio Review',
  },
  {
    candidate: 'David Wilson',
    jobPosition: 'Backend Developer',
    recruiter: 'Olivia',
    email: 'david.wilson@example.com',
    status: 'Accepted',
    stage: 'Technical Interview',
  },
  {
    candidate: 'Emily Thompson',
    jobPosition: 'Marketing Specialist',
    recruiter: 'Daniel',
    email: 'emily.thompson@example.com',
    status: 'Pending',
    stage: 'Phone Interview',
  },
  {
    candidate: 'Michael Davis',
    jobPosition: 'Data Scientist',
    recruiter: 'Sophia',
    email: 'michael.davis@example.com',
    status: 'Rejected',
    stage: 'Technical Interview',
  },
];
