import CheckIcon from '@mui/icons-material/Check';
import { FormControl, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulModal } from '../../../../components/AzulModal';
import { AzulTextField } from '../../../../components/AzulTextField';
import { getAllDepartmentOptions } from '../../../../store/department/thunks';
import { getAllLocationOptions } from '../../../../store/location/thunks';
import { Spacings } from '../../../../styles/partials/spacings';
import { ModalNewUserProps } from '../../../../ts/interfaces/ModalNewUser';
import { useAppDispatch } from '../../../../utils/hooks/storeHooks';
import { validationSchema } from '../../validators/validationSchema';

export const ModalNewUser = ({
  departmentOptions,
  initialValues,
  isOpen,
  locationOptions,
  roleOptions,
  title,
  onClose,
  onSubmit,
}: ModalNewUserProps) => (
  <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ values, touched, errors, validateForm, setErrors, resetForm, setSubmitting, handleBlur, handleChange }) => {
      const dispatch = useAppDispatch();

      const handleFormSubmit = async () => {
        const errors = await validateForm();
        // If there are validation errors, set them in Formik's state
        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          // If there are no errors, proceed with the form submission
          onSubmit(values, { setSubmitting });
          resetForm();
        }
      };

      useEffect(() => {
        if (isOpen) {
          dispatch(getAllLocationOptions(true));
          dispatch(getAllDepartmentOptions(true));
        }
      }, [isOpen]);
      return (
        <AzulModal isOpen={isOpen} title={title} onClose={onClose}>
          <Form>
            <Grid
              columnSpacing={Spacings.spacing10}
              container
              justifyContent={'space-between'}
              rowSpacing={Spacings.spacing8}
            >
              <Grid container flexDirection='column' item xs={6}>
                <FormControl>
                  <AzulTextField
                    error={Boolean(errors.email)}
                    helper={(touched.email && errors.email) || ''}
                    label='e-mail'
                    name='email'
                    touched={touched.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection='column' item xs={6}>
                <FormControl>
                  <AzulDropdownMenu
                    error={Boolean(errors.role)}
                    helperText={(touched.role && errors.role) || ''}
                    label='Role'
                    name='role'
                    options={roleOptions}
                    touched={touched.role}
                    value={values.role}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection='column' item xs={6}>
                <FormControl>
                  <AzulTextField
                    error={Boolean(errors.firstName)}
                    helper={(touched.firstName && errors.firstName) || ''}
                    label='First name'
                    name='firstName'
                    touched={touched.firstName}
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection={'column'} item xs={6}>
                <FormControl>
                  <AzulTextField
                    error={Boolean(errors.lastName)}
                    helper={(touched.lastName && errors.lastName) || ''}
                    label='Last name'
                    name='lastName'
                    touched={touched.lastName}
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection={'column'} item xs={6}>
                <FormControl>
                  <AzulDropdownMenu
                    error={Boolean(errors.location)}
                    helperText={(touched.location && errors.location) || ''}
                    label='Location'
                    name='location'
                    options={locationOptions}
                    touched={touched.location}
                    value={values.location}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection={'column'} item xs={6}>
                <FormControl>
                  <AzulDropdownMenu
                    error={Boolean(errors.department)}
                    helperText={(touched.department && errors.department) || ''}
                    label='Department'
                    name='department'
                    options={departmentOptions}
                    touched={touched.department}
                    value={values.department}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container item justifyContent='flex-end' mt={Spacings.spacing7} xs={12}>
                <AzulButton
                  endIcon={<CheckIcon />}
                  isSubmit
                  label='Submit'
                  variant='primary'
                  onClick={handleFormSubmit}
                />
              </Grid>
            </Grid>
          </Form>
        </AzulModal>
      );
    }}
  </Formik>
);
