import { createSlice } from '@reduxjs/toolkit';
import { CandidateRow } from '../../modules/Candidates/components/Table/CandidateRow';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Candidate, CandidateColumn, Note } from '../../ts/types/candidate';
import reducers from './reducer';

export interface CandidateState {
  candidate: Candidate;
  candidatePrivateNotes: Note[];
  candidatePublicNotes: Note[];
  candidatePrivateNotesRequestStatus: RequestStatus;
  candidatePublicNotesRequestStatus: RequestStatus;
  candidateRequestStatus: RequestStatus;
  candidates: Candidate[];
  candidatesRequestStatus: RequestStatus;
  firstCandidatesColumn: CandidateColumn[];
  firstCandidatesColumnRequestStatus: RequestStatus;
  secondCandidatesColumn: CandidateColumn[];
  secondCandidatesColumnRequestStatus: RequestStatus;
  thirdCandidatesColumn: CandidateColumn[];
  thirdCandidatesColumnRequestStatus: RequestStatus;
  candidatesTable: CandidateRow[];
  candidatesTableCount: number;
  candidatesTableRequestStatus: RequestStatus;
}

const initialState: CandidateState = {
  candidate: {
    id: '0',
    email: '',
    name: '',
    jobId: '',
    status: CandidateStatus.Pipeline,
    hiringTeam: {
      recruiter: '',
    },
    phoneNo: '',
    seniority: '',
    yearsOfExperience: 0,
    salaryExpectations: '',
    country: '',
    source: '',
    linkedinProfile: '',
    referredBy: '',
    firstContactDate: '',
    stage: '',
    jobPosition: {
      title: '',
      id: '',
      location: '',
      lowerPriceRange: 0,
      upperPriceRange: 0,
      jobType: '',
      jobTime: '',
      skills: [],
      description: '',
      client: '',
    },
  },
  candidatePrivateNotes: [],
  candidatePublicNotes: [],
  candidatePrivateNotesRequestStatus: RequestStatus.Idle,
  candidatePublicNotesRequestStatus: RequestStatus.Idle,
  candidateRequestStatus: RequestStatus.Idle,
  candidates: [],
  candidatesRequestStatus: RequestStatus.Idle,
  firstCandidatesColumn: [],
  firstCandidatesColumnRequestStatus: RequestStatus.Idle,
  secondCandidatesColumn: [],
  secondCandidatesColumnRequestStatus: RequestStatus.Idle,
  thirdCandidatesColumn: [],
  thirdCandidatesColumnRequestStatus: RequestStatus.Idle,
  candidatesTable: [],
  candidatesTableCount: 0,
  candidatesTableRequestStatus: RequestStatus.Idle,
};

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers,
});

// Extract the action creators generated by createSlice
export const {
  setCandidate,
  setCandidatePrivateNotes,
  setCandidatePublicNotes,
  setNewCandidatePrivateNote,
  setNewCandidatePublicNote,
  setCandidatePrivateNotesRequestStatus,
  setCandidatePublicNotesRequestStatus,
  setCandidateRequestStatus,
  setCandidates,
  setNewCandidatesTable,
  setCandidatesRequestStatus,
  setFirstCandidatesColumn,
  setFirstCandidatesColumnRequestStatus,
  setSecondCandidatesColumn,
  setSecondCandidatesColumnRequestStatus,
  setThirdCandidatesColumn,
  setThirdCandidatesColumnRequestStatus,
  setHiringTeam,
  setCandidateStatus,
  setCandidateStage,
  setCandidatesTable,
  setCandidatesTableCount,
  setCandidatesTableRequestStatus,
} = candidateSlice.actions;

export default candidateSlice.reducer;
