import { PayloadAction } from '@reduxjs/toolkit';
import { DepartmentOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setDepartmentOptions = (state: DepartmentOptions, action: PayloadAction<Option[]>): void => {
  state.departmentOptions = action.payload;
};

const setDepartmentOptionsRequestStatus = (state: DepartmentOptions, action: PayloadAction<RequestStatus>): void => {
  state.departmentOptionsRequestStatus = action.payload;
};

const reducers = {
  setDepartmentOptions,
  setDepartmentOptionsRequestStatus,
};

export default reducers;
