import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AzulNavBar } from './components/AzulNavBar';
import { MAIN_ROUTES, PLAYGROUND_ROUTES } from './constants/routes';
import { Candidate } from './modules/Candidate';
import { Candidates } from './modules/Candidates';
import { Home } from './modules/Home';
import { Jobs } from './modules/Jobs';
import { Notifications } from './modules/Notifications/Notifications';
import { Playground } from './modules/Playground';
import { Users } from './modules/Users';
import { store } from './store/store';
import { theme } from './styles/theme/customTheme';

function App() {
  const { HOME, JOBS, CANDIDATES, CANDIDATE, USERS, PLAYGROUND, NOTIFICATIONS } = MAIN_ROUTES;
  const navTabs = [
    {
      id: 1,
      label: 'Home',
      routePath: HOME,
    },
    {
      id: 2,
      label: 'Jobs',
      routePath: JOBS,
    },
    {
      id: 3,
      label: 'Candidates',
      routePath: CANDIDATES,
    },
    {
      id: 4,
      label: 'Users',
      routePath: USERS,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div className='App'>
          <Router>
            <AzulNavBar pageName={'KS Hire'} tabItems={navTabs} />
            <Routes>
              <Route element={<Home />} index />
              <Route element={<Jobs />} path={JOBS} />
              <Route element={<Candidates />} path={CANDIDATES} />
              <Route element={<Candidate />} path={CANDIDATE} />
              <Route element={<Users />} path={USERS} />
              <Route element={<Playground />} path={PLAYGROUND} />
              <Route element={<Playground />} path={PLAYGROUND_ROUTES.EDIT} />
              <Route element={<Notifications />} path={NOTIFICATIONS} />
            </Routes>
          </Router>
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
