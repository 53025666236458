import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Box, CircularProgress, Grid, Pagination, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { Fragment, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import emptyState01 from '../../assets/emptyState01.svg';
import emptyState04 from '../../assets/emptyState04.svg';
import emptyState05 from '../../assets/emptyState05.svg';
import { AzulButton } from '../../components/AzulButton';
import { CandidateCard } from '../../components/AzulCandidateCard';
import { AzulDeleteConfirmationModal } from '../../components/AzulDeleteConfirmationModal';
import { AzulDropdownMenu } from '../../components/AzulDropdownMenu';
import { AzulEmptyState } from '../../components/AzulEmptyState';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulRowsPerPageButton } from '../../components/AzulRowsPerPageButton';
import { AzulSearchBar } from '../../components/AzulSearchBar';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { AzulToastNotification } from '../../components/AzulToastNotification';
import { JOB_POSITION_CARD_OPTIONS } from '../../constants/JobPositionCardOptions';
import { jobStatusOptions, newJobStatusOptions } from '../../constants/jobsOptions';
import { MAIN_ROUTES } from '../../constants/routes';
import { SearchJobPositionParams, jobPositionService } from '../../service/jobPositionService';
import { selectCandidatesRequestStatus } from '../../store/candidate/selectors';
import { getCandidatesByJobId } from '../../store/candidate/thunks';
import { selectClientOptions } from '../../store/client/selector';
import { getAllClientOptions } from '../../store/client/thunks';
import { selectDepartmentOptions } from '../../store/department/selector';
import { getAllDepartmentOptions } from '../../store/department/thunks';
import { selectHiringManagerOptions } from '../../store/hiringManager/selector';
import { getAllHiringManagerOptions } from '../../store/hiringManager/thunks';
import {
  selectJobPositionsRequestStatus,
  selectSelectedJobPosition,
  selectjobPositions,
} from '../../store/jobPosition/selectors';
import { createNewJobPosition, getAllJobPositions, searchJobPositions } from '../../store/jobPosition/thunks';
import { selectJobTimeOptions } from '../../store/jobTime/selector';
import { getAllJobTimeOptions } from '../../store/jobTime/thunks';
import { selectJobTypeOptions } from '../../store/jobType/selector';
import { getAllJobTypeOptions } from '../../store/jobType/thunks';
import { selectLocationOptions } from '../../store/location/selector';
import { getAllLocationOptions } from '../../store/location/thunks';
import { selectManagerOptions } from '../../store/manager/selector';
import { getAllManagerOptions } from '../../store/manager/thunks';
import { selectSalaryPeriodOptions } from '../../store/salaryPeriod/selector';
import { getAllSalaryPeriodOptions } from '../../store/salaryPeriod/thunks';
import { selectSeniorityOptions } from '../../store/seniority/selector';
import { getAllSeniorityOptions } from '../../store/seniority/thunks';
import { selectSkillOptions } from '../../store/skill/selector';
import { getAllSkillOptions } from '../../store/skill/thunks';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { ItemToDelete } from '../../ts/enums/ItemsToDelete';
import { JobPositionStatus as JobPositionStatusEnum } from '../../ts/enums/JobPositionStatus';
import { ModalJobType } from '../../ts/enums/ModalJobType';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { FilterSection } from '../../ts/interfaces/Filter';
import { ModalNewJobType, emptyValues } from '../../ts/interfaces/forms/ModalNewJob';
import { JobPositionStatus, NewJobPosition } from '../../ts/types/JobPosition';
import { NewCandidate } from '../../ts/types/candidate';
import { countFilter } from '../../utils/filters';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { JobDetailsCard } from './components/JobDetailsCard';
import { JobPositionCard } from './components/JobPositionCard';
import { ModalJobFilters } from './components/ModalJobFilters';
import { ModalNewJob } from './components/ModalNewJob/ModalNewJob';
import { filtersDataJson, optionsCandidates, optionsJobs } from './mockData';

export const Jobs = () => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filtersModel, setFiltersModel] = useState<FilterSection>({ ...filtersDataJson });
  const [filterSettings, setFilterSettings] = useState<SearchJobPositionParams>({});
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [isModalNewJobOpen, setIsModalNewJobOpen] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState(ModalJobType.NewJob);
  const [isModalDeleteConfirmationOpen, setIsModalDeleteConfirmationOpen] = useState(false);
  const [jobPositionIdToDelete, setJobPositionIdToDelete] = useState<string | null>(null);
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);

  // Get the `dispatch` function from the store
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get the job positions state from the store
  const jobPositions = useAppSelector(selectjobPositions);
  const selectedJobPosition = useAppSelector(selectSelectedJobPosition);

  // Get the job positions request status from the store
  const jobPositionsRequestStatus = useAppSelector(selectJobPositionsRequestStatus);
  const candidatesRequestStatus = useAppSelector(selectCandidatesRequestStatus);

  // Determine if the data is loading
  const isLoading = jobPositionsRequestStatus === RequestStatus.Loading;
  const isLoadingCandidate = candidatesRequestStatus === RequestStatus.Loading;

  // Filtered states
  const [filteredCandidates, setFilteredCandidates] = useState<NewCandidate[]>();

  // New Job form initial values
  const initialValues = emptyValues;

  // New Job form options
  const clientOptions = useAppSelector(selectClientOptions);
  const departmentOptions = useAppSelector(selectDepartmentOptions);
  const hiringManagerOptions = useAppSelector(selectHiringManagerOptions);
  const jobTimeOptions = useAppSelector(selectJobTimeOptions);
  const jobTypeOptions = useAppSelector(selectJobTypeOptions);
  const locationOptions = useAppSelector(selectLocationOptions);
  const managerOptions = useAppSelector(selectManagerOptions);
  const salaryPeriodOptions = useAppSelector(selectSalaryPeriodOptions);
  const seniorityOptions = useAppSelector(selectSeniorityOptions);
  const skillsOptions = useAppSelector(selectSkillOptions);

  const typeOfStatusOptions = typeOfModal === ModalJobType.NewJob ? newJobStatusOptions : jobStatusOptions;

  // Jobs pagination
  const rowsPerPage = 3;
  const [jobsPage, setJobsPage] = useState(1); // State for the current page of the table
  const jobsPageCount = Math.ceil(jobPositions.length / rowsPerPage); // Total number of pages based on the number of items and rows per page
  const formatFiltersToFilterSettings = (filters: FilterSection): Record<string, string> => {
    return Object.entries(filters).reduce((acc, next) => {
      const [filterKey, value] = next;
      return {
        [filterKey]: value[0].label,
        ...acc,
      };
    }, {});
  };

  const handleChangeJobsPage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setJobsPage(newPage);
  };

  const handleOnClickFiltersButton = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  const handleOnSubmitFilters = (filter: FilterSection) => {
    setFiltersModel(filtersModel);
    const formatted = formatFiltersToFilterSettings(filter);
    setFilterSettings(prevFilters => ({ locations: formatted['Location'] || undefined, ...prevFilters }));
    setFilterSettings(prevFilters => ({ urgent: formatted['Priority'] || undefined, ...prevFilters }));
    setFilterSettings(prevFilters => ({ department: formatted['Department'] || undefined, ...prevFilters }));
    setFilterSettings(prevFilters => ({ seniorities: formatted['Seniority'] || undefined, ...prevFilters }));
    setFilterSettings(prevFilters => ({ job_times: formatted['Time'] || undefined, ...prevFilters }));
    setFilterSettings(prevFilters => ({ job_types: formatted['Type'] || undefined, ...prevFilters }));
    dispatch(
      searchJobPositions({
        locations: formatted['Location'] || undefined,
        urgent: formatted['Priority'] || undefined,
        department: formatted['Department'] || undefined,
        seniorities: formatted['Seniority'] || undefined,
        job_times: formatted['Time'] || undefined,
        job_types: formatted['Type'] || undefined,
        ...filterSettings,
      })
    );

    setFiltersCount(countFilter(filter));
    setIsFiltersModalOpen(false);
  };

  const handleOnResetFilters = () => {
    setFiltersModel({ ...filtersDataJson });
    setFiltersCount(0);
    setIsFiltersModalOpen(false);
  };

  const handleOnClickCandidate = (id: string) => {
    navigate(`${MAIN_ROUTES.CANDIDATES}/candidate/${id}`);
  };

  const handleOnClickJobPosition = (jobId: string) => {
    // Convert array of jobData to single object
    dispatch(getCandidatesByJobId(jobId, true));
  };

  const handleOnClickSearchBar = (value: string) => {
    setFilterSettings(prevFilters => ({ title: value, ...prevFilters }));
    dispatch(searchJobPositions({ title: value, ...filterSettings }));
  };

  const handleOnClickNewJob = () => {
    dispatch(getAllLocationOptions(true));
    dispatch(getAllDepartmentOptions(true));
    dispatch(getAllSeniorityOptions(true));
    dispatch(getAllJobTypeOptions(true));
    dispatch(getAllJobTimeOptions(true));
    dispatch(getAllSkillOptions(true));
    dispatch(getAllHiringManagerOptions(true));
    dispatch(getAllManagerOptions(true));
    dispatch(getAllClientOptions(true));
    dispatch(getAllSalaryPeriodOptions());
    setTypeOfModal(ModalJobType.NewJob);
    setIsModalNewJobOpen(true);
  };

  const handleOnClickEditJob = () => {
    setTypeOfModal(ModalJobType.EditJob);
    setIsModalNewJobOpen(true);
  };

  const handleOnClickDuplicateJob = () => {
    setTypeOfModal(ModalJobType.Duplicate);
    setIsModalNewJobOpen(true);
  };

  const handleOnClickDeleteJob = (jobPositionIdToDelete: string) => {
    setJobPositionIdToDelete(jobPositionIdToDelete);
    setIsModalDeleteConfirmationOpen(true);
  };

  const handleCloseNewJobModal = () => {
    setIsModalNewJobOpen(prevState => !prevState);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setIsModalDeleteConfirmationOpen(prevState => !prevState);
  };

  const handleDeleteJobPosition = (jobPositionIdToDelete: string) => {
    jobPositionService.deleteJobPosition(jobPositionIdToDelete).then(() => {
      dispatch(getAllJobPositions());
    });
  };

  const handleOnSubmitNewJob = (
    values: ModalNewJobType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    handleCloseNewJobModal();
    dispatch(createNewJobPosition(values));
    setToastNotificationOpen(true);
  };

  const handleUpdate = (
    values: ModalNewJobType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    handleCloseNewJobModal();
    alert(JSON.stringify(values));
  };

  const handleDuplicate = (
    values: ModalNewJobType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    handleCloseNewJobModal();
    alert(JSON.stringify(values));
  };

  const handleConfirmDeleteCandidate = () => {
    if (jobPositionIdToDelete) {
      handleDeleteJobPosition(jobPositionIdToDelete);
      setJobPositionIdToDelete(null);
      setIsModalDeleteConfirmationOpen(false);
    }
    console.log(jobPositionIdToDelete);
  };

  // This function is for the options that are displayed when clicking the IconButton of the JobPositionCard
  const getActionByOptionValue = {
    [JOB_POSITION_CARD_OPTIONS[0].value]: handleOnClickEditJob,
    [JOB_POSITION_CARD_OPTIONS[1].value]: handleOnClickDuplicateJob,
    [JOB_POSITION_CARD_OPTIONS[2].value]: handleOnClickDeleteJob,
  };

  const handleOnClickOption = (id: string, job: string, value: string) => {
    const action = getActionByOptionValue[value];
    if (action) {
      action(id);
    }
  };

  // Options to handle types of modal
  const modalHandlers = {
    [ModalJobType.NewJob]: handleOnSubmitNewJob,
    [ModalJobType.EditJob]: handleUpdate,
    [ModalJobType.Duplicate]: handleDuplicate,
  };

  const handleModalRightButton = modalHandlers[typeOfModal as ModalJobType];

  // Function filter for JobPosition
  const handleFilterJobPosition = (status: string) => {
    let finalStatus: JobPositionStatusEnum;
    if (!status) {
      finalStatus = JobPositionStatusEnum.Open;
    } else {
      finalStatus = status as JobPositionStatusEnum;
      setFilterSettings(prevFilters => ({ ...prevFilters, status: finalStatus }));
      dispatch(searchJobPositions({ ...filterSettings, status: finalStatus }));
    }
  };
  // Function filter for Candidate
  const handleFilterCandidate = (event: SelectChangeEvent<string>) => {
    const selectedStatus = event.target.value as string;
    if (selectedStatus === CandidateStatus.All) {
      setFilteredCandidates(selectedJobPosition?.candidates);
    } else {
      const filteredCandidates = selectedJobPosition?.candidates.filter(
        candidate => candidate.status === selectedStatus
      );
      setFilteredCandidates(filteredCandidates);
    }
  };

  const handleToastNotificationOnClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastNotificationOpen(false);
  };

  const dispatchJobPositions = () => {
    try {
      if (!jobPositions.length) {
        dispatch(getAllJobPositions());
      }
      handleFilterJobPosition('Open');
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  // Make the API call to get all job positions
  useEffect(() => {
    dispatchJobPositions();
  }, []);

  useEffect(() => {
    if (jobPositions.length)
      if (!jobPositions.find(jobPosition => jobPosition.selected)) {
        dispatch(getCandidatesByJobId(jobPositions[0]?.id, true));
      }
  }, [jobPositions[0]]);

  useEffect(() => {
    setFilteredCandidates(selectedJobPosition?.candidates);
  }, [selectedJobPosition?.candidates]);

  const getJobPositionDetails = (selectedJobPosition: NewJobPosition) => {
    const {
      data,
      job_type,
      daysOpen,
      job_time,
      department,
      client,
      manager,
      client_job_id,
      hiring_manager,
      description,
      skills,
      title,
      urgent,
    } = selectedJobPosition;
    if (data) {
      const parsedCreatedAt = new Date(data.createdAt);

      return (
        <JobDetailsCard
          data={{
            createdAt: parsedCreatedAt,
            jobType: job_type,
            daysOpen,
            jobTime: job_time,
            department,
            client,
            manager,
            clientJobId: client_job_id,
            hiringManager: hiring_manager,
          }}
          description={description}
          key={client_job_id}
          skills={skills}
          title={title}
          urgent={urgent}
        />
      );
    }
  };

  return (
    <AzulLayout buttonLabel='New job' title='Jobs' onClickButton={handleOnClickNewJob}>
      <Grid
        container
        item
        spacing={{
          xs: Spacings.spacing4,
          sm: Spacings.spacing6,
          md: Spacings.spacing8,
          lg: Spacings.spacing10,
        }}
        xs={12}
      >
        <Grid item xs={4}>
          <AzulSearchBar
            disabled
            id='test'
            options={[]}
            placeholder='Search job positions'
            value='test'
            onClick={handleOnClickSearchBar}
          />
        </Grid>
        <Grid item xs={4}>
          <AzulButton
            disabled={isLoading}
            label={filtersCount > 0 ? `Filters(${filtersCount})` : 'Filters'}
            startIcon={<FilterAltRoundedIcon sx={{ backgroundColor: colorPalette.accentBlue1, background: 'none' }} />}
            variant='secondary'
            onClick={handleOnClickFiltersButton}
          />
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: Spacings.spacing5,
            marginBottom: Spacings.spacing5,
          }}
        >
          <Box alignItems={'center'} display={'flex'} height={Spacings.spacing11} justifyContent={'space-between'}>
            <Typography sx={{ color: colorPalette.paragraph }} variant='h3'>
              Job positions
            </Typography>
            <KeyboardArrowRightRoundedIcon />
          </Box>
          <Box sx={{ height: 48 }}>
            <AzulDropdownMenu
              disabled={isLoading}
              options={optionsJobs}
              value={optionsJobs[0].label}
              onClick={status => handleFilterJobPosition(status)}
            />
          </Box>
        </Box>
        {/* Render loading state or job details card */}
        {isLoading ? (
          <Stack spacing={Spacings.spacing5}>
            <AzulSkeleton height={176} />
            <AzulSkeleton height={176} />
            <AzulSkeleton height={176} />
          </Stack>
        ) : (
          <Stack
            maxHeight={560}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: Spacings.spacing5,
              paddingTop: Spacings.spacing3,
            }}
          >
            {jobPositions.length ? (
              <Fragment>
                {jobPositions.map(({ data, id, title, location, pay, status, urgent }) => (
                  <JobPositionCard
                    data={data}
                    id={id}
                    job={title}
                    key={`${uuidv4()}-${title}`}
                    location={location}
                    pay={pay}
                    selected={selectedJobPosition?.id === id}
                    status={status as JobPositionStatus}
                    urgent={urgent}
                    onClick={() => handleOnClickJobPosition(id)}
                    onClickOption={value => handleOnClickOption(id, title, value)}
                  />
                ))}
                <Pagination
                  count={jobsPageCount}
                  page={jobsPage}
                  sx={{ alignSelf: 'center', marginTop: Spacings.spacing4 }}
                  onChange={(_e, newPage) => handleChangeJobsPage(null, newPage)}
                />
              </Fragment>
            ) : (
              <AzulEmptyState
                description={'At the moment there are not Job positions available.'}
                image={emptyState01}
                marginTop={85}
                title={'No Job positions available'}
              />
            )}
          </Stack>
        )}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Box
          alignItems={'center'}
          display={'flex'}
          height={Spacings.spacing11}
          justifyContent={'space-between'}
          marginBottom={Spacings.spacing4}
        >
          <Typography sx={{ color: colorPalette.paragraph }} variant='h3'>
            Details
          </Typography>
          <KeyboardArrowRightRoundedIcon />
        </Box>
        {/* Render loading state or job positions */}
        {isLoading ? (
          <AzulSkeleton height={680} />
        ) : (
          // Render the job positions
          <Box display='flex' flexDirection='column' maxWidth={'100%'} rowGap={Spacings.spacing8}>
            {selectedJobPosition && jobPositions.length ? (
              getJobPositionDetails(selectedJobPosition)
            ) : (
              <AzulEmptyState
                description={'At the moment there are not details available.'}
                image={emptyState01}
                marginTop={85}
                title={'No details available'}
              />
            )}
          </Box>
        )}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Box
          alignItems={'center'}
          display={'flex'}
          height={Spacings.spacing11}
          justifyContent={'space-between'}
          marginBottom={Spacings.spacing5}
        >
          <Typography sx={{ color: colorPalette.paragraph }} variant='h3'>
            Candidates (
            {isLoadingCandidate ? (
              <CircularProgress
                aria-label='Loading'
                size={'10px'}
                sx={{
                  color: colorPalette.paragraph,
                }}
                thickness={6}
              />
            ) : (
              filteredCandidates?.length ?? 0
            )}
            )
          </Typography>
          <Box sx={{ gap: Spacings.spacing4 }}>
            <AzulRowsPerPageButton
              disabled={isLoadingCandidate || isLoading}
              options={optionsCandidates}
              onClickOption={selectedOption => {
                handleFilterCandidate({
                  target: {
                    value: selectedOption,
                  },
                } as SelectChangeEvent<string>);
              }}
            />
          </Box>
        </Box>
        {/* Render loading state or candidates */}
        {isLoadingCandidate ? (
          <Stack spacing={Spacings.spacing5} sx={{ marginTop: Spacings.spacing4 }}>
            <AzulSkeleton height={96} />
            <AzulSkeleton height={96} />
            <AzulSkeleton height={96} />
          </Stack>
        ) : (
          // Render the job positions
          <Stack spacing={Spacings.spacing4}>
            {!selectedJobPosition?.candidates.length || !filteredCandidates?.length ? (
              <AzulEmptyState
                image={!selectedJobPosition?.candidates.length ? emptyState05 : emptyState04}
                marginTop={85}
                title={
                  !selectedJobPosition?.candidates.length
                    ? 'There are no candidates for this position yet'
                    : 'There are no candidates that match this filter'
                }
              />
            ) : (
              filteredCandidates?.map(({ id, email, name, interviews, status, numOfInterview, referred }) => {
                return (
                  <CandidateCard
                    email={email}
                    key={id}
                    name={name}
                    numOfInterview={numOfInterview}
                    recruiter={interviews ? interviews[0]?.user?.name : 'N/A'}
                    referred={referred}
                    status={status}
                    onClick={() => handleOnClickCandidate(id)}
                  />
                );
              })
            )}
          </Stack>
        )}
      </Grid>
      <ModalJobFilters
        filtersData={filtersModel}
        isOpen={isFiltersModalOpen}
        onClose={handleOnClickFiltersButton}
        onReset={handleOnResetFilters}
        onSubmit={handleOnSubmitFilters}
      />
      <ModalNewJob
        clientOptions={clientOptions}
        departmentOptions={departmentOptions}
        hiringManagerOptions={hiringManagerOptions}
        initialValues={initialValues}
        isOpen={isModalNewJobOpen}
        jobTimeOptions={jobTimeOptions}
        jobTypeOptions={jobTypeOptions}
        locationOptions={locationOptions}
        managerOptions={managerOptions}
        modalType={typeOfModal}
        salaryPeriodOptions={salaryPeriodOptions}
        seniorityOptions={seniorityOptions}
        skillsOptions={skillsOptions}
        statusOptions={typeOfStatusOptions}
        onClose={handleCloseNewJobModal}
        onSubmit={handleModalRightButton}
      />
      <AzulDeleteConfirmationModal
        isOpen={isModalDeleteConfirmationOpen}
        itemToDelete={ItemToDelete.jobPosition}
        onClickDeleteButton={handleConfirmDeleteCandidate}
        onClose={handleCloseDeleteConfirmationModal}
      />
      <AzulToastNotification
        message='Job created sucessfully'
        open={toastNotificationOpen}
        severity='success'
        onClose={handleToastNotificationOnClose}
      />
    </AzulLayout>
  );
};
