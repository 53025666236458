import { setClientOptions, setClientOptionsRequestStatus } from '.';
import { clientService } from '../../service/clientService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllClientOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setClientOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await clientService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setClientOptions(parsedData));

      dispatch(setClientOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setClientOptionsRequestStatus(RequestStatus.Failed));
    }
  };
