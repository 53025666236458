import { ModalNewCandidateType } from '../../ts/interfaces/forms/ModalNewCandidateType';
import { NewCandidateModel } from '../../ts/interfaces/models/CandidateModel';
import { InterviewPostModel } from '../../ts/interfaces/models/post/InterviewPostModel';

export const parseModalNewCandidateTypeToInterviewPostModel = (
  { recruiter, stage, firstContactDate }: ModalNewCandidateType,
  candidateData: NewCandidateModel
): InterviewPostModel => {
  return {
    candidate_id: Number(candidateData.id),
    user_id: recruiter,
    stage: stage,
    interview_date: firstContactDate,
  };
};
