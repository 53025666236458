import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Box, Grid } from '@mui/material';
import React, { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import emptyState05 from '../../assets/emptyState05.svg';
import { AzulButton } from '../../components/AzulButton';
import { AzulDeleteConfirmationModal } from '../../components/AzulDeleteConfirmationModal';
import { AzulEmptyState } from '../../components/AzulEmptyState';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulSearchBar } from '../../components/AzulSearchBar';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { AzulToastNotification } from '../../components/AzulToastNotification';
import { userService } from '../../service/userService';
import { selectDepartmentOptions } from '../../store/department/selector';
import { selectLocationOptions } from '../../store/location/selector';
import { selectUsersTable, selectUsersTableCount, selectUsersTableRequestStatus } from '../../store/user/selectors';
import { createNewUser, getUsersTable } from '../../store/user/thunks';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { ItemToDelete } from '../../ts/enums/ItemsToDelete';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { FilterSection } from '../../ts/interfaces/Filter';
import { ModalNewUserType, emptyValues } from '../../ts/interfaces/forms/ModalNewUserType';
import { countFilter } from '../../utils/filters';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { ModalNewUser } from './components/ModalNewUser';
import { ModalUserFilters } from './components/ModalUserFilters';
import { UsersTable } from './components/Table/UsersTable';
import { filtersDataJson } from './mockData';
import { roleOptions } from './mockData';

export const Users = () => {
  //Handling states for the filter modal
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filters, setFilters] = useState<FilterSection>({ ...filtersDataJson });
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);

  //Getting the data from the store
  const dispatch = useAppDispatch();

  //Getting the users and the users request status
  const users = useAppSelector(selectUsersTable);
  const userRequestStatus = useAppSelector(selectUsersTableRequestStatus);
  const userTableCount = useAppSelector(selectUsersTableCount);
  //Variables for the new User button functionality
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const locationOptions = useAppSelector(selectLocationOptions);
  const departmentOptions = useAppSelector(selectDepartmentOptions);
  const initialValues = emptyValues;

  const handleOnClickNewUser = () => {
    setIsNewUserModalOpen(prevState => !prevState);
  };

  //Loading data
  const isLoading = userRequestStatus === RequestStatus.Loading;

  //State to render columns
  const columns = ['Created', 'First Name', 'Last Name', 'Location', 'Role', 'Department', 'Email'];
  const rows = users;
  const [tablePage, setTablePage] = useState(1); // State for the current page of the table
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for the number of rows per page
  const [filteredRows, setFilteredRows] = useState(rows.slice(0, rowsPerPage)); // State for the filtered rows based on the current page and rows per page

  //Delete actions
  const [isModalDeleteConfirmationOpen, setIsModalDeleteConfirmationOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);

  const handleOnClickFiltersButton = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  const handleSubmit = (
    values: ModalNewUserType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    handleOnClickNewUser();
    dispatch(createNewUser(values));
    setToastNotificationOpen(true);
  };

  const handleOnSubmitFilters = (filter: FilterSection) => {
    setFilters(filters);
    setFiltersCount(countFilter(filter));
    setIsFiltersModalOpen(false);
  };

  const handleOnResetFilters = () => {
    setFilters({ ...filtersDataJson });
    setFiltersCount(0);
    setIsFiltersModalOpen(false);
  };

  const handleOnClickSearchBar = () => {
    console.log('Searchbar clicked');
  };

  const dispatchTableData = () => {
    if (!filteredRows.length) {
      dispatch(getUsersTable());
    }
  };

  const handleChangeTablePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleCloseDeleteConfirmationModal = () => {
    setIsModalDeleteConfirmationOpen(prevState => !prevState);
  };

  const handleDeleteUser = (userIdToDelete: string) => {
    userService.deleteUser(userIdToDelete).then(() => {
      dispatch(getUsersTable());
    });
  };

  const handleConfirmDeleteUser = () => {
    if (userIdToDelete) {
      handleDeleteUser(userIdToDelete);
      setUserIdToDelete(null);
      setIsModalDeleteConfirmationOpen(false);
    }
  };

  const getTableSkeleton = () => {
    return (
      <Box display='flex' flexDirection='column' gap={Spacings.spacing5}>
        <AzulSkeleton height={52} />
        <Box display='flex' flexDirection='column' gap={Spacings.spacing3}>
          {[...Array(10)].map((_, index) => (
            <AzulSkeleton height={70} key={index} />
          ))}
        </Box>
        <Box display='flex' justifyContent='center' marginTop={Spacings.spacing9}>
          <AzulSkeleton height={Spacings.spacing11} width={172} />
        </Box>
      </Box>
    );
  };

  useEffect(dispatchTableData, [filteredRows]);
  const handleToastNotificationOnClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastNotificationOpen(false);
  };

  useEffect(dispatchTableData, []);

  useEffect(() => {
    const startIndex = (tablePage - 1) * rowsPerPage; // Calculate the starting index of the current page
    const endIndex = startIndex + rowsPerPage; // Calculate the ending index of the current page
    const newFilteredRows = rows.slice(startIndex, endIndex); // Extract the rows corresponding to the current page
    setFilteredRows(newFilteredRows); // Update the filtered rows state with the new page data
  }, [rows, rowsPerPage, tablePage]);

  return (
    <AzulLayout buttonLabel='New user' title='Users' onClickButton={handleOnClickNewUser}>
      <Grid
        container
        item
        paddingLeft={28}
        paddingTop={16}
        spacing={{
          xs: Spacings.spacing4,
          sm: Spacings.spacing6,
          md: Spacings.spacing8,
          lg: Spacings.spacing10,
        }}
        xs={12}
      >
        <Grid item xs={5}>
          <AzulSearchBar
            disabled
            id='test'
            options={[]}
            placeholder='Search user'
            value='test'
            onClick={handleOnClickSearchBar}
          />
        </Grid>
        <Grid item xs={4}>
          <AzulButton
            disabled={isLoading}
            label={filtersCount > 0 ? `Filters (${filtersCount})` : 'Filters'}
            startIcon={<FilterAltRoundedIcon sx={{ backgroundColor: colorPalette.accentBlue1, background: 'none' }} />}
            variant='secondary'
            onClick={handleOnClickFiltersButton}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid item marginY={Spacings.spacing4} xs={12}>
        {isLoading ? (
          getTableSkeleton()
        ) : !filteredRows.length ? (
          <AzulEmptyState image={emptyState05} marginTop={85} title={'There are no users registered'} />
        ) : (
          <UsersTable
            columns={columns}
            handleDeleteUser={(userId: string) => {
              setUserIdToDelete(userId);
              setIsModalDeleteConfirmationOpen(true);
            }}
            page={tablePage}
            rows={filteredRows}
            rowsPerPage={rowsPerPage}
            totalItems={userTableCount}
            onChangePage={handleChangeTablePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Grid>
      <ModalUserFilters
        filtersData={filters}
        isOpen={isFiltersModalOpen}
        onClose={handleOnClickFiltersButton}
        onReset={handleOnResetFilters}
        onSubmit={handleOnSubmitFilters}
      />
      <ModalNewUser
        departmentOptions={departmentOptions}
        initialValues={initialValues}
        isOpen={isNewUserModalOpen}
        locationOptions={locationOptions}
        roleOptions={roleOptions}
        title='New User'
        onClose={handleOnClickNewUser}
        onSubmit={handleSubmit}
      />

      <AzulToastNotification
        message='User created sucessfully'
        open={toastNotificationOpen}
        severity='success'
        onClose={handleToastNotificationOnClose}
      />
      <AzulDeleteConfirmationModal
        isOpen={isModalDeleteConfirmationOpen}
        itemToDelete={ItemToDelete.user}
        onClickDeleteButton={handleConfirmDeleteUser}
        onClose={handleCloseDeleteConfirmationModal}
      />
    </AzulLayout>
  );
};
