import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import candidate from './candidate';
import client from './client';
import country from './country';
import department from './department';
import hiringManager from './hiringManager';
import jobPosition from './jobPosition';
import jobTime from './jobTime';
import jobType from './jobType';
import location from './location';
import manager from './manager';
import notification from './notification';
import overview from './overview';
import salaryPeriod from './salaryPeriod';
import seniority from './seniority';
import skill from './skill';
import user from './user';

export const store = configureStore({
  reducer: {
    country,
    jobPosition,
    candidate,
    overview,
    location,
    department,
    notification,
    user,
    seniority,
    jobType,
    jobTime,
    skill,
    client,
    hiringManager,
    manager,
    salaryPeriod,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['errors.error'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
