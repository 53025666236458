import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface ClientOptions {
  clientOptions: Option[];
  clientOptionsRequestStatus: RequestStatus;
}

const initialState: ClientOptions = {
  clientOptions: [],
  clientOptionsRequestStatus: RequestStatus.Idle,
};

export const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers,
});

export const { setClientOptions, setClientOptionsRequestStatus } = clientSlice.actions;

export default clientSlice.reducer;
