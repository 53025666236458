import { PayloadAction } from '@reduxjs/toolkit';
import { HiringManagerOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setHiringManagerOptions = (state: HiringManagerOptions, action: PayloadAction<Option[]>): void => {
  state.hiringManagerOptions = action.payload;
};

const setHiringManagerOptionsRequestStatus = (
  state: HiringManagerOptions,
  action: PayloadAction<RequestStatus>
): void => {
  state.hiringManagerOptionsRequestStatus = action.payload;
};

const reducers = {
  setHiringManagerOptions,
  setHiringManagerOptionsRequestStatus,
};

export default reducers;
