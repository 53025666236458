// Import necessary modules and interfaces
import { NewCandidateModel } from '../ts/interfaces/models/CandidateModel';
import { JobPositionModel } from '../ts/interfaces/models/JobPositionModel';
import { WhereOperator, createWhereOperator } from '../utils/flugzeug';
import { BaseService, ResponseData } from './baseService';

// TODO: VER DONDE PONER ESTE INTERFACE
export interface SearchJobPositionParams {
  title?: string;
  urgent?: string;
  remote?: string;
  job_times?: string;
  job_types?: string;
  locations?: string;
  seniorities?: string;
  department?: string;
  page?: number;
  limit?: number;
  status?: string;
}

// Create a custom JobPositionService class that extends the BaseService class
class JobPositionService extends BaseService<JobPositionModel> {
  // Set the controllerName property to 'Job'
  controllerName = 'Job';

  // Custom async functions like this one are created when the request is significantly different
  // from the methods available in the BaseService class by default.
  async getJobPositionById(id: string): Promise<ResponseData<JobPositionModel>> {
    // Set default headers for the API request
    this.api.defaults.headers = this.getDefaultHeaders();

    // Send a GET request to the server to retrieve the job position data with the specified ID
    // The response is expected to contain data of type ResponseData<JobPositionModel>
    const { data } = await this.api.get<ResponseData<JobPositionModel>>(
      // The URL for the request includes the controller name and the ID, along with related entities to include in the response
      `/${this.controllerName}/${id}`
    );

    // Return the data retrieved from the server
    return data;
  }

  async getJobsPositions(): Promise<ResponseData<JobPositionModel[]>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ResponseData<JobPositionModel[]>>(`/${this.controllerName}/`);

    return data;
  }

  async getAllJobCandidates(jobId: string): Promise<ResponseData<NewCandidateModel[]>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ResponseData<NewCandidateModel[]>>(
      `/${this.controllerName}/${jobId}/candidate/`
    );

    return data;
  }

  async searchJobPosition({
    status,
    title,
    locations,
    urgent,
    department,
    seniorities,
    job_times,
    job_types,
  }: SearchJobPositionParams): Promise<ResponseData<JobPositionModel[]>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const where = createWhereOperator([
      {
        name: 'status',
        op: WhereOperator.EQ,
        value: status ? status.toLocaleLowerCase().replace(' ', '_') : undefined,
      },
      { name: 'title', op: WhereOperator.SUBSTRING, value: title },
    ]);
    const mySearchParams = new URLSearchParams();

    mySearchParams.set('where', JSON.stringify(where));

    if (locations) {
      mySearchParams.set('locations', locations);
    }

    if (urgent) {
      mySearchParams.set('urgent', urgent.toString());
    }

    if (department) {
      mySearchParams.set('department', department);
    }

    if (seniorities) {
      mySearchParams.set('seniority', seniorities);
    }

    if (job_times) {
      mySearchParams.set('job_times', job_times);
    }

    if (job_types) {
      mySearchParams.set('job_types', job_types);
    }

    mySearchParams.set('limit', '10');
    mySearchParams.set('offset', '0');
    const { data } = await this.api.get<ResponseData<JobPositionModel[]>>(
      `/${this.controllerName}/search?${decodeURIComponent(mySearchParams.toString())}`
    );

    return data;
  }

  async deleteJobPosition(jobPositionId: string): Promise<void> {
    try {
      this.api.defaults.headers = this.getDefaultHeaders();
      await this.api.delete(`/${this.controllerName}/${jobPositionId}`);
    } catch (error) {
      console.error('Error deleting job position:', error);
      throw new Error('Failed to delete job position.');
    }
  }
}

// Create an instance of the JobPositionService class
export const jobPositionService = new JobPositionService();
