import { setHiringManagerOptions, setHiringManagerOptionsRequestStatus } from '.';
import { userService } from '../../service/userService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseUserToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllHiringManagerOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setHiringManagerOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await userService.getAllHiringManagers();
      const parsedData = parseUserToOptions(data, false, useIdAsValue);

      dispatch(setHiringManagerOptions(parsedData));

      dispatch(setHiringManagerOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setHiringManagerOptionsRequestStatus(RequestStatus.Failed));
    }
  };
