import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Size } from '../../ts/enums/Size';
import { AzulIconButtonProps } from '../../ts/interfaces/AzulIconButton';

/**
 * Represents an icon button component.
 *
 * @param ariaLabel - The ARIA label for the icon button. (Optional)
 * @param disabled - Indicates whether the button is disabled. (Optional)
 * @param icon - The icon element to display. (Optional)
 * @param size - The size of the icon button. (Optional)
 * @param tooltipTitle - The title of the tooltip. (Optional)
 * @param onClick - Event handler for the button click event.
 */

export const AzulIconButton = forwardRef<HTMLButtonElement, AzulIconButtonProps>(
  ({ ariaLabel = 'icon button', disabled, icon, size = Size.Medium, tooltipTitle, onClick }, ref) => {
    const iconColor = disabled ? colorPalette.disabledGray2 : colorPalette.primaryBlue;

    return (
      <Tooltip arrow placement='top' title={tooltipTitle}>
        <IconButton
          aria-label={ariaLabel}
          disableFocusRipple
          disabled={disabled}
          ref={ref}
          size={size}
          onClick={onClick}
        >
          {icon ?? (
            <CreateRoundedIcon
              fontSize={size}
              sx={{
                color: iconColor,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    );
  }
);
