import { Box, Grid, Pagination, Stack } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import emptyState04 from '../../assets/emptyState04.svg';
import { AzulButton } from '../../components/AzulButton';
import { AzulChip } from '../../components/AzulChip';
import { AzulEmptyState } from '../../components/AzulEmptyState';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { AzulToastNotification } from '../../components/AzulToastNotification';
import { selectNotifications, selectNotificationsRequestStatus } from '../../store/notification/selectors';
import {
  getAllNotificationPage,
  getAllUnreadNotificationPage,
  putAllAsReadNotification,
} from '../../store/notification/thunks';
import { Spacings } from '../../styles/partials/spacings';
import { NotificationFilter } from '../../ts/enums/NotificationFilter';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { Notification } from './components/Notifications/Notification';

export const Notifications = () => {
  const userId = 1;
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const notificationsRequestStatus = useAppSelector(selectNotificationsRequestStatus);
  const notificationsCount = useAppSelector(state => state.notification.notificationsCount);
  const isLoading = notificationsRequestStatus === RequestStatus.Loading;
  const [filter, setFilter] = useState(NotificationFilter.All);
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);

  // Pagination
  const limit = 10;
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(notificationsCount / limit);

  const handleChangePage = async (newPage: number) => {
    setPage(newPage);
    const offset = (newPage - 1) * limit;
    if (filter === NotificationFilter.All) {
      dispatch(getAllNotificationPage(userId, limit, offset));
    } else if (filter === NotificationFilter.Unread) {
      dispatch(getAllUnreadNotificationPage(userId, limit, offset));
    }
  };

  const handleFilterClick = (newFilter: NotificationFilter) => {
    setFilter(newFilter);
    setPage(1);
    if (newFilter === NotificationFilter.All) {
      dispatch(getAllNotificationPage(userId, limit, 0));
    } else if (newFilter === NotificationFilter.Unread) {
      dispatch(getAllUnreadNotificationPage(userId, limit, 0));
    }
  };

  const handleMarkAllAsReadClick = () => {
    setFilter(NotificationFilter.All);
    dispatch(putAllAsReadNotification(userId, limit, 0));
    setToastNotificationOpen(true);
    setPage(1);
  };

  const handleToastNotificationOnClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastNotificationOpen(false);
  };

  useEffect(() => {
    handleFilterClick(NotificationFilter.All);
  }, []);

  return (
    <AzulLayout title='Notifications'>
      <Grid container item justifyContent='center' xs={12}>
        <Box display='flex' flexDirection='column' maxWidth='756px' width='100%'>
          <Box
            alignItems='flex-end'
            display='flex'
            justifyContent='space-between'
            marginBottom={Spacings.spacing11}
            width='100%'
          >
            <Box display='flex' gap={Spacings.spacing8}>
              <AzulChip
                disabled={isLoading}
                label='All'
                selected={filter === NotificationFilter.All}
                onClick={() => handleFilterClick(NotificationFilter.All)}
              />
              <AzulChip
                disabled={isLoading}
                label='Not read'
                selected={filter === NotificationFilter.Unread}
                onClick={() => handleFilterClick(NotificationFilter.Unread)}
              />
            </Box>
            <AzulButton
              disabled={isLoading}
              label='Mark all as read'
              variant='tertiary'
              onClick={handleMarkAllAsReadClick}
            />
          </Box>
          {isLoading ? (
            <Stack spacing={Spacings.spacing2}>
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
              <AzulSkeleton height={Spacings.spacing13} />
            </Stack>
          ) : (
            <Box display='flex' flexDirection='column' height={Spacings.spacing13}>
              {notifications.length ? (
                <Fragment>
                  {notifications.map(({ createdAt, notRead, title }, index) => (
                    <Notification createdAt={createdAt} key={`${index}-${title}`} notRead={notRead} title={title} />
                  ))}
                  <Pagination
                    count={totalPages}
                    page={page}
                    sx={{ alignSelf: 'center', marginTop: Spacings.spacing4 }}
                    onChange={(_e, newPage) => handleChangePage(newPage)}
                  />
                </Fragment>
              ) : (
                <AzulEmptyState
                  image={emptyState04}
                  marginTop={85}
                  title={"We don't have any new notifications for you now"}
                />
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <AzulToastNotification
        message='The notifications has been marked as read successfully!'
        open={toastNotificationOpen}
        severity='success'
        onClose={handleToastNotificationOnClose}
      />
    </AzulLayout>
  );
};
