import { RootState } from '../store';

export const selectUser = (state: RootState) => state.user.user;
export const selectUserRequestStatus = (state: RootState) => state.user.userRequestStatus;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUsersRequestStatus = (state: RootState) => state.user.usersRequestStatus;
export const selectRecruiterOptions = (state: RootState) => state.user.recruiterOptions;
export const selectRecruiterOptionsRequestStatus = (state: RootState) => state.user.recruiterOptionsRequestStatus;
export const selectUsersTable = (state: RootState) => state.user.usersTable;
export const selectUsersTableCount = (state: RootState) => state.user.usersTableCount;
export const selectUsersTableRequestStatus = (state: RootState) => state.user.usersTableRequestStatus;