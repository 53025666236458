import { CandidateFeedbackTemplate } from '../../ts/interfaces/ModalCandidateFeedback';

export const clientInterviewOptions = [
  { label: 'TSU One', value: 'tsu_one' },
  { label: 'Alejandra Suero', value: 'alejandra_suero' },
  { label: 'Daniel Alcudia', value: 'daniel_alcudia' },
];

export const firstContactInterviewOptions = [
  { label: 'Romina Quezada', value: 'romina_quezada' },
  { label: 'Alejandra Suero', value: 'alejandra_suero' },
  { label: 'Daniel Alcudia', value: 'daniel_alcudia' },
];

export const firstTechInterviewOptions = [
  { label: 'Romina Quezada', value: 'romina_quezada' },
  { label: 'Alejandra Suero', value: 'alejandra_suero' },
  { label: 'Daniel Alcudia', value: 'daniel_alcudia' },
];

export const recruiterOptions = [
  { label: 'Romina Quezada', value: 'romina_quezada' },
  { label: 'Alejandra Suero', value: 'alejandra_suero' },
  { label: 'Daniel Alcudia', value: 'daniel_alcudia' },
];

export const secondTechInterviewOptions = [
  { label: 'Alejandra Campoy', value: 'alejandra_campoy' },
  { label: 'Alejandra Suero', value: 'alejandra_suero' },
  { label: 'Daniel Alcudia', value: 'daniel_alcudia' },
];

export const modalTemplate: CandidateFeedbackTemplate[] = [
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'englishLevelRate',
    ratingValue: 0,
    ratingFieldValue: 'englishLevelRate',
    textName: 'ENGLISH LEVEL',
    textNameLower: 'English level',
    textValue: '',
    textFieldValue: 'englishLevelComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'communicationSkillsRate',
    ratingValue: 0,
    ratingFieldValue: 'communicationSkillsRate',
    textName: 'COMMUNICATION SKILLS',
    textNameLower: 'Communication skills',
    textValue: '',
    textFieldValue: 'communicationSkillsComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'culturalFitRate',
    ratingValue: 0,
    ratingFieldValue: 'culturalFitRate',
    textName: 'CULTURAL FIT',
    textNameLower: 'Cultural fit',
    textValue: '',
    textFieldValue: 'culturalFitComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'problemSolvingRate',
    ratingValue: 0,
    ratingFieldValue: 'problemSolvingRate',
    textName: 'PROBLEM SOLVING',
    textNameLower: 'Problem solving',
    textValue: '',
    textFieldValue: 'problemSolvingComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'technicalSkillsRate',
    ratingValue: 0,
    ratingFieldValue: 'technicalSkillsRate',
    textName: 'TECHNICAL SKILLS',
    textNameLower: 'Technical skills',
    textValue: '',
    textFieldValue: 'technicalSkillsComment',
  },
];

export const modalFeedbackData: CandidateFeedbackTemplate[] = [
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'englishLevelRate',
    ratingValue: 3,
    ratingFieldValue: 'englishLevelRate',
    textName: 'ENGLISH LEVEL',
    textNameLower: 'English level',
    textValue: 'Comment for English Level',
    textFieldValue: 'englishLevelComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'communicationSkillsRate',
    ratingValue: 4,
    ratingFieldValue: 'communicationSkillsRate',
    textName: 'COMMUNICATION SKILLS',
    textNameLower: 'Communication skills',
    textValue: 'Comment for Communication Skills',
    textFieldValue: 'communicationSkillsComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'culturalFitRate',
    ratingValue: 0,
    ratingFieldValue: 'culturalFitRate',
    textName: 'CULTURAL FIT',
    textNameLower: 'Cultural fit',
    textValue: 'Comment for Cultural Fit',
    textFieldValue: 'culturalFitComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'problemSolvingRate',
    ratingValue: 5,
    ratingFieldValue: 'problemSolvingRate',
    textName: 'PROBLEM SOLVING',
    textNameLower: 'Problem solving',
    textValue: '',
    textFieldValue: 'problemSolvingComment',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    ratingName: 'technicalSkillsRate',
    ratingValue: 1,
    ratingFieldValue: 'technicalSkillsRate',
    textName: 'TECHNICAL SKILLS',
    textNameLower: 'Technical skills',
    textValue: 'Comment for Technical Skills',
    textFieldValue: 'technicalSkillsComment',
  },
];

export const feedbackFields = [
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    textFieldTitle: 'ENGLISH LEVEL',
    textFieldName: 'englishLevelComment',
    ratingName: 'englishLevelRate',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    textFieldTitle: 'COMMUNICATION SKILLS',
    textFieldName: 'communicationSkillsComment',
    ratingName: 'communicationSkillsRate',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    textFieldTitle: 'CULTURAL FIT',
    textFieldName: 'culturalFitComment',
    ratingName: 'culturalFitRate',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    textFieldTitle: 'PROBLEM SOLVING',
    textFieldName: 'problemSolvingComment',
    ratingName: 'problemSolvingRate',
  },
  {
    interviewDate: 'MM/DD/AAAA',
    interviewRecruiter: 'ALEJANDRA SUERO',
    interviewStep: '1ST INTERVIEW',
    textFieldTitle: 'TECHNICAL SKILLS',
    textFieldName: 'technicalSkillsComment',
    ratingName: 'technicalSkillsRate',
  },
];
