import { setCountryOptions, setCountryOptionsRequestStatus } from '.';
import { countryService } from '../../service/countryService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseCountryToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllCountryOptions = (): AppThunk => async dispatch => {
  try {
    dispatch(setCountryOptionsRequestStatus(RequestStatus.Loading));

    const { data } = await countryService.getAll()

    const parsedData = parseCountryToOptions(data);

    dispatch(setCountryOptions(parsedData));

    dispatch(setCountryOptionsRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setCountryOptionsRequestStatus(RequestStatus.Failed));
  }
};
