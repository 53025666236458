import { setNotifications, setNotificationsCount, setNotificationsRequestStatus } from '.';
import { notificationService } from '../../service/notificationService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseNotificationModelToNotification } from '../../utils/parsers/notification';
import { AppThunk } from '../store';

export const getAllNotificationPage =
  (id: number, limit: number, offset: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setNotificationsRequestStatus(RequestStatus.Loading));

      const { data, count } = await notificationService.getAllNotificationPage(id, limit, offset);

      const parsedData = parseNotificationModelToNotification(data);

      dispatch(setNotifications(parsedData));
      dispatch(setNotificationsCount(count));

      dispatch(setNotificationsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setNotificationsRequestStatus(RequestStatus.Failed));
    }
  };

export const getAllUnreadNotificationPage =
  (id: number, limit: number, offset: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setNotificationsRequestStatus(RequestStatus.Loading));

      const { data, count } = await notificationService.getAllUnreadNotificationPage(id, limit, offset);

      const parsedData = parseNotificationModelToNotification(data);

      dispatch(setNotifications(parsedData));
      dispatch(setNotificationsCount(count));

      dispatch(setNotificationsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setNotificationsRequestStatus(RequestStatus.Failed));
    }
  };

export const putAllAsReadNotification =
  (id: number, limit: number, offset: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setNotificationsRequestStatus(RequestStatus.Loading));

      await notificationService.putAllAsReadNotification(id);

      const { data, count } = await notificationService.getAllNotificationPage(id, limit, offset);

      const parsedData = parseNotificationModelToNotification(data);

      dispatch(setNotifications(parsedData));
      dispatch(setNotificationsCount(count));

      dispatch(setNotificationsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setNotificationsRequestStatus(RequestStatus.Failed));
    }
  };

export const getAllPopoverNotificationPage = (id: number, limit: number, offset: number) => {
  return async () => {
    try {
      const { data, count } = await notificationService.getAllNotificationPage(id, limit, offset);

      const parsedData = parseNotificationModelToNotification(data);

      return { parsedData, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const getAllPopoverUnreadNotificationPage = (id: number, limit: number, offset: number) => {
  return async () => {
    try {
      const { data, count } = await notificationService.getAllUnreadNotificationPage(id, limit, offset);

      const parsedData = parseNotificationModelToNotification(data);

      return { parsedData, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};
