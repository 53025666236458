import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, FormHelperText, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { AzulDropdownMenuProps } from '../../ts/interfaces/AzulDropdownMenu';
import { renderStatusIcon } from '../../utils/renderStatusIcon';

/**
 * Represents a customized dropdown menu component.
 *
 * @param options - The array of options for the dropdown menu.
 * @param disabled - Indicates whether the dropdown menu is disabled. (Optional)
 * @param error - Indicates whether the dropdown menu has an error. (Optional)
 * @param helperText - The helper text for the dropdown menu. (Optional)
 * @param label - The label text for the dropdown menu. (Optional)
 * @param name - The name attribute for the dropdown menu. (Optional)
 * @param touched - Indicates whether the dropdown menu has been touched. (Optional)
 * @param renderStatus - Indicates whether the status icon is showed. (Optional)
 * @param stringLength - Indicates a maximum length for the string display inside the input box. Default value set to 25 (Optional)
 * @param value - The selected value of the dropdown menu. (Optional)
 * @param onBlur - Event handler for the dropdown menu's blur event. (Optional)
 * @param onChange - Event handler for the dropdown menu's change event. (Optional)
 * @param onClick - Event handler for the dropdown menu's click event. (Optional)
 */

export const AzulDropdownMenu = ({
  error,
  helperText,
  name,
  label,
  options,
  disabled,
  renderStatus = false,
  stringLength = 25,
  touched,
  value,
  onBlur,
  onChange,
  onClick,
}: AzulDropdownMenuProps) => {
  const { shadow, rgba } = Hierarchies[Hierarchy.Hierarchy2];
  const [selectedOption, setSelectedOption] = useState<string | undefined>(value);

  const handleOnClick = (value: string) => {
    if (onClick) {
      onClick(value);
    }
  };

  const handleRenderValue = (_: string) => {
    const maxLength = stringLength; // Max length allowed
    const ellipsis = '...';

    if (selectedOption && selectedOption.length > maxLength) {
      const truncatedValue = selectedOption.slice(0, maxLength) + ellipsis;
      return (
        <Box alignItems='center' display='flex'>
          {renderStatus && renderStatusIcon(selectedOption as CandidateStatus)}
          <Typography>{truncatedValue}</Typography>
        </Box>
      );
    }

    return (
      <Box alignItems='center' display='flex'>
        {renderStatus && renderStatusIcon(selectedOption as CandidateStatus)}
        <Typography>{selectedOption}</Typography>
      </Box>
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        disabled={disabled}
        id='dropdownMenuInputLabel'
        sx={{
          '&.Mui-focused': {
            paddingX: Spacings.spacing4,
            marginLeft: '-6px', //solution for uniformly overlay the label (shrunk to the bottom left) over the outline and border of the textfield when focused
          },
          color: disabled ? colorPalette.disabledGray2 : colorPalette.paragraph,
        }}
      >
        {label}
      </InputLabel>
      <Select
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={{
          PaperProps: {
            sx: {
              '&::-webkit-scrollbar': {
                width: Spacings.spacing3,
                height: Spacings.spacing3,
              },
              '&::-webkit-scrollbar-track': {
                marginY: Spacings.spacing3,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: colorPalette.paragraphPlaceholder,
                borderRadius: Spacings.spacing6,
              },
              marginTop: Spacings.spacing3,
              maxHeight: '199px',
              overflowX: 'overlay',
              overflowY: 'overlay',
              '&.Mui-disabled': {
                color: colorPalette.disabledGray2,
                backgroundColor: colorPalette.disabledGray1,
              },
              boxShadow: `${shadow} rgba(${rgba})`,
            },
          },
        }}
        disabled={disabled}
        error={Boolean(touched && error)}
        id='dropdownMenu'
        label={label}
        name={name}
        renderValue={handleRenderValue}
        sx={{
          height: Spacings.spacing12,
          '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: disabled ? colorPalette.disabledGray2 : colorPalette.primaryBlue,
            },
            '& .MuiSelect-icon': {
              color: disabled ? colorPalette.disabledGray2 : colorPalette.paragraph,
            },
          },
        }}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      >
        {options.map(({ label, value: values }, index) => (
          <MenuItem
            disableRipple
            key={`${index}-${label}`}
            sx={{
              alignItems: 'center',
              borderBottom: `${Spacings.spacing1}px solid ${colorPalette.neutralGray2}`,
              display: 'flex',
              height: Spacings.spacing11,
              justifyContent: 'space-between',
              width: '100%',
            }}
            value={values}
            onClick={() => {
              setSelectedOption(label);
              handleOnClick(label);
            }}
          >
            {label}
            {selectedOption === label && <CheckRoundedIcon sx={{ color: colorPalette.primaryBlue }} />}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText
          sx={{
            color: colorPalette.paragraph,
          }}
        >
          <Typography
            color={error && touched ? colorPalette.destructiveRed1 : colorPalette.paragraphBaseline}
            variant='caption'
          >
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};
