import React, { CSSProperties } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulScrollProps } from '../../ts/interfaces/AzulScroll';

/**
 * Represents a custom scrollable area.
 *
 * @param children - The content to be scrolled.
 * @param height - The height of the scrollable area.
 * @param marginTop - The top margin of the scrollable area.
 */

export const AzulScroll = ({ children, height = 188, marginTop = 0 }: AzulScrollProps) => {
  // Custom render function for vertical scrollbar thumb
  const renderThumbVertical = ({ style, ...props }: { style: CSSProperties }) => {
    // Custom style for scrollbar thumb
    const thumbStyle: CSSProperties = {
      ...style,
      backgroundColor: colorPalette.accentBlue2,
      borderRadius: Spacings.spacing4,
      maxHeight: height,
      minHeight: height,
      width: Spacings.spacing4,
    };

    return <div {...props} style={thumbStyle} />;
  };

  // Custom render function for scrollbar view
  const renderView = ({ style, ...props }: { style: CSSProperties }) => {
    const viewStyle: CSSProperties = {
      ...style,
      position: 'inherit',
      marginTop: marginTop, // Reset top spacing
      overflowX: 'hidden',
    };
    return <div {...props} style={viewStyle} />;
  };

  return (
    <Scrollbars
      autoHide
      autoHideDuration={200}
      autoHideTimeout={1000}
      renderThumbVertical={renderThumbVertical}
      renderView={renderView}
    >
      {children}
    </Scrollbars>
  );
};
