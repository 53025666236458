import CheckRounded from '@mui/icons-material/CheckRounded';
import { Box, FormControl, Grid, Rating, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import { AzulButton } from '../../../../../components/AzulButton';
import { AzulDatePicker } from '../../../../../components/AzulDatePicker';
import { AzulModal } from '../../../../../components/AzulModal';
import { AzulTextField } from '../../../../../components/AzulTextField';
import { colorPalette } from '../../../../../styles/partials/colors';
import { Spacings } from '../../../../../styles/partials/spacings';
import { ModalCandidateFeedbackProps } from '../../../../../ts/interfaces/ModalCandidateFeedback';
import { ModalCandidateFeedbackType } from '../../../../../ts/interfaces/forms/ModalCandidateFeedbackType';
import { feedbackFields } from '../../../mockData';
import { validationSchema } from '../../../validators/validationSchema';

export const ModalCandidateFeedback = ({
  initialValues,
  isOpen,
  readOnly,
  editMode,
  title,
  candidateFeedbackTemplate,
  onClose,
  onSubmit,
}: ModalCandidateFeedbackProps) => (
  <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ values, handleChange, setFieldValue }) => {
      return (
        <AzulModal isOpen={isOpen} title={title} onClose={onClose}>
          <Form>
            <Grid container height={'100%'} rowGap={Spacings.spacing8} xs={12}>
              <Grid item marginBottom={Spacings.spacing3} xs={12}>
                <FormControl>
                  {!editMode && !readOnly && (
                    <AzulDatePicker
                      label='Interview date'
                      type='year'
                      onChange={value => setFieldValue('interviewDate', value)}
                    />
                  )}
                  {(editMode || readOnly) && (
                    <Fragment>
                      <Typography marginBottom={Spacings.spacing0} variant='body1'>
                        {candidateFeedbackTemplate[0].interviewRecruiter} - {candidateFeedbackTemplate[0].interviewStep}
                      </Typography>
                      <Typography marginBottom={Spacings.spacing5} variant='body2'>
                        {candidateFeedbackTemplate[0].interviewDate}
                      </Typography>
                    </Fragment>
                  )}
                </FormControl>
              </Grid>
              {feedbackFields.map(({ textFieldTitle, textFieldName, ratingName }, index) => (
                <Grid
                  columnSpacing={Spacings.spacing10}
                  container
                  flexDirection={'row'}
                  height={'100%'}
                  item
                  justifyContent={'space-around'}
                  key={`${textFieldName}-${index}`}
                  marginBottom={Spacings.spacing3}
                  rowGap={Spacings.spacing0}
                  xs={12}
                >
                  <Grid item xs={2}>
                    <Rating
                      name={ratingName}
                      readOnly={readOnly}
                      size='medium'
                      value={values[ratingName as keyof ModalCandidateFeedbackType] as number}
                      onChange={(event, value) => setFieldValue(ratingName, value)}
                    />
                  </Grid>
                  <Grid
                    display={'flex'}
                    flexDirection={'column'}
                    gap={Spacings.spacing0}
                    item
                    marginTop={Spacings.spacing1}
                    width={'100%'}
                    xs={10}
                  >
                    <Typography marginBottom={Spacings.spacing4} marginLeft={Spacings.spacing4} variant='subtitle2'>
                      {textFieldTitle}
                    </Typography>
                    {readOnly && (
                      <Typography marginBottom={Spacings.spacing5} marginLeft={Spacings.spacing4} variant='body1'>
                        {textFieldName}
                      </Typography>
                    )}
                    {!readOnly && (
                      <Box gap={Spacings.spacing0} marginLeft={Spacings.spacing4}>
                        <AzulTextField
                          label='Comment (optional)'
                          name={textFieldName}
                          value={values[textFieldName as keyof ModalCandidateFeedbackType] as string}
                          onChange={handleChange}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ))}
              {!readOnly && (
                <Grid container flexDirection={'row-reverse'} item justifyContent={'space-between'} xs={12}>
                  <AzulButton
                    isSubmit
                    label={editMode ? 'Update' : 'Submit'}
                    startIcon={
                      !editMode && (
                        <CheckRounded sx={{ backgroundColor: colorPalette.accentBlue1, background: 'none' }} />
                      )
                    }
                    variant='primary'
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        </AzulModal>
      );
    }}
  </Formik>
);
