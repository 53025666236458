import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulFabButtonProps } from '../../ts/interfaces/AzulFabButton';

/**
 * Represents a floating action button component.
 *
 * @param disabled - Indicates whether the button is disabled. (Optional)
 * @param icon - A custom icon. (Optional)
 * @param isButton - Indicates whether the component should render as a button. (Optional)
 * @param shortened - The shortened text to display in the non-button mode. (Optional)
 * @param title - The title text for the button or non-button mode. (Optional)
 * @param value - The value text for the non-button mode. (Optional)
 * @param onClick - Event handler for the button click event. (Optional)
 */

export const AzulFabButton = ({
  disabled,
  icon,
  isButton = true,
  shortened,
  title,
  toolTip,
  value,
  onClick,
}: AzulFabButtonProps) => {
  return (
    <Fragment>
      {isButton ? (
        <Box alignItems='center' display='flex' gap={Spacings.spacing4}>
          <Tooltip arrow placement='top' title={toolTip}>
            <IconButton className='fabButton' disabled={disabled} onClick={onClick}>
              {icon ?? (
                <AddRoundedIcon
                  sx={{
                    color: disabled ? colorPalette.disabledGray2 : colorPalette.backgroundWhite,
                  }}
                />
              )}
            </IconButton>
          </Tooltip>

          <Typography color={colorPalette.primaryBlue} variant='button'>
            {title}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: Spacings.spacing4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: Spacings.spacing11,
              width: Spacings.spacing11,
              backgroundColor: colorPalette.secondaryBlue,
              borderRadius: '100%',
              color: colorPalette.neutralGray1,
            }}
          >
            <Typography variant='caption'>{shortened}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: Spacings.spacing11 }}>
            <Typography variant='body2-bold'>{title}</Typography>
            <Typography variant='body2'>{value}</Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
