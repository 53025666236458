// Declare a constant enum for spacingsList (Figma) spacings
const enum Spacings {
  spacing0 = 0,
  spacing1 = 1,
  spacing2 = 2,
  spacing3 = 4,
  spacing4 = 8,
  spacing5 = 12,
  spacing6 = 16,
  spacing7 = 20,
  spacing8 = 24,
  spacing9 = 28,
  spacing10 = 32,
  spacing11 = 40,
  spacing12 = 48,
  spacing13 = 56,
  spacing14 = 64,
  spacing15 = 72,
  spacing16 = 80,
  spacingStep = 167,
}

const spacingScale = 1;

// Export the spacingsList enum for use in other files
export { Spacings, spacingScale };
