import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Box, Grid } from '@mui/material';
import React, { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import emptyState01 from '../../assets/emptyState01.svg';
import { AzulButton } from '../../components/AzulButton';
import { AzulCheckbox } from '../../components/AzulCheckbox';
import { AzulDeleteConfirmationModal } from '../../components/AzulDeleteConfirmationModal';
import { AzulEmptyState } from '../../components/AzulEmptyState';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulSearchBar } from '../../components/AzulSearchBar';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { AzulToastNotification } from '../../components/AzulToastNotification';
import { newCandidateService } from '../../service/newCandidateService';
import {
  selectCandidatesTable,
  selectCandidatesTableCount,
  selectCandidatesTableRequestStatus,
} from '../../store/candidate/selectors';
import { createNewCandidate } from '../../store/candidate/thunks';
import { getCandidatesTable } from '../../store/candidate/thunks';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { ItemToDelete } from '../../ts/enums/ItemsToDelete';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { FilterSection } from '../../ts/interfaces/Filter';
import { ModalNewCandidateType, emptyValues } from '../../ts/interfaces/forms/ModalNewCandidateType';
import { countFilter } from '../../utils/filters';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { ModalCandidateFilters } from './components/ModalCandidateFilters';
import { ModalNewCandidate } from './components/ModalNewCandidate';
import { CandidatesTable } from './components/Table/CandidateTable';
import { filtersDataJson } from './mockData';

export const Candidates = () => {
  //Getting the data from the store
  const dispatch = useAppDispatch();

  //Getting the users and the users request status
  const candidatesTable = useAppSelector(selectCandidatesTable);
  const candidatesTableCount = useAppSelector(selectCandidatesTableCount);
  const candidatesTableRequestStatus = useAppSelector(selectCandidatesTableRequestStatus);

  //State to render columns
  const rows = candidatesTable;
  const columns = ['Candidate', 'Job Position', 'Recruiter', 'Source', 'Email', 'Status', 'Stage'];
  const [tablePage, setTablePage] = useState(1); // State for the current page of the table
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for the number of rows per page
  const [filteredRows, setFilteredRows] = useState(rows.slice(0, rowsPerPage)); // State for the filtered rows based on the current page and rows per page
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);

  //State to render the loading stage
  const isLoading = candidatesTableRequestStatus === RequestStatus.Loading;

  // Modals
  const initialValues = emptyValues;
  const [isNewCandidateModalOpen, setIsNewCandidateModalOpen] = useState(false);
  const [isModalDeleteConfirmationOpen, setIsModalDeleteConfirmationOpen] = useState(false);
  const [candidateIdToDelete, setCandidateIdToDelete] = useState<string | null>(null);

  //Handling states for the filter modal
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filters, setFilters] = useState<FilterSection>({ ...filtersDataJson });
  const [filtersCount, setFiltersCount] = useState<number>(0);

  const handleOnSubmitFilters = (filter: FilterSection) => {
    setFilters(filters);
    setFiltersCount(countFilter(filter));
    setIsFiltersModalOpen(false);
  };

  const handleOnResetFilters = () => {
    setFilters({ ...filtersDataJson });
    setFiltersCount(0);
    setIsFiltersModalOpen(false);
  };

  //Loading data

  const handleOnClickNewCandidate = () => {
    setIsNewCandidateModalOpen(() => !isNewCandidateModalOpen);
  };

  const handleOnClickSearchBar = () => {
    console.log('Searchbar clicked');
  };

  const handleCheckboxChange = () => {
    console.log('Checkbox clicked');
  };

  const handleOnSubmitNewCandidate = (
    values: ModalNewCandidateType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    handleOnClickNewCandidate();
    dispatch(createNewCandidate(values));
    setToastNotificationOpen(true);
  };

  const dispatchTableData = () => {
    if (!filteredRows.length) {
      dispatch(getCandidatesTable());
    }
  };

  const handleChangeTablePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setTablePage(newPage);
  };

  const handleOnClickFiltersButton = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleDeleteCandidate = (candidateIdToDelete: string) => {
    newCandidateService.deleteCandidate(candidateIdToDelete).then(() => {
      dispatch(getCandidatesTable());
    });
  };

  const handleCloseDeleteConfirmationModal = () => {
    setIsModalDeleteConfirmationOpen(prevState => !prevState);
  };

  const handleConfirmDeleteCandidate = () => {
    if (candidateIdToDelete) {
      handleDeleteCandidate(candidateIdToDelete);
      setCandidateIdToDelete(null);
      setIsModalDeleteConfirmationOpen(false);
    }
  };

  const getTableSkeleton = () => {
    const skeletonLength = Math.min(10, rowsPerPage);
    return (
      <Box display='flex' flexDirection='column' gap={Spacings.spacing5}>
        <AzulSkeleton height={52} />
        <Box display='flex' flexDirection='column' gap={Spacings.spacing3}>
          {[...Array(skeletonLength)].map((_, index) => (
            <AzulSkeleton height={70} key={`skeleton-${index}`} />
          ))}
        </Box>
        <Box display='flex' justifyContent='center' marginTop={Spacings.spacing9}>
          <AzulSkeleton height={Spacings.spacing11} width={172} />
        </Box>
      </Box>
    );
  };

  const handleToastNotificationOnClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastNotificationOpen(false);
  };

  useEffect(dispatchTableData, [filteredRows]);

  useEffect(() => {
    const startIndex = (tablePage - 1) * rowsPerPage; // Calculate the starting index of the current page
    const endIndex = startIndex + rowsPerPage; // Calculate the ending index of the current page
    const newFilteredRows = rows.slice(startIndex, endIndex); // Extract the rows corresponding to the current page
    setFilteredRows(newFilteredRows); // Update the filtered rows state with the new page data
  }, [rows, rowsPerPage, tablePage]);

  return (
    <AzulLayout buttonLabel='New candidate' title='Candidates' onClickButton={handleOnClickNewCandidate}>
      <Grid
        container
        item
        paddingLeft={28}
        paddingTop={16}
        spacing={{
          xs: Spacings.spacing4,
          sm: Spacings.spacing6,
          md: Spacings.spacing8,
          lg: Spacings.spacing10,
        }}
        xs={12}
      >
        <Grid item xs={5}>
          <AzulSearchBar
            disabled
            id='test'
            options={[]}
            placeholder='Search candidate'
            value='test'
            onClick={() => handleOnClickSearchBar}
          />
        </Grid>
        <Grid alignItems='flex-start' container gap={Spacings.spacing4} item xs={4}>
          <AzulButton
            disabled={isLoading}
            label={filtersCount > 0 ? `Filters (${filtersCount})` : 'Filters'}
            startIcon={<FilterAltRoundedIcon sx={{ backgroundColor: colorPalette.accentBlue1, background: 'none' }} />}
            variant='secondary'
            onClick={handleOnClickFiltersButton}
          />
          <AzulCheckbox disabled={isLoading} label='My candidates' onChange={handleCheckboxChange} />
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid item marginY={Spacings.spacing4} xs={12}>
        {isLoading ? (
          getTableSkeleton()
        ) : !filteredRows.length ? (
          <AzulEmptyState image={emptyState01} marginTop={85} title={'There are no candidates registered'} />
        ) : (
          <CandidatesTable
            columns={columns}
            handleDeleteCandidate={(candidateId: string) => {
              setCandidateIdToDelete(candidateId);
              setIsModalDeleteConfirmationOpen(true);
            }}
            page={tablePage}
            rows={filteredRows}
            rowsPerPage={rowsPerPage}
            totalItems={candidatesTableCount}
            onChangePage={handleChangeTablePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Grid>
      <ModalNewCandidate
        initialValues={initialValues}
        isOpen={isNewCandidateModalOpen}
        title={'New candidate'}
        onClose={handleOnClickNewCandidate}
        onSubmit={handleOnSubmitNewCandidate}
      />
      <ModalCandidateFilters
        filtersData={filters}
        isOpen={isFiltersModalOpen}
        onClose={handleOnClickFiltersButton}
        onReset={handleOnResetFilters}
        onSubmit={handleOnSubmitFilters}
      />
      <AzulToastNotification
        message='Candidate created sucessfully'
        open={toastNotificationOpen}
        severity='success'
        onClose={handleToastNotificationOnClose}
      />
      <AzulDeleteConfirmationModal
        isOpen={isModalDeleteConfirmationOpen}
        itemToDelete={ItemToDelete.candidate}
        onClickDeleteButton={handleConfirmDeleteCandidate}
        onClose={handleCloseDeleteConfirmationModal}
      />
    </AzulLayout>
  );
};
