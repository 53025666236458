import { FormControl, Grid } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent } from 'react';
import { FocusEventHandler } from 'react';
import { AzulDatePicker } from '../../../../components/AzulDatePicker';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulTextField } from '../../../../components/AzulTextField';
import { stageOptions, statusOptions } from '../../../../constants/candidateOptions';
import { Spacings } from '../../../../styles/partials/spacings';
import { CandidateSource } from '../../../../ts/enums/CandidateSource';
import { Option } from '../../../../ts/interfaces/AzulOption';
import { ModalNewCandidateType } from '../../../../ts/interfaces/forms/ModalNewCandidateType';
import { enumToOption } from '../../../../utils/parsers/option/enumToOption';

interface ModalNewCandidateSecondPartProps {
  errors: FormikErrors<ModalNewCandidateType>;
  jobPositionOptions: Option[];
  recruiterOptions: Option[];
  touched: FormikTouched<ModalNewCandidateType>;
  values: ModalNewCandidateType;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: {
    (event: ChangeEvent<HTMLInputElement>): void;
    <T = string | ChangeEvent<HTMLInputElement>>(field: T): T extends ChangeEvent<HTMLInputElement>
      ? void
      : (event: string | ChangeEvent<HTMLInputElement>) => void;
  };
  setFieldValue: (field: string, value: NonNullable<unknown> | null, shouldValidate?: boolean | undefined) => void;
}

export const ModalNewCandidateSecondPart = ({
  errors,
  jobPositionOptions,
  recruiterOptions,
  touched,
  values,
  onBlur,
  onChange,
  setFieldValue,
}: ModalNewCandidateSecondPartProps) => {
  const sourceOptions = enumToOption(CandidateSource);

  return (
    <Grid columnSpacing={Spacings.spacing10} container justifyContent={'space-between'} rowSpacing={Spacings.spacing8}>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.jobPosition)}
            helperText={(touched.jobPosition && errors.jobPosition) || ''}
            label='Job position'
            name='jobPosition'
            options={jobPositionOptions}
            touched={touched.jobPosition}
            value={values.jobPosition}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.recruiter)}
            helperText={(touched.recruiter && errors.recruiter) || ''}
            label='Recruiter'
            name='recruiter'
            options={recruiterOptions}
            touched={touched.recruiter}
            value={values.recruiter}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.status)}
            helperText={(touched.status && errors.status) || ''}
            label='Status'
            name='status'
            options={statusOptions}
            touched={touched.status}
            value={values.status}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.stage)}
            helperText={(touched.stage && errors.stage) || ''}
            label='Stage'
            name='stage'
            options={stageOptions}
            touched={touched.stage}
            value={values.stage}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            label='Source (optional)'
            name='source'
            options={sourceOptions}
            value={values.source}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            label='Specify source (optional)'
            name='specifySource'
            value={values.specifySource}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDatePicker
            label='First contact date (optional)'
            type='year'
            onChange={value => setFieldValue('firstContactDate', value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
