import { FilterSection } from '../../ts/interfaces/Filter';

export const options = [
  { label: 'Open', value: 'open' },
  { label: 'On hold', value: 'on_hold' },
  { label: 'Closed', value: 'closed' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Contacted', value: 'Contacted' },
  { label: 'No Response', value: 'No Response' },
  { label: 'Active', value: 'Active' },
  { label: 'Pipeline', value: 'Pipeline' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Declined', value: 'Declined' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Withdrew', value: 'Withdrew' },
  { label: 'Hired', value: 'Hired' },
  { label: 'Contacted', value: 'Contacted' },
];

export const optionsJobs = [
  { label: 'Open', value: 'open' },
  { label: 'On hold', value: 'on_hold' },
  { label: 'Closed', value: 'closed' },
  { label: 'Canceled', value: 'canceled' },
];

export const optionsCandidates = [
  { value: 'All', label: 'All' },
  { value: 'Contacted', label: 'Contacted' },
  { value: 'No Response', label: 'No Response' },
  { value: 'Active', label: 'Active' },
  { value: 'Pipeline', label: 'Pipeline' },
  { value: 'Hold', label: 'Hold' },
  { value: 'Declined', label: 'Declined' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Withdrew', label: 'Withdrew' },
  { value: 'Hired', label: 'Hired' },
  { value: 'Contacted', label: 'Contacted' },
];

export const filtersDataJson: FilterSection = {
  Priority: [{ label: 'Urgent', checked: false }],
  Location: [
    { label: 'USA', checked: false },
    { label: 'MX', checked: false },
    { label: 'IN', checked: false },
    { label: 'DR', checked: false },
    { label: 'Remote', checked: false },
  ],
  Department: [
    { label: 'HR', checked: false },
    { label: 'KSQU', checked: false },
    { label: 'Marketing', checked: false },
    { label: 'Operation', checked: false },
    { label: 'PM', checked: false },
    { label: 'Technology', checked: false },
    { label: 'UX', checked: false },
  ],
  Seniority: [
    { label: 'Lead', checked: false },
    { label: 'Senior', checked: false },
    { label: 'Mid', checked: false },
    { label: 'Junior', checked: false },
  ],
  Type: [
    { label: 'Permanent', checked: false },
    { label: 'Temporal', checked: false },
  ],
  Time: [
    { label: 'Full time', checked: false },
    { label: 'Part time', checked: false },
  ],
};
