import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulSkeletonProps } from '../../ts/interfaces/AzulSkeleton';

/**
 * Represents a skeleton component used for loading placeholders.
 *
 * @param height - The height of the skeleton.
 * @param variant - The variant of the skeleton (text or rounded).
 * @param width - The width of the skeleton.
 */

export const AzulSkeleton: FC<AzulSkeletonProps> = ({
  height = Spacings.spacing15,
  variant = 'rounded',
  width,
}: AzulSkeletonProps) => {
  return <Skeleton color={colorPalette.disabledGray1} height={height} variant={variant} width={width ?? '100%'} />;
};

export default AzulSkeleton;
