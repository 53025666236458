import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { JobPositionState } from '.';
import { jobPositionService } from '../../service/jobPositionService';
import { parseJobPositionModelToJobPosition } from '../../utils/parsers/jobPosition';

export const getJobsPositions = createAsyncThunk('jobsPositions/getJobsPositions', async () => {
  const { data } = await jobPositionService.getJobsPositions();
  const parsedData = parseJobPositionModelToJobPosition(data);
  return parsedData;
});

export const jobPositionReducer = (builder: ActionReducerMapBuilder<JobPositionState>) => {
  builder
    .addCase(getJobsPositions.pending, () => {
      // Pending
    })
    .addCase(getJobsPositions.fulfilled, (state, action) => {
      state.jobPositions = action.payload;
    })
    .addCase(getJobsPositions.rejected, () => {
      // Reject
    });
};

export const extraReducers = (builder: ActionReducerMapBuilder<JobPositionState>) => {
  jobPositionReducer(builder);
};