export type ModalCandidateFeedbackType = {
  communicationSkillsComment: string;
  communicationSkillsRate: number;
  culturalFitComment: string;
  culturalFitRate: number;
  englishLevelComment: string;
  englishLevelRate: number;
  problemSolvingComment: string;
  problemSolvingRate: number;
  technicalSkillsComment: string;
  technicalSkillsRate: number;
  interviewDate?: string;
  interviewRecruiter?: string;
  interviewStep?: string;
};

export type ModalCandidateFeedbackKeyTypes =
  | 'communicationSkillsComment'
  | 'communicationSkillsRate'
  | 'culturalFitComment'
  | 'culturalFitRate'
  | 'englishLevelComment'
  | 'englishLevelRate'
  | 'interviewDate'
  | 'interviewRecruiter'
  | 'interviewStep'
  | 'problemSolvingComment'
  | 'problemSolvingRate'
  | 'technicalSkillsComment'
  | 'technicalSkillsRate';

export const emptyValues: ModalCandidateFeedbackType = {
  communicationSkillsComment: '',
  communicationSkillsRate: 0,
  culturalFitComment: '',
  culturalFitRate: 0,
  englishLevelComment: '',
  englishLevelRate: 0,
  interviewDate: '',
  interviewRecruiter: '',
  interviewStep: '',
  problemSolvingComment: '',
  problemSolvingRate: 0,
  technicalSkillsComment: '',
  technicalSkillsRate: 0,
};
