import { Filter, FilterSection } from '../ts/interfaces/Filter';

export function addFilter(filterObject: FilterSection, category: string, filterToAdd: Filter) {
  if (filterObject[category]) {
    filterObject[category].push(filterToAdd);
  } else {
    filterObject[category] = [filterToAdd];
  }
}

export function removeFilter(filterObject: FilterSection, category: string, indexToRemove: number) {
  if (filterObject[category] && Array.isArray(filterObject[category])) {
    filterObject[category].splice(indexToRemove, 1);
  }
}

export function updateFilter(filterObject: FilterSection, category: string, filterToUpdate: Filter): FilterSection {
  if (filterToUpdate.checked) {
    addFilter(filterObject, category, filterToUpdate);
  } else {
    const index = filterObject[category]?.findIndex(item => item.label === filterToUpdate.label);
    if (index !== undefined && index >= 0) {
      removeFilter(filterObject, category, index);
    }
  }
  return filterObject;
}

export function countFilter(filterObject: FilterSection): number {
  let count = 0;

  for (const key in filterObject) {
    const items = filterObject[key];

    if (Array.isArray(items)) {
      count += items.reduce((total, item) => total + (item.checked ? 1 : 0), 0);
    } else if (items && (items as Filter).checked) {
      count++;
    }
  }
  return count;
}
