import { JobPositionStatus, NewJobPositionStatus } from '../ts/enums/JobPositionStatus';
import { Option } from '../ts/interfaces/AzulOption';

export const newJobStatusOptions: Option[] = Object.values(NewJobPositionStatus).map(value => {
  const optionValue = value
    .replace(/\s+/g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase();
  return { value: optionValue, label: value };
});

export const jobStatusOptions: Option[] = Object.values(JobPositionStatus).map(value => {
  const optionValue = value
    .replace(/\s+/g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase();
  return { value: optionValue, label: value };
});
