import { Box, Card, Typography } from '@mui/material';
import React, { KeyboardEvent } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { CandidateCardProps } from '../../ts/interfaces/AzulCandidateCard';
import { renderStatusIcon } from '../../utils/renderStatusIcon';

/**
 * Represents a candidate card component.
 *
 * @param name - The name of the candidate.
 * @param recruiter - The name of the recruiter associated with the candidate.
 * @param email - The email address of the candidate. (Optional)
 * @param job - The job title of the candidate. (Optional)
 * @param numOfInterview - The number of interviews the candidate has undergone. (Optional)
 * @param referred - Indicates whether the candidate was referred. (Optional)
 * @param selected - Indicates whether the card is selected. (Optional)
 * @param status - The status of the candidate. (Optional)
 * @param onClick - Event handler for the card's click event. (Optional)
 */

export const CandidateCard = ({
  email,
  job,
  name,
  numOfInterview,
  recruiter,
  selected = false,
  status,
  referred = false,
  onClick,
}: CandidateCardProps) => {
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];

  const numberOfInterview = (interview: number) => {
    const interviews: { [key: number]: string } = {
      1: '1st interview',
      2: '2nd interview',
      3: '3rd interview',
    };

    return interviews[interview];
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick && onClick();
    }
  };

  return (
    <Card
      aria-label={`Candidate card: ${name}`}
      elevation={0}
      sx={{
        maxWidth: '100%',
        backgroundColor: selected ? colorPalette.accentBlue1 : colorPalette.backgroundWhite,
        overflow: 'unset',
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
        paddingX: Spacings.spacing8,
        paddingY: Spacings.spacing5,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: colorPalette.accentBlue1,
        },
        '&:focus': {
          outline: `${Spacings.spacing3}px solid ${colorPalette.focusPurple}`,
          backgroundColor: selected ? colorPalette.primaryHoverBlue1 : colorPalette.accentBlue1,
          offset: Spacings.spacing3,
        },
        cursor: 'pointer',
      }}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleOnKeyDown}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1-medium'>{name.toUpperCase()}</Typography>
        {referred && (
          <Box
            sx={{
              backgroundColor: colorPalette.accentPurple,
              borderRadius: `${Spacings.spacing3}px`,
              height: Spacings.spacing8,
              width: '70px',
            }}
          >
            <Typography
              sx={{
                color: colorPalette.backgroundWhite,
                paddingX: Spacings.spacing4,
                paddingY: Spacings.spacing3,
                textAlign: 'center',
              }}
              variant='caption-bold'
            >
              Referred
            </Typography>
          </Box>
        )}
      </Box>
      {job && <Typography variant='body2'>{job}</Typography>}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body2'>Recruiter: {recruiter}</Typography>
        <Typography
          sx={{
            textAlign: 'justify',
          }}
          variant='body2'
        >
          {status && renderStatusIcon(status)}
          {status}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {email && <Typography variant='body2'>{email}</Typography>}
        {numOfInterview && (
          <Typography
            sx={{
              color: colorPalette.accentBlue2,
            }}
            variant='caption'
          >
            {numberOfInterview(numOfInterview)}
          </Typography>
        )}
      </Box>
    </Card>
  );
};
