import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface SenioritiesOptions {
  seniorityOptions: Option[];
  seniorityOptionsRequestStatus: RequestStatus;
}

const initialState: SenioritiesOptions = {
  seniorityOptions: [],
  seniorityOptionsRequestStatus: RequestStatus.Idle,
};

export const senioritiesSlice = createSlice({
  name: 'seniorities',
  initialState,
  reducers,
});

export const { setSeniorityOptions, setSeniorityOptionsRequestStatus } = senioritiesSlice.actions;

export default senioritiesSlice.reducer;
