import { PayloadAction } from '@reduxjs/toolkit';
import { SkillOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setSkillOptions = (state: SkillOptions, action: PayloadAction<Option[]>): void => {
  state.skillOptions = action.payload;
};

const setSkillOptionsRequestStatus = (state: SkillOptions, action: PayloadAction<RequestStatus>): void => {
  state.skillOptionsRequestStatus = action.payload;
};

const reducers = {
  setSkillOptions,
  setSkillOptionsRequestStatus,
};

export default reducers;
