import { Option } from '../../../ts/interfaces/AzulOption';

export const enumToOption = <T extends Record<string, string>>(enumObject: T): Option[] => {
  const options: Option[] = [];
  for (const key in enumObject) {
    if (Object.prototype.hasOwnProperty.call(enumObject, key)) {
      const label = key;
      const value = enumObject[key];
      options.push({ label, value });
    }
  }
  return options;
};
