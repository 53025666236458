import CheckRoundedIcon from '@mui/icons-material/Check';
import ClearRoundedIcon from '@mui/icons-material/Clear';
import { Chip } from '@mui/material';
import React, { ReactElement } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { ChipVariants } from '../../ts/enums/ChipVariants';
import { AzulChipProps } from '../../ts/interfaces/AzulChip';
import { AzulIconButton } from '../AzulIconButton';

/**
 * Represents a customized chip component.
 *
 * @param label - The label text for the chip.
 * @param disabled - Indicates whether the chip is disabled. (Optional)
 * @param selected - Indicates whether the chip is selected. (Optional)
 * @param onClick - Event handler for the chip's click event. (Optional)
 */

export const AzulChip = ({ disabled, label, removable, selected, onClick, onRemove }: AzulChipProps) => {
  const getIcon = (selected?: boolean, removable?: boolean): ReactElement | null => {
    if (selected) return <CheckRoundedIcon />;
    if (removable) {
      return (
        <AzulIconButton
          ariaLabel={`Remove ${label}`}
          icon={<ClearRoundedIcon sx={{ color: colorPalette.primaryBlue }} />}
          onClick={onRemove}
        />
      );
    }
    return null;
  };

  return (
    <Chip
      // TODO: refactor these styles
      className={`AzulMuiChip${selected ? 'Selected' : 'Unselected'}${disabled ? 'Disabled' : ''}${
        removable ? 'BoldText' : ''
      }`}
      disabled={disabled}
      icon={getIcon(selected, removable) as ReactElement}
      label={label}
      size='small'
      variant={selected ? ChipVariants.Filled : ChipVariants.Outlined}
      onClick={onClick}
    />
  );
};
