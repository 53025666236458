import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { JobSummaryCardProps } from '../../../../ts/interfaces/JobSummaryCard';
import formatNumberAbbreviated from '../../../../utils/formatNumberAbbreviated';

export const JobSummaryCard = ({ label, value }: JobSummaryCardProps) => {
  const optionsHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxHeight: 52,
        maxWidth: '100%',
        borderRadius: Spacings.spacing1,
        backgroundColor: colorPalette.accentBlue1,
        filter: `drop-shadow(${optionsHierarchy.shadow} rgba(${optionsHierarchy.rgba}))`,
        paddingY: '10px',
        paddingX: Spacings.spacing8,
      }}
    >
      <CardContent
        sx={{
          padding: Spacings.spacing0,
          display: 'flex',
          alignItems: 'center',
          gap: Spacings.spacing1,
          '&:last-child': {
            padding: Spacings.spacing0,
          },
        }}
      >
        <Typography
          sx={{ color: colorPalette.accentBlue2, display: 'inline', marginRight: Spacings.spacing4 }}
          variant='h2'
        >
          {formatNumberAbbreviated(value)}
        </Typography>
        <Typography sx={{ color: colorPalette.accentBlue2, display: 'inline' }} variant='h3'>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default JobSummaryCard;
