import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded';
import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { AzulIconButton } from '../../../../../components/AzulIconButton';
import { colorPalette } from '../../../../../styles/partials/colors';
import { Spacings } from '../../../../../styles/partials/spacings';

export interface ExampleRow {
  candidate: string;
  email: string;
  jobPosition: string;
  recruiter: string;
  stage: string;
  status: string;
}

interface ExampleRowProps {
  exampleRow: ExampleRow;
}

export const ExampleRow = ({ exampleRow }: ExampleRowProps) => {
  const handleOnClickIcon = () => {
    alert('clicked!'); // Handle click events on the icons (can be customized with actual functionality)
  };

  return (
    <TableRow>
      {Object.values(exampleRow).map((value, index) => (
        <TableCell key={`${index}-${value}`} style={{ maxWidth: 112 }}>
          <Typography color='initial' noWrap variant='body1'>
            {value} {/* Display the value of each property */}
          </Typography>
        </TableCell>
      ))}
      <TableCell sx={{ borderTop: `${Spacings.spacing1}px solid ${colorPalette.neutralGray2}` }}>
        {/* Render an icon button for editing */}
        <AzulIconButton
          icon={<MoreHorizRounded sx={{ color: colorPalette.paragraph }} />} // Use the EditRoundedIcon from MUI with customized styling
          onClick={handleOnClickIcon} // Assign the click event handler for the edit icon button
        />
      </TableCell>
    </TableRow>
  );
};
