import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  department: yup.string().required('The Department field is required'),
  email: yup.string().email('Invalid email format').required('The e-mail field is required'),
  firstName: yup.string().required('The First name field is required'),
  lastName: yup.string().required('The Last name field is required'),
  location: yup.string().required('The Location  field is required'),
  role: yup.string().required('The Role field is required'),
});
