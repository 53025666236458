import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulModal } from '../../../../components/AzulModal';
import { AzulStepper } from '../../../../components/AzulStepper';
import { ModalJobType } from '../../../../ts/enums/ModalJobType';
import { ModalNewJobProps } from '../../../../ts/interfaces/ModalNewJob';
import { validationSchema } from '../../validators/validationSchema';
import { NewJobFormStepOne } from './NewJobForms/NewJobFormStepOne';
import { NewJobFormStepTwo } from './NewJobForms/NewJobFormStepTwo';

export const ModalNewJob = ({
  clientOptions,
  departmentOptions,
  hiringManagerOptions,
  initialValues,
  isOpen,
  jobTimeOptions,
  jobTypeOptions,
  locationOptions,
  managerOptions,
  modalType = ModalJobType.NewJob,
  salaryPeriodOptions,
  seniorityOptions,
  skillsOptions,
  statusOptions,
  onClose,
  onSubmit,
}: ModalNewJobProps) => (
  <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({
      touched,
      values,
      errors,
      validateForm,
      handleBlur,
      handleChange,
      setErrors,
      resetForm,
      setSubmitting,
      setFieldValue,
    }) => {
      const [activeStep, setActiveStep] = useState(0);
      const steps = ['Basic Info', 'Management'];

      const buttonLabel = modalType !== ModalJobType.NewJob ? 'Update' : 'Publish';

      const handleNext = () => {
        if (activeStep === 0) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
      };

      const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
      };

      const handleOnClose = () => {
        onClose();
        resetForm();
        setActiveStep(0);
      };

      const handleOnSubmit = async () => {
        const errors = await validateForm();
        // If there are validation errors, set them in Formik's state
        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          // If there are no errors, proceed with the form submission
          onSubmit(values, { setSubmitting });
          resetForm();
          setActiveStep(0);
        }
      };

      return (
        <AzulModal isOpen={isOpen} title={modalType} onClose={handleOnClose}>
          <AzulStepper activeStep={activeStep} steps={steps} />
          <Form>
            {activeStep === 0 && (
              <Fragment>
                <NewJobFormStepOne
                  departmentOptions={departmentOptions}
                  errors={errors}
                  jobTimeOptions={jobTimeOptions}
                  jobTypeOptions={jobTypeOptions}
                  locationOptions={locationOptions}
                  seniorityOptions={seniorityOptions}
                  setFieldValue={setFieldValue}
                  skillsOptions={skillsOptions}
                  touched={touched}
                  values={values}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Box display='flex' justifyContent='end'>
                  <AzulButton endIcon={<NavigateNextIcon />} label='Next' variant='primary' onClick={handleNext} />
                </Box>
              </Fragment>
            )}
            {activeStep === 1 && (
              <Fragment>
                <NewJobFormStepTwo
                  clientOptions={clientOptions}
                  errors={errors}
                  hiringManagerOptions={hiringManagerOptions}
                  managerOptions={managerOptions}
                  salaryPeriodOptions={salaryPeriodOptions}
                  setFieldValue={setFieldValue}
                  skillsOptions={skillsOptions}
                  statusOptions={statusOptions}
                  touched={touched}
                  values={values}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Box display='flex' justifyContent={'space-between'}>
                  <AzulButton label='Back' startIcon={<NavigateBeforeIcon />} onClick={handleBack} />
                  <AzulButton isSubmit label={buttonLabel} variant='primary' onClick={handleOnSubmit} />
                </Box>
              </Fragment>
            )}
          </Form>
        </AzulModal>
      );
    }}
  </Formik>
);
