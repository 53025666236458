import { FormControl, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulCheckbox } from '../../../../components/AzulCheckbox';
import { AzulModal } from '../../../../components/AzulModal';
import { Spacings } from '../../../../styles/partials/spacings';
import { Filter, FilterSection } from '../../../../ts/interfaces/Filter';
import { ModalJobFilterProps } from '../../../../ts/interfaces/ModalJobFilter';
import { updateFilter } from '../../../../utils/filters';

export const ModalJobFilters = ({ filtersData, isOpen, onClose, onReset, onSubmit }: ModalJobFilterProps) => {
  const [submitFiltersData, setSubmitFiltersData] = useState<FilterSection>({});
  const [filters, setFilters] = useState<FilterSection>({ ...filtersData });
  const [resetButtonDisabled, setResetButtonDisabled] = useState<boolean>(true);

  const handleCheckboxChange = (category: string, index: number) => {
    const updatedFilters = JSON.parse(JSON.stringify(filters));
    updatedFilters[category][index].checked = !updatedFilters[category][index].checked;
    setSubmitFiltersData(updateFilter(submitFiltersData, category, updatedFilters[category][index]));
    setResetButtonDisabled(false);
    setFilters(updatedFilters);
  };

  function handleReset() {
    setResetButtonDisabled(true);
    setSubmitFiltersData({});
    setFilters({ ...filtersData });
    onReset();
  }

  function handleSubmit() {
    onSubmit(submitFiltersData);
  }

  return (
    <AzulModal isOpen={isOpen} title='Filters' onClose={onClose}>
      <Grid
        columnSpacing={Spacings.spacing10}
        container
        height={'552px'}
        justifyContent={'space-between'}
        rowGap={Spacings.spacing6}
      >
        {Object.entries(filters).map(([key, values]) => (
          <Grid container flexDirection={'column'} item key={`JobFilter-${key}`} xs={values.length === 2 ? 6 : 12}>
            <Typography variant='h3'>{key}</Typography>
            <FormControl sx={{ marginTop: Spacings.spacing4 }}>
              <Grid container flexDirection='row' item spacing={4} xs={12}>
                {values.map((item: Filter, index: number) => (
                  <Grid
                    alignContent={'flex-start'}
                    container
                    flexDirection='row'
                    item
                    key={`${item.label}-${index}`}
                    xs={values.length === 2 ? 6 : 3}
                  >
                    <AzulCheckbox
                      checked={item.checked}
                      label={item.label}
                      onChange={() => handleCheckboxChange(key, index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </Grid>
        ))}
        <Grid item xs={6} />
      </Grid>
      <Grid container item justifyContent={'space-between'} xs={12}>
        <AzulButton disabled={resetButtonDisabled} label='Reset Filter' variant='tertiary' onClick={handleReset} />
        <AzulButton disabled={resetButtonDisabled} label='Apply' variant='primary' onClick={handleSubmit} />
      </Grid>
    </AzulModal>
  );
};
