import { UserRoles } from '../ts/enums/UserRoles';
import { UserModel } from '../ts/interfaces/models/UserModel';
import { BaseService, ListResponseData } from './baseService';

class UserService extends BaseService<UserModel> {
  controllerName = 'user';

  async getUserTable(limit: number, offest: number): Promise<ListResponseData<UserModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<UserModel>>(
      `/${this.controllerName}?limit=${limit}&offset=${offest}`
    );

    return data;
  }

  async getAllRecruiters(): Promise<ListResponseData<UserModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<UserModel>>(
      `/${this.controllerName}/search?role=${UserRoles.Recruiter}`
    );

    return data;
  }

  async getAllHiringManagers(): Promise<ListResponseData<UserModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<UserModel>>(
      `/${this.controllerName}/search?role=${UserRoles.HiringManager}`
    );

    return data;
  }

  async getAllManagers(): Promise<ListResponseData<UserModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<UserModel>>(
      `/${this.controllerName}/search?role=${UserRoles.Manager}`
    );

    return data;
  }

  async deleteUser(userId: string): Promise<void> {
    try {
      this.api.defaults.headers = this.getDefaultHeaders();
      await this.api.delete(`/${this.controllerName}/${userId}`);
    } catch (error) {
      console.error('Error deleting user:', error);
      throw new Error('Failed to delete user.');
    }
  }
}

export const userService = new UserService();
