import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { JobOfferCardProps } from '../../../../ts/interfaces/JobOfferCard';
import formatNumberAbbreviated from '../../../../utils/formatNumberAbbreviated';

export const JobOfferCard = ({ label, value }: JobOfferCardProps) => {
  const optionsHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxHeight: Spacings.spacing13,
        maxWidth: '100%',
        borderRadius: Spacings.spacing1,
        backgroundColor: colorPalette.backgroundWhite,
        filter: `drop-shadow(${optionsHierarchy.shadow} rgba(${optionsHierarchy.rgba}))`,
        paddingY: Spacings.spacing6,
        paddingX: '1.063rem',
      }}
    >
      <CardContent
        sx={{
          padding: Spacings.spacing0,
          display: 'flex',
          alignItems: 'center',
          gap: Spacings.spacing1,
          '&:last-child': {
            padding: Spacings.spacing0,
          },
        }}
      >
        <Typography sx={{ color: colorPalette.paragraphBaseline, display: 'inline' }} variant='body1'>
          {label}
        </Typography>
        <Typography
          sx={{
            color: colorPalette.paragraphBaseline,
            display: 'inline',
            marginLeft: Spacings.spacing3,
          }}
          variant='body1-medium'
        >
          {formatNumberAbbreviated(value)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default JobOfferCard;
