import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FocusEventHandler, RefObject, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulCurrencyCombobox } from '../../../../components/AzulCurrencyCombobox';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulTextField } from '../../../../components/AzulTextField';
import { AzulToastNotification } from '../../../../components/AzulToastNotification';
import { currencyOptions } from '../../../../constants/currencyOptions';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';
import { Option } from '../../../../ts/interfaces/AzulOption';
import { ModalNewCandidateType } from '../../../../ts/interfaces/forms/ModalNewCandidateType';
import { Severity } from '../../../../ts/types/Severity';
import { DocumentChip } from './components/DocumentChip';
import { getToastMessage, handleFileChange, handleFileDrop } from './utils';

interface ModalNewCandidateFirstPartProps {
  countryOptions: Option[];
  errors: FormikErrors<ModalNewCandidateType>;
  seniorityOptions: Option[];
  touched: FormikTouched<ModalNewCandidateType>;
  values: ModalNewCandidateType;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: {
    (event: ChangeEvent<HTMLInputElement>): void;
    <T = string | ChangeEvent<HTMLInputElement>>(field: T): T extends ChangeEvent<HTMLInputElement>
      ? void
      : (event: string | ChangeEvent<HTMLInputElement>) => void;
  };
  setFieldValue: (field: string, value: File | string | null, shouldValidate?: boolean | undefined) => void;
}

export const ModalNewCandidateFirstPart = ({
  countryOptions,
  errors,
  seniorityOptions,
  touched,
  values,
  onBlur,
  onChange,
  setFieldValue,
}: ModalNewCandidateFirstPartProps) => {
  const [cvFileStorage, setCvFileStorage] = useState<File | null>(null);
  const [portfolioFileStorage, setPortfolioFileStorage] = useState<File | null>(null);
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);
  const [toastNotificationSeverity, setToastNotificationSeverity] = useState<Severity>('success');
  const cvFileInputRef = useRef<HTMLInputElement>(null);
  const portfolioFileInputRef = useRef<HTMLInputElement>(null);
  const isCvFileError = errors.cvFile && touched.cvFile;

  const handleOnClickButton = (fileInputRef: RefObject<HTMLInputElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click event
    }
  };

  const handleToastNotificationOnClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastNotificationOpen(false);
  };

  const handleCvFileChipOnClick = () => {
    setFieldValue('cvFile', null);
    setCvFileStorage(null);
  };

  const handlePortfolioFileChipOnClick = () => {
    setFieldValue('portfolioFile', null);
    setPortfolioFileStorage(null);
  };

  useEffect(() => {
    // Update Formik form field when cvFileStorage or portfolioFileStorage changes
    if (cvFileStorage) setFieldValue('cvFile', cvFileStorage);

    if (portfolioFileStorage) setFieldValue('portfolioFile', portfolioFileStorage);
  }, [cvFileStorage, portfolioFileStorage]);

  useEffect(() => {
    if (isCvFileError) {
      setToastNotificationSeverity('error');
      setToastNotificationOpen(true);
    } else {
      setToastNotificationSeverity('success');
      setToastNotificationOpen(false);
    }
  }, [errors.cvFile, touched.cvFile]);

  return (
    <Grid columnSpacing={Spacings.spacing10} container justifyContent={'space-between'} rowSpacing={Spacings.spacing8}>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            error={Boolean(errors.firstName)}
            helper={(touched.firstName && errors.firstName) || ''}
            label='First name'
            name='firstName'
            touched={touched.firstName}
            value={values.firstName}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            error={Boolean(errors.lastName)}
            helper={(touched.lastName && errors.lastName) || ''}
            label='Last name'
            name='lastName'
            touched={touched.lastName}
            value={values.lastName}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            error={Boolean(errors.email)}
            helper={(touched.email && errors.email) || ''}
            label='Email'
            name='email'
            touched={touched.email}
            value={values.email}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            label='Phone no. (optional)'
            name='phoneNumber'
            value={values.phoneNumber}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.country)}
            helperText={(touched.country && errors.country) || ''}
            label='Country'
            name='country'
            options={countryOptions}
            touched={touched.country}
            value={values.country}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            label='Seniority (optional)'
            name='seniority'
            options={seniorityOptions}
            value={values.seniority}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulTextField
            label='Years of experience (optional)'
            name='yearsOfExperience'
            type='number'
            value={values.yearsOfExperience}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulCurrencyCombobox
            label={'Salary range'}
            name={'salaryRange'}
            options={currencyOptions}
            placeholder={'Salary range'}
            value={Number(values.salaryRange)}
            onChange={onChange}
            onClickOption={option => setFieldValue('salaryCurrency', option)}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <div
          onDragOver={event => event.preventDefault()}
          onDrop={event =>
            handleFileDrop(
              event,
              cvFileInputRef,
              setToastNotificationOpen,
              setToastNotificationSeverity,
              setCvFileStorage
            )
          }
        >
          <Typography variant='subtitle2'>CV DOCUMENT</Typography>
          <Box display='flex' marginTop={Spacings.spacing8}>
            {cvFileStorage !== null && <DocumentChip label={cvFileStorage.name} onClick={handleCvFileChipOnClick} />}
          </Box>
          {!cvFileStorage && (
            <Box
              alignItems='center'
              border={`${Spacings.spacing2}px dotted ${
                isCvFileError ? colorPalette.destructiveRed1 : colorPalette.primaryBlue
              }`}
              borderRadius={`${Spacings.spacing4}px`}
              display='flex'
              flexDirection='column'
              gap={Spacings.spacing5}
              height={184}
              justifyContent='center'
              marginTop={Spacings.spacing8}
              width={'100%'}
            >
              <Typography variant='subtitle2'>DROP YOUR FILE HERE </Typography>
              <Typography textAlign='center' variant='body1'>
                Files can be .PDF, JPG, .JPEG, .PNG, .DOC format and no more than 10 MB.
              </Typography>
              <AzulButton
                label='Select files'
                startIcon={<ArrowUpwardIcon />}
                variant='primary'
                onClick={() => handleOnClickButton(cvFileInputRef)}
              />
              <input
                accept='.pdf, .doc, .docx, .jpeg, .jpg, .png'
                name={'cvFile'}
                placeholder='DROP YOUR FILES HERE (OPTIONAL)'
                ref={cvFileInputRef}
                style={{ display: 'none' }}
                type='file'
                onChange={event =>
                  handleFileChange(event, setToastNotificationOpen, setToastNotificationSeverity, setCvFileStorage)
                }
              />
            </Box>
          )}
        </div>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <div
          onDragOver={event => event.preventDefault()}
          onDrop={event =>
            handleFileDrop(
              event,
              portfolioFileInputRef,
              setToastNotificationOpen,
              setToastNotificationSeverity,
              setPortfolioFileStorage
            )
          }
        >
          <Typography variant='subtitle2'>PORTFOLIO DOCUMENT</Typography>
          <Box display='flex' marginTop={Spacings.spacing8}>
            {portfolioFileStorage !== null && (
              <DocumentChip label={portfolioFileStorage.name} onClick={handlePortfolioFileChipOnClick} />
            )}
          </Box>
          {!portfolioFileStorage && (
            <Box
              alignItems='center'
              border={`${Spacings.spacing2}px dotted ${colorPalette.primaryBlue}`}
              borderRadius={`${Spacings.spacing4}px`}
              display='flex'
              flexDirection='column'
              gap={Spacings.spacing5}
              height={184}
              justifyContent='center'
              marginTop={Spacings.spacing8}
              width={'100%'}
            >
              <Typography variant='subtitle2'>DROP YOUR FILE HERE (OPTIONAL)</Typography>
              <Typography textAlign='center' variant='body1'>
                Files can be .PDF, JPG, .JPEG, .PNG, .DOC format and no more than 10 MB.
              </Typography>
              <AzulButton
                label='Select files'
                startIcon={<ArrowUpwardIcon />}
                variant='primary'
                onClick={() => handleOnClickButton(portfolioFileInputRef)}
              />
              <input
                accept='.pdf, .doc, .docx, .jpeg, .jpg, .png'
                name={'portfolioFile'}
                placeholder='DROP YOUR FILES HERE (OPTIONAL)'
                ref={portfolioFileInputRef}
                style={{ display: 'none' }}
                type='file'
                onChange={event =>
                  handleFileChange(
                    event,
                    setToastNotificationOpen,
                    setToastNotificationSeverity,
                    setPortfolioFileStorage
                  )
                }
              />
            </Box>
          )}
        </div>
      </Grid>
      <AzulToastNotification
        message={getToastMessage(toastNotificationSeverity, errors)}
        open={toastNotificationOpen}
        severity={toastNotificationSeverity}
        onClose={handleToastNotificationOnClose}
      />
    </Grid>
  );
};
