// TO DO: all comments will be deleted on redux implementation
// import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Card, CardContent, Grid, Rating, Typography } from '@mui/material';
// import { Box, Card, CardContent, Grid, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import emptyState01 from '../../../../assets/emptyState01.svg';
import { AzulButton } from '../../../../components/AzulButton';
// import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulEmptyState } from '../../../../components/AzulEmptyState/AzulEmptyState';
import { AzulIconButton } from '../../../../components/AzulIconButton';
// import { AzulIconButton } from '../../../../components/AzulIconButton';
// import { stageOptions, statusOptions } from '../../../../constants/candidateOptions';
// import { setCandidateStage, setCandidateStatus } from '../../../../store/candidate';
// import { setJobPositionCandidatesStage, setJobPositionCandidatesStatus } from '../../../../store/jobPosition/';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { CandidateFeedbackTemplate } from '../../../../ts/interfaces/ModalCandidateFeedback';
import { ModalCandidateFeedbackType, emptyValues } from '../../../../ts/interfaces/forms/ModalCandidateFeedbackType';
import { modalFeedbackData, modalTemplate } from '../../mockData';
// import { Spacings } from '../../../../styles/partials/spacings';
// import { CandidateStage } from '../../../../ts/enums/CandidateStage';
// import { CandidateStatus } from '../../../../ts/enums/CandidateStatus';
// import { Size } from '../../../../ts/enums/Size';
// import { useAppDispatch } from '../../../../utils/hooks/storeHooks';
// import { ModalJobDetails } from '../ModalJobDetails';
import { ModalCandidateFeedback } from './ModalCandidateFeedback';

export const CandidateFeedback = () => {
  const modalCandidateFeedbackTemplate = modalTemplate;
  const modalCandidateFeedbackData = modalFeedbackData;

  const [modalTitle, setModalTitle] = useState('');
  const [modalEditMode, setModalEditMode] = useState(false);
  const [modalReadOnly, setModalReadOnly] = useState(false);
  const [modalData, setModalData] = useState<CandidateFeedbackTemplate[]>(modalCandidateFeedbackTemplate);
  const initialValues = emptyValues;
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  //   const dispatch = useAppDispatch();

  const [isCFModalOpen, setCFModalOpen] = useState(false);

  function toggleCandidateFeedbackModal(
    candidateFeedbackTemplate: CandidateFeedbackTemplate[],
    editMode: boolean,
    readOnly: boolean,
    title: string
  ) {
    setCFModalOpen(prevState => !prevState);
    setModalTitle(title);
    setModalEditMode(editMode);
    setModalReadOnly(readOnly);
    setModalData(candidateFeedbackTemplate);
  }

  const handleSubmit = (
    values: ModalCandidateFeedbackType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    toggleCandidateFeedbackModal(modalCandidateFeedbackTemplate, false, false, '');
    // dispatch(setHiringTeam(values));
  };

  //   const handleOnChangeStatus = (event: SelectChangeEvent<string>) => {
  //     const newStatus = event.target.value as CandidateStatus;
  //     dispatch(setCandidateStatus({ id: candidate.id, status: newStatus }));
  //     dispatch(
  //       setJobPositionCandidatesStatus({
  //         jobId: candidate.jobId,
  //         candidateId: candidate.id,
  //         status: newStatus,
  //       })
  //     );
  //   };

  //   const handleOnChangeStage = (event: SelectChangeEvent<string>) => {
  //     const newStage = event.target.value as CandidateStage;
  //     dispatch(setCandidateStage({ id: candidate.id, stage: newStage }));
  //     dispatch(
  //       setJobPositionCandidatesStage({
  //         jobId: candidate.jobId,
  //         candidateId: candidate.id,
  //         stage: newStage,
  //       })
  //     );
  //   };

  //   const candidateDetails = [
  //     { label: 'Email', value: candidate.email },
  //     { label: 'Phone.no.', value: candidate.phoneNo },
  //     { label: 'Seniority', value: candidate.seniority },
  //     { label: 'Years of experience', value: candidate.yearsOfExperience },
  //     { label: 'Salary expectations', value: candidate.salaryExpectations },
  //     { label: 'Country', value: candidate.country },
  //   ];

  return (
    <Card
      elevation={0}
      sx={{
        height: '496px',
        width: '100%',
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
        background: colorPalette.accentBlue1,
      }}
    >
      <CardContent>
        <Grid alignItems={'center'} display='flex' item justifyContent={'space-between'} xs={12}>
          <Typography marginBottom={Spacings.spacing5} variant='h3'>
            Feedback
          </Typography>
          <AzulIconButton
            icon={<AddRoundedIcon />}
            tooltipTitle='Add feedback'
            onClick={() => toggleCandidateFeedbackModal(modalCandidateFeedbackTemplate, false, false, 'Add feedback')}
          />
        </Grid>
        {!modalCandidateFeedbackData.length && (
          <AzulEmptyState image={emptyState01} marginTop={85} title={'Nothing here yet'} />
        )}
        {modalCandidateFeedbackData.length && (
          <Grid display='flex' flexDirection={'column'} item justifyContent={'space-between'} xs={12}>
            <Box
              alignItems={'center'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              marginBottom={Spacings.spacing0}
            >
              <Typography color={colorPalette.primaryPressedBlue} variant='h3'>
                {modalCandidateFeedbackData[0].interviewStep}
              </Typography>
              <Typography variant='caption'>{modalCandidateFeedbackData[0].interviewDate}</Typography>
            </Box>
            <Box
              alignItems={'center'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              marginBottom={Spacings.spacing0}
            >
              <Typography marginBottom={Spacings.spacing5} variant='h3'>
                {modalCandidateFeedbackData[0].interviewRecruiter}
              </Typography>
              <AzulIconButton
                tooltipTitle='Edit feedback'
                onClick={() => toggleCandidateFeedbackModal(modalCandidateFeedbackData, false, true, 'Edit feedback')}
              />
            </Box>
            {modalCandidateFeedbackData.map(({ textName, textNameLower, ratingName, ratingValue }, index) => (
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={0}
                justifyContent={'space-between'}
                key={`${textName}-Details-${index}`}
                marginBottom={Spacings.spacing0}
              >
                <Typography gap={0} marginTop={Spacings.spacing1} noWrap variant='body2'>
                  {textNameLower}
                </Typography>
                <Rating name={ratingName} readOnly value={ratingValue} />
              </Box>
            ))}
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              marginBottom={Spacings.spacing6}
              marginLeft={Spacings.spacing0}
              marginTop={Spacings.spacing4}
            >
              <AzulButton
                label='See more'
                variant='tertiary'
                onClick={() =>
                  toggleCandidateFeedbackModal(modalCandidateFeedbackData, true, false, 'Feedback details')
                }
              />
            </Box>
          </Grid>
        )}
      </CardContent>
      <ModalCandidateFeedback
        candidateFeedbackTemplate={modalData}
        editMode={modalEditMode}
        initialValues={initialValues}
        isOpen={isCFModalOpen}
        readOnly={modalReadOnly}
        title={modalTitle}
        onClose={() => toggleCandidateFeedbackModal(modalCandidateFeedbackTemplate, false, false, '')}
        onSubmit={handleSubmit}
      />
    </Card>
  );
};
