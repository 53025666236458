const mainRouter = () => ({
  HOME: '/',
  LOGIN: '/login',
  JOBS: '/jobs',
  CANDIDATES: '/candidates',
  CANDIDATE: '/candidates/candidate/:id',
  USERS: '/users',
  PLAYGROUND: '/playground',
  NOTIFICATIONS: '/notifications',
  NOT_FOUND: '*',
});
export const MAIN_ROUTES = mainRouter();

const playgroundRouter = (root: string) => ({
  ROOT: root,
  EDIT: `${root}/:id`,
});
export const PLAYGROUND_ROUTES = playgroundRouter(MAIN_ROUTES.PLAYGROUND);
