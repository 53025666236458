import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface CountryOptions {
  countryOptions: Option[];
  countryOptionsRequestStatus: RequestStatus;
}

const initialState: CountryOptions = {
  countryOptions: [],
  countryOptionsRequestStatus: RequestStatus.Idle,
};

export const countrySlice = createSlice({
  name: 'countries',
  initialState,
  reducers,
});

export const { setCountryOptions, setCountryOptionsRequestStatus } = countrySlice.actions;

export default countrySlice.reducer;
