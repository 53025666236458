// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export enum WhereOperator {
  AND = '$and',
  BETWEEN = '$between',
  CONTAINS = '$contains',
  ENDS_WITH = '$endsWith',
  EQ = '$eq',
  GT = '$gt',
  GTE = '$gte',
  IN = '$in',
  IS = '$is',
  I_LIKE = '$iLike',
  LIKE = '$like',
  LTE = '$lte',
  NE = '$ne',
  NOT = '$not',
  NOT_INT = '$notIn',
  NOT_LIKE = '$notLike',
  OR = '$or',
  STARTS_WITH = '$startsWith',
  SUBSTRING = '$substring',
  NOT_BETWEEN = '$notBetween',
}

type FormatMapFn = (value: unknown) => string;

const OP_FORMATTERS: Partial<Record<WhereOperator, FormatMapFn>> = {
  [WhereOperator.SUBSTRING]: value => `%${value}%`,
};

type WhereOperatorFragment = Record<string, Partial<Record<WhereOperator, string>>>;

type WhereOperatorFragmentFactory = {
  op: WhereOperator;
  name: string;
  value?: string | number | boolean;
};

const createWhereOperatorFragment = ({ name, op, value }: WhereOperatorFragmentFactory): WhereOperatorFragment => {
  return {
    [name]: {
      [op]: OP_FORMATTERS[op]
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          OP_FORMATTERS[op]!(value)
        : value,
    },
  };
};

export const createWhereOperator = (params: WhereOperatorFragmentFactory[]) => {
  return params.reduce((whereObj, next) => {
    if (next.value) {
      return { ...createWhereOperatorFragment(next), ...whereObj };
    }
    return whereObj;
  }, {});
};

/*const where = createWhereOperator([{ name: "status", op: WhereOperator.EQ, value: "open" }, { name: "title", op: WhereOperator.SUBSTRING, value: "Product Designer"}])
  
  const mySearchParams = new URLSearchParams();
  
  mySearchParams.set('where', JSON.stringify(where))
      
  decodeURIComponent(mySearchParams.toString());*/
