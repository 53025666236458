import { UserRoles } from '../../ts/enums/UserRoles';
import { FilterSection } from '../../ts/interfaces/Filter';
import { enumToOption } from '../../utils/parsers/option/enumToOption';

export const roleOptions = enumToOption(UserRoles);

export const options = [
  { label: 'Open', value: 'open' },
  { label: 'On hold', value: 'on_hold' },
  { label: 'Closed', value: 'closed' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Contacted', value: 'Contacted' },
  { label: 'No Response', value: 'No Response' },
  { label: 'Active', value: 'Active' },
  { label: 'Pipeline', value: 'Pipeline' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Declined', value: 'Declined' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Withdrew', value: 'Withdrew' },
  { label: 'Hired', value: 'Hired' },
  { label: 'Contacted', value: 'Contacted' },
];
export const filtersDataJson: FilterSection = {
  Status: [
    { label: 'Active', checked: false },
    { label: 'Contacted', checked: false },
    { label: 'Ipeline', checked: false },
    { label: 'Declined', checked: false },
    { label: 'Rejected', checked: false },
    { label: 'Hold', checked: false },
    { label: 'No response', checked: false },
  ],
  Stage: [
    { label: 'Make an offer', checked: false },
    { label: '1st tech interview', checked: false },
    { label: '2nd tech interview', checked: false },
    { label: 'Test dome', checked: false },
    { label: 'Client interview', checked: false },
  ],
};
