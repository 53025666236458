import { setSkillOptions, setSkillOptionsRequestStatus } from '.';
import { skillService } from '../../service/skillService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllSkillOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setSkillOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await skillService.getAll();
      const parsedData = parseDataToOptions(data, true, useIdAsValue);

      dispatch(setSkillOptions(parsedData));

      dispatch(setSkillOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setSkillOptionsRequestStatus(RequestStatus.Failed));
    }
  };
