import {
  setNewUsersTable,
  setRecruiterOptions,
  setRecruiterOptionsRequestStatus,
  setUsers,
  setUsersRequestStatus,
  setUsersTable,
  setUsersTableCount,
  setUsersTableRequestStatus,
} from '.';
import { userService } from '../../service/userService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { ModalNewUserType } from '../../ts/interfaces/forms/ModalNewUserType';
import { UserModel } from '../../ts/interfaces/models/UserModel';
import { parseUserToOptions } from '../../utils/parsers/option/options';
import {
  parseModalNewUserTypeToUserPostModel,
  parseUserModelToUser,
  parseUsersModelArrayToUserArray,
} from '../../utils/parsers/user';
import { AppThunk } from '../store';

const mockData: UserModel[] = [
  {
    id: '1',
    email: 'email@example.com',
    first_name: 'Alejandra',
    last_name: 'Guzman',
    location_id: 'RD',
    department_id: 'HR',
    name: 'Alejandra Guzman',
    role: 'Recruiter',
    password: 'user1password',
    sso_id: 'sso_id_1',
  },
  {
    id: '2',
    email: 'email2@example.com',
    first_name: 'Christian',
    last_name: 'Nodal',
    location_id: 'UK',
    department_id: 'Operation',
    name: 'Christian Nodal',
    role: 'Recruiter',
    password: 'user2password',
    sso_id: 'sso_id_2',
  },
  {
    id: '3',
    email: 'email3@example.com',
    first_name: 'Joan',
    last_name: 'Sebastian',
    location_id: 'DEU',
    department_id: 'Sells',
    name: 'Joan Sebastian',
    role: 'Manager',
    password: 'user3password',
    sso_id: 'sso_id_3',
  },
  {
    id: '4',
    email: 'email3@example.com',
    first_name: 'Jenny',
    last_name: 'Rivera',
    location_id: 'MX',
    department_id: 'Sales',
    name: 'Jenny Rivera',
    role: 'Manager',
    password: 'user4password',
    sso_id: 'sso_id_4',
  },
  {
    id: '5',
    email: 'email3@example.com',
    first_name: 'Vicente',
    last_name: 'Fernandez',
    location_id: 'MX',
    department_id: 'Sales',
    name: 'Vicente Fernandez',
    role: 'Manager',
    password: 'user5password',
    sso_id: 'sso_id_5',
  },
  {
    id: '6',
    email: 'email3@example.com',
    first_name: 'Pedrito',
    last_name: 'Fernandez',
    location_id: 'MX',
    department_id: 'Sales',
    role: 'Recruiter',
    name: 'Pedrito Fernandez',
    createdAt: new Date('2022-05-23'),
    password: 'user6password',
    sso_id: 'sso_id_6',
  },
  {
    id: '7',
    email: 'email@example.com',
    first_name: 'Valentin',
    last_name: 'Elizalde',
    location_id: 'MX',
    department_id: 'Marketing',
    role: 'Recruiter',
    name: 'Valentin Elizalde',
    createdAt: new Date('1995-12-25T23:15:30'),
    password: 'user7password',
    sso_id: 'sso_id_7',
  },
  {
    id: '8',
    email: 'email@example.com',
    first_name: 'Jose Alfredo',
    last_name: 'Jimenez',
    location_id: 'MX',
    department_id: 'Marketing',
    role: 'Interviewer',
    name: 'Jose Alfredo Jimenez',
    createdAt: new Date('2004-02-25T23:15:30'),
    password: 'user8password',
    sso_id: 'sso_id_8',
  },
  {
    id: '9',
    email: 'email@example.com',
    first_name: 'Luis',
    last_name: 'Miguel',
    location_id: 'MX',
    department_id: 'Operations',
    role: 'Manager',
    name: 'Luis Miguel',
    createdAt: new Date('October 13, 2014 11:13:00'),
    password: 'user9password',
    sso_id: 'sso_id_9',
  },
  {
    id: '10',
    email: 'email@example.com',
    first_name: 'Amanda',
    last_name: 'Miguel',
    location_id: 'MX',
    department_id: 'Operations',
    role: 'Manager',
    name: 'Amanda Miguel',
    createdAt: new Date('May 23, 2019 11:13:00'),
    password: 'user10password',
    sso_id: 'sso_id_10',
  },
  {
    id: '11',
    email: 'email@example.com',
    first_name: 'Diego',
    last_name: 'Verdaguer',
    location_id: 'MX',
    department_id: 'Operations',
    role: 'Recruiter',
    name: 'Diego Verdaguer',
    createdAt: new Date('Feb 23, 2019 11:13:00'),
    password: 'user11password',
    sso_id: 'sso_id_11',
  },
  {
    id: '12',
    email: 'email@example.com',
    first_name: 'Marco Antonio',
    last_name: 'Solis',
    location_id: 'MX',
    department_id: 'Operations',
    name: 'Marco Antonio Solis',
    role: 'Recruiter',
    createdAt: new Date('Nov 23, 2019 11:13:00'),
    password: 'user12password',
    sso_id: 'sso_id_12',
  },
  {
    id: '13',
    email: 'email@example.com',
    first_name: 'Juan',
    last_name: 'Gabriel',
    location_id: 'MX',
    department_id: 'HR',
    name: 'Juan Gabriel',
    role: 'Recruiter',
    createdAt: new Date('Nov 30, 2019 11:13:00'),
    password: 'user13password',
    sso_id: 'sso_id_13',
  },
  {
    id: '14',
    email: 'email@example.com',
    first_name: 'Javier',
    last_name: 'Solis',
    location_id: 'MX',
    department_id: 'HR',
    name: 'Javier Solis',
    role: 'Recruiter',
    createdAt: new Date('Mar 30, 2022 11:13:00'),
    password: 'user14password',
    sso_id: 'sso_id_14',
  },
  {
    id: '15',
    email: 'email@example.com',
    first_name: 'Julieta',
    last_name: 'Venegas',
    location_id: 'MX',
    department_id: 'HR',
    name: 'Julieta Venegas',
    role: 'Recruiter',
    createdAt: new Date('Mar 12, 2022 11:13:00'),
    password: 'user15password',
    sso_id: 'sso_id_15',
  },
];

export const getUsersTable =
  (): AppThunk =>
    async dispatch => {
      try {
        dispatch(setUsersTableRequestStatus(RequestStatus.Loading));

        const { data, count } = await userService.getAll();

        const parsedData = parseUsersModelArrayToUserArray(data);

        dispatch(setUsersTable(parsedData));
        dispatch(setUsersTableCount(count));

        dispatch(setUsersTableRequestStatus(RequestStatus.Success));
      } catch (error) {
        dispatch(setUsersTableRequestStatus(RequestStatus.Failed));
      }
    };

export const getAllUsers = (): AppThunk => async dispatch => {
  try {
    dispatch(setUsersRequestStatus(RequestStatus.Loading));
    setTimeout(() => {
      const parsedData = parseUsersModelArrayToUserArray(mockData);
      dispatch(setUsers(parsedData));
      dispatch(setUsersRequestStatus(RequestStatus.Success));
    }, 2000);
  } catch (error) {
    dispatch(setUsersRequestStatus(RequestStatus.Failed));
  }
};

export const createNewUser =
  (userInfo: ModalNewUserType): AppThunk =>
    async dispatch => {
      try {
        dispatch(setUsersTableRequestStatus(RequestStatus.Loading));
        const userBody = parseModalNewUserTypeToUserPostModel(userInfo);
        const { data } = await userService.create(userBody);

        const parsedData = parseUserModelToUser(data);

        dispatch(setNewUsersTable(parsedData));
        dispatch(setUsersTableRequestStatus(RequestStatus.Success));
      } catch (error) {
        dispatch(setUsersTableRequestStatus(RequestStatus.Failed));
      }
    };

export const getAllRecruiterOptions =
  (useIdAsValue = false): AppThunk =>
    async dispatch => {
      try {
        dispatch(setRecruiterOptionsRequestStatus(RequestStatus.Loading));

        const { data } = await userService.getAllRecruiters();
        const parsedData = parseUserToOptions(data, false, useIdAsValue);

        dispatch(setRecruiterOptions(parsedData));
        dispatch(setRecruiterOptionsRequestStatus(RequestStatus.Success));
      } catch (error) {
        dispatch(setRecruiterOptionsRequestStatus(RequestStatus.Failed));
      }
    };
