// Icons
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
// MUI components
import { Box, Card, Popover, Typography } from '@mui/material';
// Hook
import React, { Fragment, useRef, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulIconButton } from '../../../../components/AzulIconButton';
// Component
import { AzulOptions } from '../../../../components/AzulOptions';
import { JOB_POSITION_CARD_OPTIONS } from '../../../../constants/JobPositionCardOptions';
// Colors
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { Size } from '../../../../ts/enums/Size';
// Interface
import { JobPositionCardProps } from '../../../../ts/interfaces/JobPositionCard';

/**
 * Represents a JobPositionCard component that displays information about a job position.
 *
 * @param data - The data associated with the job position. (Optional)
 * @param job - The job title or position name.
 * @param id - The unique identifier for the job position.
 * @param location - The location of the job position.
 * @param pay - The pay or salary information for the job position. (Optional)
 * @param selected - Indicates whether the job position is selected. (Optional)
 * @param urgent - Indicates whether the job position is urgent. (Optional)
 * @param onClick - Event handler for the JobPositionCard's click event. (Optional)
 * @param onClickOption - Event handler for a specific option click event within the JobPositionCard. (Optional)
 */

export const JobPositionCard = ({
  data,
  job,
  id,
  location,
  pay,
  selected = false,
  urgent = false,
  onClick,
  onClickOption,
}: JobPositionCardProps) => {
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const moreIconButtonRef = useRef<HTMLButtonElement>(null);

  const handlePopoverOpen = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: selected ? colorPalette.primaryHoverBlue1 : colorPalette.backgroundWhite,
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
        overflow: 'unset',
        padding: `${Spacings.spacing5}px`,
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1-medium'>{job}</Typography>
        <Box sx={{ textAlign: 'right', alignItems: 'center', display: 'flex' }}>
          {urgent && <PriorityHighRoundedIcon sx={{ color: colorPalette.destructiveRed2 }} />}
          {data && (
            <AzulIconButton
              ariaLabel={`Open ${job} Card: Options`}
              icon={<MoreHorizRoundedIcon sx={{ color: colorPalette.primaryBlue }} />}
              ref={moreIconButtonRef}
              size={Size.Small}
              tooltipTitle='More actions'
              onClick={handlePopoverOpen}
            />
          )}
          <Popover
            anchorEl={moreIconButtonRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isPopoverOpen}
            tabIndex={0}
            onClose={handlePopoverClose}
          >
            {onClickOption && (
              <AzulOptions options={JOB_POSITION_CARD_OPTIONS} width='100%' onClickOption={onClickOption} />
            )}
          </Popover>
          {selected && <ArrowForwardIosRoundedIcon sx={{ color: colorPalette.primaryBlue, fontSize: '1rem' }} />}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: Spacings.spacing1 }}>
          <Typography
            sx={{
              color: colorPalette.primaryBlue,
              display: 'inline-block',
              textAlign: 'center',
              width: '1.5em',
            }}
            variant='body2-bold'
          >
            ID
          </Typography>
          <Typography variant='body2'>{id}</Typography>
        </Box>
        {data && <Typography variant='caption'>Created: {data.createdAt}</Typography>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: Spacings.spacing1 }}>
          <FmdGoodRoundedIcon sx={{ color: colorPalette.primaryBlue }} />
          <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: Spacings.spacing1 }} variant='body2'>
            {location}
          </Typography>
        </Box>
        {data && <Typography variant='body2'>{data.availables} Availables</Typography>}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: Spacings.spacing1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: Spacings.spacing1 }}>
          {pay && (
            <Fragment>
              <AttachMoneyRoundedIcon sx={{ color: colorPalette.primaryBlue }} />
              <Typography variant='body2'>{pay}</Typography>
            </Fragment>
          )}
        </Box>
        <Box sx={{ display: 'flex', columnGap: Spacings.spacing4, fontSize: Spacings.spacing4 }}>
          {data && <Typography variant='caption'>{data.applications} applications</Typography>}
          {data && <Typography variant='caption'>{data.hired} Hired</Typography>}
        </Box>
      </Box>

      {onClick && (
        <Box marginTop={Spacings.spacing4}>
          <AzulButton disabled={selected} label='View more details' variant='tertiary' onClick={onClick} />
        </Box>
      )}
    </Card>
  );
};
