import { Box, Grid, Typography } from '@mui/material';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AzulDatePicker } from '../../components/AzulDatePicker';
import { AzulLayout } from '../../components/AzulLayout';
import { selectJobPosition, selectJobPositionRequestStatus } from '../../store/jobPosition/selectors';
import { getJobPositionById } from '../../store/jobPosition/thunks';
import { Spacings } from '../../styles/partials/spacings';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { FormExampleType, emptyValues, formMockData } from '../../ts/interfaces/forms/example';
import { JobPositionStatus } from '../../ts/types/JobPosition';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { JobPositionCard } from '../Jobs/components/JobPositionCard';
import { FormExample } from './examples/Form';
import { ExampleTable } from './examples/Table/ExampleTable';
import { columns, rows } from './mockData';

// Define a Playground component for testing components or functionality
export const Playground = () => {
  const locationHook = useLocation();
  const [paramExample, setParamExample] = useState<string | null>();
  const dispatch = useAppDispatch();

  // Get the "id" from the URL parameters using the useParams() hook from react-router-dom
  const { id } = useParams();

  // Set the initial values for the form based on whether "id" exists or not
  const initialValues = id ? formMockData : emptyValues;

  const [tablePage, setTablePage] = useState(1); // State for the current page of the table
  const [rowsPerPage, setRowsPerPage] = useState(5); // State for the number of rows per page
  const [filteredRows, setFilteredRows] = useState(rows.slice(0, rowsPerPage)); // State for the filtered rows based on the current page and rows per page
  const totalItems = rows.length; // Total number of items/rows in the table

  // Get the job positions state from the store
  const { id: jobId, title, location, pay, status, urgent } = useAppSelector(selectJobPosition);

  // Get the job positions request status from the store
  const jobPositionRequestStatus = useAppSelector(selectJobPositionRequestStatus);

  // Determine if the data is loading
  const isLoading = jobPositionRequestStatus === RequestStatus.Loading;

  const handleChangeTablePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Define the form submission function
  const handleSubmit = (
    values: FormExampleType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 400);
  };

  const handleOnClickLayoutButton = () => {
    alert('clicked!');
  };

  // Extract the value of the "example" query parameter from the URL and update the state
  useEffect(() => {
    const query = new URLSearchParams(locationHook.search);
    const paramExample = query.get('example');
    setParamExample(paramExample);
  }, [locationHook]);

  useEffect(() => {
    const startIndex = (tablePage - 1) * rowsPerPage; // Calculate the starting index of the current page
    const endIndex = startIndex + rowsPerPage; // Calculate the ending index of the current page
    const newFilteredRows = rows.slice(startIndex, endIndex); // Extract the rows corresponding to the current page
    setFilteredRows(newFilteredRows); // Update the filtered rows state with the new page data
  }, [rows, rowsPerPage, tablePage]);

  //  GET example
  useEffect(() => {
    dispatch(getJobPositionById('1'));
  }, []);

  return (
    <AzulLayout buttonLabel='For Testing' title='Playground' onClickButton={handleOnClickLayoutButton}>
      <Grid container item xs={6}>
        <Typography color='primary' marginBottom={Spacings.spacing6} variant='h2'>
          1. Formik Example
        </Typography>
        <Grid item xs={12}>
          <FormExample initialValues={initialValues} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <Typography color='primary' marginBottom={Spacings.spacing6} variant='h2'>
          2. Params Example
        </Typography>
        <Typography marginBottom={Spacings.spacing8} variant='body1'>
          Please pass the query parameter: example, in the URL of the website to see how the application displays the
          value provided.
        </Typography>
        {paramExample && (
          <Typography color='red' component='h3' variant='h1'>
            Example: {paramExample}
          </Typography>
        )}
      </Grid>
      <Grid item marginY={Spacings.spacing4} xs={12}>
        <Typography color='primary' marginBottom={Spacings.spacing6} variant='h2'>
          3. Table Example
        </Typography>
        <ExampleTable
          columns={columns}
          page={tablePage}
          rows={filteredRows}
          rowsPerPage={rowsPerPage}
          totalItems={totalItems}
          onChangePage={handleChangeTablePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <Typography color='primary' marginBottom={Spacings.spacing6} variant='h2'>
          4. Date Picker
        </Typography>
        <AzulDatePicker label='Playground' onChange={value => alert(value)} />
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <Typography color='primary' marginBottom={Spacings.spacing6} variant='h2'>
          5. Coming Soon
        </Typography>
        <Box marginBottom={48}>
          {isLoading ? (
            <Typography color='initial' variant='h3'>
              Is Loading...
            </Typography>
          ) : (
            <JobPositionCard
              id={String(jobId)}
              job={title}
              location={location}
              pay={pay}
              status={status as JobPositionStatus}
              urgent={urgent}
            />
          )}
        </Box>
      </Grid>
    </AzulLayout>
  );
};
