import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { AppBar, Badge, Box, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import React, { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NotificationPopover } from '../../modules/Notifications/components/NotificationPopover/NotificationPopover';
import { selectNotifications } from '../../store/notification/selectors';
import { getAllNotificationPage } from '../../store/notification/thunks';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { Size } from '../../ts/enums/Size';
import { AzulNavBarProps } from '../../ts/interfaces/AzulNavBar';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { AzulIconButton } from '../AzulIconButton';
import { AzulProfileSettings } from '../AzulProfileSettings';

/**
 * Represents a navigation bar component.
 *
 * @param pageName - The name of the current page.
 * @param tabItems - An array of tab items for rendering navigation tabs.
 *                   Each tab item should have an `id`, `label`, and `routePath`.
 */

export const AzulNavBar = ({ pageName, tabItems }: AzulNavBarProps) => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [isProfileSettingsOpen, setIsProfileSettingsOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const tabRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  const location = useLocation();
  const optionsHierarchy = Hierarchies[Hierarchy.Hierarchy4];
  const notifications = useAppSelector(selectNotifications);
  const showNotificationsBadge = notifications.filter(notification => notification.notRead).length > 0;

  const handleOnClickProfileSettings = () => {
    setIsProfileSettingsOpen(!isProfileSettingsOpen);
  };

  const handleNotificationClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    if (tabItems && tabItems.length) {
      const activeTabIndex = tabItems.findIndex(tab => tab.routePath === location.pathname);
      setActiveTab(activeTabIndex + 1);
    }
  }, [location.pathname, tabItems]);

  useEffect(() => {
    dispatch(getAllNotificationPage(1, 10, 0));
  }, [dispatch]);

  const handleKeyDown = (event: KeyboardEvent<HTMLAnchorElement>, tabIndex: number) => {
    if (event.key === 'Enter') {
      setActiveTab(tabIndex);
    }
  };

  return (
    <AppBar
      position='sticky'
      sx={{
        alignItems: 'center',
        boxShadow: `${optionsHierarchy.shadow} rgba(${optionsHierarchy.rgba})`,
        display: 'inline-flex',
        minWidth: 'min-content',
        width: '100%',
      }}
    >
      <Toolbar sx={{ maxWidth: '1122px', width: '100%' }}>
        <Typography sx={{ alignSelf: 'center', flexGrow: 1 }} variant='h3'>
          {pageName}
        </Typography>
        <Box sx={{ alignItems: 'center', display: 'inline-flex', gap: Spacings.spacing6 }}>
          {tabItems && tabItems.length && (
            <Tabs value={activeTab || tabItems[0].id} onChange={(_event, newValue) => setActiveTab(newValue)}>
              {tabItems.map(({ id, label, routePath }, index) => (
                <Tab
                  aria-selected={routePath === location.pathname}
                  component={NavLink}
                  disableFocusRipple
                  key={`${index}-${label}`}
                  label={label}
                  ref={tab => (tabRefs.current[index] = tab)}
                  sx={{ textTransform: 'none' }}
                  tabIndex={0}
                  to={routePath}
                  value={id}
                  onKeyDown={e => handleKeyDown(e, index)}
                />
              ))}
            </Tabs>
          )}
          <AzulIconButton
            icon={
              showNotificationsBadge ? (
                <Badge badgeContent='' color='error'>
                  <NotificationsRoundedIcon fontSize={Size.Large} sx={{ color: colorPalette.primaryBlue }} />
                </Badge>
              ) : (
                <NotificationsRoundedIcon fontSize={Size.Large} sx={{ color: colorPalette.primaryBlue }} />
              )
            }
            size={Size.Large}
            tooltipTitle='Notifications'
            onClick={handleNotificationClick}
          />
          <AzulProfileSettings
            options={[
              { label: 'Option A', value: 'a', icon: <StarOutlineOutlinedIcon /> },
              { label: 'Option B', value: 'b', icon: <StarOutlineOutlinedIcon /> },
              { label: 'Option C', value: 'c', icon: <StarOutlineOutlinedIcon /> },
              { label: 'Option D', value: 'd', icon: <StarOutlineOutlinedIcon /> },
              { label: 'Log Out', value: 'logout', icon: <LogoutOutlinedIcon /> },
            ]}
            onClick={handleOnClickProfileSettings}
            onClickOption={value => value}
          />
          <NotificationPopover anchorEl={anchorEl} popoverOpen={popoverOpen} onClose={handlePopoverClose} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
