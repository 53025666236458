import { NotificationModel } from '../ts/interfaces/models/NotificationModel';
import { BaseService, ListResponseData } from './baseService';

class NotificationService extends BaseService<NotificationModel> {
  controllerName = 'notifications';

  async getAllNotificationPage(
    id: number,
    limit: number,
    offset: number
  ): Promise<ListResponseData<NotificationModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<NotificationModel>>(
      `user/${id}/${this.controllerName}?limit=${limit}&offset=${offset}`
    );

    return data;
  }

  async getAllUnreadNotificationPage(
    id: number,
    limit: number,
    offset: number
  ): Promise<ListResponseData<NotificationModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<NotificationModel>>(
      `user/${id}/${this.controllerName}/unread?limit=${limit}&offset=${offset}`
    );

    return data;
  }

  async putAllAsReadNotification(id: number): Promise<ListResponseData<NotificationModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.put<ListResponseData<NotificationModel>>(
      `user/${id}/${this.controllerName}/allread`
    );

    return data;
  }
}

export const notificationService = new NotificationService();
