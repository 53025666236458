import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { rowsPerPageOptions } from '../../../../../constants/rowsPerPage';
import { Spacings } from '../../../../../styles/partials/spacings';
import { toCamelCase } from '../../../../../utils/toCamelCase';
import { ExampleRow } from '../ExampleRow';

interface ExampleTableProps {
  columns: string[]; // Array of column names
  page: number; // Active page
  rows: ExampleRow[]; // Array of ExampleRow objects
  rowsPerPage: number; // Number of rows per page
  totalItems: number; // Array length
  onChangePage: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const ExampleTable = ({
  columns,
  page,
  rows,
  rowsPerPage,
  totalItems,
  onChangePage,
  onChangeRowsPerPage,
}: ExampleTableProps) => {
  const [activeColumn, setActiveColumn] = useState(''); // Track the currently active column for sorting
  const [isAscending, setIsAscending] = useState(true); // Track the sorting order (ascending or descending)
  const startIndex = (page - 1) * rowsPerPage + 1; // Initial index of the displayed elements on the current page
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalItems); // Final index of the displayed elements on the current page, limited by the total number of items
  const pageCount = Math.ceil(totalItems / rowsPerPage); // Total number of pages based on the number of items and rows per page

  // Checks if an attribute is a valid key of the ExampleRow interface
  const isAttributeValid = (attribute: string, obj: ExampleRow): attribute is keyof ExampleRow => {
    return attribute in obj;
  };

  const handleOnClickSort = (column: string) => {
    if (activeColumn === column) {
      setIsAscending(!isAscending); // Reverse the sorting order if the same column is clicked
    } else {
      setActiveColumn(column); // Set the clicked column as the active column
      setIsAscending(true); // Set the sorting order to ascending by default
    }
  };

  const sortRows = (a: ExampleRow, b: ExampleRow) => {
    if (isAttributeValid(activeColumn, a)) {
      if (a[activeColumn] < b[activeColumn]) {
        return isAscending ? -1 : 1; // Sort in ascending or descending order based on the active column and sorting order
      }
      if (a[activeColumn] > b[activeColumn]) {
        return isAscending ? 1 : -1; // Sort in ascending or descending order based on the active column and sorting order
      }
    }
    return 0; // No sorting necessary if the active column is not valid or the values are equal
  };

  return (
    <TableContainer>
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Typography variant='body2'>{`Showing ${startIndex}-${endIndex} of ${totalItems} candidates profile`}</Typography>
        <TablePagination
          component='div'
          count={rows.length}
          page={0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const parsedColumn = toCamelCase(column); // Convert the column name to camelCase format
              return (
                <TableCell key={`${index}-${column}`} style={{ maxWidth: 112 }}>
                  <TableSortLabel
                    active={activeColumn === parsedColumn} // Highlight the column if it is the currently active column
                    direction={isAscending ? 'asc' : 'desc'} // Set the sorting direction based on the sorting order
                    onClick={() => handleOnClickSort(parsedColumn)} // Handle click events on column headers for sorting
                  >
                    <Typography color='initial' noWrap variant='subtitle1'>
                      {column.toUpperCase()} {/* Display the column name in uppercase */}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.sort(sortRows).map((row, index) => (
            <ExampleRow exampleRow={row} key={index} /> // Render each row using the ExampleRow component
          ))}
        </TableBody>
      </Table>
      <Box display='flex' justifyContent='center' marginTop={Spacings.spacing11}>
        <Pagination count={pageCount} page={page} onChange={(_e, newPage) => onChangePage(null, newPage)} />
      </Box>
    </TableContainer>
  );
};
