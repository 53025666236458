import { setOverview, setOverviewRequestStatus } from '.';
import { overviewService } from '../../service/overviewService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { AppThunk } from '../store';

export const getOverview = (): AppThunk => async dispatch => {
  try {
    dispatch(setOverviewRequestStatus(RequestStatus.Loading));

    const { data } = await overviewService.getOverview();

    dispatch(setOverview(data));
    dispatch(setOverviewRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setOverviewRequestStatus(RequestStatus.Failed));
  }
};
