import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface SkillOptions {
  skillOptions: Option[];
  skillOptionsRequestStatus: RequestStatus;
}

const initialState: SkillOptions = {
  skillOptions: [],
  skillOptionsRequestStatus: RequestStatus.Idle,
};

export const skillSlice = createSlice({
  name: 'skills',
  initialState,
  reducers,
});

export const { setSkillOptions, setSkillOptionsRequestStatus } = skillSlice.actions;

export default skillSlice.reducer;
