import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Overview } from '../../ts/types/Overview';
import reducers from './reducer';

export interface OverviewState {
    overview: Overview;
    overviewRequestStatus: RequestStatus;
}

const initialState: OverviewState = {
    overview: {
        newOpenPositions: 0,
        closedPositions: 0,
        canceledPosition: 0,
        onHoldPositions: 0,
        activeCandidates: 0, offers: { pending: 0, accepted: 0 }
    },
    overviewRequestStatus: RequestStatus.Idle,
};

export const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers,
});

// Extract the action creators generated by createSlice
export const { setOverview, setOverviewRequestStatus } =
    overviewSlice.actions;

export default overviewSlice.reducer;
