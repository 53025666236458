/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  setJobPosition,
  setJobPositionOptions,
  setJobPositionOptionsRequestStatus,
  setJobPositionRequestStatus,
  setJobPositions,
  setJobPositionsColumn,
  setJobPositionsColumnRequestStatus,
  setJobPositionsRequestStatus,
  setNewJobPosition,
} from '.';
import { SearchJobPositionParams, jobPositionService } from '../../service/jobPositionService';
import { jobSkillService } from '../../service/jobSkillService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { ModalNewJobType } from '../../ts/interfaces/forms/ModalNewJob';
import {
  parseJobPositionModelToJobPosition,
  parseSingleJobPositionModelToJobPosition,
} from '../../utils/parsers/jobPosition';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getJobPositionById =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobPositionRequestStatus(RequestStatus.Loading));

      const { data } = await jobPositionService.getJobPositionById(id);

      const parsedData = parseSingleJobPositionModelToJobPosition(data);
      dispatch(setJobPosition(parsedData));

      dispatch(setJobPositionRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setJobPositionRequestStatus(RequestStatus.Failed));
    }
  };

// Thunk action to get all job positions
export const getAllJobPositions = (): AppThunk => async dispatch => {
  try {
    dispatch(setJobPositionsRequestStatus(RequestStatus.Loading));

    const { data } = await jobPositionService.getJobsPositions();

    const parsedData = parseJobPositionModelToJobPosition(data);
    dispatch(setJobPositions(parsedData));
    dispatch(setJobPositionsRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setJobPositionsRequestStatus(RequestStatus.Failed));
  }
};

export const getJobPositionsColumn = (): AppThunk => async dispatch => {
  try {
    dispatch(setJobPositionsColumnRequestStatus(RequestStatus.Loading));

    const { data } = await jobPositionService.getJobsPositions();
    const parsedData = parseJobPositionModelToJobPosition(data);

    dispatch(setJobPositionsColumn(parsedData));

    dispatch(setJobPositionsColumnRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setJobPositionsColumnRequestStatus(RequestStatus.Failed));
  }
};
export const getAllJobPositionOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobPositionOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await jobPositionService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setJobPositionOptions(parsedData));
      dispatch(setJobPositionOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setJobPositionOptionsRequestStatus(RequestStatus.Failed));
    }
  };

export const searchJobPositions =
  (params: SearchJobPositionParams): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobPositionsRequestStatus(RequestStatus.Loading));
      const { data } = await jobPositionService.searchJobPosition(params);
      const parsedData = parseJobPositionModelToJobPosition(data);
      dispatch(setJobPositions(parsedData));
      dispatch(setJobPositionsRequestStatus(RequestStatus.Success));
    } catch (error: any) {
      if (error.response.status === 404 && error.response.data.data) {
        dispatch(setJobPositions(error.response.data.data));
        dispatch(setJobPositionsRequestStatus(RequestStatus.Success));
      } else {
        dispatch(setJobPositionsRequestStatus(RequestStatus.Failed));
      }
    }
  };
export const createNewJobPosition =
  (jobPositionInfo: ModalNewJobType): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobPositionsRequestStatus(RequestStatus.Loading));

      const jobPositionBody = {
        title: jobPositionInfo.jobTitle,
        description: jobPositionInfo.description,
        department_id: jobPositionInfo.department,
        required_positions: jobPositionInfo.requiredPositions,
        status: jobPositionInfo.status,
        client_id: jobPositionInfo.client,
        seniority_id: jobPositionInfo.seniority,
        salary_min: jobPositionInfo.salaryRange,
        salary_max: jobPositionInfo.salaryRange,
        salary_currency_code: jobPositionInfo.salaryCurrency,
        salary_period: jobPositionInfo.salaryPeriod,
        urgent: jobPositionInfo.urgent,
        remote: jobPositionInfo.remote,
        salary_visible: jobPositionInfo.salaryVisible,
        job_position_visible: jobPositionInfo.jobPositionVisible,
        jobType_id: jobPositionInfo.jobType,
        jobTime_id: jobPositionInfo.jobTime,
        location_id: jobPositionInfo.location,
      };

      const { data } = await jobPositionService.create(jobPositionBody);

      jobPositionInfo.skills?.forEach(
        async skill => await jobSkillService.create({ jobId: data.id, skillId: skill.value })
      );

      const { data: finalResponse } = await jobPositionService.getJobPositionById(data.id);
      const parsedData = parseSingleJobPositionModelToJobPosition(finalResponse);

      dispatch(setNewJobPosition(parsedData));

      dispatch(setJobPositionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setJobPositionsRequestStatus(RequestStatus.Failed));
    }
  };
