import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ReactNode } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulButtonProps } from '../../ts/interfaces/AzulButton';

/**
 * Represents a customized button component.
 *
 * @param label - The label text for the button.
 * @param disabled - Indicates whether the button is disabled. (Optional)
 * @param isSubmit - Indicates whether the button is a submit button. (Optional)
 * @param endIcon - The icon component to display at the end of the button. (Optional)
 * @param startIcon - The icon component to display at the start of the button. (Optional)
 * @param loading - Indicates whether the button is in a loading state. (Optional)
 * @param variant - The variant of the button. (Optional)
 * @param onClick - Event handler for the button's click event. (Optional)
 */

export const AzulButton = ({
  disabled,
  endIcon,
  label,
  isSubmit,
  loading,
  startIcon,
  variant,
  onClick,
}: AzulButtonProps) => {
  const isDisabled = disabled || loading;

  const renderIcon = (icon: ReactNode) => {
    if (icon || loading) {
      return loading && icon === startIcon ? (
        <Box display='flex' padding={Spacings.spacing2}>
          <CircularProgress
            aria-label='Loading'
            size={'19px'}
            sx={{
              fontSize: Spacings.spacing8,
              color: colorPalette.disabledGray2,
            }}
            thickness={6}
          />
        </Box>
      ) : (
        <Box sx={{ fontSize: Spacings.spacing8, display: 'flex' }}>{icon}</Box>
      );
    }

    return null;
  };

  return (
    <Button
      disabled={isDisabled}
      endIcon={!loading && renderIcon(endIcon)}
      startIcon={renderIcon(startIcon)}
      type={isSubmit ? 'submit' : 'button'}
      variant={variant}
      onClick={onClick}
    >
      <Typography
        sx={{
          textTransform: 'none',
        }}
        variant='button'
      >
        {label}
      </Typography>
    </Button>
  );
};
