import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulEmptyStateProps } from '../../ts/interfaces/AzulEmptyState';
import { AzulButton } from '../AzulButton';

/**
 * Represents an empty state component.
 *
 * @param title - The title text for the empty state.
 * @param image - The URL of the image for the empty state.
 * @param alt - The alt text for the image. (Optional)
 * @param buttonLabel - The label text for the button. (Optional)
 * @param description - The description text for the empty state. (Optional)
 * @param marginTop - The top margin of the component. (Optional)
 * @param onClick - Event handler for the button click event. (Optional)
 */

export const AzulEmptyState = ({
  alt = 'Empty state image',
  description,
  image,
  buttonLabel,
  marginTop,
  title,
  onClick,
}: AzulEmptyStateProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        margin: 'auto',
        width: '100%',
        marginTop: `${marginTop}px`,
      }}
    >
      <img alt={alt} src={image} width='180px' />
      <Typography
        sx={{
          color: colorPalette.paragraph,
          paddingBottom: Spacings.spacing3,
          paddingTop: Spacings.spacing7,
          textAlign: 'center',
        }}
        variant='h3'
      >
        {title}
      </Typography>
      {description && (
        <Typography
          sx={{ color: colorPalette.paragraph, paddingBottom: Spacings.spacing8, textAlign: 'center' }}
          variant='body1'
        >
          {description}
        </Typography>
      )}
      {buttonLabel && onClick && <AzulButton label={buttonLabel} variant='primary' onClick={onClick} />}
    </Box>
  );
};
