import { Box, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, Fragment, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulScroll } from '../../../../components/AzulScroll';
import { AzulSkeleton } from '../../../../components/AzulSkeleton';
import { AzulTextField } from '../../../../components/AzulTextField';
import {
  selectCandidatePrivateNotes,
  selectCandidatePrivateNotesRequestStatus,
  selectCandidatePublicNotes,
  selectCandidatePublicNotesRequestStatus,
} from '../../../../store/candidate/selectors';
import {
  createNewPrivateNote,
  createNewPublicNote,
  getCandidatePrivateNotes,
  getCandidatePublicNotes,
} from '../../../../store/candidate/thunks';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { RequestStatus } from '../../../../ts/enums/RequestStatus';
import { hasMoreThanOneDayDifference } from '../../../../utils/hasMoreThanOneDayDifference';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks/storeHooks';
import { CandidateChatDate } from '../CandidateChatDate';
import { ChatMessage } from '../ChatMessage/ChatMessage';
import { NoteVisibility } from '../NoteVisibility/NoteVisibility';

interface CandidateChatProps {
  candidateId: string;
}

export const CandidateChat = ({ candidateId }: CandidateChatProps) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [newMessage, setNewMessage] = useState('');
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];

  const dispatch = useAppDispatch();

  const candidatePrivateNotes = useAppSelector(selectCandidatePrivateNotes);
  const candidatePublicNotes = useAppSelector(selectCandidatePublicNotes);
  const candidatePrivateNotesRequestStatus = useAppSelector(selectCandidatePrivateNotesRequestStatus);
  const candidatePublicNotesRequestStatus = useAppSelector(selectCandidatePublicNotesRequestStatus);
  const isLoadingCandidatePrivateNotes = candidatePrivateNotesRequestStatus === RequestStatus.Loading;
  const isLoadingCandidatePublicteNotes = candidatePublicNotesRequestStatus === RequestStatus.Loading;
  const isLoadingCandidateNotes = isLoadingCandidatePrivateNotes || isLoadingCandidatePublicteNotes;

  // user_example should be removed and replaced with active session user
  const userExample = { id: '1', name: 'Andy Hernandez' };

  const tabsArray = [
    { id: 1, label: 'Public Notes', onClick: handleOnClickPublicTab },
    { id: 2, label: 'Private', onClick: handleOnClickPrivateTab },
  ];

  const getCandidateNotesList = () => {
    if (activeTab === 1) {
      return candidatePublicNotes.map(({ createdAt, comment, name, userId }, index) => {
        if (createdAt)
          return (
            <Fragment key={`${index}-${name}-${comment}`}>
              {index !== 0 &&
                hasMoreThanOneDayDifference(
                  createdAt?.toISOString(),
                  candidatePublicNotes[index - 1].createdAt?.toISOString()
                ) && <CandidateChatDate date={createdAt} />}
              <ChatMessage
                comment={comment}
                createdAt={createdAt}
                key={`${index}-${name}-${comment}`}
                name={name.toUpperCase()}
                noteUserId={userId}
                userId={userExample.id}
              />
            </Fragment>
          );
      });
    }

    if (activeTab === 2) {
      return candidatePrivateNotes.map(({ createdAt, comment, name, userId }, index) => {
        if (createdAt)
          return (
            <Fragment key={`${index}-${name}-${comment}`}>
              {index !== 0 &&
                hasMoreThanOneDayDifference(
                  createdAt?.toISOString(),
                  candidatePrivateNotes[index - 1].createdAt?.toISOString()
                ) && <CandidateChatDate date={createdAt} />}
              <ChatMessage
                comment={comment}
                createdAt={createdAt}
                key={`${index}-${name}-${comment}`}
                name={name.toUpperCase()}
                noteUserId={userId}
                userId={userExample.id}
              />
            </Fragment>
          );
      });
    }
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  const handleMessageSend = () => {
    if (activeTab === 1) {
      dispatch(createNewPublicNote(userExample, newMessage));
    } else {
      dispatch(createNewPrivateNote(userExample, newMessage));
    }
    setNewMessage('');
  };

  function handleOnClickPrivateTab() {
    dispatch(getCandidatePrivateNotes(candidateId, userExample));
  }

  function handleOnClickPublicTab() {
    dispatch(getCandidatePublicNotes(candidateId, userExample));
  }

  return (
    <Box
      borderRadius={`${Spacings.spacing3}px`}
      boxSizing='border-box'
      display='flex'
      flexDirection='column'
      height={496}
      paddingBottom={Spacings.spacing10}
      paddingTop={Spacings.spacing5}
      paddingX={Spacings.spacing8}
      sx={{
        backgroundColor: activeTab === 1 ? colorPalette.accentBlue1 : colorPalette.primaryHoverBlue1,
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
      }}
      width='100%'
    >
      <Tabs
        sx={{ marginBottom: Spacings.spacing4 }}
        value={activeTab || tabsArray[0].id}
        onChange={(_event, newValue) => setActiveTab(newValue)}
      >
        {tabsArray.map(({ id, label, onClick }, index) => (
          <Tab
            disableFocusRipple
            key={`${index}-${label}`}
            label={label}
            sx={{ textTransform: 'none', background: 'none' }}
            tabIndex={0}
            value={id}
            onClick={onClick}
          />
        ))}
      </Tabs>
      <NoteVisibility
        label={activeTab === 1 ? 'Notes visible to the hiring team.' : 'These notes are only visible to you'}
      />
      <Box marginBottom={Spacings.spacing7}>
        <AzulScroll height={101}>
          <Box height={226} marginBottom={Spacings.spacing5}>
            {isLoadingCandidateNotes ? (
              <Box display='flex' flexDirection='column' gap={20}>
                <Box alignSelf='flex-end'>
                  <AzulSkeleton height={100} width={260} />
                </Box>
                <AzulSkeleton height={100} width={260} />
                <Box alignSelf='flex-end'>
                  <AzulSkeleton height={100} width={260} />
                </Box>
              </Box>
            ) : (
              <Fragment>
                <Box>
                  {activeTab === 1 && candidatePublicNotes[0]?.createdAt ? (
                    <CandidateChatDate date={candidatePublicNotes[0].createdAt} />
                  ) : (
                    candidatePrivateNotes[0]?.createdAt && (
                      <CandidateChatDate date={candidatePrivateNotes[0].createdAt} />
                    )
                  )}
                </Box>
                {getCandidateNotesList()}
              </Fragment>
            )}
          </Box>
        </AzulScroll>
      </Box>
      <AzulTextField
        disabled={isLoadingCandidateNotes}
        label={activeTab === 1 ? 'Add comment' : 'Add private note'}
        maxLength={150}
        name='Notes'
        value={newMessage}
        onChange={handleMessageChange}
      />
      <Box display='flex' justifyContent='flex-end'>
        <AzulButton
          disabled={Boolean(!newMessage || isLoadingCandidateNotes || newMessage.length > 150)}
          label='Send'
          variant='primary'
          onClick={handleMessageSend}
        />
      </Box>
    </Box>
  );
};
