import { ChangeEvent, Dispatch, DragEvent, ReactElement, RefObject, SetStateAction } from 'react';
import { Severity } from '../../../../ts/types/Severity';
import { ToastNotificationSeverity } from '../../../../ts/enums/ToastNotificationSeverity';

interface IconMapping {
  pdf: ReactElement;
  docs: ReactElement;
  png: ReactElement;
  jpeg: ReactElement;
  jpg: ReactElement;
}
export const getFileIcon = (fileName: string, iconMapping: IconMapping) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  return (extension && iconMapping[extension as keyof typeof iconMapping]) || iconMapping.pdf;
};

const isAllowedFileType = (file: File): boolean => {
  const allowedExtensions = ['.pdf', '.doc', '.docx', '.jpeg', '.jpg', '.png'];
  const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

export const handleFileChange = (
  event: ChangeEvent<HTMLInputElement>,
  toastNotificationOpenSetter: Dispatch<SetStateAction<boolean>>,
  toastNotificationSeveritySetter: Dispatch<SetStateAction<Severity>>,
  setter: Dispatch<SetStateAction<File | null>>
) => {
  if (event.target.files) {
    const selectedFiles = Array.from(event.target.files);

    // Function to check if the file size is allowed (less than or equal to 10 MB)
    const isAllowedFileSize = (file: File): boolean => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
      return file.size <= maxSizeInBytes;
    };

    // Filter allowed files based on both type and size
    const allowedFiles = selectedFiles.filter(file => isAllowedFileType(file) && isAllowedFileSize(file));

    if (allowedFiles.length < selectedFiles.length) {
      // Display an error message or perform any other action for disallowed files
      toastNotificationOpenSetter(true);
      toastNotificationSeveritySetter('warning');
      return;
    }

    // Update the state variable with the names of selected files
    setter(allowedFiles[0]);
    toastNotificationOpenSetter(true);
    toastNotificationSeveritySetter('success');
  }
};

export const handleFileDrop = (
  event: DragEvent<HTMLDivElement>,
  fileInputRef: RefObject<HTMLInputElement>,
  toastNotificationOpenSetter: Dispatch<SetStateAction<boolean>>,
  toastNotificationSeveritySetter: Dispatch<SetStateAction<Severity>>,
  setter: Dispatch<SetStateAction<File | null>>
) => {
  event.preventDefault();
  event.stopPropagation();

  const selectedFiles = event.dataTransfer.files;

  // Filter allowed files from the dropped files
  const allowedFiles = Array.from(selectedFiles).filter(file => isAllowedFileType(file));

  if (allowedFiles.length === 0) {
    // Display an error message or perform any other action for disallowed files
    console.log('Only PDF, DOC, and image files are allowed.');
    toastNotificationOpenSetter(true);
    toastNotificationSeveritySetter('warning');
    return;
  }

  // Convert the array of File to FileList
  const fileInput = fileInputRef.current;
  if (fileInput) {
    const dataTransfer = new DataTransfer();
    allowedFiles.forEach(file => dataTransfer.items.add(file));
    fileInput.files = dataTransfer.files;
  }

  // Create a promise-based function to read the files asynchronously
  const readFile = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(file);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the file.'));
      };
      reader.readAsDataURL(file);
    });
  };

  // Use Promise.all to read the allowed files asynchronously and then update the state and Formik form field
  Promise.all(allowedFiles.map(readFile))
    .then(files => {
      // Update the state variable with the selected files
      setter(files[0]);
      toastNotificationOpenSetter(true);
      toastNotificationSeveritySetter('success');
    })
    .catch(error => {
      // Handle any errors that occurred during file reading, if necessary.
      console.error(error);
    });
};

export const truncateLabel = (label: string, maxLength: number): string => {
  if (label.length > maxLength) {
    return label.substr(0, maxLength - 3) + '...';
  }
  return label;
};

export const getToastMessage = (
  toastNotificationSeverity: Severity,
  errors: { cvFile?: string }
): string => {
  if (toastNotificationSeverity === ToastNotificationSeverity.Warning) {
    return 'That extension is not allowed';
  } else if (toastNotificationSeverity === ToastNotificationSeverity.Error) {
    return errors.cvFile || 'An error occurred';
  } else {
    return 'File successfully submitted';
  }
};