import { FormControl, Grid } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FocusEventHandler } from 'react';
import { AzulDropdownMenu } from '../../../../../components/AzulDropdownMenu';
import { AzulMultipleAutocomplete } from '../../../../../components/AzulMultipleAutocomplete';
import { AzulTextField } from '../../../../../components/AzulTextField';
import { Spacings } from '../../../../../styles/partials/spacings';
import { Option } from '../../../../../ts/interfaces/AzulOption';
import { ModalNewJobType } from '../../../../../ts/interfaces/forms/ModalNewJob';

interface NewJobFormStepOneProps {
  departmentOptions: Option[];
  errors: FormikErrors<ModalNewJobType>;
  seniorityOptions: Option[];
  locationOptions: Option[];
  jobTimeOptions: Option[];
  jobTypeOptions: Option[];
  skillsOptions: Option[];
  touched: FormikTouched<ModalNewJobType>;
  values: ModalNewJobType;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange?: {
    (e: ChangeEvent<HTMLInputElement>): void;
    <T = string | ChangeEvent<HTMLInputElement>>(field: T): T extends ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | ChangeEvent<HTMLInputElement>) => void;
  };
  setFieldValue?: (field: string, value: NonNullable<unknown>, shouldValidate?: boolean) => void;
}

export const NewJobFormStepOne = ({
  departmentOptions,
  errors,
  seniorityOptions,
  locationOptions,
  jobTimeOptions,
  jobTypeOptions,
  skillsOptions,
  touched,
  values,
  onBlur,
  onChange,
  setFieldValue,
}: NewJobFormStepOneProps) => {
  return (
    <Grid
      columnSpacing={Spacings.spacing10}
      container
      justifyContent={'space-between'}
      marginBottom={Spacings.spacing11}
      rowGap={Spacings.spacing7}
    >
      <Grid container flexDirection='column' item xs={6}>
        <FormControl sx={{ marginTop: Spacings.spacing6 }}>
          <AzulTextField
            error={Boolean(errors.jobTitle)}
            helper={(touched.jobTitle && errors.jobTitle) || ''}
            label='Job Title'
            name='jobTitle'
            touched={touched.jobTitle}
            value={values.jobTitle}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl sx={{ marginTop: Spacings.spacing6 }}>
          <AzulDropdownMenu
            error={Boolean(errors.department)}
            helperText={(touched.department && errors.department) || ''}
            label='Department'
            name='department'
            options={departmentOptions}
            touched={touched.department}
            value={values.department}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.seniority)}
            helperText={(touched.seniority && errors.seniority) || ''}
            label='Seniority'
            name='seniority'
            options={seniorityOptions}
            touched={touched.seniority}
            value={values.seniority}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.location)}
            helperText={(touched.location && errors.location) || ''}
            label='Location'
            name='location'
            options={locationOptions}
            touched={touched.location}
            value={values.location}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' gap={Spacings.spacing7} item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.jobType)}
            helperText={(touched.jobType && errors.jobType) || ''}
            label='Job Type'
            name='jobType'
            options={jobTypeOptions}
            touched={touched.jobType}
            value={values.jobType}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.jobTime)}
            helperText={(touched.jobTime && errors.jobTime) || ''}
            label='Job Time'
            name='jobTime'
            options={jobTimeOptions}
            touched={touched.jobTime}
            value={values.jobTime}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={12}>
        <FormControl>
          <AzulMultipleAutocomplete
            label='Skills (optional)'
            options={skillsOptions}
            value={values.skills}
            onChange={value => setFieldValue && setFieldValue('skills', value)}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={12}>
        <FormControl>
          <AzulTextField
            error={Boolean(errors.description)}
            helper={(touched.description && errors.description) || ''}
            isMultiline
            label='Description'
            minRows={10}
            name='description'
            touched={touched.description}
            value={values.description}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
