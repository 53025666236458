import { PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '.';
import { UserRow } from '../../modules/Users/components/Table/UserRow';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import { User } from '../../ts/types/user';

const setUser = (state: UserState, action: PayloadAction<User>): void => {
  state.user = action.payload;
};

const setUserRequestStatus = (state: UserState, action: PayloadAction<RequestStatus>): void => {
  state.userRequestStatus = action.payload;
};

const setUsers = (state: UserState, action: PayloadAction<User[]>): void => {
  state.users = action.payload;
};

const setUsersRequestStatus = (state: UserState, action: PayloadAction<RequestStatus>): void => {
  state.usersRequestStatus = action.payload;
};

const setRecruiterOptions = (state: UserState, action: PayloadAction<Option[]>): void => {
  state.recruiterOptions = action.payload;
};

const setRecruiterOptionsRequestStatus = (state: UserState, action: PayloadAction<RequestStatus>): void => {
  state.recruiterOptionsRequestStatus = action.payload;
};

const setUsersTable = (state: UserState, action: PayloadAction<UserRow[]>): void => {
  state.usersTable = action.payload;
};

const setNewUsersTable = (state: UserState, action: PayloadAction<UserRow>): void => {
  state.usersTable = [...state.usersTable, action.payload];
};

const setUsersTableCount = (state: UserState, action: PayloadAction<number>): void => {
  state.usersTableCount = action.payload;
};

const setUsersTableRequestStatus = (state: UserState, action: PayloadAction<RequestStatus>): void => {
  state.usersTableRequestStatus = action.payload;
};

// Combine the reducer functions into an object
const reducers = {
  setNewUsersTable,
  setRecruiterOptions,
  setRecruiterOptionsRequestStatus,
  setUser,
  setUserRequestStatus,
  setUsers,
  setUsersRequestStatus,
  setUsersTable,
  setUsersTableCount,
  setUsersTableRequestStatus,
};

export default reducers;
