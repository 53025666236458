import { OverviewModel } from '../ts/interfaces/models/OverviewModel';
import { BaseService, ResponseData } from './baseService';

class OverviewService extends BaseService<OverviewModel> {
  controllerName = 'overview';

  async getOverview(): Promise<ResponseData<OverviewModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ResponseData<OverviewModel>>(`Job/${this.controllerName}/`);

    return data;
  }
}

export const overviewService = new OverviewService();
