import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Spacings } from '../../styles/partials/spacings';
import { AzulDeleteConfirmationModalProps } from '../../ts/interfaces/AzulDeleteConfirmationModal';
import { AzulButton } from '../AzulButton';
import { AzulModal } from '../AzulModal';

export const AzulDeleteConfirmationModal: FC<AzulDeleteConfirmationModalProps> = ({
  isOpen,
  itemToDelete,
  onClickDeleteButton,
  onClose,
}) => {
  return (
    <AzulModal isConfirmationBox isOpen={isOpen} title={`Delete ${itemToDelete}?`} onClose={onClose}>
      <Typography variant='body1-medium'>
        By doing this, the {itemToDelete} will <br /> be eliminated forever.
      </Typography>
      <Box display={'flex'} gap={Spacings.spacing6} justifyContent={'right'} mt={Spacings.spacing11}>
        <AzulButton label={'Cancel'} variant='secondary' onClick={onClose} />
        <AzulButton label={'Delete'} variant='destructive' onClick={onClickDeleteButton} />
      </Box>
    </AzulModal>
  );
};
