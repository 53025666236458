import { Search } from '@mui/icons-material';
import { Box, Button, Divider, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { FC, Fragment } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulSearchBarProps } from '../../ts/interfaces/AzulSearchBar';

/**
 * Represents a search bar component.
 *
 * @param id - The ID of the search bar.
 * @param options - The options available for autocomplete.
 * @param placeholder - The placeholder text for the search bar.
 * @param value - The current value of the search bar.
 * @param disabled - Determines if the search bar is disabled.
 * @param onClick - The callback function called when the search button is clicked.
 */

export const AzulSearchBar: FC<AzulSearchBarProps> = ({ disabled, id, options, placeholder, value, onClick }) => (
  <Box display={'flex'} width={'100%'}>
    <Autocomplete
      className='AzulSearchBar'
      disableClearable
      disabled={disabled}
      freeSolo
      fullWidth
      id={id}
      options={options}
      renderInput={params => (
        <TextField
          className='AzulSearchBar'
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: disabled ? colorPalette.disabledGray2 : colorPalette.primaryBlue,
                },
              },
            },
          }}
          value={value}
          {...params}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, { label }, { index }) => (
        <Fragment key={`${index}-${label}`}>
          <MenuItem
            sx={{
              height: Spacings.spacing11,
            }}
            {...props}
          >
            {label}
          </MenuItem>
          {index !== options.length - 1 && (
            <Divider
              style={{
                backgroundColor: colorPalette.neutralGray2,
                borderRadius: '2px',
                width: '93%',
                marginBottom: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 0,
              }}
            />
          )}
        </Fragment>
      )}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colorPalette.paragraphBaseline}`,
          borderRight: 0,
        },
      }}
    />
    <Button
      aria-label='Search button'
      className='AzulSearchBarButton'
      disabled={disabled}
      sx={{
        backgroundColor: disabled ? colorPalette.disabledGray1 : colorPalette.primaryBlue,
      }}
      variant='primary'
      onClick={() => onClick(value)}
    >
      <Search sx={{ color: disabled ? colorPalette.disabledGray2 : colorPalette.backgroundWhite }} />
    </Button>
  </Box>
);
