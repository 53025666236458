/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  inputBaseClasses,
  toolbarClasses,
} from '@mui/material';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { rowsPerPageOptions } from '../../../../../constants/rowsPerPage';
import { colorPalette } from '../../../../../styles/partials/colors';
import { Spacings } from '../../../../../styles/partials/spacings';
import { toCamelCase } from '../../../../../utils/toCamelCase';
import { CandidateRow } from '../CandidateRow';

interface CandidatesTableProps {
  columns: string[]; // Array of column names
  page: number; // Active page
  rows: CandidateRow[]; // Array of ExampleRow objects
  rowsPerPage: number; // Number of rows per page
  totalItems: number; // Array length
  handleDeleteCandidate: (userId: string) => void;
  onChangePage: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const CandidatesTable = ({
  columns,
  page,
  rows,
  rowsPerPage,
  totalItems,
  handleDeleteCandidate,
  onChangePage,
  onChangeRowsPerPage,
}: CandidatesTableProps) => {
  const [activeColumn, setActiveColumn] = useState(''); // Track the currently active column for sorting
  const [isAscending, setIsAscending] = useState(true); // Track the sorting order (ascending or descending)
  const startIndex = (page - 1) * rowsPerPage + 1; // Initial index of the displayed elements on the current page
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalItems); // Final index of the displayed elements on the current page, limited by the total number of items
  const pageCount = Math.ceil(totalItems / rowsPerPage); // Total number of pages based on the number of items and rows per page
  const keys = rows.map(() => uuidv4()); // Unique identifiers for each value

  // Checks if an attribute is a valid key of the ExampleRow interface
  const isAttributeValid = (attribute: string, obj: CandidateRow): attribute is keyof CandidateRow => {
    return attribute in obj;
  };

  const handleOnClickSort = (column: string) => {
    if (activeColumn === column) {
      setIsAscending(!isAscending); // Reverse the sorting order if the same column is clicked
    } else {
      setActiveColumn(column);
      setIsAscending(true); // Set the sorting order to ascending by default
    }
  };

  const sortRows = (a: CandidateRow, b: CandidateRow) => {
    // Check if the activeColumn attribute is a valid key in the objects a and b.
    if (isAttributeValid(activeColumn, a) && isAttributeValid(activeColumn, b)) {
      const valueA = a[activeColumn]!;
      const valueB = b[activeColumn]!;

      if (valueA < valueB) {
        return isAscending ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAscending ? 1 : -1;
      }
    }

    return 0;
  };

  return (
    <TableContainer>
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Typography variant='body2'>{`Showing ${startIndex}-${endIndex} of ${totalItems} candidates profile`}</Typography>
        <TablePagination
          component='div'
          count={rows.length}
          page={0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          sx={{
            [`.${toolbarClasses.root}`]: {
              paddingRight: Spacings.spacing4,
            },
            [`.${toolbarClasses.root} .${inputBaseClasses.root} div:focus`]: {
              outline: `${Spacings.spacing3}px solid ${colorPalette.focusPurple}`,
              offset: Spacings.spacing3,
            },
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              let parsedColumn = toCamelCase(column); // Convert the column name to camelCase format
              if (parsedColumn === 'candidate') {
                parsedColumn = 'name';
              }
              if (parsedColumn === 'jobPosition') {
                parsedColumn = 'jobId';
              }
              return (
                <TableCell key={`${index}-${column}`} style={{ maxWidth: 112 }}>
                  <TableSortLabel
                    active={activeColumn === parsedColumn} // Highlight the column if it is the currently active column
                    direction={isAscending ? 'asc' : 'desc'} // Set the sorting direction based on the sorting order
                    onClick={() => handleOnClickSort(parsedColumn)} // Handle click events on column headers for sorting
                  >
                    <Typography color='initial' noWrap variant='subtitle1'>
                      {column.toUpperCase()} {/* Display the column name in uppercase */}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...rows].sort(sortRows).map((row, index) => (
            <CandidateRow candidateRow={row} key={keys[index]} onDeleteUser={handleDeleteCandidate} />
          ))}
        </TableBody>
      </Table>
      <Box display='flex' justifyContent='center' marginY={Spacings.spacing11}>
        <Pagination count={pageCount} page={page} onChange={(_e, newPage) => onChangePage(null, newPage)} />
      </Box>
    </TableContainer>
  );
};
