export type ModalHiringTeamType = {
  clientInterview?: string;
  firstContactInterview?: string;
  firstTechInterview?: string;
  recruiter?: string;
  secondTechInterview?: string;
};

export const emptyValues = {
  clientInterview: '',
  firstContactInterview: '',
  firstTechInterview: '',
  recruiter: '',
  secondTechInterview: '',
};
