import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Box, TextField, Typography } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { outlinedInputClasses } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulTextFieldProps } from '../../ts/interfaces/AzulTextField';

/**
 * Represents a text field component used for inputting text.
 *
 * @param ariaLabel - The ARIA label for the text field.
 * @param disabled - Determines whether the text field is disabled or not. Defaults to false.
 * @param error - Determines whether the text field has an error state. Defaults to false.
 * @param helper - The helper text to be displayed below the text field.
 * @param id - The ID of the text field.
 * @param isMultiline - Determines whether the text field supports multiple lines. Defaults to false.
 * @param label - The label for the text field.
 * @param maxLength - The maximum length of the input value.
 * @param maxRows - The maximum number of rows for multiline input.
 * @param minRows - The minimum number of rows for multiline input.
 * @param name - The name of the text field.
 * @param placeholder - The placeholder text for the text field.
 * @param type - The type of the text field.
 * @param value - The current value of the text field.
 * @param onBlur - A callback function invoked when the text field loses focus.
 * @param onChange - A callback function invoked when the text field value changes.
 */

export const AzulTextField = ({
  ariaLabel,
  disabled,
  error,
  helper,
  id,
  isMultiline,
  label,
  maxLength,
  maxRows,
  minRows,
  name,
  placeholder,
  type,
  touched,
  value,
  onBlur,
  onChange,
}: AzulTextFieldProps) => {
  return (
    <Box display='flex' flexDirection='column'>
      <TextField
        InputLabelProps={{
          sx: {
            '&.Mui-focused': {
              paddingX: Spacings.spacing4,
              marginLeft: '-6px', //solution for uniformly overlay the label (shrunk to the top left) over the outline and border of the textfield when focused
            },
          },
        }}
        InputProps={
          error && touched
            ? {
                endAdornment: error && (
                  <InputAdornment position='end'>
                    <ErrorRoundedIcon sx={{ color: colorPalette.destructiveRed2 }} />
                  </InputAdornment>
                ),
                sx: {
                  [`&.${outlinedInputClasses.root}`]: {
                    '&.Mui-focused ': {
                      outline: 'none',
                      [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: colorPalette.destructiveRed1,
                        borderWidth: 1,
                      },
                    },
                    '&:hover fieldset': {
                      borderColor: colorPalette.destructiveRed1,
                    },
                  },
                },
              }
            : undefined
        }
        aria-label={ariaLabel}
        disabled={disabled}
        error={Boolean(touched && error)}
        id={id}
        label={label}
        maxRows={maxRows}
        minRows={minRows}
        multiline={isMultiline}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      {(helper || maxLength) && (
        <Box
          display='flex'
          justifyContent='space-between'
          paddingLeft={Spacings.spacing7}
          paddingRight={Spacings.spacing2}
          paddingTop={Spacings.spacing3}
        >
          {helper && (
            <Typography
              color={
                error && touched
                  ? colorPalette.destructiveRed1
                  : disabled
                  ? colorPalette.disabledGray2
                  : colorPalette.paragraphBaseline
              }
              variant='caption'
            >
              {helper}
            </Typography>
          )}
          {maxLength && maxLength > 0 && (
            <Typography
              color={
                error
                  ? colorPalette.destructiveRed1
                  : disabled
                  ? colorPalette.disabledGray2
                  : colorPalette.paragraphBaseline
              }
              variant='caption'
            >
              {`${value?.length}/${maxLength}`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
