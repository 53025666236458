// Declare a constant enum for colorPalette (Figma) colors
const enum colorPalette {
  // Main Brand Colors
  primaryBlue = '#2A5BDF', // Define a specific shade of blue for the primary color
  backgroundWhite = '#FFFF', // Define white as a background color
  backgroundBlue = '#F9FBFF', // Define a light blue as another background color
  paragraph = '#474748', // Define a color for paragraphs

  // Auxiliar Colors
  accentRed = '#EF565F',
  destructiveRed1 = '#DE1A1A',
  destructiveRed2 = '#AD1414',
  destructiveRed3 = '#730D0D',
  accentYellow = '#EDCF66',
  accentGreen1 = '#3DDB69',
  accentGreen2 = '#076105',
  accentBlue1 = '#ECF1FD',
  accentPurple = '#9240B9',
  primaryHoverBlue1 = '#C0D1FF',
  secondaryBlue = '#7F9CEC',
  primaryHoverBlue2 = '#2046AD',
  primaryPressedBlue = '#152E73',
  accentBlue2 = '#215585',
  focusPurple = '#B770FE',

  // Black Tints and Shades
  neutralGray1 = '#F5F5F6',
  disabledGray1 = '#ECECED',
  neutralGray2 = '#D8D8D9',
  disabledGray2 = '#B5B5B6',
  paragraphPlaceholder = '#979899',
  paragraphBaseline = '#474748',
  neutralGray3 = '#323232',
  black = '#111111',

  // MuiRadio Colors
  radioHover = 'rgba(181, 181, 182, 0.12)',
  radioFocus = 'rgba(181, 181, 182, 0.25)',
}

// Export the colorPalette enum for use in other files
export { colorPalette };
