import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Card, CardContent, Grid, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulIconButton } from '../../../../components/AzulIconButton';
import { stageOptions, statusOptions } from '../../../../constants/candidateOptions';
import { setCandidateStage, setCandidateStatus } from '../../../../store/candidate';
import { updateCandidateStage, updateCandidateStatus } from '../../../../store/candidate/thunks';
import { setJobPositionCandidatesStage } from '../../../../store/jobPosition/';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { CandidateStage } from '../../../../ts/enums/CandidateStage';
import { CandidateStatus } from '../../../../ts/enums/CandidateStatus';
import { Size } from '../../../../ts/enums/Size';
import { Candidate } from '../../../../ts/types/candidate';
import { useAppDispatch } from '../../../../utils/hooks/storeHooks';
import { ModalJobDetails } from '../ModalJobDetails';

interface CandidateDetailsProps {
  candidate: Candidate;
}

export const CandidateDetails = ({ candidate }: CandidateDetailsProps) => {
  const {
    firstContactDate,
    country,
    email,
    hiringTeam,
    phoneNo,
    seniority,
    yearsOfExperience,
    salaryExpectations,
    jobPosition,
    referredBy,
    referred,
    source,
    linkedinProfile,
  } = candidate;
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  const dispatch = useAppDispatch();

  const [isModalOpen, setModalOpen] = useState(false);

  const candidateDetails = [
    { label: 'Email', value: email },
    { label: 'Phone.no.', value: phoneNo },
    { label: 'Seniority', value: seniority },
    { label: 'Years of experience', value: yearsOfExperience },
    { label: 'Salary expectations', value: salaryExpectations },
    { label: 'Country', value: country },
  ];

  const handleOnClickEditButton = () => {
    alert('click!');
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnChangeStatus = (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value as CandidateStatus;
    dispatch(setCandidateStatus({ id: candidate.id, status: newStatus }));
    dispatch(updateCandidateStatus(candidate.id, newStatus));
  };

  const handleOnChangeStage = (event: SelectChangeEvent<string>) => {
    const newStage = event.target.value as CandidateStage;
    dispatch(updateCandidateStage(candidate.id, newStage));
    dispatch(setCandidateStage({ id: candidate.id, stage: newStage }));
    dispatch(
      setJobPositionCandidatesStage({
        jobId: candidate.jobId,
        candidateId: candidate.id,
        stage: newStage,
      })
    );
  };

  return (
    <Card
      elevation={0}
      sx={{
        height: '496px',
        width: '100%',
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
        background: colorPalette.accentBlue1,
      }}
    >
      <CardContent>
        <Typography marginBottom={13} variant='h3'>
          Details
        </Typography>
        <Grid container spacing={Spacings.spacing5}>
          <Grid height={Spacings.spacing12} item xs={6}>
            <AzulDropdownMenu
              label='Status'
              options={statusOptions}
              renderStatus
              stringLength={7}
              value={candidate.status}
              onChange={handleOnChangeStatus}
            />
          </Grid>
          <Grid height={Spacings.spacing12} item xs={6}>
            <AzulDropdownMenu
              label='Stage'
              options={stageOptions}
              stringLength={7}
              value={candidate.stage}
              onChange={handleOnChangeStage}
            />
          </Grid>
          <Grid alignItems='flex-start' display='flex' item justifyContent='space-between' marginTop={16} xs={12}>
            <Box>
              {candidateDetails.map(({ label, value }, index) => (
                <Typography
                  color={colorPalette.paragraphBaseline}
                  display='flex'
                  gap={3}
                  key={`${label}-${index}`}
                  noWrap
                  variant='body2-bold'
                >
                  {label}: <Typography variant='body2'>{value}</Typography>
                </Typography>
              ))}
              <Typography color={colorPalette.paragraphBaseline} marginTop={Spacings.spacing8} variant='h3'>
                Recruitment
              </Typography>
              <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2-bold'>
                Job position:
                <a
                  href='#'
                  style={{
                    color: colorPalette.primaryBlue,
                    cursor: 'pointer',
                    fontWeight: 'normal',
                    textDecoration: 'none',
                  }}
                  onClick={handleOpenModal}
                >
                  {jobPosition.title}
                </a>
              </Typography>
              <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2-bold'>
                Recruiter:
                <Typography color={colorPalette.paragraphBaseline} variant='body2'>
                  {hiringTeam.recruiter}
                </Typography>
              </Typography>
              {referred && (
                <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2-bold'>
                  Referred by: <Typography variant='body2'>{referredBy}</Typography>
                </Typography>
              )}
              <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2-bold'>
                First contact date:{' '}
                <Typography color={colorPalette.paragraphBaseline} variant='body2'>
                  {firstContactDate}
                </Typography>
              </Typography>
              <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2-bold'>
                Source:
                <Typography color={colorPalette.paragraphBaseline} display='flex' gap={3} variant='body2'>
                  {source}
                  {source === 'Linkedin' && linkedinProfile && (
                    <Typography sx={{ color: colorPalette.primaryBlue }} variant='body2'>
                      -{' '}
                      <a
                        href={linkedinProfile}
                        rel='noreferrer'
                        style={{
                          color: colorPalette.primaryBlue,
                          textDecoration: 'none',
                        }}
                        target='_blank'
                      >
                        Go to profile
                      </a>
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </Box>
            <AzulIconButton
              icon={<EditRoundedIcon sx={{ color: colorPalette.primaryBlue }} />}
              size={Size.Small}
              tooltipTitle={`Edit candidate`}
              onClick={handleOnClickEditButton} //Change this for the redirection to the edit profile view when its done.
            />
          </Grid>
        </Grid>
      </CardContent>
      <ModalJobDetails candidate={candidate} isOpen={isModalOpen} onClose={handleCloseModal} />
    </Card>
  );
};
