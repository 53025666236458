import Box from '@mui/material/Box/Box';
import { StepIconProps } from '@mui/material/StepIcon';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';

export const AzulStepIcon = ({ active, completed }: StepIconProps) => {
  return (
    <Box
      sx={{
        width: Spacings.spacing4,
        height: Spacings.spacing4,
        margin: [10, 10, 0, 10],
        borderRadius: Spacings.spacing2,
        backgroundColor: active || completed ? `${colorPalette.primaryBlue}` : `${colorPalette.disabledGray1}`,
      }}
    />
  );
};
