import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { FormControl, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulModal } from '../../../../components/AzulModal';
import { Spacings } from '../../../../styles/partials/spacings';
import { ModalHiringTeamProps } from '../../../../ts/interfaces/ModalHiringTeam';
import { validationSchema } from '../../validators/validationSchema';

export const ModalHiringTeam = ({
  clientInterviewOptions,
  firstContactInterviewOptions,
  firstTechInterviewOptions,
  initialValues,
  isOpen,
  recruiterOptions,
  secondTechInterviewOptions,
  title,
  onClose,
  onSubmit,
}: ModalHiringTeamProps) => (
  <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ values, touched, errors, handleBlur, handleChange }) => {
      return (
        <AzulModal isOpen={isOpen} title={title} onClose={onClose}>
          <Form>
            <Grid
              columnSpacing={Spacings.spacing10}
              container
              height={'100%'}
              justifyContent={'space-between'}
              rowGap={Spacings.spacing8}
            >
              <Grid container flexDirection='column' item xs={6}>
                <FormControl sx={{ marginTop: Spacings.spacing6 }}>
                  <AzulDropdownMenu
                    error={Boolean(errors.recruiter)}
                    helperText={errors.recruiter || 'This field is required'}
                    label='Recruiter'
                    name='recruiter'
                    options={recruiterOptions}
                    touched={touched.recruiter}
                    value={values.recruiter}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} />
              <Grid container flexDirection='column' item xs={6}>
                <Typography variant='h3'>1st contact interview</Typography>
                <FormControl sx={{ marginTop: Spacings.spacing6, marginBottom: Spacings.spacing8 }}>
                  <AzulDropdownMenu
                    label='First contact interview'
                    name='firstContactInterview'
                    options={firstContactInterviewOptions}
                    value={values.firstContactInterview}
                    onChange={handleChange}
                  />
                </FormControl>
                <Typography variant='h3'>2nd tech interview</Typography>
                <FormControl sx={{ marginTop: Spacings.spacing6 }}>
                  <AzulDropdownMenu
                    disabled={Boolean(!values.firstTechInterview)}
                    label='Second tech interview'
                    name='secondTechInterview'
                    options={secondTechInterviewOptions}
                    value={values.secondTechInterview}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container flexDirection={'column'} item xs={6}>
                <Typography variant='h3'>1st tech interview</Typography>
                <FormControl sx={{ marginTop: Spacings.spacing6, marginBottom: Spacings.spacing8 }}>
                  <AzulDropdownMenu
                    label='First tech interview'
                    name='firstTechInterview'
                    options={firstTechInterviewOptions}
                    value={values.firstTechInterview}
                    onChange={handleChange}
                  />
                </FormControl>
                <Typography variant='h3'>Client interview</Typography>
                <FormControl sx={{ marginTop: Spacings.spacing6 }}>
                  <AzulDropdownMenu
                    label='Team member (optional)'
                    name='clientInterview'
                    options={clientInterviewOptions}
                    value={values.clientInterview}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid container item justifyContent='flex-end' mt={Spacings.spacing8} xs={12}>
                <AzulButton
                  disabled={!values.recruiter}
                  endIcon={<CheckRoundedIcon />}
                  isSubmit
                  label='Submit'
                  variant='primary'
                />
              </Grid>
            </Grid>
          </Form>
        </AzulModal>
      );
    }}
  </Formik>
);
