import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { Fragment } from 'react';
import { Spacings } from '../../styles/partials/spacings';
import { StepType } from '../../ts/enums/Steps';
import { AzulStepperProps } from '../../ts/interfaces/AzulStepper';
import { AzulStepConnector } from './components/AzulStepConnector';
import { AzulStepIcon } from './components/AzulStepIcon';
import { getStepType, getTypographyColor } from './helpers';

/**
 * Represents a stepper component used for guiding users through a series of steps.
 *
 * @param activeStep - The index of the active step.
 * @param steps - An array of strings representing the labels for each step.
 */

export const AzulStepper = ({ steps, activeStep }: AzulStepperProps) => {
  return (
    <Fragment>
      <Stack spacing={Spacings.spacing3} sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<AzulStepConnector />}>
          {steps.map((label, index) => {
            const stepType = getStepType(activeStep, index);
            const typographyColor = getTypographyColor(stepType);
            return (
              <Step key={`${index}-${label}`}>
                <StepLabel
                  StepIconComponent={props => <AzulStepIcon {...props} completed={stepType === StepType.Completed} />}
                >
                  <Typography sx={typographyColor} variant={StepType.Active === stepType ? 'body2-medium' : 'body2'}>
                    {index + 1}
                  </Typography>
                  <Typography sx={typographyColor} variant={StepType.Active === stepType ? 'body2-medium' : 'body2'}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    </Fragment>
  );
};
