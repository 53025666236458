import { Box, Grid, Pagination, Stack, Typography } from '@mui/material';
import React, { Fragment, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CandidateCard } from '../../components/AzulCandidateCard';
import { AzulDropdownMenu } from '../../components/AzulDropdownMenu';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { MAIN_ROUTES } from '../../constants/routes';
import {
  selectFirstCandidatesColumn,
  selectFirstCandidatesColumnRequestStatus,
  selectSecondCandidatesColumn,
  selectSecondCandidatesColumnRequestStatus,
  selectThirdCandidatesColumn,
  selectThirdCandidatesColumnRequestStatus,
} from '../../store/candidate/selectors';
import {
  getFirstCandidateColumn,
  getSecondCandidateColumn,
  getThirdCandidateColumn,
} from '../../store/candidate/thunks';
import { getJobsPositions } from '../../store/jobPosition/extraReducers';
import { selectJobPositions, selectJobPositionsColumnRequestStatus } from '../../store/jobPosition/selectors';
import { getJobPositionsColumn } from '../../store/jobPosition/thunks';
import { selectOverview, selectOverviewRequestStatus } from '../../store/overview/selectors';
import { getOverview } from '../../store/overview/thunks';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { NewJobPosition } from '../../ts/types/JobPosition';
import { Candidate, CandidateColumn } from '../../ts/types/candidate';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { JobOfferCard } from '../Jobs/components/JobOfferCard';
import { JobPositionCard } from '../Jobs/components/JobPositionCard';
import { JobSummaryCard } from './components/JobSummaryCard';
import { OverviewCard } from './components/OverviewCard';
import { options } from './mockData';

export const Home = () => {
  const user = {
    name: 'Oliver Khan',
  };

  // Get the `dispatch` function from the store
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const jobPositions = useAppSelector(selectJobPositions);
  const firstCandidates = useAppSelector(selectFirstCandidatesColumn);
  const secondCandidates = useAppSelector(selectSecondCandidatesColumn);
  const thirdCandidates = useAppSelector(selectThirdCandidatesColumn);
  const { activeCandidates, canceledPosition, closedPositions, newOpenPositions, offers, onHoldPositions } =
    useAppSelector(selectOverview);

  // Get all request status from the store
  const jobPositionsRequestStatus = useAppSelector(selectJobPositionsColumnRequestStatus);
  const firstCandidatesRequestStatus = useAppSelector(selectFirstCandidatesColumnRequestStatus);
  const secondCandidatesRequestStatus = useAppSelector(selectSecondCandidatesColumnRequestStatus);
  const thirdCandidatesRequestStatus = useAppSelector(selectThirdCandidatesColumnRequestStatus);
  const overviewRequestStatus = useAppSelector(selectOverviewRequestStatus);

  // Determine if the data is loading
  const isLoadingJobPositions = jobPositionsRequestStatus === RequestStatus.Loading;
  const isLoadingFirstCandidates = firstCandidatesRequestStatus === RequestStatus.Loading;
  const isLoadingSecondCandidates = secondCandidatesRequestStatus === RequestStatus.Loading;
  const isLoadingThirdCandidates = thirdCandidatesRequestStatus === RequestStatus.Loading;
  const isLoadingOverview = overviewRequestStatus === RequestStatus.Loading;

  const dispatchHomeData = () => {
    if (!firstCandidates.length && !secondCandidates.length && !thirdCandidates.length) {
      dispatch(getJobPositionsColumn());
      dispatch(getFirstCandidateColumn('1st technical interview'));
      dispatch(getSecondCandidateColumn('2nd technical interview'));
      dispatch(getThirdCandidateColumn('Make an offer'));
      dispatch(getOverview());
      dispatch(getJobsPositions());
    }
  };

  const renderTrackingColumn = (
    elements: Candidate[] | NewJobPosition[] | CandidateColumn[],

    isLoading: boolean,

    isCandidate?: boolean,
    defaultValue?: string
  ) => {
    //  Paginations
    const rowsPerPage = 4;
    const elementsLength = elements.length;
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(elementsLength / rowsPerPage);
    const [filteredElements, setFilteredCandidatesRows] = useState(elements.slice(0, rowsPerPage));

    const handleChangeCandidatePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    };

    const handleOnClickCandidate = (id: string) => {
      navigate(`${MAIN_ROUTES.CANDIDATES}/candidate/${id}`);
    };

    // Make the API call
    useEffect(dispatchHomeData, [navigate, dispatch]);

    useEffect(() => {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const newFilteredElements = elements.slice(startIndex, endIndex);
      setFilteredCandidatesRows(newFilteredElements);
    }, [elements, page]);

    return (
      <Grid
        item
        md={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: Spacings.spacing5,
          marginBottom: Spacings.spacing8,
          marginTop: Spacings.spacing1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: Spacings.spacing5,
            paddingTop: Spacings.spacing3,
          }}
        >
          <AzulDropdownMenu disabled={isLoading} options={options} value={defaultValue || options[0].value} />
          {isLoading ? (
            <Stack spacing={Spacings.spacing5}>
              <AzulSkeleton height={Spacings.spacing12} />
              <AzulSkeleton height={isCandidate ? 96 : 136} />
              <AzulSkeleton height={isCandidate ? 96 : 136} />
              <AzulSkeleton height={isCandidate ? 96 : 136} />
              <AzulSkeleton height={isCandidate ? 96 : 136} />
            </Stack>
          ) : (
            <Fragment>
              <JobSummaryCard label={`Active ${isCandidate ? 'candidates' : 'job positions'}`} value={elementsLength} />
              {
                // TODO: Refactor this
              }
              {elementsLength ? (
                <Fragment>
                  {filteredElements.map(element => {
                    if (isCandidate) {
                      const { id, job, name, recruiter } = element as CandidateColumn;
                      return (
                        <CandidateCard
                          job={job}
                          key={`${uuidv4()}-${name}`}
                          name={name}
                          recruiter={recruiter}
                          onClick={() => handleOnClickCandidate(id.toString())}
                        />
                      );
                    }
                    const { id, title, location, pay } = element as NewJobPosition;
                    return (
                      <JobPositionCard id={id} job={title} key={id} location={location} pay={pay} selected={false} />
                    );
                  })}
                  <Pagination
                    count={pageCount}
                    page={page}
                    sx={{ alignSelf: 'center', marginTop: Spacings.spacing4 }}
                    onChange={(_e, newPage) => handleChangeCandidatePage(null, newPage)}
                  />
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <AzulLayout title={`Welcome back, ${user.name}`}>
      <Grid item md={9}>
        <Typography color={colorPalette.paragraphBaseline} marginBottom={Spacings.spacing6} variant='h3'>
          New on this week
        </Typography>
        {isLoadingOverview ? (
          <AzulSkeleton height={120} />
        ) : (
          <OverviewCard
            activeCandidates={activeCandidates}
            canceledPositions={canceledPosition}
            closedPositions={closedPositions}
            newOpenPositions={newOpenPositions}
            onHoldPositions={onHoldPositions}
          />
        )}
      </Grid>
      <Grid container item md={3}>
        <Typography color={colorPalette.paragraphBaseline} marginBottom={Spacings.spacing6} variant='h3'>
          Offers
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          gap={Spacings.spacing5}
          marginBottom={Spacings.spacing5}
          width='100%'
        >
          {isLoadingOverview ? (
            <Fragment>
              <AzulSkeleton height={56} />
              <AzulSkeleton height={56} />
            </Fragment>
          ) : (
            <Fragment>
              <JobOfferCard label='Pending to make an offer: ' value={offers.pending} />
              <JobOfferCard label='Offers accepted: ' value={offers.accepted} />
            </Fragment>
          )}
        </Box>
      </Grid>
      <Grid container direction={'column'} item>
        <Grid container item sx={{ maxWidth: '100%' }}>
          <Typography color={colorPalette.paragraphBaseline} marginBottom={Spacings.spacing6} variant='h3'>
            Tracking
          </Typography>
        </Grid>
        <Grid container item spacing={32}>
          {renderTrackingColumn(jobPositions, isLoadingJobPositions)}
          {renderTrackingColumn(firstCandidates, isLoadingFirstCandidates, true, options[1].value)}
          {renderTrackingColumn(secondCandidates, isLoadingSecondCandidates, true, options[2].value)}
          {renderTrackingColumn(thirdCandidates, isLoadingThirdCandidates, true, options[5].value)}
        </Grid>
      </Grid>
    </AzulLayout>
  );
};
