import { Option } from '../AzulOption';

export type ModalNewJobType = {
  client?: string;
  department?: string;
  description?: string;
  hiringManager?: string;
  jobPositionVisible?: boolean;
  jobTime?: string;
  jobTitle?: string;
  jobType?: string;
  location?: string;
  manager?: string;
  remote?: boolean;
  requiredPositions?: string;
  salaryPeriod?: string;
  salaryRange?: string;
  salaryCurrency?: string;
  salaryVisible?: boolean;
  seniority?: string;
  skills?: Option[];
  status?: string;
  urgent?: boolean;
};

export const emptyValues = {
  client: '',
  department: '',
  description: '',
  hiringManager: '',
  jobPositionVisible: false,
  jobTime: '',
  jobTitle: '',
  jobType: '',
  location: '',
  manager: '',
  remote: false,
  requiredPositions: '1',
  salaryPeriod: '',
  salaryRange: '',
  salaryCurrency: 'DOP',
  salaryVisible: false,
  seniority: '',
  skills: [],
  status: '',
  urgent: false,
};
