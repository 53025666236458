import { PayloadAction } from '@reduxjs/toolkit';
import { SenioritiesOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setSeniorityOptions = (state: SenioritiesOptions, action: PayloadAction<Option[]>): void => {
  state.seniorityOptions = action.payload;
};

const setSeniorityOptionsRequestStatus = (state: SenioritiesOptions, action: PayloadAction<RequestStatus>): void => {
  state.seniorityOptionsRequestStatus = action.payload;
};

const reducers = {
  setSeniorityOptions,
  setSeniorityOptionsRequestStatus,
};

export default reducers;
