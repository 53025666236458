import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded';
import { Popover, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React, { Fragment, MouseEvent, useEffect, useRef, useState } from 'react';
import { AzulIconButton } from '../../../../../components/AzulIconButton';
import { AzulOptions } from '../../../../../components/AzulOptions';
import { editDeleteOptions } from '../../../../../constants/editDeleteOptions';
import { colorPalette } from '../../../../../styles/partials/colors';
import { Hierarchy } from '../../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../../styles/partials/spacings';

export interface UserRow {
  id?: string;
  createdAt: string;
  departmentId: string;
  email: string;
  firstName: string;
  lastName: string;
  locationId: string;
  role: string;
}

interface UserRowProps {
  userRow: UserRow;
  onDeleteUser: (userId: string) => void;
}

export const UserRow = ({ userRow, onDeleteUser }: UserRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorOrigin, setMenuAnchorOrigin] = useState<number>(0);
  const isPopoverOpen = Boolean(anchorEl);
  const moreIconButtonRef = useRef<HTMLButtonElement>(null);
  const options = editDeleteOptions;

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickOption = (option: string) => {
    if (option == '1') {
      option = 'Edit';
    }
    if (option == '2' && userRow.id) {
      option = 'Delete';
      onDeleteUser(userRow.id);
    }
  };

  const handleOnClickIcon = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuAnchorOrigin(event.currentTarget.offsetHeight);
  };

  const handleClickOutside = (event: globalThis.MouseEvent) => {
    if (moreIconButtonRef.current && !moreIconButtonRef.current.contains(event.target as Node)) {
      handlePopoverClose();
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopoverOpen]);

  return (
    <Fragment>
      <TableRow>
        {Object.entries(userRow).map(
          ([key, value], index) =>
            key !== 'id' && (
              <TableCell key={`${index}-${value}`} style={{ maxWidth: 112 }}>
                {value.length > 15 /* TODO: Refactor this functionallity later */ ? (
                  <Tooltip arrow placement='top' title={value}>
                    {/* Display the value of each property */}
                    <Typography color='initial' noWrap variant='body1'>
                      {value} {/* Display the value of each property */}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography color='initial' noWrap variant='body1'>
                    {value} {/* Display the value of each property */}
                  </Typography>
                )}
              </TableCell>
            )
        )}
        <TableCell sx={{ borderTop: `${Spacings.spacing1}px solid ${colorPalette.neutralGray2}` }}>
          {/* Render an icon button for editing */}
          <AzulIconButton
            icon={<MoreHorizRounded sx={{ color: colorPalette.paragraph }} />} // Use the EditRoundedIcon from MUI with customized styling
            ref={moreIconButtonRef}
            tooltipTitle='More actions'
            onClick={handleOnClickIcon} // Assign the click event handler for the edit icon button
          />
        </TableCell>
      </TableRow>
      <Popover
        anchorEl={moreIconButtonRef.current}
        anchorOrigin={{
          vertical: menuAnchorOrigin,
          horizontal: 'left',
        }}
        open={isPopoverOpen}
        onClose={handlePopoverClose}
      >
        <AzulOptions
          hierarchy={Hierarchy.Hierarchy2}
          options={options}
          width={'240px'}
          onClickOption={handleOnClickOption}
        />
      </Popover>
    </Fragment>
  );
};
