import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import { NewJobPosition } from '../../ts/types/JobPosition';
import { extraReducers } from './extraReducers';
import reducers from './reducer';

// Define the state shape for job positions
export interface JobPositionState {
  jobPosition: NewJobPosition;
  jobPositionRequestStatus: RequestStatus; // Tracks the request status for the selected job position
  jobPositions: NewJobPosition[]; // Holds the list of job positions
  jobPositionsRequestStatus: RequestStatus;
  jobPositionOptions: Option[];
  jobPositionOptionsRequestStatus: RequestStatus;
  jobPositionsColumn: NewJobPosition[];
  jobPositionsColumnRequestStatus: RequestStatus;
}

// Define the initial state for job positions
const initialState: JobPositionState = {
  jobPosition: {
    id: '0',
    title: '',
    description: '',
    pay: '0000 - 0000',
    urgent: false,
    required_positions: 2,
    status: 'Open',
    remote: false,
    salary_visible: true,
    job_position_visible: true,
    seniorities: [],
    location: '',
    department: '',
    client: '',
    client_job_id: '',
    hiring_manager: '',
    manager: '',
    daysOpen: '0',
    skills: [],
    job_type: '',
    job_time: '',
    candidates: [],
  },
  jobPositionRequestStatus: RequestStatus.Idle,
  jobPositions: [],
  jobPositionsRequestStatus: RequestStatus.Idle,
  jobPositionOptions: [],
  jobPositionOptionsRequestStatus: RequestStatus.Idle,
  jobPositionsColumn: [],
  jobPositionsColumnRequestStatus: RequestStatus.Idle,
};

// Create a job position slice using createSlice from Redux Toolkit
export const jobPositionSlice = createSlice({
  name: 'jobPosition',
  initialState,
  reducers,
  extraReducers,
});

// Extract the action creators generated by createSlice
export const {
  setJobPosition,
  setJobPositionRequestStatus,
  setJobPositions,
  setJobPositionsRequestStatus,
  setNewJobPosition,
  setJobPositionOptions,
  setJobPositionOptionsRequestStatus,
  setJobPositionCandidates,
  setSelectedJobPosition,
  setJobPositionsColumn,
  setJobPositionsColumnRequestStatus,
  setJobPositionCandidatesStatus,
  setJobPositionCandidatesStage,
} = jobPositionSlice.actions;

// Export the job position reducer
export default jobPositionSlice.reducer;
