import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded';
import { Box, Link, Popover, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React, { MouseEvent, useRef, useState } from 'react';
import { AzulIconButton } from '../../../../../components/AzulIconButton';
import { AzulOptions } from '../../../../../components/AzulOptions';
import { stageOptions, statusOptions } from '../../../../../constants/candidateOptions';
import { editDeleteOptions } from '../../../../../constants/editDeleteOptions';
import { MAIN_ROUTES } from '../../../../../constants/routes';
import { colorPalette } from '../../../../../styles/partials/colors';
import { Hierarchy } from '../../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../../styles/partials/spacings';
import { CandidateSource } from '../../../../../ts/enums/CandidateSource';
import { CandidateStatus } from '../../../../../ts/enums/CandidateStatus';
import { renderStatusIcon } from '../../../../../utils/renderStatusIcon';

export interface CandidateRow {
  name: string;
  jobPosition: string;
  recruiter: string;
  source: string;
  email: string;
  status: CandidateStatus;
  stage: string;
  id: string;
  linkedinProfile?: string;
}

interface CandidatesRow {
  candidateRow: CandidateRow;
  onDeleteUser: (userId: string) => void;
}

export const CandidateRow = ({ candidateRow, onDeleteUser }: CandidatesRow) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [stageAnchorEl, setStageAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorOrigin, setMenuAnchorOrigin] = useState<number>(0);
  const [stageAnchorOrigin, setStageAnchorOrigin] = useState<number>(0);
  const [statusAnchorOrigin, setStatusAnchorOrigin] = useState<number>(0);
  const isPopoverOpen = Boolean(anchorEl);
  const isStatusPopoverOpen = Boolean(statusAnchorEl);
  const isStagePopoverOpen = Boolean(stageAnchorEl);
  const statusIconButtonRef = useRef<HTMLButtonElement>(null);
  const stageIconButtonRef = useRef<HTMLButtonElement>(null);
  const moreIconButtonRef = useRef<HTMLButtonElement>(null);
  const options = editDeleteOptions;
  const { name, jobPosition, recruiter, source, email, status, stage, id, linkedinProfile } = candidateRow;

  const handleOnClickStatusIcon = (event: MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget);
    setStatusAnchorOrigin(event.currentTarget.offsetHeight);
  };

  const handleStatusPopoverClose = () => {
    setStatusAnchorEl(null);
  };

  const handleOnClickStageIcon = (event: MouseEvent<HTMLElement>) => {
    setStageAnchorEl(event.currentTarget);
    setStageAnchorOrigin(event.currentTarget.offsetHeight);
  };

  const handleOnCloseStagePopover = () => {
    setStageAnchorEl(null);
  };

  const handleOnClickIcon = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuAnchorOrigin(event.currentTarget.offsetHeight);
  };

  const handleOnClickOption = (option: string) => {
    if (option == '1') {
      option = 'Edit';
    }
    if (option == '2' && candidateRow.id) {
      option = 'Delete';
      onDeleteUser(candidateRow.id);
    }
  };

  const handleOnClosePopover = () => {
    setAnchorEl(null);
  };

  const linkToUser = (id: string) => {
    return `${MAIN_ROUTES.CANDIDATES}/candidate/${id}`;
  };

  return (
    <TableRow sx={{ maxHeight: 36 }}>
      <TableCell key={`${name}`} style={{ maxWidth: 112 }}>
        {name.length > 20 ? (
          <Tooltip arrow placement='top' title={name}>
            <Typography noWrap variant='body2'>
              <Link color={colorPalette.primaryBlue} href={linkToUser(id)} tabIndex={0} underline='none'>
                {name}
              </Link>
            </Typography>
          </Tooltip>
        ) : (
          <Typography noWrap variant='body2'>
            <Link color={colorPalette.primaryBlue} href={linkToUser(id)} tabIndex={0} underline='none'>
              {name}
            </Link>
          </Typography>
        )}
      </TableCell>
      <TableCell key={`${jobPosition}`} style={{ maxWidth: 112 }}>
        {jobPosition.length > 15 ? (
          <Tooltip arrow placement='top' title={jobPosition}>
            <Typography color='initial' noWrap variant='body2'>
              {jobPosition}
            </Typography>
          </Tooltip>
        ) : (
          <Typography color='initial' noWrap variant='body2'>
            {jobPosition}
          </Typography>
        )}
      </TableCell>
      <TableCell key={`${recruiter}`} style={{ maxWidth: 112 }}>
        {recruiter.length > 15 ? (
          <Tooltip arrow placement='top' title={recruiter}>
            <Typography color='initial' noWrap variant='body2'>
              {recruiter}
            </Typography>
          </Tooltip>
        ) : (
          <Typography color='initial' noWrap variant='body2'>
            {recruiter}
          </Typography>
        )}
      </TableCell>
      <TableCell key={`${source}`} style={{ maxWidth: 112 }}>
        {source == CandidateSource.Referred ? (
          <Box
            sx={{
              backgroundColor: colorPalette.accentPurple,
              borderRadius: `${Spacings.spacing3}px`,
              height: Spacings.spacing8,
              width: '70px',
            }}
          >
            <Typography
              sx={{
                color: colorPalette.backgroundWhite,
                paddingX: Spacings.spacing4,
                paddingY: Spacings.spacing3,
                textAlign: 'center',
              }}
              variant='caption-bold'
            >
              {source}
            </Typography>
          </Box>
        ) : source == CandidateSource.LinkedIn ? (
          <Typography noWrap variant='body2-bold'>
            <Link color={colorPalette.primaryBlue} href={linkedinProfile} underline='none'>
              {source}
            </Link>
          </Typography>
        ) : (
          <Typography color='initial' noWrap variant='body2'>
            {source}
          </Typography>
        )}
      </TableCell>
      <TableCell key={`${email}`} style={{ maxWidth: 112 }}>
        {email.length > 15 ? (
          <Tooltip arrow placement='top' title={email}>
            <Typography color='initial' noWrap variant='body2'>
              {email}
            </Typography>
          </Tooltip>
        ) : (
          <Typography color='initial' noWrap variant='body2'>
            {email}
          </Typography>
        )}
      </TableCell>
      <TableCell key={`${status}`} style={{ maxWidth: 112 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {status.length > 8 ? (
            <Tooltip arrow placement='top' title={status}>
              <Typography color='initial' noWrap variant='body2'>
                {status && renderStatusIcon(status)}
                {status}
              </Typography>
            </Tooltip>
          ) : (
            <Typography color='initial' noWrap variant='body2'>
              {status && renderStatusIcon(status)}
              {status}
            </Typography>
          )}
          <AzulIconButton
            icon={<ExpandMoreIcon sx={{ color: colorPalette.paragraph }} />}
            ref={statusIconButtonRef}
            tooltipTitle='Status'
            onClick={handleOnClickStatusIcon}
          />
          <Popover
            anchorEl={statusIconButtonRef.current}
            anchorOrigin={{
              vertical: statusAnchorOrigin,
              horizontal: 'left',
            }}
            open={isStatusPopoverOpen}
            onClose={handleStatusPopoverClose}
          >
            <AzulOptions
              hierarchy={Hierarchy.Hierarchy2}
              options={statusOptions}
              width={'240px'}
              onClickOption={handleOnClickOption}
            />
          </Popover>
        </Box>
      </TableCell>
      <TableCell key={`${stage}`} style={{ maxWidth: 112 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {stage.length > 12 ? (
            <Tooltip arrow placement='top' title={stage}>
              <Typography color='initial' noWrap variant='body2'>
                {stage}
              </Typography>
            </Tooltip>
          ) : (
            <Typography color='initial' noWrap variant='body2'>
              {stage}
            </Typography>
          )}
          <AzulIconButton
            icon={<ExpandMoreIcon sx={{ color: colorPalette.paragraph }} />}
            ref={stageIconButtonRef}
            tooltipTitle='Stage'
            onClick={handleOnClickStageIcon}
          />
          <Popover
            anchorEl={stageIconButtonRef.current}
            anchorOrigin={{
              vertical: stageAnchorOrigin,
              horizontal: 'left',
            }}
            open={isStagePopoverOpen}
            onClose={handleOnCloseStagePopover}
          >
            <AzulOptions
              hierarchy={Hierarchy.Hierarchy2}
              options={stageOptions}
              width={'240px'}
              onClickOption={handleOnClickOption}
            />
          </Popover>
        </Box>
      </TableCell>
      <TableCell sx={{ borderTop: `${Spacings.spacing1}px solid ${colorPalette.neutralGray2}` }}>
        {/* Render an icon button for editing */}
        <AzulIconButton
          icon={<MoreHorizRounded sx={{ color: colorPalette.paragraph }} />}
          ref={moreIconButtonRef}
          tooltipTitle='More actions'
          onClick={handleOnClickIcon}
        />
        <Popover
          anchorEl={moreIconButtonRef.current}
          anchorOrigin={{
            vertical: menuAnchorOrigin,
            horizontal: 'left',
          }}
          open={isPopoverOpen}
          onClose={handleOnClosePopover}
        >
          <AzulOptions
            hierarchy={Hierarchy.Hierarchy2}
            options={options}
            width={'240px'}
            onClickOption={handleOnClickOption}
          />
        </Popover>
      </TableCell>
    </TableRow>
  );
};
