import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { AzulModalProps } from '../../ts/interfaces/AzulModal';
import { AzulIconButton } from '../AzulIconButton';

/**
 * Represents a modal component.
 *
 * @param children - The content to be rendered within the modal.
 * @param isConfirmationBox - Set if the modal will be used as a confirmation box.
 * @param isOpen - Indicates whether the modal is open or closed.
 * @param title - The title of the modal.
 * @param onClose - Event handler for the close event.
 */

export function AzulModal({ children, title, onClose, isOpen, isConfirmationBox = false }: AzulModalProps) {
  const modalHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  // TODO: size should depend on content
  const modalWidth = isConfirmationBox ? '360px' : '756px';
  const modalMinHeight = isConfirmationBox ? '240px' : '405px';

  return (
    <Modal
      aria-describedby='modal-description'
      aria-labelledby='modal-title'
      open={isOpen}
      sx={{
        '& > div:focus-visible': { outline: 'none' },
        filter: `drop-shadow(${modalHierarchy.shadow} rgba(${modalHierarchy.rgba}))`,
      }}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: modalWidth,
          minHeight: modalMinHeight,
          bgcolor: colorPalette.backgroundWhite,
          boxSizing: 'border-box',
          borderRadius: `${Spacings.spacing4}px`,
          paddingX: Spacings.spacing10,
          paddingY: Spacings.spacing11,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: Spacings.spacing8,
          }}
        >
          <Typography id='modal-title' sx={{ color: colorPalette.paragraph }} variant='h2'>
            {title}
          </Typography>
          {!isConfirmationBox && (
            <AzulIconButton
              icon={<CloseRoundedIcon sx={{ color: colorPalette.primaryBlue }} />}
              tooltipTitle='Close'
              onClick={onClose}
            />
          )}
        </Box>
        {children}
      </Box>
    </Modal>
  );
}
