import { PayloadAction } from '@reduxjs/toolkit';
import { ClientOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setClientOptions = (state: ClientOptions, action: PayloadAction<Option[]>): void => {
  state.clientOptions = action.payload;
};

const setClientOptionsRequestStatus = (state: ClientOptions, action: PayloadAction<RequestStatus>): void => {
  state.clientOptionsRequestStatus = action.payload;
};

const reducers = {
  setClientOptions,
  setClientOptionsRequestStatus,
};

export default reducers;
