import { Gender } from '../../enums/Gender';

export type FormExampleType = {
  age: string;
  email: string;
  gender: string;
  name: string;
  password: string;
  salary?: number; // New field for the amount
  salaryCurrency: string; // New field for the type
};

export const emptyValues = {
  age: '',
  email: '',
  gender: '',
  name: '',
  password: '',
  salary: undefined, // Empty value for the amount
  salaryCurrency: '1', // Empty value for the type
};

export const formMockData = {
  age: '24',
  email: 'dnalcudia@hotmail.com',
  gender: Gender.Man,
  name: 'Daniel Alcudia',
  password: 'password',
  salary: 100, // Mock data for the amount
  salaryCurrency: '1', // Mock data for the type
};
