import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material/';
import Box from '@mui/material/Box';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { AzulOptionsProps } from '../../ts/interfaces/AzulOption';
import { Option } from '../../ts/interfaces/AzulOption';
import { AzulScroll } from '../AzulScroll';

/**
 * Represents a list of options.
 *
 * @param options - An array of options to display.
 *                   Each option should have a `label` and a `value`.
 * @param checkbox - Determines whether to display checkboxes next to the options.
 * @param hierarchy - The hierarchy level of the options.
 * @param placeholder - The placeholder text for the search box.
 * @param selectedOption - The value of the selected option.
 * @param showIconInline - Determines whether to show the icon inline with the option label.
 * @param width - The width of the options container.
 * @param onClickOption - The function called when an option is clicked.
 */

export const AzulOptions = ({
  checkbox,
  placeholder,
  hierarchy = Hierarchy.Hierarchy1,
  options,
  selectedOption,
  showIconInline,
  width,
  onClickOption,
}: AzulOptionsProps) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const optionsHierarchy = Hierarchies[hierarchy];
  const numItemsSearchBox = 26;
  const shouldUseAzulScroll = filteredOptions.length >= 6;

  const handleOnClickOption = (value: string) => {
    const tempOptions = filteredOptions.map((option: Option) => {
      if (option.value === value) {
        return {
          ...option,
          selected: !option.selected,
        };
      }
      return option;
    });
    setFilteredOptions(tempOptions);
    onClickOption(value);
  };

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);

    const filteredOptions =
      value.trim() === ''
        ? options
        : options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));

    setFilteredOptions(filteredOptions);
  };

  useEffect(() => {
    setShowSearchBox(filteredOptions.length > numItemsSearchBox);
  }, []);

  const optionsList = filteredOptions.map(({ label, icon, value, disabled, selected }, index) => {
    const isFirst = index === 0 && options.length <= numItemsSearchBox - 1;
    const isLast = index === filteredOptions.length - 1;
    return (
      <Fragment key={`${label}-${index}`}>
        <ListItem className='AzulOptions'>
          <ListItemButton
            className={isFirst ? 'first-item' : isLast ? 'last-item' : ''}
            disabled={disabled}
            sx={{
              opacity: disabled ? 0.5 : 1,
              height: Spacings.spacing11,
              ...(disabled && {
                pointerEvents: 'none',
                color: colorPalette.disabledGray2,
                backgroundColor: colorPalette.disabledGray1,
              }),
              ...(isFirst && {
                borderTopLeftRadius: `${Spacings.spacing4}px`,
                borderTopRightRadius: `${Spacings.spacing4}px`,
              }),
              ...(isLast && {
                borderBottomLeftRadius: `${Spacings.spacing4}px`,
                borderBottomRightRadius: `${Spacings.spacing4}px`,
              }),
              ...(showIconInline && selected && { display: 'flex', justifyContent: 'space-between' }),
            }}
            onClick={() => handleOnClickOption(value)}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: '0',
                }}
              >
                {icon}
              </ListItemIcon>
            )}
            {checkbox &&
              (selected ? (
                <CheckBoxRoundedIcon sx={{ color: colorPalette.primaryBlue, marginRight: Spacings.spacing4 }} />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon
                  sx={{ color: colorPalette.primaryBlue, marginRight: Spacings.spacing4 }}
                />
              ))}

            <Typography
              sx={{
                color: colorPalette.paragraphBaseline,
                marginLeft: icon ? Spacings.spacing5 : 0,
              }}
              variant='subtitle1'
            >
              {label}
            </Typography>
            {selectedOption === value && (
              <ListItemIcon sx={{ marginLeft: 'auto', minWidth: Spacings.spacing0 }}>
                <CheckRoundedIcon sx={{ color: colorPalette.primaryBlue }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        {!isLast && (
          <li aria-label='divider'>
            <Divider sx={{ marginX: Spacings.spacing6 }} variant='middle' />
          </li>
        )}
      </Fragment>
    );
  });

  return (
    <Box
      component='nav'
      sx={{
        display: 'inline-block',
        flexDirection: 'row',
        alignItems: 'center',
        gap: Spacings.spacing2,
        marginTop: 0,
        backgroundColor: colorPalette.backgroundWhite,
        borderRadius: `${Spacings.spacing4}px`,
        filter: `drop-shadow(${optionsHierarchy.shadow} rgba(${optionsHierarchy.rgba}))`,
        width: width,
        zIndex: 9999,
      }}
    >
      {shouldUseAzulScroll ? (
        <AzulScroll height={170}>
          <List sx={{ maxHeight: '212px', paddingY: 0 }}>
            {showSearchBox && (
              <Fragment>
                <li aria-label='search box'>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={placeholder}
                    size='small'
                    sx={{
                      padding: `${Spacings.spacing4}px ${Spacings.spacing6}px`,
                      '&:focus': {
                        outline: `${Spacings.spacing4}px solid ${colorPalette.focusPurple}`,
                        margin: Spacings.spacing2,
                      },
                      borderTopLeftRadius: `${Spacings.spacing3}px`,
                      borderTopRightRadius: `${Spacings.spacing3}px`,
                    }}
                    tabIndex={0}
                    value={searchValue}
                    variant='standard'
                    onChange={handleOnSearchChange}
                  />
                </li>
                <li aria-label='divider'>
                  <Divider sx={{ marginX: Spacings.spacing6 }} variant='middle' />
                </li>
              </Fragment>
            )}
            {optionsList}
          </List>
        </AzulScroll>
      ) : (
        <List sx={{ maxHeight: '212px', paddingY: 0 }}>
          {showSearchBox && (
            <Fragment>
              <li aria-label='search box'>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={placeholder}
                  size='small'
                  sx={{
                    padding: `${Spacings.spacing4}px ${Spacings.spacing6}px`,
                    '&:focus': {
                      outline: `${Spacings.spacing4}px solid ${colorPalette.focusPurple}`,
                      margin: Spacings.spacing2,
                    },
                    borderTopLeftRadius: `${Spacings.spacing3}px`,
                    borderTopRightRadius: `${Spacings.spacing3}px`,
                  }}
                  tabIndex={0}
                  value={searchValue}
                  variant='standard'
                  onChange={handleOnSearchChange}
                />
              </li>
              <li aria-label='divider'>
                <Divider sx={{ marginX: Spacings.spacing6 }} variant='middle' />
              </li>
            </Fragment>
          )}
          {optionsList}
        </List>
      )}
    </Box>
  );
};
