import { FormControl } from '@mui/material';
import { Grid } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FocusEventHandler } from 'react';
import { AzulCheckbox } from '../../../../../components/AzulCheckbox';
import { AzulCurrencyCombobox } from '../../../../../components/AzulCurrencyCombobox';
import { AzulDropdownMenu } from '../../../../../components/AzulDropdownMenu';
import { AzulTextField } from '../../../../../components/AzulTextField';
import { currencyOptions } from '../../../../../constants/currencyOptions';
import { Spacings } from '../../../../../styles/partials/spacings';
import { Option } from '../../../../../ts/interfaces/AzulOption';
import { ModalNewJobType } from '../../../../../ts/interfaces/forms/ModalNewJob';

interface NewJobFormStepTwoProps {
  clientOptions: Option[];
  errors: FormikErrors<ModalNewJobType>;
  hiringManagerOptions: Option[];
  managerOptions: Option[];
  salaryPeriodOptions: Option[];
  skillsOptions: Option[];
  statusOptions: Option[];
  touched: FormikTouched<ModalNewJobType>;
  values: ModalNewJobType;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange?: {
    (e: ChangeEvent<HTMLInputElement>): void;
    <T = string | ChangeEvent<HTMLInputElement>>(field: T): T extends ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | ChangeEvent<HTMLInputElement>) => void;
  };
  setFieldValue: (field: string, value: string) => void;
}

export const NewJobFormStepTwo = ({
  clientOptions,
  errors,
  hiringManagerOptions,
  managerOptions,
  salaryPeriodOptions,
  statusOptions,
  touched,
  values,
  onBlur,
  onChange,
  setFieldValue,
}: NewJobFormStepTwoProps) => {
  return (
    <Grid columnSpacing={Spacings.spacing10} container justifyContent={'space-between'} rowGap={Spacings.spacing7}>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl sx={{ marginTop: Spacings.spacing6 }}>
          <AzulTextField
            error={Boolean(errors.requiredPositions)}
            helper={(touched.requiredPositions && errors.requiredPositions) || ''}
            label='Required Positions'
            name='requiredPositions'
            touched={touched.requiredPositions}
            type='number'
            value={values.requiredPositions}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl sx={{ marginTop: Spacings.spacing6 }}>
          <AzulDropdownMenu
            error={Boolean(errors.status)}
            helperText={(touched.status && errors.status) || ''}
            label='Status'
            name='status'
            options={statusOptions}
            touched={touched.status}
            value={values.status}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.hiringManager)}
            helperText={(touched.hiringManager && errors.hiringManager) || ''}
            label='Hiring Manager'
            name='hiringManager'
            options={hiringManagerOptions}
            touched={touched.hiringManager}
            value={values.hiringManager}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.manager)}
            helperText={(touched.manager && errors.manager) || ''}
            label='Manager'
            name='manager'
            options={managerOptions}
            touched={touched.manager}
            value={values.manager}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection='column' gap={Spacings.spacing7} item marginBottom={Spacings.spacing11} xs={6}>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.client)}
            helperText={(touched.client && errors.client) || ''}
            label='Client'
            name='client'
            options={clientOptions}
            touched={touched.client}
            value={values.client}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
        <FormControl>
          <AzulCurrencyCombobox
            error={Boolean(errors.salaryRange)}
            helper={(touched.salaryRange && errors.salaryRange) || ''}
            label={'Salary range'}
            name='salaryRange'
            options={currencyOptions}
            placeholder='Salary range'
            value={Number(values.salaryRange)}
            onBlur={onBlur}
            onChange={onChange}
            onClickOption={option => setFieldValue('salaryCurrency', option)}
          />
        </FormControl>
        <FormControl>
          <AzulDropdownMenu
            error={Boolean(errors.salaryPeriod)}
            helperText={(touched.salaryPeriod && errors.salaryPeriod) || ''}
            label='Salary Period'
            name='salaryPeriod'
            options={salaryPeriodOptions}
            touched={touched.salaryPeriod}
            value={values.salaryPeriod}
            onBlur={onBlur}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid container flexDirection={'column'} item xs={6}>
        <FormControl sx={{ display: 'flex', gap: Spacings.spacing4 }}>
          <AzulCheckbox checked={values.urgent} label='Urgent!' name='urgent' onChange={onChange} />
          <AzulCheckbox checked={values.remote} label='Remote' name='remote' onChange={onChange} />
          <AzulCheckbox
            checked={values.salaryVisible}
            label='Salary visible to everyone'
            name='salaryVisible'
            onChange={onChange}
          />
          <AzulCheckbox
            checked={values.jobPositionVisible}
            label='Job position visible on website'
            name='jobPositionVisible'
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
