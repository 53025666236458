import { Box, Typography } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';

interface ChatMessageProps {
  comment: string;
  name: string;
  createdAt?: Date;
  noteUserId?: string;
  userId?: string;
}

export const ChatMessage = ({ comment, createdAt, name, noteUserId, userId }: ChatMessageProps) => {
  const hours = String(createdAt?.getHours()).padStart(2, '0');
  const minutes = String(createdAt?.getMinutes()).padStart(2, '0');

  return (
    <Box alignItems={userId === noteUserId ? 'flex-end' : 'flex-start'} display='flex' flexDirection='column'>
      <Box
        sx={{
          padding: Spacings.spacing5,
          boxSizing: 'border-box',
          borderRadius: `${Spacings.spacing4}px`,
          backgroundColor: colorPalette.backgroundWhite,
          maxWidth: 260,
          minWidth: 240,
          marginBottom: Spacings.spacing6,
        }}
      >
        <Box alignItems='center' display='flex' gap={Spacings.spacing3}>
          <Typography color={colorPalette.paragraphBaseline} variant='subtitle2'>
            {name}
          </Typography>
          <Typography variant='caption'>{`(${hours}:${minutes})`}</Typography>
        </Box>
        <Typography variant='body2'>{comment}</Typography>
      </Box>
    </Box>
  );
};
