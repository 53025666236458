import {
  buttonBaseClasses,
  formHelperTextClasses,
  inputAdornmentClasses,
  inputLabelClasses,
  outlinedInputClasses,
} from '@mui/material';
import { DateView, pickersCalendarHeaderClasses, pickersDayClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pickersArrowSwitcherClasses } from '@mui/x-date-pickers/internals';
import React from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulDatePickerProps } from '../../ts/interfaces/AzulDatePicker';

//  TODO: move these styles to the customTheme
const desktopPaperStyles = {
  borderRadius: '8px',
  boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.2)',
  [`& .${pickersDayClasses.dayWithMargin}`]: {
    color: colorPalette.paragraphBaseline,
    fontWeight: 400,
    '&.Mui-focusVisible': {
      bgcolor: colorPalette.accentBlue1,
      outline: `1px solid ${colorPalette.focusPurple}`,
      '&.Mui-selected': {
        bgcolor: colorPalette.primaryBlue,
      },
    },
    '&.Mui-selected': {
      bgcolor: colorPalette.primaryBlue,
      color: colorPalette.backgroundWhite,
    },
    '&.Mui-disabled': {
      color: colorPalette.paragraphPlaceholder,
    },
  },
  [`& .${pickersDayClasses.dayWithMargin}:focus.Mui-selected`]: {
    bgcolor: colorPalette.primaryBlue,
  },
  [`& .${pickersDayClasses.dayWithMargin}:hover`]: {
    bgcolor: colorPalette.accentBlue1,
  },
  '& .MuiDayCalendar-weekDayLabel': {
    color: colorPalette.paragraphBaseline,
    fontWeight: 500,
  },
  [`& .${pickersCalendarHeaderClasses.label}`]: {
    color: colorPalette.paragraphBaseline,
    fontWeight: 500,
    fontSize: Spacings.spacing6,
    lineHeight: Spacings.spacing8,
    cursor: 'default',
  },
  [`& .${pickersArrowSwitcherClasses.root}`]: {
    gap: Spacings.spacing5,
  },
  [`& .${pickersDayClasses.dayOutsideMonth}`]: {
    color: colorPalette.paragraphPlaceholder,
  },
  [`& .${pickersCalendarHeaderClasses.switchViewButton}`]: {
    marginRight: Spacings.spacing2,
  },
};

const datePickerStyles = {
  [`& .${inputLabelClasses.root}`]: {
    color: colorPalette.paragraphBaseline,
    fontFamily: 'Rubik',
    zIndex: 1,
    '&.Mui-focused': {
      color: colorPalette.primaryBlue,
    },
    '&.Mui-error': {
      color: colorPalette.destructiveRed2,
    },
    '&.Mui-disabled': {
      color: colorPalette.disabledGray2,
    },
  },
  [`& .${outlinedInputClasses.input}`]: {
    fontSize: Spacings.spacing6,
    height: '1.5rem',
    letterSpacing: '0.002em',
    paddingX: Spacings.spacing6,
    paddingY: Spacings.spacing5,
  },
  [`& .${outlinedInputClasses.root}`]: {
    '&.Mui-disabled': {
      border: `1px solid ${colorPalette.disabledGray2}`,
      bgcolor: colorPalette.disabledGray1,
    },
    '& fieldset': {
      borderColor: colorPalette.paragraphBaseline,
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: colorPalette.primaryBlue,
    },
    '&.Mui-error fieldset': {
      borderColor: colorPalette.destructiveRed2,
    },
    '&.Mui-disabled fieldset': {
      zIndex: -1,
    },
    [`& .${inputAdornmentClasses.root} .${buttonBaseClasses.root}`]: {
      right: Spacings.spacing4,
    },
  },
  [`& .${formHelperTextClasses.root}`]: {
    color: colorPalette.paragraphBaseline,
    fontFamily: 'Rubik',
    '&.Mui-error': {
      color: colorPalette.destructiveRed2,
    },
    '&.Mui-disabled': {
      color: colorPalette.disabledGray2,
    },
  },
};

const getDatePickerViews = {
  day: ['day'],
  month: ['day', 'month'],
  year: ['day', 'month', 'year'],
};

/**
 * Represents a customized date picker component.
 *
 * @param disableFuture - Indicates whether future dates are disabled. (Optional)
 * @param disablePast - Indicates whether past dates are disabled. (Optional)
 * @param disabled - Indicates whether the date picker is disabled. (Optional)
 * @param error - Indicates whether the date picker has an error. (Optional)
 * @param helperText - The helper text for the date picker. (Optional)
 * @param label - The label text for the date picker. (Optional)
 * @param type - The type of the date picker. (Optional)
 * @param value - The selected value of the date picker. (Optional)
 * @param onChange - Event handler for the date picker's change event. (Optional)
 */

export const AzulDatePicker = ({
  disableFuture = false,
  disablePast = false,
  disabled,
  error,
  helperText = 'MM/DD/YYYY',
  label,
  type = 'day',
  value,
  onChange,
}: AzulDatePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      disableFuture={disableFuture}
      disableHighlightToday
      disablePast={disablePast}
      disabled={disabled}
      label={label}
      showDaysOutsideCurrentMonth
      slotProps={{
        textField: {
          error,
          helperText,
        },
        desktopPaper: {
          sx: desktopPaperStyles,
        },
      }}
      sx={datePickerStyles}
      value={value}
      views={getDatePickerViews[type] as DateView[]}
      onChange={onChange}
    />
  </LocalizationProvider>
);
