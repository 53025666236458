import { setJobTypeOptions, setJobTypeOptionsRequestStatus } from '.';
import { jobTypeService } from '../../service/jobTypeService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllJobTypeOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobTypeOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await jobTypeService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setJobTypeOptions(parsedData));

      dispatch(setJobTypeOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setJobTypeOptionsRequestStatus(RequestStatus.Failed));
    }
  };
