import * as yup from 'yup';

// Define the validation schema using Yup
export const validationSchema = yup.object().shape({
  name: yup.string().required('The name field is required').min(3, 'The name must be at least 3 characters long'),
  email: yup.string().email('Invalid email address').required('The email field is required'),
  gender: yup.string().required('The gender field is required'),
  age: yup
    .number()
    .required('The age field is required')
    .min(18, 'You must be at least 18 years old')
    .max(100, 'Age cannot exceed 100 years'),
  password: yup
    .string()
    .required('The password field is required')
    .min(6, 'The password must be at least 6 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{6,}$/,
      'The password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  salary: yup
    .number()
    .required('The currency amount is required')
    .positive('The currency amount must be a positive number')
    .integer('The currency amount must be an integer'),
});
