import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Popover, Typography } from '@mui/material';
import React, { Fragment, MouseEvent, useRef, useState } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { AzulRowsPerPageButtonProps } from '../../ts/interfaces/AzulRowsPerPageButton';
import { AzulOptions } from '../AzulOptions';

/**
 * Represents a button to select the number of rows per page.
 *
 * @param options - An array of options for the rows per page selection.
 * @param onClickOption - The event handler called when an option is selected.
 */

export const AzulRowsPerPageButton = ({ options, disabled, onClickOption }: AzulRowsPerPageButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>(options[0].label);
  const open = Boolean(anchorEl);
  const moreIconButtonRef = useRef<HTMLButtonElement>(null);
  const handleOnClickIconButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseIconButton = () => {
    setAnchorEl(null);
  };

  const handleOnClickOption = (selectedOption: string) => {
    setSelectedValue(selectedOption);
    onClickOption(selectedOption);
    handleOnCloseIconButton();
  };

  return (
    <Fragment>
      <Button
        className='AzulRowsPerPageButton'
        disabled={disabled}
        focusRipple={false}
        ref={moreIconButtonRef}
        sx={{
          backgroundColor: open ? colorPalette.primaryHoverBlue1 : colorPalette.backgroundWhite,
          border: 'none',
        }}
        onClick={handleOnClickIconButton}
      >
        <Typography color={colorPalette.paragraph} textTransform='none' variant='body2'>
          {selectedValue}
        </Typography>
        {open ? (
          <ExpandLessRoundedIcon
            sx={{
              color: colorPalette.paragraph,
              width: Spacings.spacing8,
              height: Spacings.spacing8,
            }}
          />
        ) : (
          <ExpandMoreRoundedIcon
            sx={{
              color: colorPalette.paragraph,
              width: Spacings.spacing8,
              height: Spacings.spacing8,
            }}
          />
        )}
      </Button>
      <Popover
        anchorEl={moreIconButtonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        sx={{ marginTop: Spacings.spacing12 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleOnCloseIconButton}
      >
        <AzulOptions
          hierarchy={Hierarchy.Hierarchy2}
          options={options}
          selectedOption={selectedValue}
          width={'240px'}
          onClickOption={handleOnClickOption}
        />
      </Popover>
    </Fragment>
  );
};
