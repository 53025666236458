import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface ManagerOptions {
  managerOptions: Option[];
  managerOptionsRequestStatus: RequestStatus;
}

const initialState: ManagerOptions = {
  managerOptions: [],
  managerOptionsRequestStatus: RequestStatus.Idle,
};

export const managerSlice = createSlice({
  name: 'managers',
  initialState,
  reducers,
});

export const { setManagerOptions, setManagerOptionsRequestStatus } = managerSlice.actions;

export default managerSlice.reducer;
