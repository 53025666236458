import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import * as colors from '../../../../styles/partials/colors';

const gradientBackground = `linear-gradient(89.78deg, ${colors.colorPalette.primaryHoverBlue1} 14.37%, ${colors.colorPalette.primaryBlue} 110.74%)`;
export const AzulStepConnector  = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: gradientBackground,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: gradientBackground,
      },
    },
  }));