import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';

interface CandidateChatDateProps {
  date: Date;
}

export const CandidateChatDate = ({ date }: CandidateChatDateProps) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get the correct month (0-11)
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='center'
      marginBottom={Spacings.spacing2}
      marginTop={Spacings.spacing3}
      width='100%'
    >
      <Divider
        sx={{
          border: `${colorPalette.paragraphBaseline} 1px solid`,
          flex: 1,
        }}
      />
      <Typography padding={'0 10px'} variant='caption'>{`${month}/${day}/${year}`}</Typography>
      <Divider
        sx={{
          border: `${colorPalette.paragraphBaseline} 1px solid`,
          flex: 1,
        }}
      />
    </Box>
  );
};
