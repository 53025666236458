import { RootState } from '../store';

// Selects the current job position from the state
export const selectJobPosition = (state: RootState) => state.jobPosition.jobPosition;

// Selects the request status for the current job position from the state
export const selectJobPositionRequestStatus = (state: RootState) => state.jobPosition.jobPositionRequestStatus;

// Selects the list of job positions from the state
export const selectjobPositions = (state: RootState) => state.jobPosition.jobPositions;

// Selects the request status for the job positions from the state
export const selectJobPositionsRequestStatus = (state: RootState) => state.jobPosition.jobPositionsRequestStatus;

export const selectJobPositionOptions = (state: RootState) => state.jobPosition.jobPositionOptions;

export const selectJobPositionOptionsRequestStatus = (state: RootState) => state.jobPosition.jobPositionOptionsRequestStatus;

export const selectSelectedJobPosition = (state: RootState) =>
  state.jobPosition.jobPositions.find(jobPosition => jobPosition.selected);

// Selects the list of job positions from the state
export const selectJobPositionsColumn = (state: RootState) => state.jobPosition.jobPositionsColumn;

export const selectJobPositions = (state: RootState) => state.jobPosition.jobPositions;

// Selects the request status for the job positions from the state
export const selectJobPositionsColumnRequestStatus = (state: RootState) =>
  state.jobPosition.jobPositionsColumnRequestStatus;
