import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AzulLayout } from '../../components/AzulLayout';
import { AzulSkeleton } from '../../components/AzulSkeleton';
import { setHiringTeam } from '../../store/candidate';
import { selectCandidate, selectCandidateRequestStatus } from '../../store/candidate/selectors';
import { getCandidatePublicNotes } from '../../store/candidate/thunks';
import { getCandidateById } from '../../store/candidate/thunks';
import { Spacings } from '../../styles/partials/spacings';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { ModalHiringTeamType, emptyValues } from '../../ts/interfaces/forms/ModalHiringTeamType';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { parseHiringTeamFormDataToArray } from '../../utils/parsers/hiringTeamFormData';
import { CandidateChat } from './components/CandidateChat';
import { CandidateDetails } from './components/CandidateDetails';
import { CandidateFeedback } from './components/CandidateFeedback';
import { HiringTeamContainer } from './components/HiringTeamContainer';
import { ModalHiringTeam } from './components/ModalHiringTeam';
import {
  clientInterviewOptions,
  firstContactInterviewOptions,
  firstTechInterviewOptions,
  recruiterOptions,
  secondTechInterviewOptions,
} from './mockData';

export const Candidate = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const candidate = useAppSelector(selectCandidate);
  const candidateRequestStatus = useAppSelector(selectCandidateRequestStatus);
  const isLoadingCandidate = candidateRequestStatus === RequestStatus.Loading;
  const userExample = { id: '1', name: 'Andy Hernandez' };

  const hiringTeam = id ? candidate.hiringTeam : emptyValues;
  const hiringTeamArray = parseHiringTeamFormDataToArray(hiringTeam);
  const isHiringTeamComplete = hiringTeamArray?.length === 4;

  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  const handleSubmit = (
    values: ModalHiringTeamType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    toggleModal();
    dispatch(setHiringTeam(values));
  };

  const handleOnClickIconButton = () => {
    navigate(-1); //  Go back
  };

  const dipatchGetCandidateById = () => {
    if (id) {
      dispatch(getCandidateById(id));
      dispatch(getCandidatePublicNotes(id, userExample));
    }
  };

  useEffect(dipatchGetCandidateById, []);

  return (
    <AzulLayout
      isTitleLoading={isLoadingCandidate}
      referred={candidate.referred}
      title={candidate.name}
      onClickBackButton={handleOnClickIconButton}
    >
      <Grid container item xs={12}>
        {isLoadingCandidate ? (
          <AzulSkeleton height={Spacings.spacing12} />
        ) : (
          <Typography maxWidth={750} variant='body1'>
            Set the hiring team members for each step of the hiring process for this candidate (1st tech interview, 2nd
            tech interview, etc.).
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {isLoadingCandidate ? (
          <AzulSkeleton height={Spacings.spacing15} />
        ) : (
          <HiringTeamContainer hiringTeamArray={hiringTeamArray} onClick={toggleModal} />
        )}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        {isLoadingCandidate ? <AzulSkeleton height={496} /> : <CandidateDetails candidate={candidate} />}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        {isLoadingCandidate ? <AzulSkeleton height={496} /> : <CandidateFeedback />}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        {isLoadingCandidate ? <AzulSkeleton height={496} /> : <CandidateChat candidateId={candidate.id} />}
      </Grid>
      <ModalHiringTeam
        clientInterviewOptions={clientInterviewOptions}
        firstContactInterviewOptions={firstContactInterviewOptions}
        firstTechInterviewOptions={firstTechInterviewOptions}
        initialValues={hiringTeam}
        isOpen={isModalOpen}
        recruiterOptions={recruiterOptions}
        secondTechInterviewOptions={secondTechInterviewOptions}
        title={isHiringTeamComplete ? 'Edit Hiring Team' : 'Hiring Team'}
        onClose={toggleModal}
        onSubmit={handleSubmit}
      />
    </AzulLayout>
  );
};
