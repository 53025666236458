import { PayloadAction } from '@reduxjs/toolkit';
import { JobPositionState } from '.';
import { CandidateStage } from '../../ts/enums/CandidateStage';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import { NewJobPosition } from '../../ts/types/JobPosition';
import { NewCandidate } from '../../ts/types/candidate';

// Reducer function to set the job position in the state
const setJobPosition = (state: JobPositionState, action: PayloadAction<NewJobPosition>): void => {
  state.jobPosition = action.payload;
};

// Reducer function to set the request status for the job position in the state
const setJobPositionRequestStatus = (state: JobPositionState, action: PayloadAction<RequestStatus>): void => {
  state.jobPositionRequestStatus = action.payload;
};

// Reducer function to set the job positions in the state
const setJobPositions = (state: JobPositionState, action: PayloadAction<NewJobPosition[]>): void => {
  state.jobPositions = action.payload;
};

const setNewJobPosition = (state: JobPositionState, action: PayloadAction<NewJobPosition>): void => {
  state.jobPositions = [...state.jobPositions, action.payload];
};

// Reducer function to set the request status for the job positions in the state
const setJobPositionsRequestStatus = (state: JobPositionState, action: PayloadAction<RequestStatus>): void => {
  state.jobPositionsRequestStatus = action.payload;
};

const setJobPositionOptions = (state: JobPositionState, action: PayloadAction<Option[]>): void => {
  state.jobPositionOptions = action.payload;
};

const setJobPositionOptionsRequestStatus = (state: JobPositionState, action: PayloadAction<RequestStatus>): void => {
  state.jobPositionOptionsRequestStatus = action.payload;
};

const setJobPositionsColumn = (state: JobPositionState, action: PayloadAction<NewJobPosition[]>): void => {
  state.jobPositionsColumn = action.payload;
};

// Reducer function to set the request status for the job positions in the state
const setJobPositionsColumnRequestStatus = (state: JobPositionState, action: PayloadAction<RequestStatus>): void => {
  state.jobPositionsColumnRequestStatus = action.payload;
};

const setJobPositionCandidates = (
  state: JobPositionState,
  action: PayloadAction<{ id: string; candidates: NewCandidate[] }>
): void => {
  const newJobPositions = state.jobPositions.map(jobPosition => {
    if (jobPosition.id === action.payload.id) {
      return { ...jobPosition, candidates: action.payload.candidates };
    }
    return jobPosition;
  });

  state.jobPositions = newJobPositions;
};

const setSelectedJobPosition = (state: JobPositionState, action: PayloadAction<string>): void => {
  const newJobPositions = state.jobPositions.map(jobPosition => {
    if (jobPosition.id === action.payload) {
      return { ...jobPosition, selected: true };
    }
    return { ...jobPosition, selected: false };
  });
  state.jobPositions = newJobPositions;
};

const setJobPositionCandidatesStatus = (
  state: JobPositionState,
  action: PayloadAction<{ jobId: string; candidateId: string; status: CandidateStatus }>
): void => {
  const newJobPositions = state.jobPositions.map(jobPosition => {
    if (jobPosition.id === action.payload.jobId) {
      const newCandidates = jobPosition.candidates.map(candidate => {
        if (candidate.id === action.payload.candidateId) {
          return { ...candidate, status: action.payload.status };
        }
        return candidate;
      });
      jobPosition.candidates = newCandidates;
      return jobPosition;
    }
    return jobPosition;
  });
  state.jobPositions = newJobPositions;
};

const setJobPositionCandidatesStage = (
  state: JobPositionState,
  action: PayloadAction<{ jobId: string; candidateId: string; stage: CandidateStage }>
): void => {
  const newJobPositions = state.jobPositions.map(jobPosition => {
    if (jobPosition.id === action.payload.jobId) {
      const newCandidates = jobPosition.candidates.map(candidate => {
        if (candidate.id === action.payload.candidateId) {
          return { ...candidate, stage: action.payload.stage };
        }
        return candidate;
      });
      jobPosition.candidates = newCandidates;
      return jobPosition;
    }
    return jobPosition;
  });
  state.jobPositions = newJobPositions;
};

const reducers = {
  setJobPosition, // Action to set the current job position
  setJobPositionRequestStatus, // Action to set the request status for the current job position
  setJobPositions, // Action to set the list of job positions
  setJobPositionsRequestStatus, // Action to set the request status for the job positions
  setNewJobPosition,
  setJobPositionOptions,
  setJobPositionOptionsRequestStatus,
  setJobPositionCandidates,
  setSelectedJobPosition,
  setJobPositionsColumn, // Action to set the list of job positions
  setJobPositionsColumnRequestStatus, // Action to set the request status for the job positions
  setJobPositionCandidatesStatus,
  setJobPositionCandidatesStage,
};

export default reducers;
