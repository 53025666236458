import axios from 'axios';
import { FileModel } from '../ts/interfaces/models/FileModel';
import { BaseService, ResponseData } from './baseService';

class FileService extends BaseService<FileModel> {
  controllerName = 'file';

  // Method to upload a binary file using the PUT method
  async updateFile(url: string, file: File): Promise<ResponseData<File>> {
    // Perform the PUT request with the binary file data
    const { data } = await axios.put<ResponseData<File>>(url, await file.arrayBuffer(), {
      headers: {
        'Content-Type': file.type,
      },
    });

    return data;
  }
}

export const fileService = new FileService();
