import { JobPositionModel } from '../../ts/interfaces/models/JobPositionModel';
import { NewJobPosition } from '../../ts/types/JobPosition';

/**
 * Parses an array of JobPositionModel objects to an array of JobPosition objects.
 *
 * @param jobPositions - An array of JobPositionModel objects
 * @returns An array of JobPosition objects
 */

export const parseJobPositionModelToJobPosition = (jobPositions: JobPositionModel[]): NewJobPosition[] => {
  const parsedJobPositions = jobPositions.map(
    ({
      id,
      applications,
      availables,
      hired,
      title,
      lowerPriceRange,
      upperPriceRange,
      urgent,
      createdAt,
      client,
      client_job_id,
      department,
      description,
      hiring_manager,
      job_position_visible,
      job_time,
      job_type,
      location,
      manager,
      remote,
      required_positions,
      salary_visible,
      seniorities,
      skills,
      status,
    }) => {
      // Map each JobPositionModel object to a JobPosition object
      const newCreatedAt = createdAt ? new Date(createdAt) : null;
      const timeDiff = newCreatedAt ? new Date().getTime() - newCreatedAt.getTime() : null;
      const daysOpen = timeDiff ? Math.floor(timeDiff / (1000 * 60 * 60 * 24)) : 0;

      return {
        id: String(id),
        title,
        pay: `${lowerPriceRange} - ${upperPriceRange}`,
        urgent,
        data: {
          applications,
          availables,
          createdAt: newCreatedAt?.toDateString() || new Date().toDateString(), // Use the provided createdAt value or default to the current date
          hired,
        },
        client,
        client_job_id,
        department,
        description,
        hiring_manager,
        job_position_visible,
        job_time: job_time.length ? job_time : 'N/A',
        job_type: job_type.length ? job_type : 'N/A',
        location: location.length ? location : 'N/A',
        manager,
        remote,
        required_positions,
        salary_visible,
        seniorities,
        skills,
        status,
        daysOpen: newCreatedAt ? `${daysOpen}` : 'N/A',
        candidates: [],
      };
    }
  );
  return parsedJobPositions;
};

export const parseSingleJobPositionModelToJobPosition = ({
  id,
  applications,
  availables,
  hired,
  title,
  lowerPriceRange,
  upperPriceRange,
  urgent,
  createdAt,
  client,
  client_job_id,
  department,
  description,
  hiring_manager,
  job_position_visible,
  job_time,
  job_type,
  location,
  manager,
  remote,
  required_positions,
  salary_visible,
  seniorities,
  skills,
  status,
}: JobPositionModel): NewJobPosition => {
  const newCreatedAt = createdAt ? new Date(createdAt) : null;
  const timeDiff = newCreatedAt ? new Date().getTime() - newCreatedAt.getTime() : null;
  const daysOpen = timeDiff ? Math.floor(timeDiff / (1000 * 60 * 60 * 24)) : 0;
  return {
    id: String(id),
    title,
    pay: `${lowerPriceRange} - ${upperPriceRange}`,
    urgent,
    data: {
      applications,
      availables,
      createdAt: newCreatedAt?.toDateString() || new Date().toDateString(), // Use the provided createdAt value or default to the current date
      hired,
    },
    client,
    client_job_id,
    department,
    description,
    hiring_manager,
    job_position_visible,
    job_time: job_time.length ? job_time : 'N/A',
    job_type: job_type.length ? job_type : 'N/A',
    location: location.length ? location : 'N/A',
    manager,
    remote,
    required_positions,
    salary_visible,
    seniorities,
    skills,
    status,
    daysOpen: newCreatedAt ? `${daysOpen}` : 'N/A',
    candidates: [],
  };
};
