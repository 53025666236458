import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulModal } from '../../../../components/AzulModal';
import { AzulStepper } from '../../../../components/AzulStepper';
import { selectCountryOptions } from '../../../../store/country/selector';
import { getAllCountryOptions } from '../../../../store/country/thunks';
import { selectJobPositionOptions } from '../../../../store/jobPosition/selectors';
import { getAllJobPositionOptions } from '../../../../store/jobPosition/thunks';
import { selectSeniorityOptions } from '../../../../store/seniority/selector';
import { getAllSeniorityOptions } from '../../../../store/seniority/thunks';
import { selectRecruiterOptions } from '../../../../store/user/selectors';
import { getAllRecruiterOptions } from '../../../../store/user/thunks';
import { Spacings } from '../../../../styles/partials/spacings';
import { ModalNewCandidateProps } from '../../../../ts/interfaces/ModalNewCandidate';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks/storeHooks';
import { ModalNewCandidateFirstPart } from './ModalNewCandidateFirstPart';
import { ModalNewCandidateSecondPart } from './ModalNewCandidateSecondPart';
import { validationSchema } from './validators/validationSchema';

export const ModalNewCandidate = ({ initialValues, isOpen, title, onClose, onSubmit }: ModalNewCandidateProps) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        values,
        touched,
        errors,
        validateForm,
        setErrors,
        resetForm,
        setSubmitting,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        const [activeStep, setActiveStep] = useState(0);
        const steps = ['Basic Info', 'Recruitment'];

        const dispatch = useAppDispatch();

        const seniorityOptions = useAppSelector(selectSeniorityOptions);
        const countryOptions = useAppSelector(selectCountryOptions);
        const recruiterOptions = useAppSelector(selectRecruiterOptions);
        const jobPositionOptions = useAppSelector(selectJobPositionOptions);

        const handleOnClickNext = () => {
          if (activeStep === 0) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
          }
        };

        const handleOnClickBack = () => {
          setActiveStep(prevActiveStep => prevActiveStep - 1);
        };

        const handleFormSubmit = async () => {
          const errors = await validateForm();
          // If there are validation errors, set them in Formik's state
          if (Object.keys(errors).length) {
            setErrors(errors);
          } else {
            // If there are no errors, proceed with the form submission
            onSubmit(values, { setSubmitting });
            resetForm();
            setActiveStep(0);
          }
        };

        useEffect(() => {
          // TODO: Correct this if statement
          if (isOpen && !recruiterOptions.length && !countryOptions.length && !jobPositionOptions.length) {
            dispatch(getAllSeniorityOptions(true));
            dispatch(getAllRecruiterOptions(true));
            dispatch(getAllCountryOptions());
            dispatch(getAllJobPositionOptions(true));
          }
        }, [isOpen, recruiterOptions, countryOptions, jobPositionOptions]);

        return (
          <AzulModal isOpen={isOpen} title={title} onClose={onClose}>
            <Box marginBottom={Spacings.spacing8}>
              <AzulStepper activeStep={activeStep} steps={steps} />
            </Box>
            <Form>
              {activeStep === 0 ? (
                <Fragment>
                  <ModalNewCandidateFirstPart
                    countryOptions={countryOptions}
                    errors={errors}
                    seniorityOptions={seniorityOptions}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Box display='flex' justifyContent='end' marginTop={Spacings.spacing11}>
                    <AzulButton
                      endIcon={<NavigateNextIcon />}
                      label={'Next'}
                      variant={'primary'}
                      onClick={handleOnClickNext}
                    />
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <ModalNewCandidateSecondPart
                    errors={errors}
                    jobPositionOptions={jobPositionOptions}
                    recruiterOptions={recruiterOptions}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Box display='flex' justifyContent='space-between' marginTop={Spacings.spacing11}>
                    <AzulButton label={'Back'} startIcon={<NavigateBeforeIcon />} onClick={handleOnClickBack} />
                    <AzulButton isSubmit label='Submit' variant='primary' onClick={handleFormSubmit} />
                  </Box>
                </Fragment>
              )}
            </Form>
          </AzulModal>
        );
      }}
    </Formik>
  );
};
