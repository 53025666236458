import { PayloadAction } from '@reduxjs/toolkit';
import { JobTimeOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setJobTimeOptions = (state: JobTimeOptions, action: PayloadAction<Option[]>): void => {
  state.jobTimeOptions = action.payload;
};

const setJobTimeOptionsRequestStatus = (state: JobTimeOptions, action: PayloadAction<RequestStatus>): void => {
  state.jobTimeOptionsRequestStatus = action.payload;
};

const reducers = {
  setJobTimeOptions,
  setJobTimeOptionsRequestStatus,
};

export default reducers;
