const formatNumberAbbreviated = (number: number) => {
  if (number >= 1000) {
    const abbreviatedValue = Math.floor(number / 100) / 10;
    const integerPart = Math.floor(abbreviatedValue);
    const decimalPart = abbreviatedValue - integerPart;
    if (decimalPart === 0) {
      return `${integerPart}k`;
    } else {
      return `${abbreviatedValue.toFixed(1)}k`;
    }
  }

  return number.toString();
};

export default formatNumberAbbreviated;
