import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_ROUTES } from '../../../../constants/routes';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { OverviewCardProps } from '../../../../ts/interfaces/OverviewCard';
import formatNumberAbbreviated from '../../../../utils/formatNumberAbbreviated';

export const OverviewCard = ({
  activeCandidates,
  canceledPositions,
  closedPositions,
  newOpenPositions,
  onHoldPositions,
}: OverviewCardProps) => {
  const cardHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  const data = [
    { label: 'New open positions', value: newOpenPositions },
    { label: 'Closed positions', value: closedPositions },
    { label: 'Canceled positions', value: canceledPositions },
    { label: 'On hold positions', value: onHoldPositions },
    { label: 'Active candidates', value: activeCandidates },
  ];

  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        filter: `drop-shadow(${cardHierarchy.shadow} rgba(${cardHierarchy.rgba}))`,
      }}
    >
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: Spacings.spacing6,
          justifyContent: 'space-between',
          paddingY: Spacings.spacing6,
          paddingX: Spacings.spacing13,
          '&:last-child': {
            paddingBottom: Spacings.spacing6,
          },
        }}
      >
        {data.map(({ label, value }, index) => (
          <Fragment key={`${label}-${index}`}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: Spacings.spacing16 }}>
              <Typography color={colorPalette.primaryBlue} variant='h1'>
                {formatNumberAbbreviated(value)}
              </Typography>
              <Link style={{ textDecoration: 'none' }} to={MAIN_ROUTES.JOBS}>
                <Typography align='center' color={colorPalette.primaryBlue} variant='body1'>
                  {label}
                </Typography>
              </Link>
            </Box>
            {index !== data.length - 1 && (
              <Divider
                orientation='vertical'
                sx={{
                  height: '80px',
                  border: `1px solid ${colorPalette.neutralGray2}`,
                  alignSelf: 'flex-start',
                }}
              />
            )}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );
};
