export enum JobPositionStatus {
  Open = 'Open',
  OnHold = 'On_hold',
  Closed = 'Closed',
  Canceled = 'Canceled',
}

export enum NewJobPositionStatus {
  Open = 'Open',
  OnHold = 'On hold',
}
