import { setSeniorityOptions, setSeniorityOptionsRequestStatus } from '.';
import { seniorityService } from '../../service/seniorityService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllSeniorityOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setSeniorityOptionsRequestStatus(RequestStatus.Loading));
      const { data } = await seniorityService.getAll();

      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setSeniorityOptions(parsedData));

      dispatch(setSeniorityOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setSeniorityOptionsRequestStatus(RequestStatus.Failed));
    }
  };
