import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React from 'react';
import { colorPalette } from '../styles/partials/colors';

export const JOB_POSITION_CARD_OPTIONS = [
  { label: 'Edit', value: 'edit', icon: <EditRoundedIcon sx={{ color: colorPalette.primaryBlue }} /> },
  {
    label: 'Duplicate',
    value: 'duplicate',
    icon: <ControlPointDuplicateRoundedIcon sx={{ color: colorPalette.primaryBlue }} />,
  },
  { label: 'Delete', value: 'delete', icon: <DeleteRoundedIcon sx={{ color: colorPalette.primaryBlue }} /> },
];
