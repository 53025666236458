import { CandidateRow } from '../../modules/Candidates/components/Table/CandidateRow';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { InterviewStage } from '../../ts/enums/InterviewStage';
import { ModalNewCandidateType } from '../../ts/interfaces/forms/ModalNewCandidateType';
import { CandidatePostModel } from '../../ts/interfaces/models/post/CandidatePostModel';
import {
  CandidateColumnModel,
  CandidateModel,
  CandidateNotesModel,
  Interview,
  NewCandidateModel,
} from './../../ts/interfaces/models/CandidateModel';
import { Candidate, CandidateColumn, NewCandidate, Note } from './../../ts/types/candidate';

export const parseCandidatesModelArrayToCandidateRow = (candidates: NewCandidateModel[]): CandidateRow[] => {
  const parsedCandidates = candidates.map(candidate => {
    const hiringTeam = getHiringTeam(candidate.interviews);

    return {
      name: candidate.name,
      jobPosition: candidate.jobPosition.title,
      recruiter: hiringTeam.recruiter || 'N/A',
      source: candidate.source,
      email: candidate.email,
      status: candidate.status as CandidateStatus,
      stage: hiringTeam.stage || 'N/A',
      id: String(candidate.id),
    };
  });
  return parsedCandidates;
};

export const parseCandidateModelToCandidateRow = (candidate: NewCandidateModel): CandidateRow => {
  const { id, email, name, interviews, jobPosition, status, source } = candidate;
  const recruiter = interviews.find(interview => interview.stage === InterviewStage.Recruiter)?.user.name;
  const lastInterview = interviews[interviews.length - 1];

  return {
    name,
    jobPosition: jobPosition.title,
    recruiter: recruiter || 'N/A', // these validations are temporary, these values should not be optional
    source,
    email,
    status: status as CandidateStatus,
    stage: lastInterview ? lastInterview.stage : 'N/A', // these validations are temporary, these values should not be optional
    id: String(id),
  };
};

export const parseCandidatesModelArrayToCandidateArray = (candidates: CandidateModel[]): Candidate[] => {
  const parsedCandidates = candidates.map(
    ({
      firstContactInterview,
      firstTechInterview,
      secondTechInterview,
      clientInterview,
      id,
      email,
      name,
      recruiter,
      job_id,
      status,
      numOfInterview,
      referred,
      createdAt,
      phone_no,
      seniority,
      years_of_experience,
      salary_expectations,
      country,
      source,
      linkedinProfile,
      referred_by,
      first_contact_date,
      stage,
      jobPosition,
    }) => {
      return {
        id: String(id),
        email,
        name,
        recruiter,
        jobId: job_id,
        status,
        numOfInterview,
        phoneNo: phone_no,
        seniority,
        yearsOfExperience: years_of_experience,
        salaryExpectations: salary_expectations,
        country,
        source,
        linkedinProfile,
        referredBy: referred_by,
        firstContactDate: first_contact_date,
        stage,
        hiringTeam: {
          clientInterview,
          firstContactInterview,
          firstTechInterview,
          recruiter,
          secondTechInterview,
        },
        referred,
        jobPosition: {
          title: jobPosition.title,
          id: jobPosition.id,
          location: jobPosition.location,
          lowerPriceRange: jobPosition.lowerPriceRange,
          upperPriceRange: jobPosition.upperPriceRange,
          jobType: jobPosition.job_type,
          jobTime: jobPosition.job_time,
          skills: jobPosition.skills,
          description: jobPosition.description,
          client: jobPosition.client,
        },
        createdAt: createdAt ? new Date(createdAt).toISOString() : new Date().toISOString(),
      };
    }
  );
  return parsedCandidates;
};

export const parseNewCandidatesModelArrayToCandidateArray = (candidates: NewCandidateModel[]): NewCandidate[] => {
  const parsedCandidates = candidates.map(
    ({
      id,
      email,
      name,
      job_id,
      jobPosition,
      status,
      recruiter_id,
      createdAt,
      phone_no,
      seniority,
      years_of_experience,
      salary_expectation,
      country,
      source,
      linkedinProfile,
      referred_by,
      first_contact_date,
      stage,
      interviews,
    }) => {
      return {
        id: String(id),
        email,
        name,
        jobId: String(job_id),
        status,
        numOfInterview: interviews.length,
        phoneNo: phone_no,
        jobPosition,
        seniority,
        yearsOfExperience: years_of_experience,
        salaryExpectations: salary_expectation,
        country,
        source,
        linkedinProfile,
        referredBy: referred_by || 'N/A',
        firstContactDate: first_contact_date,
        stage,
        referred: source === 'Referred' ? true : false,
        interviews,
        recruiter: recruiter_id,
        createdAt: createdAt ? new Date(createdAt).toISOString() : new Date().toISOString(),
      };
    }
  );
  return parsedCandidates;
};

export const parseCandidatesColumn = (candidates: CandidateColumnModel[]): CandidateColumn[] => {
  const parsedCandidates = candidates.map(
    ({ id, first_name, last_name, recruiter_firstname, recruiter_lastname, title }) => {
      return {
        id,
        name: first_name + ' ' + last_name,
        recruiter: recruiter_firstname + ' ' + recruiter_lastname,
        job: title,
      };
    }
  );
  return parsedCandidates;
};

export const parseCandidateModelToCandidate = (candidate: CandidateModel): Candidate => {
  const {
    id,
    email,
    name,
    recruiter,
    job_id,
    status,
    numOfInterview,
    referred,
    createdAt,
    clientInterview,
    firstContactInterview,
    firstTechInterview,
    secondTechInterview,
    phone_no,
    seniority,
    years_of_experience,
    salary_expectations,
    country,
    source,
    linkedinProfile,
    referred_by,
    first_contact_date,
    stage,
    jobPosition,
  } = candidate;
  return {
    id: String(id),
    email,
    name,
    hiringTeam: {
      clientInterview,
      firstContactInterview,
      firstTechInterview,
      recruiter,
      secondTechInterview,
    },
    jobId: job_id,
    status,
    phoneNo: phone_no,
    seniority,
    yearsOfExperience: years_of_experience,
    salaryExpectations: salary_expectations,
    country,
    numOfInterview,
    referred,
    source,
    linkedinProfile,
    referredBy: referred_by,
    firstContactDate: first_contact_date,
    stage,
    jobPosition: {
      title: jobPosition.title,
      id: jobPosition.id,
      location: jobPosition.location,
      lowerPriceRange: jobPosition.lowerPriceRange,
      upperPriceRange: jobPosition.upperPriceRange,
      jobType: jobPosition.job_type,
      jobTime: jobPosition.job_time,
      skills: jobPosition.skills,
      description: jobPosition.description,
      client: jobPosition.client,
    },
    createdAt: createdAt ? new Date(createdAt).toISOString() : new Date().toISOString(),
  };
};

export const parseCandidateNotes = (candidateNotes: CandidateNotesModel[], userId: string): Note[] => {
  const parsedCandidateNotes = candidateNotes.map(({ user, comment, createdAt }) => {
    return {
      userId: user.id,
      name: userId && user.id === userId ? 'Me' : user.name,
      comment,
      createdAt,
    };
  });
  return parsedCandidateNotes;
};

export const getHiringTeam = (
  interviews: Interview[]
): {
  recruiter: string;
  stage: string;
  clientInterview?: string;
  firstContactInterview?: string;
  firstTechInterview?: string;
  secondTechInterview?: string;
} => {
  const interviewTypes: {
    recruiter: string;
    stage: string;
    clientInterview?: string;
    firstContactInterview?: string;
    firstTechInterview?: string;
    secondTechInterview?: string;
  } = {
    recruiter: interviews[0].user.name,
    stage: interviews[interviews.length - 1]?.stage,
  };

  interviews.slice(1).map(interview => {
    const key = interview.stage
      .split(' ')
      .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');

    if (key in interviewTypes) {
      // TODO: refactor
      interviewTypes[key as keyof typeof interviewTypes] = interview.user.name;
    }
  });

  return {
    ...interviewTypes,
  };
};

export const parseNewCandidateModelToCandidate = (candidate: NewCandidateModel) => {
  const {
    id,
    email,
    name,
    recruiter_id,
    status,
    job_id,
    phone_no,
    seniority,
    years_of_experience,
    salary_expectation,
    country,
    source,
    linkedinProfile,
    refered_by,
    first_contact_date,
    source_details,
    documents,
    jobPosition,
    interviews,
  } = candidate;

  const hiringTeam = getHiringTeam(interviews);

  return {
    id: String(id),
    email,
    name,
    recruiterId: recruiter_id,
    status,
    jobId: String(job_id),
    phoneNo: phone_no,
    seniority,
    yearsOfExperience: years_of_experience,
    salaryExpectations: salary_expectation,
    country,
    source,
    linkedinProfile,
    referredBy: refered_by,
    firstContactDate: new Date(first_contact_date).toLocaleDateString('en-US'),
    stage: hiringTeam.stage,
    sourceDetails: source_details,
    documents: documents.map(doc => ({
      fileType: doc.file_type,
      file: doc.file,
    })),
    jobPosition: {
      client: jobPosition.client,
      description: jobPosition.description,
      id: String(jobPosition.id),
      jobTime: jobPosition.job_time,
      jobType: jobPosition.job_type,
      location: jobPosition.location,
      lowerPriceRange: jobPosition.lowerPriceRange,
      skills: jobPosition.skills,
      title: jobPosition.title,
      upperPriceRange: jobPosition.upperPriceRange,
    },
    hiringTeam,
    numOfInterview: interviews.length,
    referred: Boolean(refered_by),
  };
};

export const parseModalNewCandidateTypeToCandidatePostModel = ({
  country,
  email,
  firstContactDate,
  firstName,
  lastName,
  phoneNumber,
  recruiter,
  salaryRange,
  seniority,
  source,
  specifySource,
  status,
  yearsOfExperience,
}: ModalNewCandidateType): CandidatePostModel => {
  return {
    country_id: Number(country),
    email: email,
    first_contact_date: firstContactDate || undefined,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber || undefined,
    recruiter_id: Number(recruiter),
    salary_expectation: `${salaryRange}-${salaryRange}`,
    seniority_id: Number(seniority) || undefined,
    source: source || undefined,
    source_details: specifySource || undefined,
    status: status,
    years_of_experience: Number(yearsOfExperience) || undefined,
  };
};
