import { Checkbox, FormControlLabel } from '@mui/material';
import React, { FC, KeyboardEvent } from 'react';
import { AzulCheckboxProps } from '../../ts/interfaces/AzulCheckbox';

/**
 * Represents a customized checkbox component.
 *
 * @param checked - Indicates whether the checkbox is checked. (Optional)
 * @param label - The label text for the checkbox. (Optional)
 * @param disabled - Indicates whether the checkbox is disabled. (Optional)
 * @param onChange - Event handler for the checkbox's change event.
 */

export const AzulCheckbox: FC<AzulCheckboxProps> = ({ label, name, disabled, checked, value, onChange }) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      event.preventDefault();
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          focusRipple={false}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      }
      label={label}
    />
  );
};

export default AzulCheckbox;
