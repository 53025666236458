/* eslint-disable @typescript-eslint/no-explicit-any */
// Import necessary modules and interfaces
import axios, { AxiosInstance } from 'axios';

// Set the base URL for the API
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Set the API timeout in milliseconds
export const API_TIMEOUT: number = 600000 * 1.01;

// Set the base headers for API requests
export const BASE_HEADERS = {
  'Content-Type': 'application/json',
};

// Interface for specifying options when making service requests
export interface ServiceOptions {
  attributes?: string[];
  include?: any[];
  where?: any;
  limit?: number;
  offset?: number;
  order?: [string, 'ASC' | 'DESC'][] | [string, string, 'ASC' | 'DESC'][];
  params?: unknown;
  headers?: unknown;
}

// Interface for the response data returned from the API
export interface ResponseData<T> {
  message?: string;
  status?: number;
  data: T;
}

// Interface for the list response data returned from the API
export interface ListResponseData<T> {
  message?: string;
  count: number;
  offset: number;
  limit: number;
  order?: any;
  data: T[];
}

// Function to prepare parameters for API requests
export function prepareParams(options?: ServiceOptions | any): { [key: string]: string } {
  const params: { [key: string]: string } = {};
  if (options != null) {
    for (const key in options) {
      if ((options as any)[key]) {
        params[key] = JSON.stringify((options as any)[key]);
      }
    }
  }
  return params;
}

// Base service class that provides common methods for API requests
export class BaseService<T> {
  // Default controller name used in API requests
  controllerName = 'default';

  // Base URL for the API
  baseUrl: string | undefined;

  // API timeout value
  apiTimeOut: number = API_TIMEOUT;

  // Axios instance for making API requests
  _api: AxiosInstance | undefined;

  // Getter function to access the Axios instance for API requests
  get api() {
    if (this._api == null || this.baseUrl == null) {
      this.baseUrl = API_BASE_URL;
      if (this.baseUrl == null) {
        console.warn('Your REACT_APP_API_BASE_URL is not defined, make sure it is correctly set in .env');
      }
      this._api = axios.create({
        timeout: this.apiTimeOut,
        headers: BASE_HEADERS,
        baseURL: `${this.baseUrl}`,
      });
    }
    return this._api;
  }

  // Function to get default headers for API requests
  getDefaultHeaders(): any {
    const token = process.env.REACT_APP_DEVELOPMENT_TOKEN;

    let defaultHeaders: any = {
      ...BASE_HEADERS,
    };
    if (token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
    return defaultHeaders;
  }

  // Method to fetch all data from the API based on the specified options
  async getAll(options?: ServiceOptions): Promise<ListResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<T>>(`/${this.controllerName}`, { params });
    return data;
  }

  // Method to fetch data for a specific ID from the API based on the specified options
  async getById(id: number | string, options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<T>>(`/${this.controllerName}/${id}`, { params });
    return data;
  }

  // Method to fetch data from the API based on the specified options
  async get(options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<T>>(`/${this.controllerName}`, { params });
    return data;
  }

  // Method to update data in the API for a specific ID with the provided body data and options
  async update(id: number | string, bodyData: any, options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.put<ResponseData<T>>(`/${this.controllerName}/${id}`, JSON.stringify(bodyData), {
      params,
    });
    return data;
  }

  // Method to create new data in the API with the provided body data
  async create(bodyData: any): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post<ResponseData<T>>(`/${this.controllerName}`, JSON.stringify(bodyData));
    return data;
  }

  // Method to patch/update partial data in the API for a specific ID with the provided body data
  async patch(id: number | string, bodyData: any): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.patch<ResponseData<T>>(`/${this.controllerName}/${id}`, JSON.stringify(bodyData));
    return data;
  }

  // Method to delete data from the API for a specific ID
  async delete(id: number | string): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.delete<ResponseData<T>>(`/${this.controllerName}/${id}`);
    return data;
  }
}
