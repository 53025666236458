import { Cancel, CheckCircle, Error, WarningRounded } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import React, { FC } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Spacings } from '../../styles/partials/spacings';
import { AzulToastNotificationProps } from '../../ts/interfaces/AzulToastNotifications';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction='right' />;
}

const severityIconMap = {
  success: <CheckCircle style={{ color: colorPalette.accentGreen1 }} />,
  error: <Cancel style={{ color: colorPalette.accentRed }} />,
  warning: <WarningRounded style={{ color: colorPalette.accentYellow }} />,
  info: <Error style={{ color: colorPalette.primaryHoverBlue1 }} />,
};

/**
 * Represents a toast notification component used to display messages with different severity levels.
 *
 * @param message - The message content of the notification.
 * @param open - Determines whether the notification is open or not.
 * @param severity - The severity level of the notification. Possible values: 'success', 'error', 'warning', 'info'.
 * @param onClose - A callback function invoked when the notification is closed.
 */

export const AzulToastNotification: FC<AzulToastNotificationProps> = ({ message, severity, open, onClose }) => {
  return (
    <Snackbar
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      autoHideDuration={5000}
      open={open}
      onClose={onClose}
    >
      <Alert
        icon={severityIconMap[severity]}
        severity={severity}
        sx={{
          minWidth: '288px',
          maxWidth: '568px',
          width: '100%',
          backgroundColor: colorPalette.neutralGray3,
          color: colorPalette.neutralGray1,
          borderRadius: Spacings.spacing2,
        }}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
