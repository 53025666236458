import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Notification } from '../../ts/types/Notification';
import reducers from './reducer';

export interface NotificationState {
  notifications: Notification[];
  notificationsRequestStatus: RequestStatus;
  notificationsCount: number;
}

const initialState: NotificationState = {
  notifications: [],
  notificationsRequestStatus: RequestStatus.Idle,
  notificationsCount: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers,
});

export const { setNotifications, setNotificationsRequestStatus, setNotificationsCount } = notificationSlice.actions;

export default notificationSlice.reducer;
