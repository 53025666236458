import { PayloadAction } from '@reduxjs/toolkit';
import { LocationOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setLocationOptions = (state: LocationOptions, action: PayloadAction<Option[]>): void => {
  state.locationOptions = action.payload;
};

const setLocationOptionsRequestStatus = (state: LocationOptions, action: PayloadAction<RequestStatus>): void => {
  state.locationOptionsRequestStatus = action.payload;
};

const reducers = {
  setLocationOptions,
  setLocationOptionsRequestStatus,
};

export default reducers;
