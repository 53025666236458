import { setManagerOptions, setManagerOptionsRequestStatus } from '.';
import { userService } from '../../service/userService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseUserToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllManagerOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setManagerOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await userService.getAllManagers();
      const parsedData = parseUserToOptions(data, false, useIdAsValue);

      dispatch(setManagerOptions(parsedData));

      dispatch(setManagerOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setManagerOptionsRequestStatus(RequestStatus.Failed));
    }
  };
