import { createSlice } from '@reduxjs/toolkit';
import { UserRow } from '../../modules/Users/components/Table/UserRow';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Role } from '../../ts/enums/Role';
import { Option } from '../../ts/interfaces/AzulOption';
import { User } from '../../ts/types/user';
import reducers from './reducer';

export interface UserState {
  user: User;
  userRequestStatus: RequestStatus;
  users: User[];
  usersRequestStatus: RequestStatus;
  recruiterOptions: Option[];
  recruiterOptionsRequestStatus: RequestStatus;
  usersTable: UserRow[];
  usersTableCount: number;
  usersTableRequestStatus: RequestStatus;
}

const initialState: UserState = {
  user: {
    id: '',
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    locationId: '',
    role: Role.Admin,
    departmentId: '',
    createdAt: '',
  },
  userRequestStatus: RequestStatus.Idle,
  users: [],
  usersRequestStatus: RequestStatus.Idle,
  recruiterOptions: [],
  recruiterOptionsRequestStatus: RequestStatus.Idle,
  usersTable: [],
  usersTableCount: 0,
  usersTableRequestStatus: RequestStatus.Idle,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers,
});

// Extract the action creators generated by createSlice
export const {
  setNewUsersTable,
  setRecruiterOptions,
  setRecruiterOptionsRequestStatus,
  setUser,
  setUserRequestStatus,
  setUsers,
  setUsersRequestStatus,
  setUsersTable,
  setUsersTableCount,
  setUsersTableRequestStatus,
} = userSlice.actions;

export default userSlice.reducer;
