import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface JobTimeOptions {
  jobTimeOptions: Option[];
  jobTimeOptionsRequestStatus: RequestStatus;
}

const initialState: JobTimeOptions = {
  jobTimeOptions: [],
  jobTimeOptionsRequestStatus: RequestStatus.Idle,
};

export const jobTimeSlice = createSlice({
  name: 'jobTimes',
  initialState,
  reducers,
});

export const { setJobTimeOptions, setJobTimeOptionsRequestStatus } = jobTimeSlice.actions;

export default jobTimeSlice.reducer;
