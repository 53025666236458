import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, Popover, Tooltip } from '@mui/material';
import React, { Fragment, MouseEvent, useRef, useState } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { ProfileSettingsProps } from '../../ts/interfaces/AzulProfileSettings';
import { AzulOptions } from '../AzulOptions';

/**
 * Represents the profile settings component.
 *
 * @param options - An array of options to display in the profile settings menu.
 *                   Each option should have a `label` and a `value`.
 * @param src - The URL of the profile photo.
 * @param onClick - The function called when the profile settings button is clicked.
 * @param onClickOption - The function called when an option in the profile settings menu is clicked.
 */

export const AzulProfileSettings = ({ options, src, onClick, onClickOption }: ProfileSettingsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorOrigin, setMenuAnchorOrigin] = useState<number>(0);
  const open = Boolean(anchorEl);
  const moreIconButtonRef = useRef<HTMLButtonElement>(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickIconButton = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    setMenuAnchorOrigin(e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 8);
    onClick();
  };

  return (
    <Fragment>
      <Tooltip arrow placement='top' title={'Profile settings'}>
        <Button
          aria-label='Profile Settings Button'
          className='AzulProfileSettingsButton'
          focusRipple={false}
          ref={moreIconButtonRef}
          sx={{
            backgroundColor: open ? colorPalette.primaryHoverBlue1 : colorPalette.backgroundWhite,
          }}
          onClick={handleOnClickIconButton}
        >
          {src ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: Spacings.spacing10,
                height: Spacings.spacing10,
                padding: '3px 2.33px 1.13px 2.8px',
              }}
            >
              <img
                alt='Profile photo'
                src={src}
                style={{ objectFit: 'cover', borderRadius: '100px', maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
          ) : (
            <AccountCircleRoundedIcon
              sx={{
                color: colorPalette.primaryBlue,
                width: Spacings.spacing10,
                height: Spacings.spacing10,
              }}
            />
          )}
          <ExpandMoreRoundedIcon
            sx={{
              color: colorPalette.primaryBlue,
              width: Spacings.spacing8,
              height: Spacings.spacing8,
            }}
          />
        </Button>
      </Tooltip>
      <Popover
        anchorEl={moreIconButtonRef.current}
        anchorOrigin={{
          vertical: menuAnchorOrigin,
          horizontal: 'left',
        }}
        open={open}
        onClose={handlePopoverClose}
      >
        <AzulOptions hierarchy={Hierarchy.Hierarchy2} options={options} width={'240px'} onClickOption={onClickOption} />
      </Popover>
    </Fragment>
  );
};
