// Import the createTheme function from the Material-UI library
import {
  autocompleteClasses,
  buttonClasses,
  chipClasses,
  inputBaseClasses,
  menuItemClasses,
  outlinedInputClasses,
  paginationItemClasses,
  radioClasses,
  selectClasses,
  stepLabelClasses,
  svgIconClasses,
  tableCellClasses,
  tablePaginationClasses,
  tableSortLabelClasses,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';
// Import all colors from a local file
import * as colors from '../partials/colors';
import { Hierarchies, Hierarchy } from '../partials/hierarchies';
import { Spacings, spacingScale } from '../partials/spacings';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    destructive: true;
    secondary: true;
    tertiary: true;
    loading: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'body1-medium': CSSProperties;
    'body2-bold': CSSProperties;
    'body2-medium': CSSProperties;
    'caption-bold': CSSProperties;
  } // allow configuration using `createTheme`

  interface TypographyVariantsOptions {
    'body1-medium'?: CSSProperties;
    'body2-bold'?: CSSProperties;
    'body2-medium'?: CSSProperties;
    'caption-bold'?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'body1-medium': true;
    'body2-bold': true;
    'body2-medium': true;
    'caption-bold': true;
  }
}

// Create a theme object with customized palette and component styles
export const theme = createTheme({
  // Customize the color palette of the theme
  palette: {
    // Customize the primary color to use a specific shade of blue
    primary: {
      main: colors.colorPalette.primaryBlue,
    },
    action: {
      disabled: colors.colorPalette.disabledGray2,
    },
  },
  spacing: (factor: number) => `${spacingScale * factor}px`,
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'Rubik',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: '40px',
      letterSpacing: '0.01em',
    },
    h2: {
      fontFamily: 'Rubik',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '32px',
      letterSpacing: '0em',
    },
    h3: {
      fontFamily: 'Rubik',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.01em',
    },
    subtitle1: {
      fontFamily: 'Rubik',
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    subtitle2: {
      fontFamily: 'Rubik',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '24px',
      letterSpacing: '0.01em',
    },
    body1: {
      fontFamily: 'Rubik',
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    body2: {
      fontFamily: 'Rubik',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    'body1-medium': {
      fontFamily: 'Rubik',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    'body2-medium': {
      fontFamily: 'Rubik',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    'body2-bold': {
      fontFamily: 'Rubik',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.002em',
    },
    button: {
      fontFamily: 'Rubik',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      textTransform: 'capitalize',
    },
    caption: {
      fontFamily: 'Rubik',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.008em',
    },
    overline: {
      fontFamily: 'Rubik',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '16px',
      letterSpacing: '0.01em',
    },
    'caption-bold': {
      fontFamily: 'Rubik',
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '0.008em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 720,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.colorPalette.paragraphBaseline,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        input: {
          '::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          'input[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: `${Hierarchies[Hierarchy.Hierarchy2].shadow} rgba(${Hierarchies[Hierarchy.Hierarchy2].rgba})`,
        },
        list: {
          padding: Spacings.spacing0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [`&.${menuItemClasses.root}`]: {
            '&:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:focus': {
              border: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
              boxSizing: 'border-box',
              '&:first-child': {
                borderTopLeftRadius: `${Spacings.spacing4}px`,
              },
              '&:last-child': {
                borderBottomLeftRadius: `${Spacings.spacing4}px`,
              },
            },
            paddingTop: Spacings.spacing0,
            paddingBottom: Spacings.spacing0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&.${inputBaseClasses.multiline}`]: {
            paddingLeft: Spacings.spacing6,
            paddingTop: Spacings.spacing5,
            paddingBottom: Spacings.spacing5,
          },
        },
        input: {
          [`&.${outlinedInputClasses.input}`]: {
            paddingLeft: Spacings.spacing6,
            paddingTop: Spacings.spacing5,
            paddingBottom: Spacings.spacing5,
          },
          [`&.${inputBaseClasses.inputMultiline}`]: {
            padding: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.colorPalette.backgroundWhite,
          borderRadius: Spacings.spacing4,
          [`&.${outlinedInputClasses.root}`]: {
            '& fieldset': {
              borderColor: colors.colorPalette.paragraph,
            },
            '&.Mui-focused': {
              borderColor: colors.colorPalette.primaryBlue,
              outline: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing2,
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: colors.colorPalette.primaryBlue,
                borderWidth: 1,
              },
            },
            '&:hover fieldset': {
              borderColor: colors.colorPalette.primaryBlue,
            },
            '& .MuiSelect-icon': {
              color: colors.colorPalette.paragraph,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.colorPalette.disabledGray1,
              color: colors.colorPalette.disabledGray2,
              borderColor: colors.colorPalette.disabledGray2,
            },
          },
        },
        input: {
          color: colors.colorPalette.paragraph,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: `${Spacings.spacing1}`,
          backgroundColor: colors.colorPalette.backgroundWhite,
          color: colors.colorPalette.paragraph,
          '&.Mui-disabled': {
            color: colors.colorPalette.disabledGray2,
            backgroundColor: colors.colorPalette.disabledGray1,
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        iconEmpty: {
          color: `${colors.colorPalette.accentBlue2}`,
        },
        root: {
          color: `${colors.colorPalette.accentBlue2}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application
      },
      styleOverrides: {
        root: {
          [`.${buttonClasses.startIcon}`]: {
            marginLeft: 0,
          },
          [`.${buttonClasses.endIcon}`]: {
            marginRight: 0,
          },
          border: '1px solid',
          padding: `${Spacings.spacing4}px ${Spacings.spacing6}px`,
          width: 'auto',
          height: `${Spacings.spacing11}px`,
          '&:focus': {
            outline: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
            outlineOffset: Spacings.spacing2,
          },
          [`&.AzulProfileSettingsButton`]: {
            display: 'flex',
            border: 0,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0.5,
            width: Spacings.spacing14,
            height: Spacings.spacing11,
            borderRadius: 25,
            flex: 'none',
            order: 0,
            flexGrow: 0,
            '&:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:focus': {
              outline: `2px solid ${colors.colorPalette.focusPurple}`,
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryHoverBlue1,
            },
          },
          [`&.AzulSearchBarButton`]: {
            borderBottomLeftRadius: `${Spacings.spacing0}px`,
            borderBottomRightRadius: `${Spacings.spacing4}px`,
            borderTopLeftRadius: `${Spacings.spacing0}px`,
            borderTopRightRadius: `${Spacings.spacing4}px`,
            minHeight: Spacings.spacing12,
            minWidth: Spacings.spacing13,
            '&.MuiButton-root': {
              '&.Mui-disabled': {
                borderColor: colors.colorPalette.disabledGray2,
              },
            },
          },
          ['&.AzulRowsPerPageButton']: {
            '&:focus': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            borderColor: colors.colorPalette.primaryBlue,
            backgroundColor: colors.colorPalette.primaryBlue,
            color: colors.colorPalette.backgroundWhite,
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: colors.colorPalette.primaryHoverBlue2,
                borderColor: colors.colorPalette.primaryHoverBlue2,
              },
              '&.Mui-disabled': {
                color: colors.colorPalette.disabledGray2,
                borderColor: colors.colorPalette.disabledGray1,
                backgroundColor: colors.colorPalette.disabledGray1,
              },
              '&:focus:active': {
                backgroundColor: colors.colorPalette.primaryPressedBlue,
                borderColor: colors.colorPalette.primaryPressedBlue,
              },
              '&:focus': {
                backgroundColor: colors.colorPalette.primaryHoverBlue2,
                borderColor: colors.colorPalette.primaryHoverBlue2,
              },
            },
          },
        },
        {
          props: { variant: 'destructive' },
          style: {
            borderColor: colors.colorPalette.destructiveRed1,
            backgroundColor: colors.colorPalette.destructiveRed1,
            color: colors.colorPalette.backgroundWhite,
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: colors.colorPalette.destructiveRed2,
                borderColor: colors.colorPalette.destructiveRed2,
              },
              '&.Mui-disabled': {
                color: colors.colorPalette.disabledGray2,
                borderColor: colors.colorPalette.disabledGray1,
                backgroundColor: colors.colorPalette.disabledGray1,
              },
              '&:focus:active': {
                backgroundColor: colors.colorPalette.destructiveRed3,
                borderColor: colors.colorPalette.destructiveRed3,
              },
              '&:focus': {
                backgroundColor: colors.colorPalette.destructiveRed2,
                borderColor: colors.colorPalette.destructiveRed2,
              },
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            borderColor: colors.colorPalette.primaryBlue,
            color: colors.colorPalette.primaryBlue,
            backgroundColor: colors.colorPalette.backgroundWhite,
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: colors.colorPalette.accentBlue1,
              },
              '&.Mui-disabled': {
                color: colors.colorPalette.disabledGray2,
                borderColor: colors.colorPalette.disabledGray2,
                backgroundColor: colors.colorPalette.backgroundWhite,
              },
              '&:focus:active': {
                backgroundColor: colors.colorPalette.primaryHoverBlue1,
              },
              '&:focus': {
                backgroundColor: colors.colorPalette.accentBlue1,
              },
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            borderColor: 'transparent',
            color: colors.colorPalette.primaryBlue,
            backgroundColor: 'none',
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: colors.colorPalette.accentBlue1,
                borderColor: colors.colorPalette.accentBlue1,
              },
              '&.Mui-disabled': {
                color: colors.colorPalette.disabledGray2,
                backgroundColor: colors.colorPalette.backgroundWhite,
              },
              '&:focus:active': {
                backgroundColor: colors.colorPalette.primaryHoverBlue1,
                borderColor: colors.colorPalette.primaryHoverBlue1,
              },
              '&:focus': {
                backgroundColor: colors.colorPalette.accentBlue1,
                borderColor: colors.colorPalette.accentBlue1,
              },
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.colorPalette.accentBlue1,
          },
          '&:active': {
            backgroundColor: colors.colorPalette.primaryHoverBlue1,
          },
          '&:focus': {
            backgroundColor: colors.colorPalette.accentBlue1,
            outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
          },
          '&.fabButton': {
            backgroundColor: colors.colorPalette.primaryBlue,
            borderRadius: '100%',
            width: Spacings.spacing11,
            height: Spacings.spacing11,
            '&:hover': {
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryPressedBlue,
            },
            '&:focus': {
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
              outline: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing2,
            },
            '&:disabled': {
              backgroundColor: colors.colorPalette.disabledGray1,
            },
          },
        },
        sizeSmall: {
          width: Spacings.spacing10,
          height: Spacings.spacing10,
        },
        sizeMedium: {
          width: Spacings.spacing11,
          height: Spacings.spacing11,
        },
        sizeLarge: {
          width: Spacings.spacing12,
          height: Spacings.spacing12,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: Spacings.spacing6,
        },
        fontSizeMedium: {
          fontSize: Spacings.spacing8,
        },
        fontSizeLarge: {
          fontSize: Spacings.spacing10,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-focusVisible': {
            background: 'rgba(181, 181, 182, 0.25)',
            outline: `2px solid ${colors.colorPalette.focusPurple}`,
          },
          '&:hover': { background: 'rgba(181, 181, 182, 0.12)' },
          '&:active': { background: 'rgba(181, 181, 182, 0.25)' },
          '&.Mui-disabled': {
            color: colors.colorPalette.disabledGray2,
          },
        },
        track: {
          backgroundColor: colors.colorPalette.disabledGray1,
        },
        root: {
          '&.Mui-checked': {
            thumb: {
              backgroundColor: colors.colorPalette.primaryBlue,
            },
          },
          '&.Mui-disabled': {
            thumb: {
              backgroundColor: colors.colorPalette.disabledGray2,
            },
          },
        },
      },
    },
    MuiChip: {
      //TODO: Optimize this, there are too much repetitive code
      styleOverrides: {
        root: {
          height: Spacings.spacing10,
          padding: `${Spacings.spacing3}px ${Spacings.spacing4}px`,
          fontSize: '0.875rem',
          fontWeight: 400,
          borderRadius: Spacings.spacing4,
          [`.${chipClasses.icon}`]: {
            color: colors.colorPalette.backgroundWhite,
            fontSize: Spacings.spacing8,
            marginRight: Spacings.spacing0,
            marginLeft: Spacings.spacing2,
          },
          [`&.AzulMuiChipSelectedDisabled`]: {
            color: colors.colorPalette.disabledGray2,
            backgroundColor: colors.colorPalette.disabledGray1,
            border: `${Spacings.spacing1}px solid ${colors.colorPalette.disabledGray1}`,
            [`.${chipClasses.icon}`]: {
              color: colors.colorPalette.disabledGray2,
            },
          },
          [`&.AzulMuiChipUnselectedDisabled`]: {
            color: colors.colorPalette.disabledGray2,
            backgroundColor: colors.colorPalette.backgroundWhite,
            border: `${Spacings.spacing1}px solid ${colors.colorPalette.disabledGray2}`,
          },
          [`&.AzulMuiChipSelected`]: {
            color: colors.colorPalette.backgroundWhite,
            backgroundColor: colors.colorPalette.primaryBlue,
            border: `${Spacings.spacing1}px solid ${colors.colorPalette.primaryBlue}`,
            '&.MuiChip-clickable:hover': {
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
            },
            '&:focus': {
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing1,
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryPressedBlue,
            },
          },
          [`&.AzulMuiChipUnselected`]: {
            color: colors.colorPalette.primaryBlue,
            backgroundColor: colors.colorPalette.backgroundWhite,
            border: `${Spacings.spacing1}px solid ${colors.colorPalette.primaryBlue}`,
            '&.MuiChip-clickable:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:focus': {
              backgroundColor: colors.colorPalette.accentBlue1,
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
            },
          },
          [`&.AzulMuiChipUnselectedBoldText`]: {
            color: colors.colorPalette.primaryBlue,
            backgroundColor: colors.colorPalette.backgroundWhite,
            border: `${Spacings.spacing1}px solid ${colors.colorPalette.primaryBlue}`,
            fontWeight: 600,
            '&.MuiChip-clickable:hover': {
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
            },
            '&:focus': {
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing1,
              backgroundColor: colors.colorPalette.primaryHoverBlue2,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryPressedBlue,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: Spacings.spacing0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          [radioClasses.checked]: {
            '&:active': {
              backgroundColor: colors.colorPalette.radioFocus,
            },
            '&:hover': {
              backgroundColor: colors.colorPalette.radioHover,
            },
            '&:focus': {
              backgroundColor: colors.colorPalette.radioFocus,
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: colors.colorPalette.radioFocus,
            outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
          },
          padding: Spacings.spacing4,
          marginRight: Spacings.spacing4,
          backgroundColor: colors.colorPalette.backgroundWhite,
          color: colors.colorPalette.primaryBlue,
          '&:focus': {
            backgroundColor: colors.colorPalette.radioFocus,
            outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: {
          top: 10,
          left: `calc(-50% + ${Spacings.spacing6})`,
          right: `calc(50% + ${Spacings.spacing6})`,
        },
        line: {
          height: Spacings.spacing4,
          borderRadius: Spacings.spacing4,
          backgroundColor: colors.colorPalette.disabledGray1,
          minWidth: Spacings.spacingStep,
          width: '100%',
          borderTopStyle: 'hidden',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          [`.${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
            marginTop: Spacings.spacing0,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.colorPalette.primaryBlue,
          marginX: Spacings.spacing4,
          '&:hover': {
            backgroundColor: colors.colorPalette.accentBlue1,
          },
          '&:active': {
            backgroundColor: `${colors.colorPalette.primaryHoverBlue1}`,
          },
          '&.Mui-focusVisible': {
            outline: `2px solid ${colors.colorPalette.focusPurple}`,
            backgroundColor: colors.colorPalette.accentBlue1,
          },
          '&.Mui-checked': {
            color: colors.colorPalette.primaryBlue,
          },
          '&.Mui-disabled': {
            color: colors.colorPalette.disabledGray2,
          },
          '&.Mui-indeterminate': {
            color: colors.colorPalette.accentYellow,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: Spacings.spacing15,
          fontWeight: 400,
          height: Spacings.spacing13,
          padding: Spacings.spacing6,
          backgroundColor: colors.colorPalette.backgroundWhite,
          color: colors.colorPalette.paragraphBaseline,
          '&:hover': {
            backgroundColor: colors.colorPalette.accentBlue1,
          },
          '&:focus-visible': {
            backgroundColor: colors.colorPalette.accentBlue1,
            border: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
          },
          '&.Mui-selected': {
            borderBottom: `${Spacings.spacing2}px solid ${colors.colorPalette.primaryBlue}`,
            color: colors.colorPalette.primaryBlue,
            fontWeight: 500,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          maxHeight: Spacings.spacing13,
          display: 'flex',
          alignItems: 'start',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: Spacings.spacing13,
          paddingInline: Spacings.spacing14,
          backgroundColor: colors.colorPalette.backgroundWhite,
          color: colors.colorPalette.primaryBlue,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'body1-medium': 'p',
          'body2-medium': 'p',
          'body2-bold': 'p',
          'caption-bold': 'p',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          ['&.AzulSearchBar']: {
            [`.${outlinedInputClasses.root}`]: {
              paddingLeft: Spacings.spacing6,
              paddingY: Spacings.spacing5,
              borderTopRightRadius: `${Spacings.spacing0}px`,
              borderBottomRightRadius: `${Spacings.spacing0}px`,
              borderTopLeftRadius: `${Spacings.spacing4}px`,
              borderBottomLeftRadius: `${Spacings.spacing4}px`,
              width: '100%',
              height: Spacings.spacing12,
              '&.Mui-disabled': {
                borderColor: colors.colorPalette.disabledGray2,
                backgroundColor: colors.colorPalette.disabledGray1,
              },
              '&.Mui-focused': {
                outline: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
                outlineOffset: Spacings.spacing2,
                [`.${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: colors.colorPalette.primaryBlue,
                  borderWidth: 1,
                },
              },
              [`.${autocompleteClasses.input}`]: {
                padding: 0,
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          paddingTop: 0,
          paddingBottom: 0,
          '&::-webkit-scrollbar': {
            width: Spacings.spacing3,
            height: Spacings.spacing3,
          },
          '&::-webkit-scrollbar-track': {
            marginY: Spacings.spacing3,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.colorPalette.paragraphPlaceholder,
            borderRadius: Spacings.spacing6,
          },
        },
        paper: {
          marginTop: Spacings.spacing3,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:active': {
            color: colors.colorPalette.primaryHoverBlue1,
          },
          '&:focus': {
            backgroundColor: colors.colorPalette.accentBlue1,
            transition: 0,
            border: `${Spacings.spacing3}px solid ${colors.colorPalette.focusPurple}`,
          },
          '&:hover': {
            backgroundColor: colors.colorPalette.accentBlue1,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          [`&.AzulOptions`]: {
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            alignItems: 'center',
            gap: Spacings.spacing1,
            minWidth: '240px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          border: `${Spacings.spacing1}px solid ${colors.colorPalette.backgroundWhite}`,
          padding: 0,
          height: Spacings.spacing5,
          minWidth: Spacings.spacing5,
          top: 8,
          right: 10,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: colors.colorPalette.backgroundBlue,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          [`&.${tableSortLabelClasses.root}.Mui-active .${tableSortLabelClasses.icon}`]: {
            color: colors.colorPalette.primaryBlue,
          },
          '&:focus': {
            [`&.${tableSortLabelClasses.root} .${tableSortLabelClasses.icon}`]: {
              opacity: 0.5,
            },
          },
        },
        icon: {
          color: colors.colorPalette.primaryBlue,
          marginLeft: Spacings.spacing4,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          [`&.${tableCellClasses.root}`]: {
            border: 'none',
            width: 'max-content',
          },
          [`.${tablePaginationClasses.selectLabel}`]: {
            marginLeft: Spacings.spacing6,
          },
          [`.${selectClasses.select}`]: {
            padding: `${Spacings.spacing4}px ${Spacings.spacing3}px`,
            '&:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:focus': {
              outline: `${Spacings.spacing2} solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing2,
              borderRadius: '8px',
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryHoverBlue1,
            },
          },
          [`.${inputBaseClasses.root}, .${tablePaginationClasses.select}`]: {
            margin: 0,
          },
          // To hide the number of rows (i.e. 5 of 8), unnecesary in our design
          [`.${tablePaginationClasses.displayedRows}`]: {
            display: 'none',
          },
        },
        actions: {
          display: 'none',
        },
        select: {
          borderRadius: '8px',
        },
        toolbar: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: Spacings.spacing4,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          [`.${paginationItemClasses.previousNext}, .${paginationItemClasses.firstLast}`]: {
            color: colors.colorPalette.primaryBlue,
            margin: 0,
            width: Spacings.spacing8,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:focus': {
              backgroundColor: colors.colorPalette.accentBlue1,
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing2,
            },
          },
          [`.${paginationItemClasses.previousNext}`]: {
            margin: `0 ${Spacings.spacing4}px`,
          },
          [`.${paginationItemClasses.page}`]: {
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: colors.colorPalette.accentBlue1,
            },
            '&:active': {
              backgroundColor: colors.colorPalette.primaryHoverBlue1,
            },
            '&:focus': {
              backgroundColor: colors.colorPalette.accentBlue1,
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing1,
            },
          },
          [`.${svgIconClasses.root} .${paginationItemClasses.icon}`]: {
            width: Spacings.spacing8,
            height: Spacings.spacing8,
          },
          [`.${paginationItemClasses.root}.Mui-selected`]: {
            backgroundColor: colors.colorPalette.primaryBlue,
            borderRadius: '8px',
            color: colors.colorPalette.backgroundWhite,
            '&:hover': {
              backgroundColor: colors.colorPalette.primaryHoverBlue1,
            },
            '&:focus': {
              color: colors.colorPalette.black,
              backgroundColor: colors.colorPalette.accentBlue1,
              outline: `${Spacings.spacing2}px solid ${colors.colorPalette.focusPurple}`,
              outlineOffset: Spacings.spacing1,
            },
          },
          [`.${paginationItemClasses.root}.Mui-disabled`]: {
            color: colors.colorPalette.paragraphPlaceholder,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: colors.colorPalette.backgroundWhite,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: colors.colorPalette.neutralGray3,
        },
        tooltip: {
          borderRadius: `${Spacings.spacing4}px`,
          minWidth: Spacings.spacing14,
          display: 'flex',
          justifyContent: 'center',
          padding: Spacings.spacing4,
          boxShadow: `${Hierarchies[Hierarchy.Hierarchy1].shadow} rgba(${Hierarchies[Hierarchy.Hierarchy1].rgba})`,
          color: colors.colorPalette.neutralGray1,
          backgroundColor: colors.colorPalette.neutralGray3,
          fontSize: Spacings.spacing5,
        },
      },
    },
  },
});
