export enum CandidateStatus {
  Active = 'Active',
  All = 'All', //TODO: delete this status
  Contacted = 'Contacted',
  Declined = 'Declined',
  Hired = 'Hired',
  Hold = 'Hold',
  NoResponse = 'No Response',
  Pipeline = 'Pipeline',
  Rejected = 'Rejected',
  Withdrew = 'Withdrew',
}
