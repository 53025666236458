import CloseIcon from '@mui/icons-material/Close';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Chip } from '@mui/material';
import React from 'react';
import { AzulIconButton } from '../../../../../../components/AzulIconButton';
import { colorPalette } from '../../../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../../../styles/partials/spacings';
import { DocumentExtensions } from '../../../../../../ts/enums/DocumentExtensions';
import { Size } from '../../../../../../ts/enums/Size';
import { truncateLabel } from '../../utils';
import { getFileIcon } from '../../utils';

interface DocumentChipProps {
  label: string;
  onClick: () => void;
}

export const DocumentChip = ({ label, onClick }: DocumentChipProps) => {
  const chipHierarchy = Hierarchies[Hierarchy.Hierarchy3];

  const sx = { width: Spacings.spacing11, height: Spacings.spacing11 };
  const iconMapping = {
    [DocumentExtensions.Pdf]: <PictureAsPdfIcon style={{ color: colorPalette.destructiveRed2 }} sx={sx} />,
    [DocumentExtensions.Docs]: <DocumentScannerIcon style={{ color: colorPalette.secondaryBlue }} />,
    [DocumentExtensions.Png]: <PermMediaIcon style={{ color: colorPalette.secondaryBlue }} sx={sx} />,
    [DocumentExtensions.Jpeg]: <PermMediaIcon style={{ color: colorPalette.secondaryBlue }} sx={sx} />,
    [DocumentExtensions.Jpg]: <PermMediaIcon style={{ color: colorPalette.secondaryBlue }} sx={sx} />,
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Chip
        icon={getFileIcon(label, iconMapping)}
        label={truncateLabel(label, 30)} // Truncate the label if it's longer than 30 characters
        sx={{
          backgroundColor: colorPalette.backgroundWhite,
          height: Spacings.spacing13,
          justifyContent: 'start',
          filter: `drop-shadow(${chipHierarchy.shadow} rgba(${chipHierarchy.rgba}))`,
          width: 324,
          padding: Spacings.spacing4,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          right: 5,
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <AzulIconButton
          icon={<CloseIcon sx={{ color: colorPalette.disabledGray2 }} />}
          size={Size.Small}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
};
