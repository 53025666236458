import { CandidateColumnModel, NewCandidateModel } from '../ts/interfaces/models/CandidateModel';
import { CandidatePostModel } from '../ts/interfaces/models/post/CandidatePostModel';
import { BaseService, ListResponseData, ResponseData } from './baseService';

class NewCandidateService extends BaseService<NewCandidateModel> {
  controllerName = 'candidate';

  async getCandidateTable(limit: number, offest: number): Promise<ListResponseData<NewCandidateModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<NewCandidateModel>>(
      `/${this.controllerName}?limit=${limit}&offset=${offest}`
    );

    return data;
  }

  async createNewCandidate(bodyData: CandidatePostModel, job_id: string): Promise<ResponseData<NewCandidateModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post<ResponseData<NewCandidateModel>>(
      `/${this.controllerName}/${job_id}`,
      JSON.stringify(bodyData)
    );
    return data;
  }

  async getCandidateByStage(stage: string): Promise<ListResponseData<CandidateColumnModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<CandidateColumnModel>>(
      `/${this.controllerName}/interviewStage?stage=${stage}`
    );
    return data;
  }

  async deleteCandidate(candidateId: string): Promise<void> {
    try {
      this.api.defaults.headers = this.getDefaultHeaders();
      await this.api.delete(`/${this.controllerName}/${candidateId}`);
    } catch (error) {
      console.error('Error deleting user:', error);
      throw new Error('Failed to delete candidate.');
    }
  }

  async updateCandidateStage(id: number | string, bodyData: { stage: string }) {
    this.api.defaults.headers = this.getDefaultHeaders();
    await this.api.put(`/${this.controllerName}/${id}/interviews`, JSON.stringify(bodyData));
  }
}

export const newCandidateService = new NewCandidateService();
