import { UserRoles } from '../../ts/enums/UserRoles';
import { FilterSection } from '../../ts/interfaces/Filter';
import { enumToOption } from '../../utils/parsers/option/enumToOption';

export const roleOptions = enumToOption(UserRoles);

export const options = [
  { label: 'Open', value: 'open' },
  { label: 'On hold', value: 'on_hold' },
  { label: 'Closed', value: 'closed' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Contacted', value: 'Contacted' },
  { label: 'No Response', value: 'No Response' },
  { label: 'Active', value: 'Active' },
  { label: 'Pipeline', value: 'Pipeline' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Declined', value: 'Declined' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Withdrew', value: 'Withdrew' },
  { label: 'Hired', value: 'Hired' },
  { label: 'Contacted', value: 'Contacted' },
];
export const filtersDataJson: FilterSection = {
  Role: [
    { label: 'Admin', checked: false },
    { label: 'Recruiter', checked: false },
    { label: 'Manage', checked: false },
    { label: 'Interviewer', checked: false },
  ],
  Deparment: [
    { label: 'HR', checked: false },
    { label: 'KSQU', checked: false },
    { label: 'Marketing', checked: false },
    { label: 'Operation', checked: false },
    { label: 'PM', checked: false },
    { label: 'Technology', checked: false },
    { label: 'UX', checked: false },
  ],
  Location: [
    { label: 'USA', checked: false },
    { label: 'MX', checked: false },
    { label: 'IN', checked: false },
    { label: 'DR', checked: false },
    { label: 'Remote', checked: false },
  ],
};
