export function toCamelCase(input: string): string {
  // Replace occurrences of `_`, `-`, or whitespace followed by a word character
  // with the uppercase version of the word character
  let camelCase = input.replace(/([-_\s]\w)/g, match => match[1].toUpperCase());

  // Convert the first letter to lowercase if the resulting camelCase string is not empty
  if (camelCase.length > 0) {
    camelCase = camelCase[0].toLowerCase() + camelCase.substr(1);
  }

  // Return the camelCase string
  return camelCase;
}
