import { Autocomplete, Box, TextField } from '@mui/material';
import React, { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { Spacings } from '../../styles/partials/spacings';
import { AzulMultipleAutocompleteProps } from '../../ts/interfaces/AzulMultipleAutocomplete';
import { Option } from '../../ts/interfaces/AzulOption';
import { AzulChip } from '../AzulChip';

export const AzulMultipleAutocomplete: FC<AzulMultipleAutocompleteProps> = ({
  value,
  options,
  label,
  disabled,
  onChange,
}) => {
  const [innerValue, setInnerValue] = useState<Option[]>(value || []);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && inputValue) {
      setInnerValue(prevValue => [...prevValue, { label: inputValue, value: inputValue }]);
      setInputValue('');
    }
  };

  const handleChipIconClick = (chipValue: Option) => {
    setInnerValue(prevValue => prevValue.filter(value => value.value !== chipValue.value));
  };

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      freeSolo
      fullWidth
      inputValue={inputValue}
      multiple
      options={options}
      renderInput={params => <TextField onChange={handleInputChange} {...params} label={label} variant='outlined' />}
      renderTags={(value, getTagProps) => (
        <Box display='flex' flexWrap={'wrap'} gap={Spacings.spacing4}>
          {innerValue.map((option, index) => (
            <AzulChip
              label={option.label.toUpperCase()}
              {...getTagProps({ index })}
              key={`chip-${index}`}
              removable
              onRemove={() => handleChipIconClick(option)}
            />
          ))}
        </Box>
      )}
      value={innerValue}
      onChange={(event, newValue) => {
        setInnerValue(newValue as Option[]);
        onChange(newValue as Option[]);
      }}
      onKeyDown={handleOnKeyDown}
    />
  );
};
