import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface JobTypeOptions {
  jobTypeOptions: Option[];
  jobTypeOptionsRequestStatus: RequestStatus;
}

const initialState: JobTypeOptions = {
  jobTypeOptions: [],
  jobTypeOptionsRequestStatus: RequestStatus.Idle,
};

export const jobTypeSlice = createSlice({
  name: 'jobTypes',
  initialState,
  reducers,
});

export const { setJobTypeOptions, setJobTypeOptionsRequestStatus } = jobTypeSlice.actions;

export default jobTypeSlice.reducer;
