import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface HiringManagerOptions {
  hiringManagerOptions: Option[];
  hiringManagerOptionsRequestStatus: RequestStatus;
}

const initialState: HiringManagerOptions = {
  hiringManagerOptions: [],
  hiringManagerOptionsRequestStatus: RequestStatus.Idle,
};

export const hiringManagerSlice = createSlice({
  name: 'hiringManagers',
  initialState,
  reducers,
});

export const { setHiringManagerOptions, setHiringManagerOptionsRequestStatus } = hiringManagerSlice.actions;

export default hiringManagerSlice.reducer;
