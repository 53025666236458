import { PayloadAction } from '@reduxjs/toolkit';
import { SalaryPeriodOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setSalaryPeriodsOptions = (state: SalaryPeriodOptions, action: PayloadAction<Option[]>): void => {
  state.salaryPeriodOptions = action.payload;
};

const setSalaryPeriodOptionsRequestStatus = (
  state: SalaryPeriodOptions,
  action: PayloadAction<RequestStatus>
): void => {
  state.salaryPeriodOptionsRequestStatus = action.payload;
};

const reducers = {
  setSalaryPeriodsOptions,
  setSalaryPeriodOptionsRequestStatus,
};

export default reducers;
