import { CandidateStage } from '../ts/enums/CandidateStage';
import { CandidateStatus } from '../ts/enums/CandidateStatus';
import { Option } from '../ts/interfaces/AzulOption';

export const statusOptions: Option[] = Object.values(CandidateStatus).map(value => {
  return { value, label: value };
});

export const stageOptions: Option[] = Object.values(CandidateStage).map(value => {
  return { value, label: value };
});
