import {SxProps, Theme } from "@mui/material";
import { colorPalette } from "../../styles/partials/colors";
import { StepType } from "../../ts/enums/Steps";

export const getStepType = (activeStep: number, index: number): StepType => {
    const isStepActive = index === activeStep;
    const isStepCompleted = index < activeStep;
    if (isStepActive) {
      return StepType.Active;
    }
    if (isStepCompleted) {
      return StepType.Completed;
    }
    return StepType.Default;
  };

export const getTypographyColor = (stepType: StepType): SxProps<Theme> => {
    const defaultStyle = {
      color: colorPalette.disabledGray2,
    };
    const activeStyle = {
      color: colorPalette.primaryBlue,
    };
    const completedStyle = {
      color: colorPalette.primaryBlue,
    };
    if (stepType === StepType.Active) return activeStyle;
    if (stepType === StepType.Completed) return completedStyle;
    return defaultStyle;
  };
