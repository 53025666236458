export type ModalNewCandidateType = {
  country: string;
  firstName: string;
  email: string;
  firstContactDate: Date;
  lastName: string;
  jobPosition: string;
  recruiter: string;
  source: string;
  specifySource: string;
  stage: string;
  status: string;
  salaryRange?: string;
  salaryCurrency?: string;
  seniority?: string;
  cvFile: File | null;
  portfolioFile?: File | null;
  phoneNumber?: string;
  yearsOfExperience?: string;
};

export const emptyValues = {
  country: '',
  seniority: '',
  firstName: '',
  email: '',
  cvFile: null,
  portfolioFile: null,
  phoneNumber: '',
  salaryRange: '',
  salaryCurrency: '',
  firstContactDate: new Date(),
  lastName: '',
  jobPosition: '',
  recruiter: '',
  source: '',
  specifySource: '',
  stage: '',
  status: '',
  yearsOfExperience: '',
};
