import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { Box, Card, CardActions, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulChip } from '../../../../components/AzulChip';
import { AzulScroll } from '../../../../components/AzulScroll';
import { colorPalette } from '../../../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../../../styles/partials/hierarchies';
import { Spacings } from '../../../../styles/partials/spacings';
import { JobDetailsCardProps } from '../../../../ts/interfaces/JobDetailsCard';

export const JobDetailsCard = ({ data, description, skills, title, urgent }: JobDetailsCardProps) => {
  const characterLimit = 170;
  const isExceedingLimit = description.length > characterLimit;
  const [isTruncated, setIsTruncated] = useState<boolean>(isExceedingLimit);
  const optionsHierarchy = Hierarchies[Hierarchy.Hierarchy3];
  const { createdAt, jobType, daysOpen, jobTime, department, client, manager, clientJobId, hiringManager } = data;
  const day = String(createdAt.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
  const month = String(createdAt.getMonth() + 1).padStart(2, '0'); // Get month (returns 0-11, so add 1) and pad with leading zero if necessary
  const year = String(createdAt.getFullYear()).slice(-2); // Get the last two digits of the year
  const formatedCreatedAt = `${day}/${month}/${year}`;

  const metadata = [
    { title: 'Created', value: formatedCreatedAt },
    { title: 'Job type', value: jobType },
    { title: 'Days open', value: daysOpen },
    { title: 'Job time', value: jobTime },
    { title: 'Department', value: department },
    { title: 'Client', value: client },
    { title: 'Manager', value: manager },
    { title: 'ClientJob ID', value: clientJobId },
    { title: 'Hiring Manager', value: hiringManager },
  ];

  const metadataList = metadata.map(({ title, value }, index) => (
    <Grid item key={`${title}-${value}-${index}`} sx={{ paddingTop: Spacings.spacing0 }} xs={6}>
      <Typography sx={{ color: colorPalette.paragraphBaseline }} variant='body1-medium'>
        {title}
      </Typography>
      <Typography sx={{ color: colorPalette.paragraphBaseline }} variant='body1'>
        {value}
      </Typography>
    </Grid>
  ));

  const skillsList = skills.map((label, index) => (
    <Grid item key={`${index}-${label}`}>
      <AzulChip label={label} />
    </Grid>
  ));

  const truncatedText = `${description.slice(0, characterLimit)}...`;
  const content = isTruncated && isExceedingLimit ? truncatedText : description;

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <Card
      elevation={0}
      sx={{
        background: colorPalette.backgroundBlue,
        borderRadius: Spacings.spacing1,
        filter: `drop-shadow(${optionsHierarchy.shadow} rgba(${optionsHierarchy.rgba}))`,
        maxWidth: '100%',
        paddingTop: Spacings.spacing5,
        paddingRight: Spacings.spacing5,
        paddingBottom: Spacings.spacing8,
        paddingLeft: Spacings.spacing7,
      }}
    >
      <AzulScroll marginTop={-Spacings.spacing3}>
        <Box
          sx={{
            height: isExceedingLimit ? 660 : '100%',
            paddingRight: Spacings.spacing5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              height: Spacings.spacing8,
              alignSelf: 'stretch',
              marginTop: Spacings.spacing6,
            }}
          >
            <Typography
              sx={{
                height: Spacings.spacing8,
                color: urgent ? colorPalette.destructiveRed2 : colorPalette.accentBlue2,
                textAlign: 'right',
                textTransform: 'uppercase',
                display: 'flex',
                alignItems: 'center',
              }}
              variant='subtitle2'
            >
              PRIORITY: {urgent ? 'URGENT' : 'NORMAL'}
              {urgent && <PriorityHighRoundedIcon sx={{ height: Spacings.spacing8, width: Spacings.spacing8 }} />}
            </Typography>
          </Box>
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: colorPalette.paragraphBaseline,
              marginTop: Spacings.spacing4,
              height: Spacings.spacing7,
            }}
            variant='subtitle2'
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontStyle: 'normal',
              color: colorPalette.paragraphBaseline,
              maxWidth: 305,
              marginTop: Spacings.spacing4,
            }}
            variant='body1'
          >
            {content}
          </Typography>
          {isExceedingLimit && (
            <CardActions sx={{ paddingLeft: Spacings.spacing4 }}>
              <AzulButton
                label={isTruncated ? 'See more' : 'See less'}
                startIcon={
                  <AddRoundedIcon
                    sx={{
                      height: Spacings.spacing8,
                      width: Spacings.spacing8,
                    }}
                  />
                }
                variant='tertiary'
                onClick={toggleTruncated}
              />
            </CardActions>
          )}
          <Box
            sx={{
              marginY: Spacings.spacing9,
              minHeight: 100,
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                color: colorPalette.paragraphBaseline,
                marginBottom: Spacings.spacing3,
              }}
              variant='subtitle2'
            >
              Skills
            </Typography>
            <Grid container spacing={Spacings.spacing4} wrap='wrap'>
              {skillsList}
            </Grid>
          </Box>
          <Grid container paddingBottom={Spacings.spacing7} spacing={Spacings.spacing2} sx={{ maxWidth: '100%' }}>
            {metadataList}
          </Grid>
        </Box>
      </AzulScroll>
    </Card>
  );
};
