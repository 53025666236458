import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import React from 'react';
import { AzulFabButton } from '../../../../components/AzulFabButton';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';
import { HiringTeamContainerProps } from '../../../../ts/interfaces/HiringTeamContainer';

export const HiringTeamContainer = ({ hiringTeamArray, onClick }: HiringTeamContainerProps) => {
  const showFabButton = hiringTeamArray && hiringTeamArray.filter(item => item.value !== '').length !== 4; // just to know if the entire hiringTeam has already been assigned

  return (
    <Box
      alignItems='center'
      border={`${Spacings.spacing1}px solid ${colorPalette.primaryHoverBlue2}`}
      borderRadius={Spacings.spacing1}
      boxSizing='border-box'
      display='flex'
      gap={Spacings.spacing8}
      height={Spacings.spacing15}
      justifyContent='center'
      width='100%'
    >
      {hiringTeamArray &&
        hiringTeamArray.map(({ title, value, shortened }, index) => {
          if (value) {
            return (
              <AzulFabButton
                isButton={false}
                key={`${index}-${title}-${value}`}
                shortened={shortened}
                title={title}
                value={value}
              />
            );
          }
        })}
      {showFabButton ? (
        <AzulFabButton title={'Hiring Team'} toolTip='Add Hiring team' onClick={onClick} />
      ) : (
        <AzulFabButton
          icon={
            <EditIcon
              sx={{ color: colorPalette.backgroundWhite, width: Spacings.spacing6, height: Spacings.spacing6 }}
            />
          }
          title='Edit Hiring Team'
          toolTip='Edit Hiring Team'
          onClick={onClick}
        />
      )}
    </Box>
  );
};
