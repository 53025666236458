import { PayloadAction } from '@reduxjs/toolkit';
import { JobTypeOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setJobTypeOptions = (state: JobTypeOptions, action: PayloadAction<Option[]>): void => {
  state.jobTypeOptions = action.payload;
};

const setJobTypeOptionsRequestStatus = (state: JobTypeOptions, action: PayloadAction<RequestStatus>): void => {
  state.jobTypeOptionsRequestStatus = action.payload;
};

const reducers = {
  setJobTypeOptions,
  setJobTypeOptionsRequestStatus,
};

export default reducers;
