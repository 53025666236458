import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';
import { NotificationProps } from '../../../../ts/interfaces/Notification';

export const Notification: FC<NotificationProps> = ({ createdAt, title, notRead, isColumn }) => {
  const parsedTitle = title.length >= 67 && isColumn ? title.slice(0, 67).concat('...') : title;
  return (
    <Box
      component={'button'}
      display='flex'
      flexDirection={isColumn ? 'column' : 'row'}
      height={isColumn ? '80px' : Spacings.spacing13}
      justifyContent='space-between'
      maxWidth='100%'
      sx={{
        background: notRead ? colorPalette.neutralGray1 : colorPalette.backgroundWhite,
        padding: isColumn ? `${Spacings.spacing5}px ${Spacings.spacing10}px` : Spacings.spacing6,
        cursor: 'pointer',
        '&:hover': {
          background: colorPalette.accentBlue1,
        },
        '&:focus': {
          background: colorPalette.accentBlue1,
          outline: 'none',
        },
        border: 0,
        textAlign: 'left',
      }}
    >
      <Typography
        color={notRead ? colorPalette.paragraphBaseline : colorPalette.paragraphPlaceholder}
        textTransform={'none'}
        variant={isColumn ? 'overline' : 'body1'}
      >
        {parsedTitle}
      </Typography>
      <Typography
        color={notRead ? colorPalette.paragraphBaseline : colorPalette.paragraphPlaceholder}
        variant={isColumn ? 'caption' : 'body2'}
      >
        {createdAt}
      </Typography>
    </Box>
  );
};
