import { CandidateStage } from '../../ts/enums/CandidateStage';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { CandidateModel } from '../../ts/interfaces/models/CandidateModel';

export const mockData: CandidateModel[] = [
  {
    id: '1',
    email: 'email1@example.com',
    name: 'Alejandra Campoy',
    recruiter: 'Alejandra Suero',
    status: CandidateStatus.Pipeline,
    job_id: '1',
    numOfInterview: 2,
    referred: false,
    clientInterview: 'Daniel Alcudia',
    firstContactInterview: 'Daniel Alcudia',
    firstTechInterview: 'Daniel Alcudia',
    secondTechInterview: 'Daniel Alcudia',
    phone_no: '+52 9999 123456',
    seniority: 'Senior',
    years_of_experience: 5,
    salary_expectations: '$20,000 - 25,000 MXN',
    country: 'Mexico',
    source: 'Linkedin',
    linkedinProfile: '#',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstContact,
    jobPosition: {
      title: 'Jr. Frontend Developer',
      id: '1',
      location: 'MX',
      lowerPriceRange: 15000,
      upperPriceRange: 22000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['React', 'MUI', 'CSS', 'Javascript'],
      description:
        'We are seeking a talented and passionate Junior Front End Developer to join our dynamic team. As a Junior Front End Developer, you will play a crucial role in creating engaging and user-friendly web experiences. You will collaborate with our experienced development team to implement innovative designs and transform them into functional, responsive websites.',
      client: 'N/A',
    },
  },
  {
    id: '2',
    email: 'email2@example.com',
    name: 'John Dregger',
    recruiter: 'Alejandra Suero',
    status: CandidateStatus.Contacted,
    job_id: '1',
    numOfInterview: 2,
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Middle',
    years_of_experience: 10,
    salary_expectations: '$30,000 - 35,000 MXN',
    country: 'Dominican Republic',
    source: 'Web applicant',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.ClientInterview,
    jobPosition: {
      title: 'Jr. Frontend Developer',
      id: '1',
      location: 'MX',
      lowerPriceRange: 15000,
      upperPriceRange: 22000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['React', 'MUI', 'CSS', 'Javascript'],
      description:
        'We are seeking a talented and passionate Junior Front End Developer to join our dynamic team. As a Junior Front End Developer, you will play a crucial role in creating engaging and user-friendly web experiences. You will collaborate with our experienced development team to implement innovative designs and transform them into functional, responsive websites.',
      client: 'N/A',
    },
  },
  {
    id: '3',
    email: 'email3@example.com',
    name: 'Rebeca Jimenez',
    recruiter: 'Alejandra Suero',
    status: CandidateStatus.Active,
    job_id: '1',
    numOfInterview: 2,
    referred: true,
    phone_no: '+52 9999 789123',
    seniority: 'Junior',
    years_of_experience: 3,
    salary_expectations: '$15,000 - 20,000 MXN',
    country: 'Mexico',
    source: 'Referred',
    referred_by: 'John Doe',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstTechnicalInterview,
    jobPosition: {
      title: 'Jr. Frontend Developer',
      id: '1',
      location: 'MX',
      lowerPriceRange: 15000,
      upperPriceRange: 22000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['React', 'MUI', 'CSS', 'Javascript'],
      description:
        'We are seeking a talented and passionate Junior Front End Developer to join our dynamic team. As a Junior Front End Developer, you will play a crucial role in creating engaging and user-friendly web experiences. You will collaborate with our experienced development team to implement innovative designs and transform them into functional, responsive websites.',
      client: 'N/A',
    },
  },
  {
    id: '4',
    email: 'email4@example.com',
    name: 'Sarah Johnson',
    recruiter: 'John Smith',
    status: CandidateStatus.Pipeline,
    job_id: '2',
    numOfInterview: 1,
    referred: false,
    phone_no: '+52 9999 456789',
    seniority: 'Senior',
    years_of_experience: 15,
    salary_expectations: '$40,000 - 45,000 MXN',
    country: 'Egypt',
    source: 'Web applicant',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.MakeAnOffer,
    jobPosition: {
      title: 'Software Engineer',
      id: '2',
      location: 'US',
      lowerPriceRange: 60000,
      upperPriceRange: 90000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Java', 'Python', 'C++', 'SQL', 'AWS'],
      description:
        'We are looking for skilled Software Engineers to join our team. As a Software Engineer, you will be responsible for developing high-quality software solutions. You will work closely with our development team to design, code, and test applications that meet our clients',
      client: 'N/A',
    },
  },
  {
    id: '5',
    email: 'email5@example.com',
    name: 'Michael Anderson',
    recruiter: 'John Smith',
    status: CandidateStatus.Active,
    job_id: '2',
    referred: true,
    phone_no: '+52 9999 654321',
    seniority: 'Middle',
    years_of_experience: 7,
    salary_expectations: '$35,000 - 40,000 MXN',
    country: 'Dominican Republic',
    source: 'Referred',
    referred_by: 'Sarah Johnson',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.SecondTechnicalInterview,
    jobPosition: {
      title: 'Software Engineer',
      id: '2',
      location: 'US',
      lowerPriceRange: 60000,
      upperPriceRange: 90000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Java', 'Python', 'C++', 'SQL', 'AWS'],
      description:
        'We are looking for skilled Software Engineers to join our team. As a Software Engineer, you will be responsible for developing high-quality software solutions. You will work closely with our development team to design, code, and test applications that meet our clients',
      client: 'N/A',
    },
  },
  {
    id: '6',
    email: 'email6@example.com',
    name: 'Jessica Lee',
    recruiter: 'John Smith',
    status: CandidateStatus.Contacted,
    job_id: '2',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Senior',
    years_of_experience: 10,
    salary_expectations: '$40,000 - 45,000 MXN',
    country: 'Mexico',
    source: 'Linkedin',
    linkedinProfile: '#',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.TestDome,
    jobPosition: {
      title: 'Software Engineer',
      id: '2',
      location: 'US',
      lowerPriceRange: 60000,
      upperPriceRange: 90000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Java', 'Python', 'C++', 'SQL', 'AWS'],
      description:
        'We are looking for skilled Software Engineers to join our team. As a Software Engineer, you will be responsible for developing high-quality software solutions. You will work closely with our development team to design, code, and test applications that meet our clients',
      client: 'N/A',
    },
  },
  {
    id: '7',
    email: 'email7@example.com',
    name: 'David Thompson',
    recruiter: 'Emily Davis',
    status: CandidateStatus.Active,
    job_id: '3',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Junior',
    years_of_experience: 3,
    salary_expectations: '$15,000 - 20,000 MXN',
    country: 'Egypt',
    source: 'Web applicant',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.ClientInterview,
    jobPosition: {
      title: 'Data Analyst',
      id: '3',
      location: 'CA',
      lowerPriceRange: 40000,
      upperPriceRange: 60000,
      job_type: 'Permanent',
      job_time: '',
      skills: ['SQL', 'Python', 'Data Visualization', 'Statistical Analysis'],
      description:
        'We are seeking a highly motivated and detail-oriented Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting complex data sets. You will collaborate with various stakeholders to identify trends, develop reports, and provide insights that drive informed business decisions.',
      client: 'N/A',
    },
  },
  {
    id: '8',
    email: 'email8@example.com',
    name: 'Laura Rodriguez',
    recruiter: 'Emily Davis',
    status: CandidateStatus.Pipeline,
    job_id: '3',
    referred: true,
    phone_no: '+52 9999 654321',
    seniority: 'Senior',
    years_of_experience: 12,
    salary_expectations: '$40,000 - 45,000 MXN',
    country: 'Dominican Republic',
    source: 'Referred',
    referred_by: 'David Thompson',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstContact,
    jobPosition: {
      title: 'Data Analyst',
      id: '3',
      location: 'CA',
      lowerPriceRange: 40000,
      upperPriceRange: 60000,
      job_type: 'Permanent',
      job_time: '',
      skills: ['SQL', 'Python', 'Data Visualization', 'Statistical Analysis'],
      description:
        'We are seeking a highly motivated and detail-oriented Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting complex data sets. You will collaborate with various stakeholders to identify trends, develop reports, and provide insights that drive informed business decisions.',
      client: 'N/A',
    },
  },
  {
    id: '9',
    email: 'email9@example.com',
    name: 'Edward Wilson',
    recruiter: 'Emily Davis',
    status: CandidateStatus.Contacted,
    job_id: '3',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Middle',
    years_of_experience: 8,
    salary_expectations: '$35,000 - 40,000 MXN',
    country: 'Mexico',
    source: 'Linkedin',
    linkedinProfile: '#',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstTechnicalInterview,
    jobPosition: {
      title: 'Data Analyst',
      id: '3',
      location: 'CA',
      lowerPriceRange: 40000,
      upperPriceRange: 60000,
      job_type: 'Permanent',
      job_time: '',
      skills: ['SQL', 'Python', 'Data Visualization', 'Statistical Analysis'],
      description:
        'We are seeking a highly motivated and detail-oriented Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting complex data sets. You will collaborate with various stakeholders to identify trends, develop reports, and provide insights that drive informed business decisions.',
      client: 'N/A',
    },
  },
  {
    id: '10',
    email: 'email10@example.com',
    name: 'Patricia Anderson',
    recruiter: 'Michael Green',
    status: CandidateStatus.Active,
    job_id: '4',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Junior',
    years_of_experience: 2,
    salary_expectations: '$15,000 - 20,000 MXN',
    country: 'Egypt',
    source: 'Web applicant',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.MakeAnOffer,
    jobPosition: {
      title: 'Senior Backend Developer',
      id: '4',
      location: 'UK',
      lowerPriceRange: 80000,
      upperPriceRange: 120000,
      job_type: '',
      job_time: 'Full time',
      skills: ['Java', 'Spring', 'RESTful API', 'Database Design', 'Microservices'],
      description:
        'We are looking for experienced Senior Backend Developers to join our team. As a Senior Backend Developer, you will be responsible for designing, developing, and maintaining the server-side logic of our web applications. You will work closely with the frontend development team to ensure seamless integration and optimal performance.',
      client: 'N/A',
    },
  },
  {
    id: '11',
    email: 'email11@example.com',
    name: 'Robert Turner',
    recruiter: 'Michael Green',
    status: CandidateStatus.Pipeline,
    job_id: '4',
    referred: true,
    phone_no: '+52 9999 654321',
    seniority: 'Senior',
    years_of_experience: 11,
    salary_expectations: '$40,000 - 45,000 MXN',
    country: 'Dominican Republic',
    source: 'Referred',
    referred_by: 'Patricia Anderson',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.SecondTechnicalInterview,
    jobPosition: {
      title: 'Senior Backend Developer',
      id: '4',
      location: 'UK',
      lowerPriceRange: 80000,
      upperPriceRange: 120000,
      job_type: '',
      job_time: 'Full time',
      skills: ['Java', 'Spring', 'RESTful API', 'Database Design', 'Microservices'],
      description:
        'We are looking for experienced Senior Backend Developers to join our team. As a Senior Backend Developer, you will be responsible for designing, developing, and maintaining the server-side logic of our web applications. You will work closely with the frontend development team to ensure seamless integration and optimal performance.',
      client: 'N/A',
    },
  },
  {
    id: '12',
    email: 'email12@example.com',
    name: 'Jennifer Williams',
    recruiter: 'Michael Green',
    status: CandidateStatus.Contacted,
    job_id: '4',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Middle',
    years_of_experience: 6,
    salary_expectations: '$35,000 - 40,000 MXN',
    country: 'Mexico',
    source: 'Linkedin',
    linkedinProfile: '#',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.TestDome,
    jobPosition: {
      title: 'Senior Backend Developer',
      id: '4',
      location: 'UK',
      lowerPriceRange: 80000,
      upperPriceRange: 120000,
      job_type: '',
      job_time: 'Full time',
      skills: ['Java', 'Spring', 'RESTful API', 'Database Design', 'Microservices'],
      description:
        'We are looking for experienced Senior Backend Developers to join our team. As a Senior Backend Developer, you will be responsible for designing, developing, and maintaining the server-side logic of our web applications. You will work closely with the frontend development team to ensure seamless integration and optimal performance.',
      client: 'N/A',
    },
  },
  {
    id: '13',
    email: 'email13@example.com',
    name: 'Thomas Brown',
    recruiter: 'Jennifer Harris',
    status: CandidateStatus.Active,
    job_id: '5',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Junior',
    years_of_experience: 1,
    salary_expectations: '$15,000 - 20,000 MXN',
    country: 'Egypt',
    source: 'Web applicant',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.ClientInterview,
    jobPosition: {
      title: 'UX/UI Designer',
      id: '5',
      location: '',
      lowerPriceRange: 50000,
      upperPriceRange: 75000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Sketch', 'Adobe XD', 'Wireframing', 'Prototyping'],
      description:
        'We are seeking a talented and creative UX/UI Designer to join our design team. As a UX/UI Designer, you will be responsible for creating intuitive and visually appealing user interfaces. You will collaborate with our development and product teams to ensure a seamless user experience across our web and mobile applications.',
      client: 'N/A',
    },
  },
  {
    id: '14',
    email: 'email14@example.com',
    name: 'Elizabeth Martin',
    recruiter: 'Jennifer Harris',
    status: CandidateStatus.Pipeline,
    job_id: '5',
    referred: true,
    phone_no: '+52 9999 654321',
    seniority: 'Senior',
    years_of_experience: 13,
    salary_expectations: '$40,000 - 45,000 MXN',
    country: 'Dominican Republic',
    source: 'Referred',
    referred_by: 'Thomas Brown',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstContact,
    jobPosition: {
      title: 'UX/UI Designer',
      id: '5',
      location: '',
      lowerPriceRange: 50000,
      upperPriceRange: 75000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Sketch', 'Adobe XD', 'Wireframing', 'Prototyping'],
      description:
        'We are seeking a talented and creative UX/UI Designer to join our design team. As a UX/UI Designer, you will be responsible for creating intuitive and visually appealing user interfaces. You will collaborate with our development and product teams to ensure a seamless user experience across our web and mobile applications.',
      client: 'N/A',
    },
  },
  {
    id: '15',
    email: 'email15@example.com',
    name: 'Richard Taylor',
    recruiter: 'Jennifer Harris',
    status: CandidateStatus.Contacted,
    job_id: '5',
    referred: false,
    phone_no: '+52 9999 654321',
    seniority: 'Middle',
    years_of_experience: 7,
    salary_expectations: '$35,000 - 40,000 MXN',
    country: 'Mexico',
    source: 'Linkedin',
    linkedinProfile: '#',
    referred_by: '',
    first_contact_date: new Date().toLocaleDateString('en-US'),
    stage: CandidateStage.FirstTechnicalInterview,
    jobPosition: {
      title: 'UX/UI Designer',
      id: '5',
      location: '',
      lowerPriceRange: 50000,
      upperPriceRange: 75000,
      job_type: 'Permanent',
      job_time: 'Full time',
      skills: ['Sketch', 'Adobe XD', 'Wireframing', 'Prototyping'],
      description:
        'We are seeking a talented and creative UX/UI Designer to join our design team. As a UX/UI Designer, you will be responsible for creating intuitive and visually appealing user interfaces. You will collaborate with our development and product teams to ensure a seamless user experience across our web and mobile applications.',
      client: 'N/A',
    },
  },
];

export const notesMockData = [
  {
    id: '1',
    candidate_id: '3',
    user: { id: '1', name: 'Andy Hernandez' },
    note_type: 'Private',
    comment: 'This candidate has strong communication skills.',
    createdAt: new Date('2022-01-01T09:30:00'),
  },
  {
    id: '2',
    candidate_id: '1',
    user: { id: '2', name: 'Daniel Alcudia' },
    note_type: 'Private',
    comment: 'The candidate performed well in the technical round.',
    createdAt: new Date('2022-02-15T14:45:00'),
  },
  {
    id: '3',
    candidate_id: '3',
    user: { id: '3', name: 'Alexis Guevara' },
    note_type: 'Private',
    comment: "The candidate's problem-solving skills need improvement.",
    createdAt: new Date('2022-03-30T11:20:00'),
  },
  {
    id: '4',
    candidate_id: '2',
    user: { id: '4', name: 'Carlos Gomez' },
    note_type: 'Private',
    comment: 'Candidate is a good team player.',
    createdAt: new Date('2022-06-10T16:50:00'),
  },
  {
    id: '5',
    candidate_id: '2',
    user: { id: '5', name: 'Alan Villanueva' },
    note_type: 'Private',
    comment: 'Candidate has excellent domain knowledge.',
    createdAt: new Date('2022-07-25T10:15:00'),
  },
  {
    id: '6',
    candidate_id: '3',
    user: { id: '1', name: 'Andy Hernandez' },
    note_type: 'Private',
    comment: 'Candidate has excellent domain knowledge.',
    createdAt: new Date('2022-10-05T13:40:00'),
  },
  {
    id: '7',
    candidate_id: '3',
    user: { id: '2', name: 'John Smith' },
    note_type: 'Public',
    comment: 'Candidate demonstrates strong problem-solving skills.',
    createdAt: new Date('2023-01-15T08:20:00'),
  },
  {
    id: '8',
    candidate_id: '3',
    user: { id: '2', name: 'John Smith' },
    note_type: 'Public',
    comment: 'Candidate communicates effectively and confidently.',
    createdAt: new Date('2023-02-20T11:55:00'),
  },
  {
    id: '9',
    candidate_id: '3',
    user: { id: '1', name: 'Andy Hernandez' },
    note_type: 'Public',
    comment: 'Candidate shows great attention to detail.',
    createdAt: new Date('2023-03-21T12:55:00'),
  },
  {
    id: '10',
    candidate_id: '3',
    user: { id: '2', name: 'John Smith' },
    note_type: 'Public',
    comment: 'Candidate has a strong work ethic and is highly motivated.',
    createdAt: new Date('2023-05-10T13:50:00'),
  },
  {
    id: '11',
    candidate_id: '3',
    user: { id: '1', name: 'Andy Hernandez' },
    note_type: 'Public',
    comment: 'Candidate adapts quickly to new challenges.',
    createdAt: new Date('2023-06-07T15:25:00'),
  },
  {
    id: '12',
    candidate_id: '3',
    user: { id: '7', name: 'Olivia Wilson' },
    note_type: 'Public',
    comment: 'Candidate consistently meets project deadlines.',
    createdAt: new Date('2023-07-20T00:00:00'),
  },
  {
    id: '13',
    candidate_id: '3',
    user: { id: '1', name: 'Andy Hernandez' },
    note_type: 'Public',
    comment: 'Candidate demonstrates strong leadership qualities.',
    createdAt: new Date('2023-07-20T00:00:01'),
  },
  {
    id: '14',
    candidate_id: '3',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-12-12T12:40:00'),
  },
  {
    id: '15',
    candidate_id: '1',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:02'),
  },
  {
    id: '16',
    candidate_id: '1',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:03'),
  },
  {
    id: '17',
    candidate_id: '2',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:04'),
  },
  {
    id: '18',
    candidate_id: '2',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:05'),
  },
  {
    id: '19',
    candidate_id: '2',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:06'),
  },
  {
    id: '20',
    candidate_id: '2',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:07'),
  },
  {
    id: '21',
    candidate_id: '2',
    user: { id: '9', name: 'Ava Anderson' },
    note_type: 'Public',
    comment: 'Candidate has a positive attitude and is a team player.',
    createdAt: new Date('2023-07-20T00:00:08'),
  },
];
