import { Option } from '../../../ts/interfaces/AzulOption';

type DataItem = { id?: string | number; title: string };

export const parseDataToOptions = (data: DataItem[], uppercaseValue = false, useIdAsValue = false): Option[] => {
  const parsedData: Option[] = data.map(({ id, title }) => {
    const label = title;
    const value =
      useIdAsValue && id ? String(id) : uppercaseValue ? title.toUpperCase() : title.toLowerCase().replace(/\s/g, '_');
    return { label, value };
  });
  return parsedData;
};

export const parseCountryToOptions = (data: DataItem[]): Option[] => {
  const parsedData: Option[] = data.map(({ id, title }) => {
    // Define a mapping of titles to labels if needed
    const titleToLabel: Record<string, string> = {
      MX: 'Mexico',
      DR: 'Dominican Republic',
      IN: 'India',
      // Add more mappings for other titles as needed
    };
    const label = titleToLabel[title] || title;
    const value = String(id) || ''; // Provide a default value if id is undefined
    return { label, value };
  });

  return parsedData;
};

/**
 * Represents a customized chip component.
 *
 * @param userObjects - An array of user objects with properties: { id?: string | number; name: string }
 * @param uppercaseValue - A boolean indicating whether the option value should be in uppercase. (Optional, default: false)
 * @param useIdAsValue - A boolean indicating whether to use the user's ID as the option value. (Optional, default: false)
 *
 * @returns An array of Option objects, where each object contains a label and value representing user information.
 */
export const parseUserToOptions = (
  userObjects: Array<{ id?: string | number; name: string }>,
  uppercaseValue = false,
  useIdAsValue = false
): Option[] => {
  const parsedOptions: Option[] = userObjects.map(userObject => {
    const { id, name } = userObject;
    const label = name;
    const value =
      useIdAsValue && id ? String(id) : uppercaseValue ? name.toUpperCase() : name.toLowerCase().replace(/\s/g, '_');
    return { label, value };
  });

  return parsedOptions;
};
