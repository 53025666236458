import { ModalHiringTeamType } from '../../ts/interfaces/forms/ModalHiringTeamType';

export const parseHiringTeamFormDataToArray = (hiringTeam?: ModalHiringTeamType) => {
  if (!hiringTeam) return;

  // Mapping of input titles to their desired formatted titles
  const titleMapping: { [key: string]: string } = {
    recruiter: 'Recruiter',
    firstTechInterview: '1st interviewer',
    secondTechInterview: '2nd interviewer',
    clientInterview: 'Client',
  };

  // Define the order for sorting the titles
  const order: { [key: string]: number } = {
    Recruiter: 1,
    '1st interviewer': 2,
    '2nd interviewer': 3,
    Client: 4,
  };

  const hiringTeamEntries = Object.entries(hiringTeam);

  // Filter out unwanted keys
  const unwantedKeys = ['firstContactInterview', 'stage'];
  const filteredHiringTeamArray = hiringTeamEntries
    .filter(([key]) => !unwantedKeys.includes(key))
    // Transform each entry into an object with formatted title, value, and shortened name
    .map(([title, value]) => {
      // Use the title mapping to get the desired formatted title
      const formattedTitle = titleMapping[title] || title;
      // Apply formatting to the value using the formatValue function
      const formattedValue = formatHiringTeamValue(value);
      // Shorten the name using the shortenName function
      const shortened = shortenName(formattedValue);

      return {
        title: formattedTitle,
        value: formattedValue,
        shortened,
      };
    });
  // Sort the array based on the defined order of titles
  return filteredHiringTeamArray.sort((a, b) => order[a.title] - order[b.title]);
};

const formatHiringTeamValue = (value?: string) => {
  return value?.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase()) || '';
};

const shortenName = (name: string) => {
  const nameParts = name.split(' ');
  if (nameParts.length === 1) {
    return nameParts[0].slice(0, 2).toUpperCase();
  } else {
    return nameParts.map(part => part[0].toUpperCase()).join('');
  }
};

type Iterator = {
  [key: string]: {
    label: string;
    value: string;
  };
};

export const parseInitialInterviewData = (interviews: Iterator): ModalHiringTeamType => {
  const parsedInterviews: Partial<ModalHiringTeamType> = {};
  for (const key in interviews) {
    if (interviews.hasOwnProperty(key)) {
      parsedInterviews[key as keyof ModalHiringTeamType] = interviews[key].value;
    }
  }
  return parsedInterviews as ModalHiringTeamType;
};
