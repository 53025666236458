import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';

type NoteVisibilityProps = { label: string };

export const NoteVisibility = ({ label }: NoteVisibilityProps) => {
  return (
    <Box
      borderRadius={`${Spacings.spacing4}px`}
      display='flex'
      gap={Spacings.spacing4}
      marginBottom={Spacings.spacing5}
      paddingX={Spacings.spacing6}
      paddingY={Spacings.spacing5}
      sx={{
        backgroundColor: colorPalette.neutralGray3,
      }}
    >
      <ErrorOutlinedIcon sx={{ rotate: '180deg', color: colorPalette.primaryHoverBlue1 }} />
      <Typography color={colorPalette.neutralGray1} variant='body2'>
        {label}
      </Typography>
    </Box>
  );
};
