import { Box, Button, FormControl } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { AzulCurrencyCombobox } from '../../../../components/AzulCurrencyCombobox';
import { AzulDropdownMenu } from '../../../../components/AzulDropdownMenu';
import { AzulTextField } from '../../../../components/AzulTextField';
import { currencyOptions } from '../../../../constants/currencyOptions';
import { genderOptions } from '../../../../constants/genderOptions';
import { Spacings } from '../../../../styles/partials/spacings';
import { FormExampleType } from '../../../../ts/interfaces/forms/example';
import { validationSchema } from './validators/validationSchema';

interface FormProps {
  initialValues: FormExampleType;
  onSubmit: (values: FormExampleType, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => void;
}

export const FormExample = ({ initialValues, onSubmit }: FormProps) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({
      values,
      isValid,
      isSubmitting,
      dirty,
      touched,
      resetForm,
      errors,
      handleChange,
      handleBlur,
      setFieldValue,
    }) => {
      //  TODO: build a getFieldProps func on utils to obtain this props obj
      /*
          const props = {
            onChange={handleChange},
            onBlur: handleBlur,
          };
          */
      return (
        <Form>
          <Box display='flex' flexDirection='column' gap={Spacings.spacing10}>
            {/* Name TextField */}
            <FormControl>
              <AzulTextField
                ariaLabel='name'
                error={Boolean(touched.name && errors.name)}
                helper={errors.name}
                id='name'
                label='Name'
                name='name'
                type='text'
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* Age TextField */}
            <FormControl>
              <AzulTextField
                ariaLabel='age'
                error={Boolean(touched.age && errors.age)}
                helper={errors.age}
                id='age'
                label='Age'
                name='age'
                type='number'
                value={values.age}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* Gender TextField */}
            <FormControl>
              <AzulDropdownMenu
                error={Boolean(touched.gender && errors.gender)}
                label='Gender'
                name='gender'
                options={genderOptions}
                value={values.gender}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* Email TextField */}
            <FormControl>
              <AzulTextField
                ariaLabel='email'
                error={Boolean(touched.email && errors.email)}
                helper={errors.email}
                id='email'
                label='Email'
                name='email'
                type='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* Password TextField */}
            <FormControl>
              <AzulTextField
                ariaLabel='password'
                error={Boolean(touched.password && errors.password)}
                helper={errors.password}
                id='password'
                label='Password'
                name='password'
                type='password'
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            {/* Currency Combobox */}
            <FormControl>
              <AzulCurrencyCombobox
                error={Boolean(touched.salary && errors.salary)}
                helper={errors.salary}
                id='amount'
                label='Amount'
                name='salary'
                options={currencyOptions}
                value={values.salary}
                onBlur={handleBlur}
                onChange={handleChange}
                onClickOption={option => setFieldValue('salaryCurrency', option)}
              />
            </FormControl>
            <Button color='primary' disabled={!isValid || isSubmitting || !dirty} type='submit' variant='contained'>
              Submit
            </Button>
            <Button color='secondary' type='button' variant='outlined' onClick={() => resetForm({})}>
              Reset
            </Button>
          </Box>
        </Form>
      );
    }}
  </Formik>
);
