import { setDepartmentOptions, setDepartmentOptionsRequestStatus } from '.';
import { departmentService } from '../../service/departmentService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllDepartmentOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setDepartmentOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await departmentService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);
      dispatch(setDepartmentOptions(parsedData));

      dispatch(setDepartmentOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setDepartmentOptionsRequestStatus(RequestStatus.Failed));
    }
  };
