import { PayloadAction } from '@reduxjs/toolkit';
import { CandidateState } from '.';
import { CandidateRow } from '../../modules/Candidates/components/Table/CandidateRow';
import { CandidateStage } from '../../ts/enums/CandidateStage';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { ModalHiringTeamType } from '../../ts/interfaces/forms/ModalHiringTeamType';
import { Candidate, CandidateColumn, Note } from '../../ts/types/candidate';

const setCandidate = (state: CandidateState, action: PayloadAction<Candidate>): void => {
  state.candidate = action.payload;
};

const setCandidateRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.candidateRequestStatus = action.payload;
};

const setCandidates = (state: CandidateState, action: PayloadAction<Candidate[]>): void => {
  state.candidates = action.payload;
};

const setCandidatesRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.candidatesRequestStatus = action.payload;
};

const setFirstCandidatesColumn = (state: CandidateState, action: PayloadAction<CandidateColumn[]>): void => {
  state.firstCandidatesColumn = action.payload;
};

const setFirstCandidatesColumnRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.firstCandidatesColumnRequestStatus = action.payload;
};

const setSecondCandidatesColumn = (state: CandidateState, action: PayloadAction<CandidateColumn[]>): void => {
  state.secondCandidatesColumn = action.payload;
};

const setSecondCandidatesColumnRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.secondCandidatesColumnRequestStatus = action.payload;
};

const setThirdCandidatesColumn = (state: CandidateState, action: PayloadAction<CandidateColumn[]>): void => {
  state.thirdCandidatesColumn = action.payload;
};

const setThirdCandidatesColumnRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.thirdCandidatesColumnRequestStatus = action.payload;
};

const setHiringTeam = (state: CandidateState, action: PayloadAction<ModalHiringTeamType>): void => {
  const hiringTeam = action.payload;
  const newCandidate = { ...state.candidate, hiringTeam: { ...state.candidate.hiringTeam, ...hiringTeam } };
  state.candidate = newCandidate;
};

const setCandidatePrivateNotes = (state: CandidateState, action: PayloadAction<Note[]>): void => {
  const newCandidateNotes = [...action.payload];
  state.candidatePrivateNotes = newCandidateNotes;
};

const setNewCandidatePrivateNote = (state: CandidateState, action: PayloadAction<Note>): void => {
  const newCandidateNotes = [...state.candidatePrivateNotes, action.payload];
  state.candidatePrivateNotes = newCandidateNotes;
};

const setCandidatePrivateNotesRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.candidatePrivateNotesRequestStatus = action.payload;
};

const setCandidatePublicNotes = (state: CandidateState, action: PayloadAction<Note[]>): void => {
  state.candidatePublicNotes = [...action.payload];
};

const setNewCandidatePublicNote = (state: CandidateState, action: PayloadAction<Note>): void => {
  const newCandidateNotes = [...state.candidatePublicNotes, action.payload];
  state.candidatePublicNotes = newCandidateNotes;
};

const setCandidatePublicNotesRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.candidatePublicNotesRequestStatus = action.payload;
};

const setCandidateStatus = (
  state: CandidateState,
  action: PayloadAction<{ id: string; status: CandidateStatus }>
): void => {
  const newCandidate: Candidate = { ...state.candidate, status: action.payload.status };
  state.candidate = newCandidate;
};

const setCandidateStage = (
  state: CandidateState,
  action: PayloadAction<{ id: string; stage: CandidateStage }>
): void => {
  const newCandidate: Candidate = { ...state.candidate, stage: action.payload.stage };
  state.candidate = newCandidate;
};

const setCandidatesTable = (state: CandidateState, action: PayloadAction<CandidateRow[]>): void => {
  state.candidatesTable = action.payload;
};

const setNewCandidatesTable = (state: CandidateState, action: PayloadAction<CandidateRow>): void => {
  state.candidatesTable = [...state.candidatesTable, action.payload];
};

const setCandidatesTableCount = (state: CandidateState, action: PayloadAction<number>): void => {
  state.candidatesTableCount = action.payload;
};

const setCandidatesTableRequestStatus = (state: CandidateState, action: PayloadAction<RequestStatus>): void => {
  state.candidatesTableRequestStatus = action.payload;
};

// Combine the reducer functions into an object
const reducers = {
  setCandidate,
  setCandidatePrivateNotes,
  setCandidatePublicNotes,
  setNewCandidatePrivateNote,
  setNewCandidatePublicNote,
  setCandidatePrivateNotesRequestStatus,
  setCandidatePublicNotesRequestStatus,
  setCandidateRequestStatus,
  setCandidates,
  setNewCandidatesTable,
  setCandidatesRequestStatus,
  setFirstCandidatesColumn,
  setFirstCandidatesColumnRequestStatus,
  setSecondCandidatesColumn,
  setSecondCandidatesColumnRequestStatus,
  setThirdCandidatesColumn,
  setThirdCandidatesColumnRequestStatus,
  setHiringTeam,
  setCandidateStatus,
  setCandidateStage,
  setCandidatesTable,
  setCandidatesTableCount,
  setCandidatesTableRequestStatus,
};

export default reducers;
