import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface DepartmentOptions {
  departmentOptions: Option[];
  departmentOptionsRequestStatus: RequestStatus;
}

const initialState: DepartmentOptions = {
  departmentOptions: [],
  departmentOptionsRequestStatus: RequestStatus.Idle,
};

export const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers,
});

export const { setDepartmentOptions, setDepartmentOptionsRequestStatus } = departmentSlice.actions;

export default departmentSlice.reducer;
