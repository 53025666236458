import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface LocationOptions {
  locationOptions: Option[];
  locationOptionsRequestStatus: RequestStatus;
}

const initialState: LocationOptions = {
  locationOptions: [],
  locationOptionsRequestStatus: RequestStatus.Idle,
};

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers,
});

export const { setLocationOptions, setLocationOptionsRequestStatus } = locationSlice.actions;

export default locationSlice.reducer;
