import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, InputAdornment, Popover, inputBaseClasses, outlinedInputClasses } from '@mui/material';
import Button, { buttonClasses } from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { Fragment, MouseEvent, useRef, useState } from 'react';
import { colorPalette } from '../../styles/partials/colors';
import { Hierarchies, Hierarchy } from '../../styles/partials/hierarchies';
import { Spacings } from '../../styles/partials/spacings';
import { AzulCurrencyComboboxProps } from '../../ts/interfaces/AzulCurrencyCombobox';
import { AzulOptions } from '../AzulOptions';

/**
 * Represents a customized currency combobox component.
 *
 * @param label - The label text for the combobox.
 * @param options - The array of currency options for the combobox.
 * @param ariaLabel - The ARIA label for the combobox. (Optional)
 * @param disabled - Indicates whether the combobox is disabled. (Optional)
 * @param error - Indicates whether the combobox has an error. (Optional)
 * @param helper - The helper text for the combobox. (Optional)
 * @param id - The id attribute for the combobox. (Optional)
 * @param name - The name attribute for the combobox. (Optional)
 * @param placeholder - The placeholder text for the combobox. (Optional)
 * @param value - The value of the combobox. (Optional)
 * @param onClickOption - Event handler for the option click event.
 * @param onBlur - Event handler for the combobox's blur event. (Optional)
 * @param onChange - Event handler for the combobox's change event. (Optional)
 */

export function AzulCurrencyCombobox({
  ariaLabel,
  disabled,
  error,
  helper,
  id,
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  onClickOption,
  onBlur,
}: AzulCurrencyComboboxProps) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuAnchorOrigin, setMenuAnchorOrigin] = useState<number>(0);

  const handleClickOption = (option: string) => {
    const index = options.findIndex(selection => selection.value === option);
    setSelectedIndex(index);
    onClickOption(option);
    setAnchorEl(null);
  };

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorOrigin(event.currentTarget.offsetTop + event.currentTarget.offsetHeight + 4);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box display='flex' ref={anchorRef}>
        <TextField
          InputLabelProps={{
            sx: {
              '&.Mui-focused': {
                paddingX: Spacings.spacing4,
                marginLeft: '-6px', //solution for uniformly overlay the label (shrunk to the bottom left) over the outline and border of the textfield when focused
              },
            },
          }}
          InputProps={{
            inputMode: 'numeric',
            placeholder: placeholder,
            endAdornment: error && (
              <InputAdornment position='end'>
                <ErrorRoundedIcon sx={{ color: colorPalette.destructiveRed2 }} />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: disabled ? colorPalette.disabledGray1 : colorPalette.backgroundWhite,
              [`&.${outlinedInputClasses.root}`]: {
                height: Spacings.spacing12,
                borderTopRightRadius: Spacings.spacing0,
                borderBottomRightRadius: Spacings.spacing0,
                '&.Mui-focused ': {
                  borderColor: error ? colorPalette.destructiveRed2 : colorPalette.primaryBlue,
                  outline: error ? 'none' : `${Spacings.spacing3}px solid ${colorPalette.focusPurple}`,
                },
                '&:hover fieldset': {
                  borderColor: error
                    ? colorPalette.destructiveRed2
                    : disabled
                    ? colorPalette.disabledGray2
                    : colorPalette.primaryBlue,
                },
              },
              [`&.${inputBaseClasses.input}`]: {
                '&::placeholder': {
                  color: colorPalette.paragraph,
                  opacity: '1',
                },
              },
            },
          }}
          aria-label={ariaLabel}
          disabled={disabled}
          error={error}
          fullWidth
          helperText={helper}
          id={id}
          label={label}
          name={name}
          type='number'
          value={value}
          variant='outlined'
          onBlur={onBlur}
          onChange={onChange}
        />
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open}
          aria-haspopup='menu'
          aria-label='Select currency'
          disabled={disabled}
          sx={{
            borderTopLeftRadius: Spacings.spacing0,
            borderBottomLeftRadius: Spacings.spacing0,
            borderTopRightRadius: Spacings.spacing4,
            borderBottomRightRadius: Spacings.spacing4,
            height: Spacings.spacing12,
            textTransform: 'none',
            [`&.${buttonClasses.root}`]: {
              '&.Mui-disabled': {
                borderColor: colorPalette.disabledGray2,
              },
            },
          }}
          variant='primary'
          onClick={handleClickButton}
        >
          {options[selectedIndex]?.label || 'Label'}
          <ExpandMoreRoundedIcon />
        </Button>
      </Box>
      <Popover
        PaperProps={{
          style: {
            boxShadow: `${Hierarchies[Hierarchy.Hierarchy2].shadow} rgba(${Hierarchies[Hierarchy.Hierarchy2].rgba})`,
            height: '212px',
            overflow: 'hidden',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: menuAnchorOrigin,
          horizontal: 'right',
        }}
        id='menuOptions'
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <AzulOptions options={options} showIconInline={true} onClickOption={handleClickOption} />
      </Popover>
    </Fragment>
  );
}
