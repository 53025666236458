import { setJobTimeOptions, setJobTimeOptionsRequestStatus } from '.';
import { jobTimeService } from '../../service/jobTimeService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllJobTimeOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setJobTimeOptionsRequestStatus(RequestStatus.Loading));

      const { data } = await jobTimeService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setJobTimeOptions(parsedData));

      dispatch(setJobTimeOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setJobTimeOptionsRequestStatus(RequestStatus.Failed));
    }
  };
