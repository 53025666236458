import { PayloadAction } from '@reduxjs/toolkit';
import { OverviewState } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Overview } from '../../ts/types/Overview';

const setOverview = (state: OverviewState, action: PayloadAction<Overview>): void => {
    state.overview = action.payload;
};

const setOverviewRequestStatus = (state: OverviewState, action: PayloadAction<RequestStatus>): void => {
    state.overviewRequestStatus = action.payload;
};

// Combine the reducer functions into an object
const reducers = {
    setOverview,
    setOverviewRequestStatus,
};

export default reducers;
