import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';
import reducers from './reducer';

export interface SalaryPeriodOptions {
  salaryPeriodOptions: Option[];
  salaryPeriodOptionsRequestStatus: RequestStatus;
}

const initialState: SalaryPeriodOptions = {
  salaryPeriodOptions: [],
  salaryPeriodOptionsRequestStatus: RequestStatus.Idle,
};

export const salaryPeriodSlice = createSlice({
  name: 'salaryPeriods',
  initialState,
  reducers,
});

export const { setSalaryPeriodsOptions, setSalaryPeriodOptionsRequestStatus } = salaryPeriodSlice.actions;

export default salaryPeriodSlice.reducer;
