import { PayloadAction } from '@reduxjs/toolkit';
import { ManagerOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setManagerOptions = (state: ManagerOptions, action: PayloadAction<Option[]>): void => {
  state.managerOptions = action.payload;
};

const setManagerOptionsRequestStatus = (state: ManagerOptions, action: PayloadAction<RequestStatus>): void => {
  state.managerOptionsRequestStatus = action.payload;
};

const reducers = {
  setManagerOptions,
  setManagerOptionsRequestStatus,
};

export default reducers;
