import { RootState } from '../store';

export const selectCandidate = (state: RootState) => state.candidate.candidate;
export const selectCandidatePrivateNotes = (state: RootState) => state.candidate.candidatePrivateNotes;
export const selectCandidatePrivateNotesRequestStatus = (state: RootState) =>
  state.candidate.candidatePrivateNotesRequestStatus;
export const selectCandidatePublicNotes = (state: RootState) => state.candidate.candidatePublicNotes;
export const selectCandidatePublicNotesRequestStatus = (state: RootState) =>
  state.candidate.candidatePublicNotesRequestStatus;
export const selectCandidateRequestStatus = (state: RootState) => state.candidate.candidateRequestStatus;
export const selectCandidates = (state: RootState) => state.candidate.candidates;
export const selectCandidatesRequestStatus = (state: RootState) => state.candidate.candidatesRequestStatus;
export const selectFirstCandidatesColumn = (state: RootState) => state.candidate.firstCandidatesColumn;
export const selectFirstCandidatesColumnRequestStatus = (state: RootState) =>
  state.candidate.firstCandidatesColumnRequestStatus;
export const selectSecondCandidatesColumn = (state: RootState) => state.candidate.secondCandidatesColumn;
export const selectSecondCandidatesColumnRequestStatus = (state: RootState) =>
  state.candidate.secondCandidatesColumnRequestStatus;
export const selectThirdCandidatesColumn = (state: RootState) => state.candidate.thirdCandidatesColumn;
export const selectThirdCandidatesColumnRequestStatus = (state: RootState) =>
  state.candidate.thirdCandidatesColumnRequestStatus;
export const selectCandidatesTable = (state: RootState) => state.candidate.candidatesTable;
export const selectCandidatesTableCount = (state: RootState) => state.candidate.candidatesTableCount;
export const selectCandidatesTableRequestStatus = (state: RootState) => state.candidate.candidatesTableRequestStatus;
