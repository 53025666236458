import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import React from 'react';
import { colorPalette } from '../styles/partials/colors';
import { Spacings } from '../styles/partials/spacings';
import { CandidateStatus } from '../ts/enums/CandidateStatus';

const statusColors: { [key: string]: string } = {
  Pipeline: colorPalette.accentYellow,
  Active: colorPalette.accentGreen1,
  Hold: colorPalette.primaryHoverBlue2,
  Hired: colorPalette.secondaryBlue,
  'No Response': colorPalette.disabledGray2,
  Declined: colorPalette.destructiveRed2,
  Withdrew: colorPalette.destructiveRed3,
  Rejected: colorPalette.destructiveRed1,
};

export const renderStatusIcon = (status: CandidateStatus) => {
  const commonStyle = { height: `${Spacings.spacing5}px`, verticalAlign: 'middle' };
  const color = statusColors[status] || colorPalette.accentYellow;

  return status === CandidateStatus.Contacted ? (
    <CircleOutlinedIcon sx={{ ...commonStyle }} />
  ) : (
    <CircleRoundedIcon sx={{ ...commonStyle, color }} />
  );
};
