import { PayloadAction } from '@reduxjs/toolkit';
import { NotificationState } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Notification } from '../../ts/types/Notification';

const setNotifications = (state: NotificationState, action: PayloadAction<Notification[]>): void => {
  state.notifications = action.payload;
};

const setNotificationsRequestStatus = (state: NotificationState, action: PayloadAction<RequestStatus>): void => {
  state.notificationsRequestStatus = action.payload;
};

const setNotificationsCount = (state: NotificationState, action: PayloadAction<number>): void => {
  state.notificationsCount = action.payload;
};

const reducers = {
  setNotifications,
  setNotificationsRequestStatus,
  setNotificationsCount,
};

export default reducers;
