import { setSalaryPeriodOptionsRequestStatus, setSalaryPeriodsOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

const data = [
  { id: '1', title: 'Hourly' },
  { id: '2', title: 'Monthly' },
  { id: '3', title: 'Yearly' },
];

export const getAllSalaryPeriodOptions = (): AppThunk => async dispatch => {
  try {
    dispatch(setSalaryPeriodOptionsRequestStatus(RequestStatus.Loading));

    setTimeout(() => {
      const parsedData = parseDataToOptions(data);

      dispatch(setSalaryPeriodsOptions(parsedData));

      dispatch(setSalaryPeriodOptionsRequestStatus(RequestStatus.Success));
    }, 2000);
  } catch (error) {
    dispatch(setSalaryPeriodOptionsRequestStatus(RequestStatus.Failed));
  }
};
