import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('The First name field is required'),
  email: yup.string().email('Invalid email format').required('The Email field is required'),
  lastName: yup.string().required('The Last name field is required'),
  country: yup.string().required('The Country field is required'),
  jobPosition: yup.string().required('The Job position field is required'),
  recruiter: yup.string().required('The Recruiter field is required'),
  status: yup.string().required('The Status field is required'),
  stage: yup.string().required('The Stage field is required'),
  cvFile: yup.mixed().required('CV file is required'),
});
