import {
  setCandidate,
  setCandidatePrivateNotes,
  setCandidatePrivateNotesRequestStatus,
  setCandidatePublicNotes,
  setCandidatePublicNotesRequestStatus,
  setCandidateRequestStatus,
  setCandidates,
  setCandidatesRequestStatus,
  setCandidatesTable,
  setCandidatesTableCount,
  setCandidatesTableRequestStatus,
  setFirstCandidatesColumn,
  setFirstCandidatesColumnRequestStatus,
  setNewCandidatePrivateNote,
  setNewCandidatePublicNote,
  setNewCandidatesTable,
  setSecondCandidatesColumn,
  setSecondCandidatesColumnRequestStatus,
  setThirdCandidatesColumn,
  setThirdCandidatesColumnRequestStatus,
} from '.';
import { candidateService } from '../../service/candidateService';
import { documentService } from '../../service/documentService';
import { fileService } from '../../service/fileService';
import { interviewService } from '../../service/interviewService';
import { jobPositionService } from '../../service/jobPositionService';
import { newCandidateService } from '../../service/newCandidateService';
import { CandidateStage } from '../../ts/enums/CandidateStage';
import { CandidateStatus } from '../../ts/enums/CandidateStatus';
import { FileType } from '../../ts/enums/FileType';
import { NoteType } from '../../ts/enums/NoteType';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { ModalNewCandidateType } from '../../ts/interfaces/forms/ModalNewCandidateType';
import { DocumentModel } from '../../ts/interfaces/models/DocumentModel';
import { SessionUser } from '../../ts/types/sessionUser';
import {
  parseCandidatesColumn,
  parseModalNewCandidateTypeToCandidatePostModel,
  parseNewCandidatesModelArrayToCandidateArray,
} from '../../utils/parsers/candidate';
import { parseModalNewCandidateTypeToInterviewPostModel } from '../../utils/parsers/interview';
import { setJobPositionCandidates, setSelectedJobPosition } from '../jobPosition';
import { AppThunk } from '../store';
import {
  parseCandidateModelToCandidateRow,
  parseCandidateNotes,
  parseCandidatesModelArrayToCandidateArray,
  parseCandidatesModelArrayToCandidateRow,
  parseNewCandidateModelToCandidate,
} from './../../utils/parsers/candidate';
import { notesMockData } from './mockData';

export const getCandidatesTable = (): AppThunk => async dispatch => {
  try {
    dispatch(setCandidatesTableRequestStatus(RequestStatus.Loading));

    const { data, count } = await newCandidateService.getAll();
    const parsedData = parseCandidatesModelArrayToCandidateRow(data);

    dispatch(setCandidatesTable(parsedData));
    dispatch(setCandidatesTableCount(count));

    dispatch(setCandidatesTableRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setCandidatesTableRequestStatus(RequestStatus.Failed));
  }
};

export const getAllCandidates = (): AppThunk => async dispatch => {
  try {
    dispatch(setCandidatesRequestStatus(RequestStatus.Loading));

    const { data } = await candidateService.getAll();
    const parsedData = parseCandidatesModelArrayToCandidateArray(data);

    dispatch(setCandidates(parsedData));

    dispatch(setCandidatesRequestStatus(RequestStatus.Success));
  } catch (error) {
    dispatch(setCandidatesRequestStatus(RequestStatus.Failed));
  }
};

export const getCandidatesByJobId =
  (jobId: string, setJobPositionAsSelected?: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(setCandidatesRequestStatus(RequestStatus.Loading));

      if (setJobPositionAsSelected) {
        dispatch(setSelectedJobPosition(jobId));
      }
      const { data } = await jobPositionService.getAllJobCandidates(jobId);

      const parsedData = parseNewCandidatesModelArrayToCandidateArray(data);
      dispatch(setJobPositionCandidates({ id: jobId, candidates: parsedData }));

      dispatch(setCandidatesRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setCandidatesRequestStatus(RequestStatus.Failed));
    }
  };

export const getCandidateById =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setCandidateRequestStatus(RequestStatus.Loading));

      const { data } = await newCandidateService.getById(id);
      const parsedData = parseNewCandidateModelToCandidate(data);
      dispatch(setCandidate(parsedData));
      dispatch(setCandidateRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setCandidateRequestStatus(RequestStatus.Failed));
    }
  };

export const updateCandidateStatus =
  (id: string, newStatus: CandidateStatus): AppThunk =>
  async dispatch => {
    try {
      await newCandidateService.update(id, { status: newStatus });

      dispatch(setCandidateRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setCandidateRequestStatus(RequestStatus.Failed));
    }
  };

export const updateCandidateStage =
  (id: string, newStage: CandidateStage): AppThunk =>
  async dispatch => {
    try {
      await newCandidateService.updateCandidateStage(id, { stage: newStage });

      dispatch(setCandidateRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setCandidateRequestStatus(RequestStatus.Failed));
    }
  };

export const getFirstCandidateColumn =
  (stage: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFirstCandidatesColumnRequestStatus(RequestStatus.Loading));

      const { data } = await newCandidateService.getCandidateByStage(stage);

      const parsedData = parseCandidatesColumn(data);

      dispatch(setFirstCandidatesColumn(parsedData));

      dispatch(setFirstCandidatesColumnRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setFirstCandidatesColumnRequestStatus(RequestStatus.Failed));
    }
  };

export const getSecondCandidateColumn =
  (stage: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setSecondCandidatesColumnRequestStatus(RequestStatus.Loading));

      const { data } = await newCandidateService.getCandidateByStage(stage);

      const parsedData = parseCandidatesColumn(data);

      dispatch(setSecondCandidatesColumn(parsedData));

      dispatch(setSecondCandidatesColumnRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setSecondCandidatesColumnRequestStatus(RequestStatus.Failed));
    }
  };

export const getThirdCandidateColumn =
  (stage: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setThirdCandidatesColumnRequestStatus(RequestStatus.Loading));

      const { data } = await newCandidateService.getCandidateByStage(stage);

      const parsedData = parseCandidatesColumn(data);

      dispatch(setThirdCandidatesColumn(parsedData));

      dispatch(setThirdCandidatesColumnRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setThirdCandidatesColumnRequestStatus(RequestStatus.Failed));
    }
  };

// Thunk function to create a new candidate
export const createNewCandidate =
  (candidateInfo: ModalNewCandidateType): AppThunk =>
  async dispatch => {
    try {
      // Set loading status before making API calls
      dispatch(setCandidatesRequestStatus(RequestStatus.Loading));

      // =========================================================================
      // Create new candidate in the database
      const candidateBody = parseModalNewCandidateTypeToCandidatePostModel(candidateInfo);
      const { data: candidateData } = await newCandidateService.createNewCandidate(
        candidateBody,
        candidateInfo.jobPosition
      );

      // =========================================================================
      // Create new Interview instance for the candidate
      const interviewBody = parseModalNewCandidateTypeToInterviewPostModel(candidateInfo, candidateData);
      await interviewService.create(interviewBody);

      // =========================================================================
      // If candidateInfo.cvFile is provided, upload CV file and create related DocumentModel
      if (candidateInfo.cvFile) {
        // Create new File instance for CV
        const cvFileBody = {
          type: FileType.Documents,
          fileName: candidateInfo.cvFile.name,
        };
        const { data: cvFileData } = await fileService.create(cvFileBody);

        // Upload CV file to the storage
        await fileService.updateFile(cvFileData.uploadUrl, candidateInfo.cvFile);

        // Create new DocumentModel for CV
        const cvDocumentBody: DocumentModel = {
          candidate_id: candidateData.id,
          file_id: String(cvFileData.id),
          file_type: candidateInfo.cvFile.type,
        };
        await documentService.create(cvDocumentBody);
      }

      // =========================================================================
      // If candidateInfo.portfolioFile is provided, upload portfolio file and create related DocumentModel
      if (candidateInfo.portfolioFile) {
        // Create new File instance for portfolio
        const portfolioFileBody = {
          type: FileType.Documents,
          fileName: candidateInfo.portfolioFile.name,
        };
        const { data: portfolioFileData } = await fileService.create(portfolioFileBody);

        // Upload portfolio file to the storage
        await fileService.updateFile(portfolioFileData.uploadUrl, candidateInfo.portfolioFile);

        // Create new DocumentModel for portfolio
        const portfolioDocumentBody: DocumentModel = {
          candidate_id: candidateData.id,
          file_id: String(portfolioFileData.id),
          file_type: candidateInfo.portfolioFile.type,
        };
        await documentService.create(portfolioDocumentBody);
      }

      // =========================================================================
      // Fetch the updated candidate data from the database
      // TODO: Update backend to remove this step
      const { data: finalResponse } = await newCandidateService.getById(Number(candidateData.id));
      const parsedFinalResponse = parseCandidateModelToCandidateRow(finalResponse);

      // Update the candidates table with the new candidate data
      dispatch(setNewCandidatesTable(parsedFinalResponse));
      dispatch(setCandidatesRequestStatus(RequestStatus.Success));
    } catch (error) {
      // If an error occurs during the process, set the request status to failed
      dispatch(setCandidatesRequestStatus(RequestStatus.Failed));
    }
  };

export const getCandidatePrivateNotes =
  (candidateId: string, user: SessionUser): AppThunk =>
  // TODO: User parameter will be removed and replaced with the user of the active session
  async dispatch => {
    try {
      dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Loading));

      // Simulating a delay to mimic a request to the database
      setTimeout(() => {
        // Parsing the mock data into the desired format
        const filteredMockData = notesMockData.filter(
          note => note.candidate_id === candidateId && note.user?.id === user.id && note.note_type === NoteType.Private
        );
        const parsedData = parseCandidateNotes(filteredMockData, user.id ?? '');

        dispatch(setCandidatePrivateNotes(parsedData));

        dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Success));
      }, 500); // Delay of 2000 milliseconds (2 seconds)
    } catch (error) {
      dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Failed));
    }
  };

export const createNewPrivateNote =
  (user: SessionUser, comment: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Loading));

      // Simulating a delay to mimic a request to the database
      setTimeout(() => {
        const newNote = { userId: user.id, name: 'Me', comment, createdAt: new Date() };

        dispatch(setNewCandidatePrivateNote(newNote));

        dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Success));
      }, 500); // Delay of 2000 milliseconds (2 seconds)
    } catch (error) {
      dispatch(setCandidatePrivateNotesRequestStatus(RequestStatus.Failed));
    }
  };

export const getCandidatePublicNotes =
  (candidateId: string, user: SessionUser): AppThunk =>
  async dispatch => {
    try {
      dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Loading));

      // Simulating a delay to mimic a request to the database
      setTimeout(() => {
        // Parsing the mock data into the desired format
        const filteredMockData = notesMockData.filter(
          note => note.candidate_id === candidateId && note.note_type === NoteType.Public
        );
        const parsedData = parseCandidateNotes(filteredMockData, user.id ?? '');

        dispatch(setCandidatePublicNotes(parsedData));

        dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Success));
      }, 500); // Delay of 2000 milliseconds (2 seconds)
    } catch (error) {
      dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Failed));
    }
  };

export const createNewPublicNote =
  (user: SessionUser, comment: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Loading));

      // Simulating a delay to mimic a request to the database
      setTimeout(() => {
        const newNote = { userId: user.id, name: 'Me', comment, createdAt: new Date() };

        dispatch(setNewCandidatePublicNote(newNote));

        dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Success));
      }, 500); // Delay of 2000 milliseconds (2 seconds)
    } catch (error) {
      dispatch(setCandidatePublicNotesRequestStatus(RequestStatus.Failed));
    }
  };
