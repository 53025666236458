import { UserRoles } from '../../enums/UserRoles';

export type ModalNewUserType = {
  email: string;
  firstName: string;
  lastName: string;
  department?: string;
  location?: string;
  role?: UserRoles | string;
};

export const emptyValues = {
  department: '',
  email: '',
  firstName: '',
  lastName: '',
  location: '',
  role: '',
};
