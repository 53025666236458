import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  client: Yup.string().required('Client is required'),
  department: Yup.string().required('Department is required'),
  description: Yup.string().required('Description is required'),
  hiringManager: Yup.string().required('Hiring manager is required'),
  jobPositionVisible: Yup.boolean(),
  jobTime: Yup.string().required('Job time is required'),
  jobTitle: Yup.string().required('Job title is required'),
  jobType: Yup.string().required('Job type is required'),
  location: Yup.string().required('Location is required'),
  manager: Yup.string().required('Manager is required'),
  remote: Yup.boolean(),
  requiredPositions: Yup.string(),
  salaryPeriod: Yup.string().required('Salary period is required'),
  salaryRange: Yup.number().moreThan(0, 'Salary range must be more than 0').required('Salary Range is required'),
  salaryCurrency: Yup.string().required('Salary currency is required'),
  salaryVisible: Yup.boolean(),
  seniority: Yup.string().required('Seniority is required'),
  skills: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required('Skill value is required'),
      label: Yup.string().required('Skill label is required'),
    })
  ),
  status: Yup.string().required('Status is required'),
  urgent: Yup.boolean(),
});
