import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import { CardActions, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, useState } from 'react';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulChip } from '../../../../components/AzulChip';
import { AzulModal } from '../../../../components/AzulModal';
import { AzulScroll } from '../../../../components/AzulScroll';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';
import { ModalJobDetailsProps } from '../../../../ts/interfaces/ModalJobDetails';

export const ModalJobDetails: FC<ModalJobDetailsProps> = ({ isOpen, onClose, candidate }) => {
  const { jobPosition } = candidate;
  const characterLimit = 170;
  const isExceedingLimit = jobPosition.description.length > characterLimit;
  const [isTruncated, setIsTruncated] = useState<boolean>(isExceedingLimit);
  const metadata = [
    { title: 'Job type', value: jobPosition.jobType },
    { title: 'Job time', value: jobPosition.jobTime },
    { title: 'Client', value: jobPosition.client },
  ];

  const metadataList = metadata.map(({ title, value }, index) => (
    <Grid item key={`${title}-${value}-${index}`} paddingTop={Spacings.spacing0} xs={10}>
      <Box display={'flex'}>
        <Typography color={colorPalette.paragraphBaseline} variant='body1-medium'>
          {`${title}:`}
        </Typography>
        <Typography color={colorPalette.paragraphBaseline} paddingLeft={Spacings.spacing1} variant='body1'>
          {value}
        </Typography>
      </Box>
    </Grid>
  ));

  const skillsList = jobPosition.skills.map((label, index) => (
    <Grid item key={`${index}-${label}`}>
      <AzulChip label={label} />
    </Grid>
  ));

  const truncatedText = `${jobPosition.description.slice(0, characterLimit)}...`;
  const content = isTruncated && isExceedingLimit ? truncatedText : jobPosition.description;

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <AzulModal isOpen={isOpen} title='Job details' onClose={onClose}>
      <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
        <Box flex={1}>
          <Typography variant='h3'>{jobPosition.title}</Typography>
          <Typography
            color={colorPalette.primaryBlue}
            display={'flex'}
            gap={3}
            marginY={Spacings.spacing4}
            paddingLeft={Spacings.spacing3}
            variant='body2-bold'
          >
            ID:
            <Typography color={colorPalette.paragraphBaseline} variant='body2'>
              {jobPosition.id}
            </Typography>
          </Typography>
          <Box alignItems={'center'} columnGap={Spacings.spacing1} display={'flex'}>
            <FmdGoodRoundedIcon sx={{ color: colorPalette.primaryBlue }} />
            <Typography alignItems={'center'} columnGap={Spacings.spacing1} display={'flex'} variant='body1'>
              {jobPosition.location}
            </Typography>
          </Box>
          <Box alignItems={'center'} columnGap={Spacings.spacing1} display={'flex'} marginY={Spacings.spacing4}>
            <AttachMoneyRoundedIcon sx={{ color: colorPalette.primaryBlue }} />
            <Typography variant='body2'>{`${jobPosition.lowerPriceRange} - ${jobPosition.upperPriceRange}`}</Typography>
          </Box>
          <Grid container maxWidth={'100%'} spacing={Spacings.spacing2}>
            {metadataList}
          </Grid>
          <Box marginY={Spacings.spacing9} minHeight={100}>
            <Typography
              color={colorPalette.paragraphBaseline}
              marginBottom={Spacings.spacing3}
              textTransform={'uppercase'}
              variant='subtitle2'
            >
              Skills
            </Typography>
            <Grid container spacing={Spacings.spacing4} wrap='wrap'>
              {skillsList}
            </Grid>
          </Box>
        </Box>
        <Box flex={2} maxHeight={288}>
          <Typography variant='subtitle2'>JOB DESCRIPTION</Typography>
          {isExceedingLimit ? (
            <AzulScroll height={170}>
              <Box maxHeight={288}>
                <Typography variant='body1'>{content}</Typography>
              </Box>
            </AzulScroll>
          ) : (
            <Typography variant='body1'>{content}</Typography>
          )}
          {isExceedingLimit && (
            <CardActions sx={{ paddingLeft: Spacings.spacing4 }}>
              <AzulButton
                label={isTruncated ? 'See more' : 'See less'}
                startIcon={
                  <AddRoundedIcon
                    sx={{
                      height: Spacings.spacing8,
                      width: Spacings.spacing8,
                    }}
                  />
                }
                variant='tertiary'
                onClick={toggleTruncated}
              />
            </CardActions>
          )}
        </Box>
      </Box>
    </AzulModal>
  );
};
