import { setLocationOptions, setLocationOptionsRequestStatus } from '.';
import { locationService } from '../../service/locationService';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { parseDataToOptions } from '../../utils/parsers/option/options';
import { AppThunk } from '../store';

export const getAllLocationOptions =
  (useIdAsValue = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setLocationOptionsRequestStatus(RequestStatus.Loading));
      const { data } = await locationService.getAll();
      const parsedData = parseDataToOptions(data, false, useIdAsValue);

      dispatch(setLocationOptions(parsedData));

      dispatch(setLocationOptionsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setLocationOptionsRequestStatus(RequestStatus.Failed));
    }
  };
