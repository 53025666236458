import { PayloadAction } from '@reduxjs/toolkit';
import { CountryOptions } from '.';
import { RequestStatus } from '../../ts/enums/RequestStatus';
import { Option } from '../../ts/interfaces/AzulOption';

const setCountryOptions = (state: CountryOptions, action: PayloadAction<Option[]>): void => {
  state.countryOptions = action.payload;
};

const setCountryOptionsRequestStatus = (state: CountryOptions, action: PayloadAction<RequestStatus>): void => {
  state.countryOptionsRequestStatus = action.payload;
};

const reducers = {
  setCountryOptions,
  setCountryOptionsRequestStatus,
};

export default reducers;
