import { Box, Popover, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emptyState04 from '../../../../assets/emptyState04.svg';
import { AzulButton } from '../../../../components/AzulButton';
import { AzulChip } from '../../../../components/AzulChip';
import { AzulEmptyState } from '../../../../components/AzulEmptyState';
import { AzulScroll } from '../../../../components/AzulScroll';
import { AzulSkeleton } from '../../../../components/AzulSkeleton';
import { MAIN_ROUTES } from '../../../../constants/routes';
import { selectNotifications, selectNotificationsRequestStatus } from '../../../../store/notification/selectors';
import {
  getAllPopoverNotificationPage,
  getAllPopoverUnreadNotificationPage,
} from '../../../../store/notification/thunks';
import { colorPalette } from '../../../../styles/partials/colors';
import { Spacings } from '../../../../styles/partials/spacings';
import { NotificationFilter } from '../../../../ts/enums/NotificationFilter';
import { RequestStatus } from '../../../../ts/enums/RequestStatus';
import { NotificationPopoverProps } from '../../../../ts/interfaces/NotificationPopover';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks/storeHooks';
import { Notification } from '../Notifications/Notification';

export const NotificationPopover = ({ popoverOpen, anchorEl, onClose }: NotificationPopoverProps) => {
  const userId = 1;
  const limit = 10;
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState(NotificationFilter.All);
  const globalNotifications = useAppSelector(selectNotifications);
  const notificationsRequestStatus = useAppSelector(selectNotificationsRequestStatus);
  const navigate = useNavigate();
  const isLoading = notificationsRequestStatus === RequestStatus.Loading;
  const [visibleNotifications, setVisibleNotifications] = useState(globalNotifications);

  const handleFilterClick = async (newFilter: NotificationFilter) => {
    setFilter(newFilter);
    let response;
    if (newFilter === NotificationFilter.All) {
      response = await dispatch(getAllPopoverNotificationPage(userId, limit, 0));
    } else if (newFilter === NotificationFilter.Unread) {
      response = await dispatch(getAllPopoverUnreadNotificationPage(userId, limit, 0));
    }
    if (response) {
      setVisibleNotifications(response.parsedData);
    }
  };

  const handleOnClickViewMore = () => {
    navigate(MAIN_ROUTES.NOTIFICATIONS);
    onClose();
  };

  useEffect(() => {
    if (popoverOpen) {
      handleFilterClick(NotificationFilter.All);
    }
  }, [dispatch, popoverOpen]);

  useEffect(() => {
    setVisibleNotifications(globalNotifications);
  }, [globalNotifications]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={popoverOpen}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <Box display='flex' flexDirection='column' maxWidth={'309px'}>
        <Box display={'flex'} flexDirection={'column'} padding={Spacings.spacing6}>
          <Typography color={colorPalette.accentBlue2} variant='h3'>
            Notifications
          </Typography>
          <Box display={'flex'} gap={Spacings.spacing8} marginTop={'12px'}>
            <AzulChip
              disabled={isLoading}
              label='All'
              selected={filter === NotificationFilter.All}
              onClick={() => handleFilterClick(NotificationFilter.All)}
            />
            <AzulChip
              disabled={isLoading}
              label='Not read'
              selected={filter === NotificationFilter.Unread}
              onClick={() => handleFilterClick(NotificationFilter.Unread)}
            />
          </Box>
        </Box>
        <AzulScroll height={103}>
          {isLoading ? (
            <Stack minWidth={'309px'} spacing={Spacings.spacing2}>
              <AzulSkeleton height={88} />
              <AzulSkeleton height={88} />
              <AzulSkeleton height={88} />
              <AzulSkeleton height={88} />
            </Stack>
          ) : (
            <Box marginBottom={Spacings.spacing5} maxHeight={'440px'}>
              {visibleNotifications.length ? (
                visibleNotifications.map(({ createdAt, notRead, title }, index) => (
                  <Notification
                    createdAt={createdAt}
                    isColumn={true}
                    key={`${index}-${title}`}
                    notRead={notRead}
                    title={title}
                  />
                ))
              ) : (
                <Box paddingX={Spacings.spacing6}>
                  <AzulEmptyState
                    image={emptyState04}
                    marginTop={85}
                    title={"We don't have any new notifications for you now"}
                  />
                </Box>
              )}
            </Box>
          )}
        </AzulScroll>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          marginTop={Spacings.spacing5}
          paddingBottom={Spacings.spacing6}
          paddingRight={Spacings.spacing6}
        >
          <AzulButton disabled={isLoading} label='View more' variant='tertiary' onClick={handleOnClickViewMore} />
        </Box>
      </Box>
    </Popover>
  );
};
